import { z } from 'zod'

import { PASStatus } from '../../domain/PAS.domain'
import { ProjectCategorySchema, ProjectSubpartSchema } from '../../domain/project.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders } from '../common'

export const PASSchema = z.object({
    id: z.number(),
    name: z.string(),
    status: z.nativeEnum(PASStatus),
    poc: z.number(),
    ewd: z.number(),
    user: z.any(),
})

export const pasApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listPASSummary: buildQuery({
                input: z.object({ projectId: z.number() }),
                output: z.any(),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/pas/summary`,
                    method: 'GET',
                }),
                providesTags: ['PASSummary'],
            }),
            editPASSubjectRemark: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    remark: z.string(),
                }),
                output: emptyContent(),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/remark`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['PASSummary'],
            }),
            listPAS: buildQuery({
                input: z.object({ projectId: z.number(), subjectId: z.number() }),
                output: z.any(),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/pas/${subjectId}`,
                    method: 'GET',
                }),
                providesTags: ['PAS'],
            }),
            editPAS: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(z.object({ id: z.number() }))
                    .merge(PASSchema.pick({ status: true, poc: true, ewd: true }))
                    .partial()
                    .merge(z.object({ userId: z.number() }))
                    .partial(),
                output: z.any(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['PAS', 'PASSummary', 'DashboardStats'],
            }),
            addPASRemark: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number(), content: z.string() })
                    .merge(z.object({ id: z.number() }))
                    .merge(z.object({ content: z.string() })),
                output: z.any(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/${id}/remark`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['PAS'],
            }),
            deletePASRemark: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    id: z.number(),
                    remarkId: z.number(),
                }),
                output: z.any(),
                query: ({ projectId, subjectId, id, remarkId }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/${id}/remark/${remarkId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['PAS'],
            }),
            listPASCategoriesBySubjectId: buildQuery({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: ListResponseSchema(ProjectCategorySchema),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/category`,
                    method: 'GET',
                }),
            }),
            addPASSubpart: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    categoryId: z.number(),
                    name: z.string(),
                }),
                output: ProjectSubpartSchema.pick({ id: true }),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/subpart`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['PAS', 'DashboardStats'],
            }),
            bulkAssignPASSubject: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    userId: z.number(),
                }),
                output: emptyContent(),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/bulk-assign`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['PAS'],
            }),

            exportPASXlsx: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/export-xlsx`,
                    method: 'POST',
                }),
            }),
            exportPASPdf: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/export-pdf`,
                    method: 'POST',
                }),
            }),
            exportPASHtml: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/pas/${subjectId}/export-html`,
                    method: 'POST',
                }),
            }),

            exportPASSummaryXlsx: buildMutation({
                input: z.object({
                    projectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/pas/export-xlsx`,
                    method: 'POST',
                }),
            }),
            exportPASSummaryPdf: buildMutation({
                input: z.object({
                    projectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/pas/export-pdf`,
                    method: 'POST',
                }),
            }),
            exportPASSummaryHtml: buildMutation({
                input: z.object({
                    projectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/pas/export-html`,
                    method: 'POST',
                }),
            }),
        }
    },
})

export const {
    useListPASSummaryQuery,
    useEditPASSubjectRemarkMutation,
    useListPASQuery,
    useEditPASMutation,
    useAddPASRemarkMutation,
    useDeletePASRemarkMutation,
    useListPASCategoriesBySubjectIdQuery,
    useAddPASSubpartMutation,
    useBulkAssignPASSubjectMutation,

    useExportPASXlsxMutation,
    useExportPASPdfMutation,
    useExportPASHtmlMutation,

    useExportPASSummaryXlsxMutation,
    useExportPASSummaryPdfMutation,
    useExportPASSummaryHtmlMutation,
} = pasApi
