import {
    mdiCheck,
    mdiClipboardText,
    mdiClose,
    mdiLoading,
    mdiFolderNetwork,
    mdiAirplane,
} from '@mdi/js'
import { Icon } from '@mdi/react'
import { TFunction } from 'i18next'
import React, { useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ErrorMessage } from '../../atoms/ErrorMessage'
import { ExternalErrors, Form, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { ToggleSwitch } from '../../atoms/Form/ToggleSwitch'
import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import {
    ProjectRole,
    ProjectRoleFormSchema,
    ProjectRoleFormType,
    ProjectRolePermissions,
} from '../../domain/project-role.domain'

type PermissionsCategories = {
    name: string
    icon: string
    permissions: Record<string, ProjectRolePermissions[]>
}

const permissionsCategories = (t: TFunction): PermissionsCategories[] => [
    {
        name: 'Project',
        icon: mdiClipboardText,
        permissions: {
            'View Project Summary': [ProjectRolePermissions.ProjectViewSummary],
            'Edit Project': [ProjectRolePermissions.ProjectEdit],
            'Archive Project': [ProjectRolePermissions.ProjectArchive],
            'Unarchive Project': [ProjectRolePermissions.ProjectUnarchive],
            'Export Project Summary as XLS': [
                ProjectRolePermissions.ProjectSummaryExportSummaryXls,
            ],
            'Export Project Summary as PDF': [
                ProjectRolePermissions.ProjectSummaryExportSummaryPdf,
            ],
            'Export Project Summary as Static Link': [
                ProjectRolePermissions.ProjectSummaryExportSummaryStatic,
            ],
        },
    },
    {
        name: 'Aircraft Information',
        icon: mdiAirplane,
        permissions: {
            'Edit Aircraft Information Date': [ProjectRolePermissions.AircraftInformationDateEdit],
            'View Aircraft Information': [ProjectRolePermissions.AircraftInformationView],
            'Export Aircraft Information as XLS': [
                ProjectRolePermissions.AircraftInformationExportXls,
            ],
            'Export Aircraft Information as PDF': [
                ProjectRolePermissions.AircraftInformationExportPdf,
            ],
            'Export Aircraft Information as Static Link': [
                ProjectRolePermissions.AircraftInformationExportStatic,
            ],
            'Create Engine Module': [ProjectRolePermissions.AircraftInformationCreateEngineModule],
            'Delete Engine Module': [ProjectRolePermissions.AircraftInformationDeleteEngineModule],
            'Edit Engine Module': [ProjectRolePermissions.AircraftInformationEditEngineModule],
            'Create Landing Gear Module': [
                ProjectRolePermissions.AircraftInformationCreateLandingGearModule,
            ],
            'Edit Landing Gear Module': [
                ProjectRolePermissions.AircraftInformationEditLandingGearModule,
            ],
            'Delete Landing Gear Module': [
                ProjectRolePermissions.AircraftInformationDeleteLandingGearModule,
            ],
            'Edit Module Block': [ProjectRolePermissions.AircraftInformationEditModuleBlock],
        },
    },
    {
        name: 'OIL',
        icon: mdiFolderNetwork,
        permissions: {
            'Create Item': [ProjectRolePermissions.OILCreate],
            'Edit Auditor Status': [ProjectRolePermissions.OILEditAuditorStatus],
            'Edit Auditee Status': [ProjectRolePermissions.OILEditAuditeeStatus],
            'Edit Subject': [ProjectRolePermissions.OILEditSubject],
            'Edit Observation Description': [ProjectRolePermissions.OILEditObservationDescription],
            'Append to Observation Description': [
                ProjectRolePermissions.OILAppendObservationDescription,
            ],
            'Add Comment': [ProjectRolePermissions.OILAddComment],
            'List Item': [ProjectRolePermissions.OILList],
            'View Item History': [ProjectRolePermissions.OILViewHistory],
            'Mark Item As Critical': [ProjectRolePermissions.OILMarkAsCritical],
            'Trash Item': [ProjectRolePermissions.OILTrash],
            'Untrash Item': [ProjectRolePermissions.OILUntrash],
            'View Trashed Items': [ProjectRolePermissions.OILViewTrashedItems],
            'ViewItems Under Review': [ProjectRolePermissions.OILViewUnderReviewItems],
            'Export Items as DOC': [ProjectRolePermissions.OILExportItemsDoc],
            'Export Items as XLS': [ProjectRolePermissions.OILExportItemsXls],
            'Export Items as PDF': [ProjectRolePermissions.OILExportItemsPdf],
            'Export Items as Static Link': [ProjectRolePermissions.OILExportItemsStatic],
        },
    },
    {
        name: 'RAS',
        icon: mdiFolderNetwork,
        permissions: {
            'View Summary': [ProjectRolePermissions.RASViewSummary],
            'List Subparts': [ProjectRolePermissions.RASListSubparts],
            'Edit Subpart': [ProjectRolePermissions.RASEditSubpart],
            'Add Subject Remark': [ProjectRolePermissions.RASAddSubjectRemark],
            'Add Subpart Remark': [ProjectRolePermissions.RASAddSubpartRemark],
            'Add Subpart': [ProjectRolePermissions.RASAddSubpart],
            'Export as XLS': [ProjectRolePermissions.RASExportXls],
            'Export as PDF': [ProjectRolePermissions.RASExportPdf],
            'Export as Static Link': [ProjectRolePermissions.RASExportStatic],
            'Export Summary as XLS': [ProjectRolePermissions.RASSummaryExportXls],
            'Export Summary as PDF': [ProjectRolePermissions.RASSummaryExportPdf],
            'Export Summary as Static Link': [ProjectRolePermissions.RASSummaryExportStatic],
        },
    },
    {
        name: 'PAS',
        icon: mdiFolderNetwork,
        permissions: {
            'View Summary': [ProjectRolePermissions.PASViewSummary],
            'List Subparts': [ProjectRolePermissions.PASListSubparts],
            'Edit Subpart': [ProjectRolePermissions.PASEditSubpart],
            'Add Subject Remark': [ProjectRolePermissions.PASAddSubjectRemark],
            'Add Subpart Remark': [ProjectRolePermissions.PASAddSubpartRemark],
            'Add Subpart': [ProjectRolePermissions.PASAddSubpart],
            'Export as XLS': [ProjectRolePermissions.PASExportXls],
            'Export as PDF': [ProjectRolePermissions.PASExportPdf],
            'Export as Static Link': [ProjectRolePermissions.PASExportStatic],
            'Export Summary as XLS': [ProjectRolePermissions.PASSummaryExportXls],
            'Export Summary as PDF': [ProjectRolePermissions.PASSummaryExportPdf],
            'Export Summary as Static Link': [ProjectRolePermissions.PASSummaryExportStatic],
        },
    },
]

type FormPermissionEntry = { permission: ProjectRolePermissions; key: string }

const ProjectRolePermissionsField: React.FC<{ name: string }> = props => {
    const { t } = useTranslation('projectRole')
    const {
        control,
        formState: { errors },
        trigger,
    } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: props.name,
        keyName: 'key',
    })

    const formPermissions = (fields as unknown) as FormPermissionEntry[]
    const error = errors[props.name]

    const onToggle = (newPermissions: ProjectRolePermissions[]) => (value: boolean) => {
        if (value) {
            const values = newPermissions.map(
                permission => ({ id: permission, permission } as const),
            )
            append(values)
        } else {
            const indexes = formPermissions
                .map((p, index) => ({ id: p.permission, index } as const))
                .filter(p => newPermissions.includes(p.id))
                .map(p => p.index)
            remove(indexes)
        }
        trigger(props.name)
    }

    const categories = useMemo(() => permissionsCategories(t), [t])

    return (
        <div>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            {categories.map(category => {
                const allCategoryPermissions = Object.values(category.permissions).flat()
                return (
                    <div key={category.name}>
                        <div className='flex'>
                            <div className='flex items-center font-medium text-atamblue-700'>
                                <Icon
                                    path={category.icon}
                                    size={1}
                                    className='mr-2 text-atamsky-900'
                                />
                                <div>{category.name}</div>

                                <ToggleSwitch
                                    value={allCategoryPermissions.every(p =>
                                        formPermissions.some(f => f.permission === p),
                                    )}
                                    size={1.5}
                                    className='ml-2'
                                    onChange={onToggle(allCategoryPermissions)}
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-4 gap-x-5 mb-5'>
                            {Object.entries(category.permissions).map(([name, permissions]) => (
                                <div key={`${category.name}-${name}`}>
                                    <ToggleSwitch
                                        value={permissions.every(p =>
                                            formPermissions.some(f => f.permission === p),
                                        )}
                                        size={1.5}
                                        onChange={onToggle(permissions)}
                                    >
                                        <div className='ml-2 text-sm leading-4 text-atamblue-900'>
                                            {name}
                                        </div>
                                    </ToggleSwitch>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const convertToFormType = (projectRole: ProjectRole): ProjectRoleFormType => ({
    name: projectRole.name,
    permissions: projectRole.permissions.map(permission => ({ permission })),
})

const convertFromFormType = (projectRole: ProjectRoleFormType): Omit<ProjectRole, 'id'> => ({
    name: projectRole.name,
    permissions: projectRole.permissions.map(({ permission }) => permission),
})

export const ProjectRoleForm: React.FC<{
    projectRole?: ProjectRole
    isSaving: boolean
    onSave: (projectRole: Omit<ProjectRole, 'id'>) => Promise<void>
    onCancel: () => void
}> = props => {
    const { t } = useTranslation('projectRole')
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<ProjectRoleFormType>>({})

    const onSubmit = (projectRole: ProjectRoleFormType) =>
        props.onSave(convertFromFormType(projectRole)).catch(error => {
            if (error.data?.name === 'ProjectRoleNameExists') {
                setExternalErrors({ name: 'Project role name already exists' })
                return
            }
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        })

    return (
        <Form
            schema={ProjectRoleFormSchema}
            defaults={props.projectRole ? convertToFormType(props.projectRole) : {}}
            externalErrors={externalErrors}
            onSubmit={onSubmit}
        >
            <TextField
                name='name'
                label='Project Role Name:'
                placeholder='Type project role name'
                className='pr-4'
                inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                labelClassName='text-atamblue-700'
            />
            <Separator className='w-full h-1 my-5 bg-ebony-400'></Separator>

            <ProjectRolePermissionsField name='permissions' />

            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    {t('Apply')}
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
