import { mdiAirplaneCheck, mdiAirplaneSearch, mdiArchiveCheck, mdiFolderNetwork } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

import { useListSubjectsQuery } from '../../api/for-company/subjects.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { Subject } from '../../domain/subject.domain'

export const SubjectSelector: React.FC<{
    isPhysical: boolean
    value?: Subject
    exclude?: Pick<Subject, 'id' | 'name'>[]
    className?: string
    iconClassName?: string
    readOnly?: boolean
    placeholder?: string
    onChange: (newSubject: Subject | undefined) => void
}> = props => {
    if (props.readOnly) {
        return (
            <div className={classNames('flex items-center text-atamblue-300', props.className)}>
                <Icon
                    path={props.isPhysical ? mdiAirplaneCheck : mdiArchiveCheck}
                    size={1}
                    className='mr-5'
                />
                {props.value?.name}
            </div>
        )
    }
    return (
        <EntitySelector<Subject>
            useResolver={useListSubjectsQuery}
            resolverParams={{ isPhysical: props.isPhysical }}
            getOptionLabel={subject => subject.name}
            isOptionDisabled={option => {
                if (props.exclude) {
                    return !!props.exclude.find(e => e.name === option.name)
                }
                return false
            }}
            defaultValue={props.value}
            exclude={props.exclude as any}
            placeholder={props.placeholder ?? 'Select Subject'}
            className={props.className}
            iconClassName={props.iconClassName}
            icon={props.isPhysical ? mdiAirplaneCheck : mdiArchiveCheck}
            onChange={props.onChange}
        />
    )
}
