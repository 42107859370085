import {
    mdiAccountCircle,
    mdiAirplane,
    mdiAirplaneSearch,
    mdiAirplaneTakeoff,
    mdiArchiveSearch,
    mdiBadgeAccountHorizontal,
    mdiChevronRight,
    mdiCog,
    mdiDomain,
    mdiFolderEdit,
    mdiFolderNetwork,
    mdiFolderOpen,
    mdiFolderSettingsOutline,
    mdiFolderStar,
    mdiStarCircle,
    mdiTableSync,
    mdiViewDashboard,
} from '@mdi/js'
import Icon from '@mdi/react'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Link, Location, useLocation, useParams } from 'react-router-dom'

import { selectProjectById } from '../../api/for-company/projects.api'
import { routes } from '../../routes'
import { useAppSelector } from '../../store'
import { ExtractRouteParams } from '../../types/ExtractRouteParams'

type BreadcrumbsType<T> = {
    [K in keyof T]: (params: ExtractRouteParams<K>) => ReactNode
}
function defineBreadcrumbs<T>(cfg: BreadcrumbsType<T>) {
    return cfg
}

const isMatch = (location: Location, options?: { includeRoot: boolean }) => (path: string) => {
    const splitLocation = location.pathname.split('/')
    const splitRoutes = path.split('/')
    const includeRoot = options?.includeRoot ?? false
    return splitRoutes.reduce((acc, segment, index) => {
        if (index === 1 && includeRoot && segment === '') return acc && true
        const result = segment[0] === ':' ? true : segment === splitLocation[index]
        return acc && result
    }, true)
}

const BreadcrumbsLink: React.FC<{ to?: string; icon: string }> = props => {
    const location = useLocation()
    const content = (
        <>
            <Icon path={props.icon} size={0.75} className='mr-1' />
            {props.children}
        </>
    )
    const isActive = props.to ? !isMatch(location)(props.to) : false
    return isActive && props.to ? (
        <Link to={props.to} className='text-atamblue-700 flex items-center'>
            {content}
        </Link>
    ) : (
        <div className='text-atamsky-900 flex items-center'>{content}</div>
    )
}

const breadcrumbs = defineBreadcrumbs({
    [routes.ActivateAccount.path]: () => null,
    [routes.ForgotPassword.path]: () => null,
    [routes.ResetPassword.path]: () => null,

    [routes.Dashboard.path]: () => (
        <BreadcrumbsLink to={routes.Dashboard.build()} icon={mdiViewDashboard}>
            Dashboard
        </BreadcrumbsLink>
    ),
    [routes.CreateProject.path]: () => (
        <BreadcrumbsLink to={routes.CreateProject.build()} icon={mdiAirplaneTakeoff}>
            Create Project
        </BreadcrumbsLink>
    ),
    [routes.EditProject.path]: params => (
        <BreadcrumbsLink to={routes.EditProject.build(params)} icon={mdiFolderEdit}>
            Edit Project
        </BreadcrumbsLink>
    ),

    [routes.Projects.path]: () => (
        <BreadcrumbsLink icon={mdiFolderSettingsOutline}>Projects</BreadcrumbsLink>
    ),
    [routes.Project.path]: params => {
        const project = useSelector(selectProjectById(+params.projectId))
        if (!project) {
            return null
        }
        return (
            <BreadcrumbsLink to={routes.Project.build(params)} icon={mdiFolderOpen}>
                {project.name}
            </BreadcrumbsLink>
        )
    },
    [routes.ROILSummary.path]: params => (
        <BreadcrumbsLink to={routes.ROILSummary.build(params)} icon={mdiTableSync}>
            Records OIL Summary
        </BreadcrumbsLink>
    ),
    [routes.POILSummary.path]: params => (
        <BreadcrumbsLink to={routes.POILSummary.build(params)} icon={mdiTableSync}>
            Physical OIL Summary
        </BreadcrumbsLink>
    ),
    [routes.ProjectSummary.path]: params => (
        <BreadcrumbsLink to={routes.ProjectSummary.build(params)} icon={mdiTableSync}>
            Summary
        </BreadcrumbsLink>
    ),
    [routes.ProjectAircraftInformation.path]: params => (
        <BreadcrumbsLink to={routes.ProjectAircraftInformation.build(params)} icon={mdiAirplane}>
            Aircraft Information
        </BreadcrumbsLink>
    ),
    [routes.ROIL.path]: params => {
        const project = useSelector(selectProjectById(+params.projectId))
        // const project = useAppSelector(selectProjectById(+params.projectId))
        if (!project) {
            return null
        }
        const subject = project.subjects.find(s => s.id === +params.subjectId)
        if (!subject) {
            return null
        }
        return (
            <BreadcrumbsLink to={routes.ROIL.build(params)} icon={mdiArchiveSearch}>
                {subject.name}
            </BreadcrumbsLink>
        )
    },
    [routes.POIL.path]: params => {
        const project = useSelector(selectProjectById(+params.projectId))
        if (!project) {
            return null
        }
        const subject = project.subjects.find(s => s.id === +params.subjectId)
        if (!subject) {
            return null
        }
        return (
            <BreadcrumbsLink to={routes.POIL.build(params)} icon={mdiAirplaneSearch}>
                {subject.name}
            </BreadcrumbsLink>
        )
    },
    [routes.RAS.path]: params => {
        const project = useSelector(selectProjectById(+params.projectId))
        if (!project) {
            return null
        }
        const subject = project.subjects.find(s => s.id === +params.subjectId)
        if (!subject) {
            return null
        }
        return (
            <BreadcrumbsLink to={routes.RAS.build(params)} icon={mdiArchiveSearch}>
                {subject.name}
            </BreadcrumbsLink>
        )
    },
    [routes.RASSummary.path]: params => {
        return (
            <BreadcrumbsLink to={routes.RASSummary.build(params)} icon={mdiTableSync}>
                RAS Summary
            </BreadcrumbsLink>
        )
    },
    [routes.PAS.path]: params => {
        const project = useSelector(selectProjectById(+params.projectId))
        if (!project) {
            return null
        }
        const subject = project.subjects.find(s => s.id === +params.subjectId)
        if (!subject) {
            return null
        }
        return (
            <BreadcrumbsLink to={routes.PAS.build(params)} icon={mdiAirplaneSearch}>
                {subject.name}
            </BreadcrumbsLink>
        )
    },
    [routes.PASSummary.path]: params => {
        return (
            <BreadcrumbsLink to={routes.PASSummary.build(params)} icon={mdiTableSync}>
                PAS Summary
            </BreadcrumbsLink>
        )
    },

    [routes.Settings.path]: () => <BreadcrumbsLink icon={mdiCog}>Settings</BreadcrumbsLink>,
    [routes.Users.path]: () => (
        <BreadcrumbsLink to={routes.Users.build()} icon={mdiAccountCircle}>
            Users
        </BreadcrumbsLink>
    ),
    [routes.AccountTypes.path]: () => (
        <BreadcrumbsLink to={routes.AccountTypes.path} icon={mdiStarCircle}>
            Account Types
        </BreadcrumbsLink>
    ),
    [routes.ProjectRoles.path]: () => (
        <BreadcrumbsLink to={routes.ProjectRoles.build()} icon={mdiBadgeAccountHorizontal}>
            Project Roles
        </BreadcrumbsLink>
    ),
    [routes.Subjects.path]: () => (
        <BreadcrumbsLink to={routes.Subjects.build()} icon={mdiFolderNetwork}>
            Subjects
        </BreadcrumbsLink>
    ),
    [routes.Templates.path]: () => (
        <BreadcrumbsLink to={routes.Templates.build()} icon={mdiFolderStar}>
            Templates
        </BreadcrumbsLink>
    ),

    [routes.Companies.path]: () => (
        <BreadcrumbsLink to={routes.Companies.build()} icon={mdiDomain}>
            Companies
        </BreadcrumbsLink>
    ),
})

export const Breadcrumbs = () => {
    const params = useParams()

    const matchedBreadcrumbs = Object.values(routes)
        .map(route => route.path)
        .filter(isMatch(useLocation(), { includeRoot: true }))
        .map(path => breadcrumbs[path])

    const separator = (
        <div className='mx-1 text-atamblue-300'>
            <Icon path={mdiChevronRight} size={0.75} />
        </div>
    )

    return (
        <div className='flex'>
            {matchedBreadcrumbs?.map((breadcrumb, index) => (
                <div key={index} className='flex items-center text-sm'>
                    {index > 0 ? separator : null}
                    {/* {index > 0 ? <div className='mx-2'>{'>'}</div> : null} */}
                    {breadcrumb(params as never)}
                </div>
            ))}
        </div>
    )
}
