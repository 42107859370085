import { mdiFlag, mdiClose, mdiLoading } from '@mdi/js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '../../atoms/IconButton'
import { Tooltip } from '../../atoms/Tooltip'
import { isNil } from '../../common/utils'
import { OILStatus, OILSummary } from '../../domain/oil.domain'

import { StatusIcon } from './common/StatusIcon'

export const Statuses = [
    OILStatus.Open,
    OILStatus.Closed,
    OILStatus.Critical,
    OILStatus.Pending,
    OILStatus.SideLetter,
    OILStatus.UnderReview,
]

export const statusesNameMap = {
    [OILStatus.Open]: 'Open',
    [OILStatus.Closed]: 'Closed',
    [OILStatus.Critical]: 'Critical',
    [OILStatus.Pending]: 'Pending',
    [OILStatus.SideLetter]: 'Side\nLetter',
    [OILStatus.UnderReview]: 'Under\nReview',
}

export const StatusesOverview: React.FC<{
    oilSummary: OILSummary | undefined
    isLoading: boolean
}> = props => {
    const { t } = useTranslation('toolbar')
    const [isExpanded, setExpanded] = useState(false)

    if (!props.oilSummary) {
        return null
    }

    return (
        <div className='flex'>
            <Tooltip text={t('StatusesOverview')} className='inline-flex'>
                <IconButton
                    path={props.isLoading ? mdiLoading : mdiFlag}
                    spin={props.isLoading}
                    className='w-16 text-atamblue-300 hover:text-atamsky-900'
                    iconClassName='mr-0 '
                    onClick={() => setExpanded(true)}
                ></IconButton>
            </Tooltip>

            {!isExpanded ? null : (
                <div className='flex px-2 rounded-r-2xl shadow-xl'>
                    {Statuses.map(status => {
                        if (isNil(props.oilSummary)) {
                            return null
                        }
                        return (
                            <div key={status} className='flex items-center px-2'>
                                <div className='pr-2 whitespace-pre-line font-medium leading-4 text-atamblue-700'>
                                    {statusesNameMap[status]}:
                                </div>
                                <div className='relative'>
                                    <div className='w-6 h-6 flex items-center justify-center absolute -top-1.5 left-8 rounded-2lg bg-teal-700 bg-opacity-80 text-center text-white font-medium text-sm shadow-md z-20'>
                                        {props.oilSummary.auditorStatus[status]}
                                    </div>
                                    <div className='w-6 h-6 flex items-center justify-center absolute -bottom-1.5 left-8 rounded-2lg bg-green-500 bg-opacity-80 text-center text-white font-medium text-sm shadow-md z-20'>
                                        {props.oilSummary.auditeeStatus[status]}
                                    </div>
                                    <StatusIcon
                                        status={status}
                                        isActive={false}
                                        isClickable={false}
                                        size='lg'
                                        className='mr-1'
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <IconButton
                        path={mdiClose}
                        className='px-2'
                        iconClassName='text-atamblue-300 hover:text-red-500'
                        onClick={() => {
                            setExpanded(false)
                        }}
                    ></IconButton>
                </div>
            )}
        </div>
    )
}
