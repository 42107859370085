import { mdiCheck, mdiClose, mdiLoading, mdiPencil } from '@mdi/js'
import classNames from 'classnames'
import React, { useState } from 'react'

import { TextareaInput } from '../../atoms/Form/TextareaInput'
import { IconButton } from '../../atoms/IconButton'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { Project } from '../../domain/project.domain'
import { ForProjectRole } from '../Auth/ForProjectRole'

import { OILModal } from './common/OILModal'

export const SubjectColumnCell: React.FC<{
    value: string
    projectId: Project['id']
    itemNumber: number
    isExpanded: boolean
    isReadOnly: boolean
    onChange: (value: string) => Promise<unknown>
}> = props => {
    const [isIconHovered, setIconHovered] = useState(false)
    const [value, setValue] = useState(props.value)

    const onChange = props.onChange
    const isLoading = false

    return (
        <div
            className={classNames(
                'group relative h-full grow flex justify-center items-center border border-transparent rounded-xl',
                {
                    truncate: !props.isExpanded,
                    'whitespace-pre-line hover:border-atamsky-300':
                        props.isExpanded && !props.isReadOnly,
                    'bg-blue-100': isIconHovered,
                },
            )}
        >
            {props.value}
            {props.isReadOnly || !props.isExpanded ? null : (
                <ForProjectRole
                    permission={ProjectRolePermissions.OILEditSubject}
                    projectId={props.projectId}
                >
                    <OILModal
                        title={
                            <>
                                Edit <span className='text-atamsky-900'>Subject</span> on{' '}
                                <span className='text-atamsky-900'>Item {props.itemNumber}</span>
                            </>
                        }
                        className='w-96 px-8'
                        body={({ close }) => (
                            <div>
                                <TextareaInput
                                    className='w-full resize-none focus:outline-none focus:ring'
                                    value={value}
                                    rows={8}
                                    maxLength={100}
                                    onChange={setValue}
                                />

                                <div className='flex justify-center text-atamsky-900 px-32 my-5'>
                                    <IconButton
                                        onClick={() => onChange(value).then(close)}
                                        path={isLoading ? mdiLoading : mdiCheck}
                                        spin={isLoading}
                                        disabled={isLoading}
                                        className='mr-10 group text-atamblue-700 hover:text-atamsky-900'
                                        iconClassName='text-green-300 group-hover:text-green-500 mr-2'
                                    >
                                        Save
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setValue(props.value)
                                            close()
                                        }}
                                        path={mdiClose}
                                        className='ml-10 group text-atamblue-700 hover:text-atamsky-900'
                                        iconClassName='text-red-400 group-hover:text-red-500 mr-2'
                                    >
                                        Cancel
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        render={({ open }) => (
                            <IconButton
                                path={mdiPencil}
                                size={0.75}
                                className={classNames(
                                    'absolute bottom-0 w-full text-center pb-2 hidden group-hover:flex',
                                )}
                                onMouseEnter={() => setIconHovered(true)}
                                onMouseLeave={() => setIconHovered(false)}
                                onClick={open}
                            ></IconButton>
                        )}
                    />
                </ForProjectRole>
            )}
        </div>
    )
}
