import React from 'react'

import { ToggleSwitch } from '../Form/ToggleSwitch'

import { TableColumn } from './Table'

import './ActiveFilters.scss'

export type ActiveFiltersProps<T, F> = {
    columns: TableColumn<T, F>[]
    filters: Partial<F>
    additionalFilters: {
        [K in keyof Partial<F>]: (args: { value: F[K]; clearFilter: () => void }) => React.ReactNode
    }
    showFilters: boolean
    onShowFiltersChange: (show: boolean) => void
    onClearFilter: (name: keyof F) => void
}
export const ActiveFilters = <T, F>(props: React.PropsWithChildren<ActiveFiltersProps<T, F>>) => {
    const hasFilters = React.useMemo(
        () => props.columns.some(c => c.Filter !== undefined),
        [props.columns],
    )

    return (
        <div className='active-filters w-2/3 flex flex-wrap items-center text-sm mb-2'>
            {hasFilters ? (
                <ToggleSwitch
                    value={props.showFilters}
                    size={1.5}
                    className='mr-3 font-bold text-atamblue-700'
                    onChange={props.onShowFiltersChange}
                >
                    Filters
                </ToggleSwitch>
            ) : null}
            {Object.entries(props.filters).map(([name, value]) => {
                const column = props.columns.find(col => col.id === name)

                if (!column || !column.ActiveFilter) {
                    return null
                }
                return (
                    <column.ActiveFilter
                        key={name}
                        value={value}
                        clearFilter={() => props.onClearFilter(name as keyof F)}
                    />
                )
            })}
            {Object.entries(props.additionalFilters).map(([name, fn]: any) => {
                return fn({
                    value: (props.filters as any)[name],
                    clearFilter: () => props.onClearFilter(name),
                })
            })}
        </div>
    )
}
