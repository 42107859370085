import { BaseQueryFn, QueryDefinition, MutationDefinition } from '@reduxjs/toolkit/dist/query'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d'
import { OmitFromUnion } from '@reduxjs/toolkit/dist/query/tsHelpers.d'
import { z } from 'zod'

import { PSF } from '../atoms/Table/Table'

export type { RequestStatusFlags as Flags } from '@reduxjs/toolkit/dist/query/core/apiState.d'

export const emptyContent = () => z.object({})
export const nullable = <T extends z.ZodTypeAny>(field: T) => z.union([field, z.null()])

export const ListResponseSchema = <R extends z.ZodType<any, any, any>>(results: R) =>
    z.object({
        count: z.number(),
        totalPages: z.number(),
        results: z.array(results),
    })

export type ListResponse<T> = {
    count: number
    totalPages: number
    results: T[]
}

const _serializeQueryParams = (psf: Partial<PSF<any>>, parent?: string): string => {
    return Object.entries(psf)
        .map(([key, value]) =>
            typeof value === 'object'
                ? _serializeQueryParams(value, key)
                : `${parent ? `${parent}[${key}]` : key}=${value}`,
        )
        .join('&')
}
export const serializeQueryParams = (psf: Partial<PSF<any>>): string => {
    const serializedQuery = _serializeQueryParams(psf)
    return serializedQuery.length > 0 ? `?${serializedQuery}` : ''
}

export const emptyPaginatedResponse = <T>(): ListResponse<T> => ({
    totalPages: 0,
    count: 0,
    results: [],
})

/** Query / Mutation Builders */
type BuilderSchemas<I, O> = {
    input: I
    output: O
}

export const queryBuilder =
    <BaseQuery extends BaseQueryFn, TagTypes extends string, ReducerPath extends string>(
        builder: EndpointBuilder<BaseQuery, TagTypes, ReducerPath>,
    ) =>
    <I extends z.ZodType<any, any, any>, O extends z.ZodType<any, any, any>>(
        definition: OmitFromUnion<
            QueryDefinition<z.TypeOf<I>, BaseQuery, TagTypes, z.TypeOf<O>>,
            'type'
        > &
            BuilderSchemas<I, O>,
    ): QueryDefinition<z.TypeOf<I>, BaseQuery, TagTypes, z.TypeOf<O>> =>
        builder.query(definition)

export const mutationBuilder =
    <BaseQuery extends BaseQueryFn, TagTypes extends string, ReducerPath extends string>(
        builder: EndpointBuilder<BaseQuery, TagTypes, ReducerPath>,
    ) =>
    <I extends z.ZodType<any, any, any>, O extends z.ZodType<any, any, any>>(
        definition: OmitFromUnion<
            MutationDefinition<z.TypeOf<I>, BaseQuery, TagTypes, z.TypeOf<O>, ReducerPath>,
            'type'
        > &
            BuilderSchemas<I, O>,
    ): MutationDefinition<z.TypeOf<I>, BaseQuery, TagTypes, z.TypeOf<O>, ReducerPath> =>
        builder.mutation(definition)

export const makeBuilders = <
    BaseQuery extends BaseQueryFn,
    TagTypes extends string,
    ReducerPath extends string,
>(
    builder: EndpointBuilder<BaseQuery, TagTypes, ReducerPath>,
) => ({
    buildQuery: queryBuilder(builder),
    buildMutation: mutationBuilder(builder),
})
