import { z } from 'zod'

import { CategorySchema, SubjectSchema, SubpartSchema } from '../../domain/subject.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

export const subjectsApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listSubjects: buildQuery({
                input: z.any(),
                output: ListResponseSchema(SubjectSchema),
                query: query => ({
                    url: `/subject${serializeQueryParams(query)}`,
                    method: 'GET',
                }),
                providesTags: ['SubjectsList'],
            }),
            createSubject: buildMutation({
                input: SubjectSchema.pick({ name: true, isPhysical: true }),
                output: SubjectSchema.pick({ id: true }),
                query: body => ({
                    url: '/subject',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['SubjectsList'],
            }),
            editSubject: buildMutation({
                input: SubjectSchema.pick({ id: true }).merge(
                    SubjectSchema.pick({ name: true }).partial(),
                ),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/subject/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['SubjectsList'],
            }),
            deleteSubject: buildMutation({
                input: SubjectSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/subject/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['SubjectsList'],
            }),

            createCategory: buildMutation({
                input: CategorySchema.pick({ subjectId: true }).merge(
                    CategorySchema.pick({ name: true }),
                ),
                output: CategorySchema.pick({ id: true }),
                query: ({ subjectId, ...body }) => ({
                    url: `/subject/${subjectId}`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['SubjectsList'],
            }),
            editCategory: buildMutation({
                input: CategorySchema.pick({ id: true }).merge(
                    CategorySchema.pick({ name: true }).partial(),
                ),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/subject/category/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['SubjectsList'],
            }),
            deleteCategory: buildMutation({
                input: CategorySchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/subject/category/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['SubjectsList'],
            }),

            createSubpart: buildMutation({
                input: SubpartSchema.pick({ categoryId: true }).merge(
                    SubpartSchema.pick({ name: true }),
                ),
                output: SubpartSchema.pick({ id: true }),
                query: ({ categoryId, ...body }) => ({
                    url: `/subject/category/${categoryId}`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['SubjectsList'],
            }),
            editSubpart: buildMutation({
                input: SubpartSchema.pick({ id: true }).merge(SubpartSchema.pick({ name: true })),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/subject/subpart/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['SubjectsList'],
            }),
            deleteSubpart: buildMutation({
                input: SubpartSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/subject/subpart/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['SubjectsList'],
            }),
        }
    },
})

export const {
    useListSubjectsQuery,
    useCreateSubjectMutation,
    useEditSubjectMutation,
    useDeleteSubjectMutation,
    useCreateCategoryMutation,
    useEditCategoryMutation,
    useDeleteCategoryMutation,
    useCreateSubpartMutation,
    useEditSubpartMutation,
    useDeleteSubpartMutation,
} = subjectsApi
