import { mdiCheck, mdiLoading, mdiAirplane, mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useState } from 'react'

import { ExternalErrors, Form, FORM_ERROR } from '../../../atoms/Form/Form'
import { TextField } from '../../../atoms/Form/TextInput'
import { IconButton } from '../../../atoms/IconButton'
import {
    AircraftInformationModuleFormSchema,
    AircraftInformationModuleFormType,
    AircraftInformationModuleType,
} from '../../../domain/aircraft-information.domain'
import { useTranslation } from '../../../lib/i18n'

export const AircraftInformationModuleForm: React.FC<{
    module: AircraftInformationModuleFormType
    isSaving: boolean
    onSave: (module: AircraftInformationModuleFormType) => Promise<void>
    onCancel: () => void
}> = props => {
    const [externalErrors, setExternalErrors] = useState<
        ExternalErrors<AircraftInformationModuleFormType>
    >()

    const onSave = (module: AircraftInformationModuleFormType) => {
        return props.onSave(module).catch(() => {
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        })
    }

    const onCancel = props.onCancel
    const { t } = useTranslation(['aircraftInformation', 'common'])

    return (
        <Form
            schema={AircraftInformationModuleFormSchema}
            defaults={props.module}
            externalErrors={externalErrors}
            onSubmit={onSave}
        >
            <div className='text-atamblue-700 flex flex-col items-center'>
                <Icon path={mdiAirplane} size={1.5} />
                <div className='mt-2 text-lg font-medium text-atamsky-900'>
                    {props.module.type === AircraftInformationModuleType.Engine
                        ? t('NewEngineModuleTitle')
                        : t('NewLandingGearModuleTitle')}
                </div>
            </div>

            <TextField
                placeholder='Module name'
                name='name'
                className='w-80 h-12 mt-3 mr-3 bg-ebony-100 border border-atamsky-300 rounded-2xl text-center focus:outline-none'
                inputClassName='h-12 p-3 leading-5 text-center'
                autoFocus
            />

            <div className='flex justify-center text-atamsky-900 mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    size={1}
                    isSubmit
                    className='mr-10 group text-atamblue-700 hover:text-atamsky-900'
                    iconClassName='text-green-300 group-hover:text-green-500 mr-2'
                >
                    {t('common:Save')}
                </IconButton>
                <IconButton
                    path={mdiClose}
                    size={1}
                    onClick={onCancel}
                    className='ml-10 group text-atamblue-700 hover:text-atamsky-900'
                    iconClassName='text-red-400 group-hover:text-red-500 mr-2'
                >
                    {t('common:Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
