import {
    mdiFolderSettingsOutline,
    mdiFolderOpen,
    mdiFolderZipOutline,
    mdiArrowLeft,
    mdiFolderZip,
    mdiAirplane,
    mdiArchiveSearch,
    mdiAirplaneSearch,
    mdiTableSync,
    mdiAirplaneCheck,
    mdiFolderEdit,
    mdiFolderCog,
    mdiArchiveCheck,
} from '@mdi/js'
import { Icon } from '@mdi/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { useGetMyProjectsQuery } from '../../../api/for-company/projects.api'
import AuditStatusModuleIcon from '../../../assets/icon_auditStatus.svg'
import OILModuleIcon from '../../../assets/icon_oil.svg'
import { Accordion } from '../../../atoms/Containers/Accordion'
import { Tab, Tabs } from '../../../atoms/Containers/Tabs'
import { LoadingSpinner } from '../../../atoms/LoadingSpinner'
import { FlipBookAnchor, FlipBookBack } from '../../../atoms/Sidebar/FlipBook'
import { NavSeparator } from '../../../atoms/Sidebar/NavSeparator'
import {
    SidebarChild,
    SidebarContainer,
    SidebarItem,
    SidebarLink,
    SidebarTabItem,
} from '../../../atoms/Sidebar/Sidebar'
import { ProjectRolePermissions } from '../../../domain/project-role.domain'
import { ProjectSchema } from '../../../domain/project.domain'
import { routes } from '../../../routes'
import { ForProjectRole } from '../../Auth/ForProjectRole'

const NavProject = ProjectSchema.pick({
    id: true,
    name: true,
    isArchived: true,
    hasROIL: true,
    hasPOIL: true,
    hasRAS: true,
    hasPAS: true,
    subjects: true,
})

const ProjectNavigation: React.FC<{ project: z.TypeOf<typeof NavProject> }> = props => {
    const { t } = useTranslation(['sidebar', 'projects'])
    const project = props.project

    const recordsSubjects = props.project.subjects.filter(subject => !subject.isPhysical)
    const physicalSubjects = props.project.subjects.filter(subject => subject.isPhysical)

    if (!project) {
        return null
    }
    return (
        <div className='flex flex-col'>
            <FlipBookBack className='flex items-center p-4 border-b-mini border-atamblue-200 text-atamblue-300 group cursor-pointer'>
                <Icon path={mdiArrowLeft} size={1} className='mr-4 group-hover:text-atamsky-900' />
                <span className='group-hover:text-atamsky-900 uppercase font-medium text-sm'>
                    {t('BackToMainMenu')}
                </span>
            </FlipBookBack>
            <SidebarChild
                whenOpened={
                    <div className='flex flex-col items-center py-4'>
                        <div className='flex'>
                            <Icon
                                path={props.project.isArchived ? mdiFolderZip : mdiFolderOpen}
                                size={1}
                                className='mr-2'
                            />
                            <span className='leading-6 text-lg font-medium truncate'>
                                {project.name}
                            </span>
                        </div>
                        <span className='leading-5 uppercase font-medium text-sm text-atamblue-300'>
                            {!props.project.isArchived
                                ? t('OperatingProjectDetailsTitle')
                                : t('ArchivedProjectDetailsTitle')}
                        </span>
                    </div>
                }
                whenClosed={
                    <div className='flex mb-5 p-4'>
                        <Icon
                            path={props.project.isArchived ? mdiFolderZip : mdiFolderOpen}
                            size={1}
                            className='mr-2'
                        />
                    </div>
                }
            />
            <ForProjectRole
                permission={ProjectRolePermissions.AircraftInformationView}
                projectId={project.id}
            >
                <SidebarLink
                    icon={mdiAirplane}
                    label={t('AircraftInformation')}
                    href={routes.ProjectAircraftInformation.build({
                        projectId: props.project.id,
                    })}
                />
            </ForProjectRole>
            <Accordion>
                {/* Audit Status */}
                {project.hasRAS || project.hasPAS ? (
                    <>
                        <NavSeparator />
                        <SidebarContainer
                            id='AuditStatusModule'
                            customIcon
                            icon={AuditStatusModuleIcon}
                            label='Audit Status'
                            className='text-asgreen-600 group'
                            labelClassName='group-hover:text-asgreen-700'
                            actionIconClassName='group-hover:text-asgreen-700'
                        >
                            <Tabs
                                initial={project.hasRAS ? 'ASRecords' : 'ASPhysical'}
                                className='mx-4'
                            >
                                {/* Audit Status - Records */}
                                {project.hasRAS ? (
                                    <Tab
                                        id='ASRecords'
                                        render={({ active, setActive }) => {
                                            const content = (
                                                <SidebarTabItem
                                                    className='w-32'
                                                    icon={mdiArchiveSearch}
                                                    label={t('Records')}
                                                    active={active}
                                                    onClick={setActive}
                                                />
                                            )

                                            return (
                                                <SidebarChild
                                                    whenOpened={content}
                                                    whenClosed={!active ? null : content}
                                                />
                                            )
                                        }}
                                    >
                                        <SidebarLink
                                            icon={mdiTableSync}
                                            iconClassName='text-asgreen-600'
                                            label={t('RecordsSummary')}
                                            href={routes.RASSummary.build({
                                                projectId: props.project.id,
                                            })}
                                        />
                                        {recordsSubjects.map(subject => (
                                            <SidebarLink
                                                key={subject.id}
                                                icon={mdiArchiveCheck}
                                                iconClassName='text-asgreen-600'
                                                label={subject.name}
                                                href={routes.RAS.build({
                                                    projectId: props.project.id,
                                                    subjectId: subject.id,
                                                })}
                                            ></SidebarLink>
                                        ))}
                                    </Tab>
                                ) : null}
                                {/* Audit Status - Physical */}
                                {project.hasPAS ? (
                                    <Tab
                                        id='ASPhysical'
                                        render={({ active, setActive }) => {
                                            const content = (
                                                <SidebarTabItem
                                                    className='w-32'
                                                    icon={mdiAirplaneSearch}
                                                    label={t('Physical')}
                                                    active={active}
                                                    onClick={setActive}
                                                />
                                            )
                                            return (
                                                <SidebarChild
                                                    whenOpened={content}
                                                    whenClosed={!active ? null : content}
                                                />
                                            )
                                        }}
                                    >
                                        <SidebarLink
                                            icon={mdiTableSync}
                                            iconClassName='text-asgreen-600'
                                            label={t('PhysicalSummary')}
                                            href={routes.PASSummary.build({
                                                projectId: props.project.id,
                                            })}
                                        />
                                        {physicalSubjects.map(subject => (
                                            <SidebarLink
                                                key={subject.id}
                                                icon={mdiAirplaneCheck}
                                                iconClassName='text-asgreen-600'
                                                label={subject.name}
                                                href={routes.PAS.build({
                                                    projectId: props.project.id,
                                                    subjectId: subject.id,
                                                })}
                                            ></SidebarLink>
                                        ))}
                                    </Tab>
                                ) : null}
                            </Tabs>
                        </SidebarContainer>
                    </>
                ) : null}

                {/* Open Item Listing */}
                {project.hasROIL || project.hasPOIL ? (
                    <>
                        <NavSeparator />
                        <SidebarContainer
                            id='OILModule'
                            customIcon
                            icon={OILModuleIcon}
                            label='Open Item Listing'
                            className='text-oilred-600 group'
                            labelClassName='group-hover:text-oilred-700'
                            actionIconClassName='group-hover:text-oilred-700'
                        >
                            <Tabs
                                initial={project.hasROIL ? 'OILRecords' : 'OILPhysical'}
                                className='mx-4'
                            >
                                {/* Open Item Listing - Records */}
                                {project.hasROIL ? (
                                    <Tab
                                        id='OILRecords'
                                        render={({ active, setActive }) => {
                                            const content = (
                                                <SidebarTabItem
                                                    className='w-32'
                                                    icon={mdiArchiveSearch}
                                                    label={t('Records')}
                                                    active={active}
                                                    onClick={setActive}
                                                />
                                            )

                                            return (
                                                <SidebarChild
                                                    whenOpened={content}
                                                    whenClosed={!active ? null : content}
                                                />
                                            )
                                        }}
                                    >
                                        <SidebarLink
                                            icon={mdiTableSync}
                                            iconClassName='text-oilred-600'
                                            label={t('RecordsSummary')}
                                            href={routes.ROILSummary.build({
                                                projectId: props.project.id,
                                            })}
                                        />
                                        {recordsSubjects.map(subject => (
                                            <SidebarLink
                                                key={subject.id}
                                                icon={mdiArchiveCheck}
                                                iconClassName='text-oilred-600'
                                                label={subject.name}
                                                href={routes.ROIL.build({
                                                    projectId: props.project.id,
                                                    subjectId: subject.id,
                                                })}
                                            ></SidebarLink>
                                        ))}
                                    </Tab>
                                ) : null}
                                {/* Open Item Listing - Physical */}
                                {project.hasPOIL ? (
                                    <Tab
                                        id='OILPhysical'
                                        render={({ active, setActive }) => {
                                            const content = (
                                                <SidebarTabItem
                                                    className='w-32'
                                                    icon={mdiAirplaneSearch}
                                                    label={t('Physical')}
                                                    active={active}
                                                    onClick={setActive}
                                                />
                                            )
                                            return (
                                                <SidebarChild
                                                    whenOpened={content}
                                                    whenClosed={!active ? null : content}
                                                />
                                            )
                                        }}
                                    >
                                        <SidebarLink
                                            icon={mdiTableSync}
                                            iconClassName='text-asgreen-600'
                                            label={t('PhysicalSummary')}
                                            href={routes.POILSummary.build({
                                                projectId: props.project.id,
                                            })}
                                        />
                                        {physicalSubjects.map(subject => (
                                            <SidebarLink
                                                key={subject.id}
                                                icon={mdiAirplaneCheck}
                                                iconClassName='text-asgreen-600'
                                                label={subject.name}
                                                href={routes.POIL.build({
                                                    projectId: props.project.id,
                                                    subjectId: subject.id,
                                                })}
                                            ></SidebarLink>
                                        ))}
                                    </Tab>
                                ) : null}
                            </Tabs>
                        </SidebarContainer>
                    </>
                ) : null}
                <ForProjectRole
                    permission={ProjectRolePermissions.ProjectEdit}
                    projectId={project.id}
                >
                    <NavSeparator />
                    <SidebarContainer
                        id='projectSettings'
                        icon={mdiFolderCog}
                        label='Project Settings'
                        className='text-atamblue-300 group'
                        iconClassName='group-hover:text-atamsky-900'
                        labelClassName='group-hover:text-atamsky-900'
                        actionIconClassName='group-hover:text-atamsky-900'
                    >
                        <SidebarLink
                            icon={mdiFolderEdit}
                            label={t('EditProject', { ns: 'projects' })}
                            href={routes.EditProject.build({
                                projectId: props.project.id,
                            })}
                        />
                    </SidebarContainer>
                </ForProjectRole>
            </Accordion>
        </div>
    )
}
export const ProjectsListNavigation: React.FC = () => {
    const { data, isLoading } = useGetMyProjectsQuery()
    if (isLoading) {
        return <LoadingSpinner />
    }
    if (!data) {
        return null
    }
    const projects = data
    const unarchivedProjects = projects.results.filter(project => !project.isArchived)
    const archivedProjects = projects.results.filter(project => project.isArchived)

    return (
        <Accordion>
            <SidebarContainer
                id='operating-projects'
                icon={mdiFolderSettingsOutline}
                label='Operating Projects'
                className='text-atamblue-300 group'
                iconClassName='group-hover:text-atamsky-900'
                labelClassName='group-hover:text-atamsky-900'
                actionIconClassName='group-hover:text-atamsky-900'
            >
                {unarchivedProjects.map(project => (
                    <FlipBookAnchor
                        key={project.id}
                        page={() => <ProjectNavigation project={project} />}
                    >
                        <SidebarItem icon={mdiFolderOpen} label={project.name} className='px-4' />
                    </FlipBookAnchor>
                ))}
            </SidebarContainer>
            <NavSeparator />
            <SidebarContainer
                id='archived-projects'
                icon={mdiFolderZipOutline}
                label='Archived Projects'
                className='text-atamblue-300 group'
                iconClassName='group-hover:text-atamsky-900'
                labelClassName='group-hover:text-atamsky-900'
                actionIconClassName='group-hover:text-atamsky-900'
            >
                {archivedProjects.map(project => (
                    <FlipBookAnchor
                        key={project.id}
                        page={() => <ProjectNavigation project={project} />}
                    >
                        <SidebarItem icon={mdiFolderOpen} label={project.name} className='px-4' />
                    </FlipBookAnchor>
                ))}
            </SidebarContainer>
        </Accordion>
    )
}
