import i18n, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'

export { useTranslation } from 'react-i18next'

export const initializeI18N = (resources: Record<string, Resource>): void => {
    i18n.use(initReactI18next).init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })
}
