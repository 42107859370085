import {
    mdiCog,
    mdiFolder,
    mdiAccountCircle,
    mdiStarCircle,
    mdiFolderEdit,
    mdiCheck,
    mdiClose,
    mdiLoading,
    mdiFolderNetwork,
} from '@mdi/js'
import Icon from '@mdi/react'
import { TFunction } from 'i18next'
import React, { useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ErrorMessage } from '../../atoms/ErrorMessage'
import { ExternalErrors, Form, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { ToggleSwitch } from '../../atoms/Form/ToggleSwitch'
import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import {
    AccountType,
    AccountTypeFormSchema,
    AccountTypeFormType,
    AccountTypePermissions,
} from '../../domain/account-type.domain'

type PermissionsCategories = {
    name: string
    icon: string
    permissions: Record<string, AccountTypePermissions[]>
}

const permissionsCategories = (t: TFunction): PermissionsCategories[] => [
    {
        name: 'Account Types',
        icon: mdiStarCircle,
        permissions: {
            'Create Account Type': [AccountTypePermissions.AccountTypeCreate],
            'Edit Account Type': [AccountTypePermissions.AccountTypeEdit],
            'List Account Types': [AccountTypePermissions.AccountTypeList],
        },
    },
    {
        name: 'Project',
        icon: mdiFolderNetwork,
        permissions: {
            'Create Project': [AccountTypePermissions.ProjectCreate],
            'Project List Assigned': [AccountTypePermissions.ProjectListAssigned],
            'Project List Archived': [AccountTypePermissions.ProjectListArchived],
        },
    },
    {
        name: 'Project Roles',
        icon: mdiStarCircle,
        permissions: {
            'Create Project Role': [AccountTypePermissions.ProjectRoleCreate],
            'Edit Project Role': [AccountTypePermissions.ProjectRoleEdit],
            'List Project Roles': [AccountTypePermissions.ProjectRoleList],
        },
    },
    {
        name: 'Subjects',
        icon: mdiFolder,
        permissions: {
            'Create Subject': [AccountTypePermissions.SubjectCreate],
            'Edit Subject': [AccountTypePermissions.SubjectEdit],
            'Delete Subject': [AccountTypePermissions.SubjectDelete],
            'List Subjects': [AccountTypePermissions.SubjectList],
        },
    },
    {
        name: 'Templates',
        icon: mdiFolderEdit,
        permissions: {
            'Create Template': [AccountTypePermissions.TemplateCreate],
            'Edit Template': [AccountTypePermissions.TemplateEdit],
            'Delete Template': [AccountTypePermissions.TemplateDelete],
            'List Templates': [AccountTypePermissions.TemplateList],
        },
    },
    {
        name: 'Users',
        icon: mdiAccountCircle,
        permissions: {
            'Create User': [AccountTypePermissions.UserCreate],
            'Edit User': [AccountTypePermissions.UserEdit],
            'Delete User': [AccountTypePermissions.UserDelete],
            'List Users': [AccountTypePermissions.UserList],
            'Activate User': [AccountTypePermissions.UserActivate],
            'Deactivate User': [AccountTypePermissions.UserDeactivate],
        },
    },
    {
        name: 'General',
        icon: mdiCog,
        permissions: {
            'View Dashboard': [AccountTypePermissions.DashboardView],
            'View Online Users': [AccountTypePermissions.OnlineUsersView],
        },
    },
]

type FormPermissionEntry = { permission: AccountTypePermissions; key: string }

const AccountTypePermissionsField: React.FC<{ name: string }> = props => {
    const { t } = useTranslation('accountType')
    const {
        control,
        formState: { errors },
        trigger,
    } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: props.name,
        keyName: 'key',
    })

    const formPermissions = fields as unknown as FormPermissionEntry[]
    const error = errors[props.name]

    const onToggle = (newPermissions: AccountTypePermissions[]) => (value: boolean) => {
        if (value) {
            const values = newPermissions.map(
                permission => ({ id: permission, permission } as const),
            )
            append(values)
        } else {
            const indexes = formPermissions
                .map((p, index) => ({ id: p.permission, index } as const))
                .filter(p => newPermissions.includes(p.id))
                .map(p => p.index)
            remove(indexes)
        }
        trigger(props.name)
    }

    const categories = useMemo(() => permissionsCategories(t), [t])

    return (
        <div>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            {categories.map(category => {
                const allCategoryPermissions = Object.values(category.permissions).flat()
                return (
                    <div key={category.name}>
                        <div className='flex'>
                            <div className='flex items-center font-medium text-atamblue-700'>
                                <Icon
                                    path={category.icon}
                                    size={1}
                                    className='mr-2 text-atamsky-900'
                                />
                                <div>{category.name}</div>

                                <ToggleSwitch
                                    value={allCategoryPermissions.every(p =>
                                        formPermissions.some(f => f.permission === p),
                                    )}
                                    size={1.5}
                                    className='ml-2'
                                    onChange={onToggle(allCategoryPermissions)}
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-4 gap-x-5 mb-5'>
                            {Object.entries(category.permissions).map(([name, permissions]) => (
                                <div key={`${category.name}-${name}`}>
                                    <ToggleSwitch
                                        value={permissions.every(p =>
                                            formPermissions.some(f => f.permission === p),
                                        )}
                                        size={1.5}
                                        onChange={onToggle(permissions)}
                                    >
                                        <div className='ml-2 text-sm leading-4 text-atamblue-900'>
                                            {name}
                                        </div>
                                    </ToggleSwitch>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const convertToFormType = (accountType: AccountType): AccountTypeFormType => ({
    name: accountType.name,
    permissions: accountType.permissions.map(permission => ({ permission })),
})

const convertFromFormType = (accountType: AccountTypeFormType): Omit<AccountType, 'id'> => ({
    name: accountType.name,
    permissions: accountType.permissions.map(({ permission }) => permission),
})

export const AccountTypeForm: React.FC<{
    accountType?: AccountType
    isSaving: boolean
    onSave: (accountType: Omit<AccountType, 'id'>) => Promise<void>
    onCancel: () => void
}> = props => {
    const { t } = useTranslation('accountType')
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<AccountTypeFormType>>({})

    const onSubmit = (accountType: AccountTypeFormType) =>
        props.onSave(convertFromFormType(accountType)).catch(error => {
            if (error.data?.name === 'AccountTypeNameExists') {
                setExternalErrors({ name: 'Account type name already exists' })
                return
            }
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        })

    return (
        <Form
            schema={AccountTypeFormSchema}
            defaults={props.accountType ? convertToFormType(props.accountType) : {}}
            externalErrors={externalErrors}
            onSubmit={onSubmit}
        >
            <TextField
                name='name'
                label='Account Type Name:'
                placeholder='Type account type name'
                className='pr-4'
                inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                labelClassName='text-atamblue-700'
            />
            <Separator className='w-full h-1 my-5 bg-ebony-400'></Separator>

            <AccountTypePermissionsField name='permissions' />

            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    {t('Apply')}
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
