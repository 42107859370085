import { useState, useCallback } from 'react'

import {
    isPending,
    isSuccess,
    isFailure,
    setEmpty,
    setPending,
    setFailure,
    setSuccess,
    AsyncState,
    getData,
    getError,
} from './asyncState'

type UseAsyncReturn<P, R, E = any> = {
    isPending: boolean
    isSuccess: boolean
    isFailure: boolean
    start: (params: P) => Promise<R>
    results: R | undefined
    error: E
}

export const useAsync = <P, R, E = any>(
    resolver: (params: P) => Promise<R>,
): UseAsyncReturn<P, R, E> => {
    const [state, setState] = useState<AsyncState<R, any>>(setEmpty())

    const start = useCallback(
        (params: P) => {
            setState(setPending)
            return resolver(params)
                .then(results => {
                    setState(setSuccess(results))
                    return results
                })
                .catch((error: any) => {
                    setState(setFailure(error))
                    throw error
                })
        },
        [resolver],
    )

    return {
        isPending: isPending(state),
        isSuccess: isSuccess(state),
        isFailure: isFailure(state),
        start,
        results: getData(state),
        error: getError(state),
    }
}
