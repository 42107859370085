import React from 'react'

import { useEditAccountTypeMutation } from '../../api/for-company/account-types.api'
import { AccountType } from '../../domain/account-type.domain'

import { AccountTypeForm } from './AccountTypeForm'

export const EditAccountType: React.FC<{
    accountType: AccountType
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [editAccountType, { isLoading }] = useEditAccountTypeMutation()

    const onSave = (accountType: Omit<AccountType, 'id'>) =>
        editAccountType({ ...accountType, id: props.accountType.id })
            .unwrap()
            .then(props.onSave)

    return (
        <AccountTypeForm
            accountType={props.accountType}
            isSaving={isLoading}
            onSave={onSave}
            onCancel={props.onCancel}
        />
    )
}
