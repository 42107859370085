
import Icon from '@mdi/react'
import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const IconAction = BaseAtom<{ path: string; size: number }>(props => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <Icon path={props.path} size={props.size} />
        </div>
    )
})
