import { mdiCalendarToday, mdiLoading, mdiAlertCircle } from '@mdi/js'
import Icon from '@mdi/react'
import { noop } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import {
    useEditAircraftInformationMutation,
    useExportAircraftInformationHtmlMutation,
    useExportAircraftInformationPdfMutation,
    useExportAircraftInformationXlsxMutation,
    useGetAircraftInformationQuery,
} from '../../../api/for-company/aircraft-information.api'
import { selectProjectById } from '../../../api/for-company/projects.api'
import { Card } from '../../../atoms/Card'
import { ExportHtmlButton } from '../../../atoms/Export/ExportHtmlButton'
import { ExportPdfButton } from '../../../atoms/Export/ExportPdfButton'
import { ExportXlsButton } from '../../../atoms/Export/ExportXlsButton'
import { DateInput } from '../../../atoms/Form/DateInput'
import {
    AircraftInformationModule,
    AircraftInformationModuleType,
} from '../../../domain/aircraft-information.domain'
import { ProjectRolePermissions } from '../../../domain/project-role.domain'
import { Project } from '../../../domain/project.domain'
import { useTranslation } from '../../../lib/i18n'
import { openFile } from '../../../lib/open-file'
import { useAppSelector } from '../../../store'
import { ForProjectRole, WithProjectRole } from '../../Auth/ForProjectRole'

import { AddModuleButtons } from './AddModuleButtons'
import { AircraftInfoModule } from './AircraftInformationModule'
import { moduleMap } from './aircraftInformationModuleMap'

const AircraftInformationSection: React.FC<{
    readOnly: boolean
    projectId: Project['id']
    modules: AircraftInformationModule[]
}> = props => (
    <div className='flex flex-wrap'>
        {props.modules.map(module => (
            <AircraftInfoModule
                projectId={props.projectId}
                key={module.name}
                readOnly={props.readOnly}
                module={module}
                mainColor={moduleMap[module.type].mainColor}
                mainColorFaded={moduleMap[module.type].mainColorFaded}
                gridSeparatorColor={moduleMap[module.type].gridSeparatorColor}
                gridTone={moduleMap[module.type].gridTone}
                onFocusBg={moduleMap[module.type].onFocusBg}
                onFocusText={moduleMap[module.type].onFocusText}
                columns={moduleMap[module.type].columns}
            />
        ))}
    </div>
)

export const AircraftInformation: React.FC<{
    projectId: Project['id']
}> = props => {
    const project = useSelector(selectProjectById(props.projectId))
    const { data } = useGetAircraftInformationQuery({ projectId: props.projectId })
    const [editAircraftInformation, editAircraftInformationMeta] =
        useEditAircraftInformationMutation()
    const [exportHtml, exportHtmlMeta] = useExportAircraftInformationHtmlMutation()
    const [exportPdf, exportPdfMeta] = useExportAircraftInformationPdfMutation()
    const [exportXlsx, exportXlsxMeta] = useExportAircraftInformationXlsxMutation()

    const { t } = useTranslation('aircraftInformation')

    const onDataPerChange = (value: Date) =>
        editAircraftInformation({ projectId: props.projectId, dataPer: value })

    if (!data || !project) {
        return null
    }

    const modules = data.modules

    return (
        <>
            <div className='flex justify-between items-center mb-1'>
                <div className='inline-flex px-2 py-0.5 border-b border-atamsky-900 text-sm'>
                    <Icon
                        path={mdiCalendarToday}
                        size={0.75}
                        className='my-auto text-atamsky-900'
                    />
                    <div className='mx-1 my-auto py-2'>{t('DataPer')}:</div>

                    <WithProjectRole projectId={props.projectId}>
                        {({ hasPermission }) => (
                            <DateInput
                                value={data.dataPer}
                                disabled={
                                    project.isArchived ||
                                    !hasPermission(
                                        ProjectRolePermissions.AircraftInformationDateEdit,
                                    )
                                }
                                onChange={onDataPerChange}
                            />
                        )}
                    </WithProjectRole>
                    {editAircraftInformationMeta.isLoading ? (
                        <Icon path={mdiLoading} spin size={1} className='ml-4 self-center' />
                    ) : null}
                    {editAircraftInformationMeta.isError ? (
                        <Icon
                            path={mdiAlertCircle}
                            title='Error while saving'
                            size={1}
                            className='ml-4 self-center text-red-500'
                        />
                    ) : null}
                </div>
                <div className='flex'>
                    {!project.isArchived ? (
                        <AddModuleButtons projectId={props.projectId} onAdd={noop} />
                    ) : null}
                    <div className='flex ml-12'>
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationExportXls}
                            projectId={props.projectId}
                        >
                            <ExportXlsButton
                                isLoading={exportXlsxMeta.isLoading}
                                onClick={() =>
                                    exportXlsx({ projectId: props.projectId })
                                        .unwrap()
                                        .then(({ path }) => openFile(path))
                                }
                            />
                        </ForProjectRole>
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationExportPdf}
                            projectId={props.projectId}
                        >
                            <ExportPdfButton
                                isLoading={exportPdfMeta.isLoading}
                                onClick={() =>
                                    exportPdf({ projectId: props.projectId })
                                        .unwrap()
                                        .then(({ path }) => openFile(path))
                                }
                            />
                        </ForProjectRole>
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationExportStatic}
                            projectId={props.projectId}
                        >
                            <ExportHtmlButton
                                isLoading={exportHtmlMeta.isLoading}
                                onClick={() =>
                                    exportHtml({ projectId: props.projectId })
                                        .unwrap()
                                        .then(({ path }) => openFile(path))
                                }
                            />
                        </ForProjectRole>
                    </div>
                </div>
            </div>
            <AircraftInformationSection
                projectId={props.projectId}
                readOnly={project.isArchived}
                modules={modules.filter(m =>
                    [
                        AircraftInformationModuleType.Airframe,
                        AircraftInformationModuleType.APU,
                    ].includes(m.type),
                )}
            />
            <AircraftInformationSection
                projectId={props.projectId}
                readOnly={project.isArchived}
                modules={modules.filter(m => AircraftInformationModuleType.Engine === m.type)}
            />
            <AircraftInformationSection
                projectId={props.projectId}
                readOnly={project.isArchived}
                modules={modules.filter(m => AircraftInformationModuleType.LandingGear === m.type)}
            />
        </>
    )
}
