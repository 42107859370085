import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ErrorMessage } from '../ErrorMessage'

export type CheckboxInputProps = {
    value: boolean
    className?: string
    iconClassName?: string
    disabled?: boolean
    error?: string
    onChange: (newValue: boolean) => void
}
export const CheckboxInput: React.FC<CheckboxInputProps> = props => {
    const [isChecked, setChecked] = useState(props.value)
    return (
        <div
            className={classNames(
                'flex items-center',
                {
                    'text-gray-500': props.disabled,
                    'cursor-pointer': !props.disabled,
                },
                props.className,
            )}
            onClick={() => {
                if (props.disabled) {
                    return
                }
                props.onChange(!isChecked)
                setChecked(isChecked => !isChecked)
            }}
        >
            <Icon
                path={isChecked ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
                size={0.75}
                className={classNames('mr-2', props.iconClassName)}
            />
            {props.children}
            {props.error ? <ErrorMessage className='ml-3'>{props.error}</ErrorMessage> : null}
        </div>
    )
}

export const CheckboxField: React.FC<
    { name: string } & Omit<CheckboxInputProps, 'value' | 'onChange'>
> = props => {
    const { name, ...checkboxProps } = props
    const {
        getValues,
        setValue,
        formState: { errors },
    } = useFormContext()
    const value = getValues()[name]
    const error = errors[name]
    return (
        <CheckboxInput
            {...checkboxProps}
            value={value}
            onChange={newValue =>
                setValue(name, newValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                })
            }
            error={error?.message}
        />
    )
}
