import { z } from 'zod'

export enum AircraftInformationBlockType {
    String = 'String',
    Number = 'Number',
    Date = 'Date',
}

// export const AircraftInformationBlockSchema = z.union([
//     z.object({
//         id: z.number(),
//         name: z.string(),
//         isEditable: z.boolean(),
//         type: z.literal(AircraftInformationBlockType.String),
//         value: z.union([z.string(), z.null()]),
//     }),
//     z.object({
//         id: z.number(),
//         name: z.string(),
//         isEditable: z.boolean(),
//         type: z.literal(AircraftInformationBlockType.Date),
//         value: z.union([z.date(), z.null()]),
//     }),
//     z.object({
//         id: z.number(),
//         name: z.string(),
//         isEditable: z.boolean(),
//         type: z.literal(AircraftInformationBlockType.Number),
//         value: z.union([z.date(), z.null()]),
//         params: z.object({ unit: z.string() }),
//     }),
// ])

export const AircraftInformationBlockSchema = z.object({
    id: z.number(),
    name: z.string(),
    value: z.union([z.string(), z.null()]),
    type: z.nativeEnum(AircraftInformationBlockType),
    params: z.union([z.any(), z.null()]),
    isEditable: z.boolean(),
})
export type AircraftInformationBlock = z.TypeOf<typeof AircraftInformationBlockSchema>

export enum AircraftInformationModuleType {
    Airframe = 'Airframe',
    APU = 'APU',
    Engine = 'Engine',
    LandingGear = 'LandingGear',
}

export const AircraftInformationModuleSchema = z.object({
    id: z.number(),
    name: z.string(),
    type: z.nativeEnum(AircraftInformationModuleType),
    blocks: z.array(AircraftInformationBlockSchema),
    isEditable: z.boolean(),
})
export type AircraftInformationModule = z.TypeOf<typeof AircraftInformationModuleSchema>

export const AircraftInformationSchema = z.object({
    dataPer: z.date(),
    modules: z.array(AircraftInformationModuleSchema),
})
export type AircraftInformation = z.TypeOf<typeof AircraftInformationSchema>

/** Form types */
export const AircraftInformationModuleFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    type: z.nativeEnum(AircraftInformationModuleType),
})
export type AircraftInformationModuleFormType = z.TypeOf<typeof AircraftInformationModuleFormSchema>

export const AircraftInformationBlockFormSchema = z
    .object({
        type: z.nativeEnum(AircraftInformationBlockType),
        name: z.string(),
        value: z.any(),
    })
    .refine(
        ({ name, value }) => {
            if (!!value && value.length > 0) {
                if (!name || name.length === 0) {
                    return false
                }
            }
            return true
        },
        {
            path: ['name'],
            message: 'Name required if value is set',
        },
    )
export type AircraftInformationBlockFormType = z.TypeOf<typeof AircraftInformationBlockFormSchema>
