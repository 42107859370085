import classNames from 'classnames'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Slider from 'react-input-slider'

import { ErrorMessage } from '../ErrorMessage'

export type NumberInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string
    inputClassName?: string
    labelClassName?: string
    label?: string
    error?: { message: string }
}

export const NumberInput = React.forwardRef<HTMLInputElement | null, NumberInputProps>(
    (props, ref) => {
        const { className, inputClassName, labelClassName, label, error, value, ...inputProps } =
            props

        return (
            <div className={className}>
                {label ? <div className={labelClassName}>{label}</div> : null}
                <input
                    {...inputProps}
                    className={classNames(
                        'bg-transparent p-2 w-full focus:outline-none',
                        {
                            'bg-ebony-300': props.disabled,
                        },
                        inputClassName,
                    )}
                    ref={ref}
                    type='number'
                    value={value ?? ''}
                />
                {error ? <ErrorMessage className='pt-1'>{error.message}</ErrorMessage> : null}
                {/* {props.maxLength ? (
                    <FieldHint
                        message={`Maximum number of characters: ${(value).length ?? 0}/${
                            props.maxLength
                        }`}
                        className='ml-2 mt-2 text-atamblue-500'
                    />
                ) : null} */}
            </div>
        )
    },
)

export type NumberFieldProps = Omit<NumberInputProps, 'value' | 'onChange'> & { name: string }
export const NumberField: React.FC<NumberFieldProps> = props => {
    const { name, ...otherProps } = props
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext()
    const inputValue = useWatch({ control, name }) as string
    const error = errors[name]

    return <NumberInput {...otherProps} {...register(name)} error={error} value={inputValue} />
}

export const SlideNumberInput: React.FC<{
    value: number
    min: number
    max: number
    step: number
    disabled?: boolean
    className?: string
    handleClassName?: string
    barClassName?: string
    onChange: (newValue: number) => void
}> = props => {
    const step = props.step ?? 1
    const disabled = props.disabled ?? false
    return (
        <Slider
            axis='x'
            x={props.value}
            xmin={props.min}
            xmax={props.max}
            disabled={disabled}
            xstep={step}
            styles={{
                track: {
                    backgroundColor: '#f2f2f8',
                    boxShadow: 'inset -4px 4px 2px 0 rgb(0 0 0 / 10%);',
                    width: '100%',
                    height: '0.75rem',
                },
                active: {
                    backgroundColor: '#00A7E1',
                    boxShadow: 'inset 4px 4px 2px 0 rgb(0 0 0 / 10%);',
                },
                thumb: {
                    backgroundColor: '#0072A3',
                    width: 20,
                    height: 20,
                    boxShadow: 'inset -6px -6px 3px 0 rgb(0 0 0 / 30%);',
                },
            }}
            onChange={({ x }) => props.onChange(x)}
        />
    )
}
