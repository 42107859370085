import React from 'react'

import { ForProjectRole } from '../components/Auth/ForProjectRole'
import { EditProject } from '../components/Projects/EditProject'
import { ProjectRolePermissions } from '../domain/project-role.domain'
import { routes } from '../routes'

import { Page } from './shared/Page'

export const EditProjectPage: React.FC = () => {
    const { projectId } = routes.EditProject.useParams()
    return (
        <ForProjectRole permission={ProjectRolePermissions.ProjectEdit} projectId={projectId}>
            <Page>
                <EditProject projectId={projectId} />
            </Page>
        </ForProjectRole>
    )
}
