import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'
import { Tooltip } from './Tooltip'

export const CircledInitial = BaseAtom<{
    tooltip?: string
    content: string
    size?: 'lg' | 'md' | 'sm'
    className?: string
}>(props => {
    const size = props.size ?? 'lg'

    const smClassName = 'rounded-lg border-2 w-5 h-5 text-xxs'
    const mdClassName = 'rounded-xlx w-10 h-10'
    const lgClassName = 'rounded-2xl border-4 w-12 h-12'

    return (
        <Tooltip text={props.tooltip}>
            <div
                className={classNames(
                    'inline-flex justify-center items-center font-medium',
                    {
                        [smClassName]: size === 'sm',
                        [mdClassName]: size === 'md',
                        [lgClassName]: size === 'lg',
                    },
                    props.className,
                )}
                onClick={props.onClick}
            >
                {props.content}
            </div>
        </Tooltip>
    )
})

export const CircledText = BaseAtom<{
    tooltip?: string
    size?: 'lg' | 'md' | 'sm'
    className?: string
}>(props => {
    const size = props.size ?? 'lg'

    const smClassName = 'rounded-2xl border-2 text-xxs p-1'
    const mdClassName = 'rounded-xlx border-2 text-xs p-2 h-10'
    const lgClassName = 'rounded-2xl border-4 p-2 h-12'

    return (
        <div
            className={classNames(
                'w-full inline-flex justify-center items-center font-medium truncate',
                {
                    [smClassName]: size === 'sm',
                    [mdClassName]: size === 'md',
                    [lgClassName]: size === 'lg',
                },
                props.className,
            )}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
})
