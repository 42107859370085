import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const FloatingButton = BaseAtom(props => {
    return (
        <button
            className='flex items-center justify-center fixed bottom-0 ml-5 bg-atamsky-900 h-12 px-10 py-2 rounded-tl-2xl rounded-tr-2xl shadow-md text-white text-base font-medium hover:bg-atamsky-700 focus:outline-none z-10'
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
})
