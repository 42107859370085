import { mdiClose, mdiCheck, mdiLoading, mdiRestore } from '@mdi/js'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import useCountDown from 'react-countdown-hook'
import { useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { useForgotPasswordMutation } from '../../api/for-public.api'
import { ExternalErrors, ExternalFormError, Form, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import { ResendForgotPasswordEmailTimerMs } from '../../domain/auth.domain'
import { useTranslation } from '../../lib/i18n'

const ForgotPasswordSchema = z.object({
    companyCode: z.string().min(1, 'Company code required'),
    email: z.string().email(),
})
type ForgotPasswordFormType = z.TypeOf<typeof ForgotPasswordSchema>

const ResendEmail: React.FC<{
    isPending: boolean
    onSend: (fields: ForgotPasswordFormType) => Promise<void>
    onCancel: () => void
}> = props => {
    const [timeLeft, { start }] = useCountDown(ResendForgotPasswordEmailTimerMs)
    const { t } = useTranslation(['login', 'common'])
    const email = useWatch({ name: 'email' })
    const companyCode = useWatch({ name: 'companyCode' })

    useEffect(() => {
        start()
    }, [start])

    const isResendDisabled = timeLeft > 0 || props.isPending

    return (
        <div className='mt-3 leading-tight text-center'>
            <div className='text-atamblue-500 text-sm'>{t('AMessageWithInstructions')}</div>
            <div className='my-2 text-atamblue-700'>{email}</div>
            <div className='text-atamblue-500 text-sm'>{t('CheckYourEmailMessage')}</div>
            <div className='text-red-500 text-sm'>{t('SpamNote')}</div>
            <div className='text-red-500 text-sm'>{t('TypoNote')}</div>
            <div className='text-atamblue-500 text-sm'>{t('ResendMessageText')}</div>
            <div className='my-2 text-atamblue-700'>{Math.round(timeLeft / 1000)} sec</div>
            <div className='flex justify-center mt-6'>
                <IconButton
                    onClick={() => props.onSend({ companyCode, email }).then(() => start())}
                    path={props.isPending ? mdiLoading : mdiRestore}
                    spin={props.isPending}
                    disabled={isResendDisabled}
                    className={classNames('h-12 mx-10 text-atamblue-700 group', {
                        'hover:text-atamsky-900': !isResendDisabled,
                    })}
                    iconClassName={classNames('mr-2 text-green-300', {
                        ' group-hover:text-green-500': !isResendDisabled,
                    })}
                >
                    {t('Resend')}
                </IconButton>
                <Separator className='w-px my-2 bg-atamsky-400'></Separator>
                <IconButton
                    onClick={props.onCancel}
                    path={mdiClose}
                    className='h-12 mx-10 transition duration-500 text-atamblue-700 group hover:text-atamsky-900'
                    iconClassName='mr-2 text-red-400 group-hover:text-red-500'
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </div>
    )
}

export const ForgotPassword: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation(['login', 'common'])

    const [forgotPassword, forgotPasswordMeta] = useForgotPasswordMutation()
    const [hasSentInitial, setSentInitial] = useState(false)
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<ForgotPasswordFormType>>({})

    const onSubmit = (fields: ForgotPasswordFormType) =>
        forgotPassword(fields)
            .unwrap()
            .then(() => setSentInitial(true))
            .catch(() => setExternalErrors({ [FORM_ERROR]: 'Unknown error' }))

    const onCancel = () => {
        navigate(-1)
    }

    return (
        <Form schema={ForgotPasswordSchema} externalErrors={externalErrors} onSubmit={onSubmit}>
            <h2 className='mb-16 text-center text-3xl font-semibold text-atamblue-900'>
                {t('ForgotPassword')}
            </h2>

            <ExternalFormError />
            {hasSentInitial ? (
                <ResendEmail
                    isPending={forgotPasswordMeta.isLoading}
                    onSend={onSubmit}
                    onCancel={onCancel}
                />
            ) : (
                <div className='formFields flex flex-col w-full'>
                    <TextField
                        name='companyCode'
                        inputClassName='appearance-none bg-transparent leading-tigh focus:outline-none mb-1 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        placeholder={t('CompanyCode')}
                    />
                    <TextField
                        name='email'
                        inputClassName='appearance-none bg-transparent leading-tigh focus:outline-none mb-1 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        placeholder={t('LoginEmailAddress')}
                    />
                    <div className='flex justify-center mt-16'>
                        <IconButton
                            path={forgotPasswordMeta.isLoading ? mdiLoading : mdiCheck}
                            spin={forgotPasswordMeta.isLoading}
                            disabled={forgotPasswordMeta.isLoading}
                            className='h-12 mx-10 text-atamblue-700 group hover:text-atamsky-900'
                            iconClassName='mr-2 text-green-300 group-hover:text-green-500'
                            isSubmit
                        >
                            {t('Confirm')}
                        </IconButton>
                        <Separator className='w-px my-2 bg-atamsky-400'></Separator>
                        <IconButton
                            onClick={onCancel}
                            path={mdiClose}
                            className='h-12 mx-10 transition duration-500 text-atamblue-700 group hover:text-atamsky-900'
                            iconClassName='mr-2 text-red-400 group-hover:text-red-500'
                        >
                            {t('Cancel')}
                        </IconButton>
                    </div>
                </div>
            )}
        </Form>
    )
}
