import { z } from 'zod'

export const CredentialsSchema = z.object({
    companyCode: z.string(),
    email: z.string(),
    password: z.string(),
})
export type Credentials = z.TypeOf<typeof CredentialsSchema>

export const ResendForgotPasswordEmailTimerMs = 30000
