import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateUserMutation } from '../../api/for-company/users.api'
import { UserFormType } from '../../domain/user.domain'

import { UserForm } from './UserForm'

export const CreateUser: React.FC<{
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [createUser, { isLoading }] = useCreateUserMutation()

    const onCreateUser = (createdUser: UserFormType) => {
        const projectsToAdd = createdUser.projectAssignments.map(assignment => ({
            projectId: assignment.project.id,
            projectRoleId: assignment.projectRole.id,
            entity: assignment.entity,
        }))

        return createUser({
            code: createdUser.code,
            name: createdUser.name,
            email: createdUser.email,
            isActive: true,
            accountTypeId: createdUser.accountType.id,
            projectAssignments: {
                add: projectsToAdd,
                remove: [],
            },
        })
            .unwrap()
            .then(props.onSave)
    }

    const { t } = useTranslation('users')

    return (
        <div>
            <UserForm isSaving={isLoading} onSave={onCreateUser} onCancel={props.onCancel} />
            <div className='m-4 text-center text-sm text-atamblue-700 whitespace-pre'>
                {t('NewAccountSideNote')}
            </div>
        </div>
    )
}
