import { createSelector } from '@reduxjs/toolkit'
import { z } from 'zod'

import {
    ProjectAssignmentEntity,
    ProjectAssignmentSchema,
    ProjectSchema,
} from '../../domain/project.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

const ProjectMutationSchema = ProjectSchema.pick({
    name: true,
    startDate: true,
    endDate: true,
    auditorName: true,
    auditorIcao: true,
    auditeeName: true,
    auditeeIcao: true,
}).merge(
    z.object({
        auditorLogo: z
            .object({
                content: z.string(),
            })
            .optional(),
        subjects: z.object({
            add: z.array(z.number()),
            remove: z.array(z.number()),
        }),

        assignments: z.object({
            add: z.array(
                z.object({
                    userId: z.number(),
                    projectRoleId: z.number(),
                    entity: z.nativeEnum(ProjectAssignmentEntity),
                }),
            ),
            remove: z.array(z.number()),
        }),
        hasRAS: z.boolean(),
        hasPAS: z.boolean(),
        hasROIL: z.boolean(),
        hasPOIL: z.boolean(),
    }),
)

export const projectsApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listProjects: buildQuery({
                input: z.any(),
                output: ListResponseSchema(ProjectSchema),
                query: params => ({
                    url: `/project${serializeQueryParams(params)}`,
                    method: 'GET',
                }),
                providesTags: ['ProjectsList'],
            }),
            getMyProjects: buildQuery({
                input: z.void(),
                output: ListResponseSchema(
                    ProjectSchema.pick({
                        id: true,
                        name: true,
                        auditorName: true,
                        auditorIcao: true,
                        auditorLogo: true,
                        auditeeName: true,
                        auditeeIcao: true,
                        startDate: true,
                        endDate: true,
                        hasROIL: true,
                        hasPOIL: true,
                        hasRAS: true,
                        hasPAS: true,
                        isArchived: true,
                        subjects: true,
                    }).merge(
                        z.object({
                            assignment: ProjectAssignmentSchema,
                            assignments: z.array(z.object({ id: z.number(), name: z.string() })),
                        }),
                    ),
                ),
                query: () => ({
                    url: '/project/assigned',
                    method: 'GET',
                }),
                providesTags: ['MyProjectsList'],
            }),
            getProjectById: buildQuery({
                input: ProjectSchema.pick({ id: true }),
                output: ProjectSchema,
                query: ({ id }) => ({
                    url: `/project/${id}`,
                    method: 'GET',
                }),
            }),
            createProject: buildMutation({
                input: z.any(), // TODO: replace this
                output: ProjectSchema.pick({ id: true }),
                query: projectFields => ({
                    url: '/project',
                    method: 'POST',
                    body: projectFields,
                }),
                invalidatesTags: ['MyProjectsList', 'ProjectsList', 'DashboardStats'],
            }),
            editProject: buildMutation({
                input: z.any(), // TODO: replace this
                output: z.void(),
                query: ({ id, ...body }) => ({
                    url: `/project/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: [
                    'MyProjectsList',
                    'ProjectsList',
                    'RASSummary',
                    'PASSummary',
                    'RAS',
                    'PAS',
                    'DashboardStats',
                ],
            }),
            archiveProject: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/project/${id}/archive`,
                    method: 'PUT',
                }),
                invalidatesTags: ['MyProjectsList', 'ProjectsList', 'DashboardStats'],
            }),
            unarchiveProject: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/project/${id}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: ['MyProjectsList', 'ProjectsList', 'DashboardStats'],
            }),
        }
    },
})

export const {
    useListProjectsQuery,
    useGetMyProjectsQuery,
    useGetProjectByIdQuery,
    useCreateProjectMutation,
    useEditProjectMutation,
    useArchiveProjectMutation,
    useUnarchiveProjectMutation,
} = projectsApi

export const selectProjectById = (projectId: number) =>
    createSelector(projectsApi.endpoints.getMyProjects.select(), projects =>
        projects.data?.results.find(p => p.id === projectId),
    )

export const selectAllProjects = () =>
    createSelector(
        projectsApi.endpoints.getMyProjects.select(),
        projects => projects.data?.results ?? [],
    )

export const useAssignedProject = (props: { projectId: number }) => {
    const { data } = useGetMyProjectsQuery()
    if (!data) {
        return undefined
    }

    return data.results.find(project => project.id === props.projectId)
}
