import { mdiPlus } from '@mdi/js'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAddAircraftInformationModuleMutation } from '../../../api/for-company/aircraft-information.api'
import { IconButton } from '../../../atoms/IconButton'
import { Modal } from '../../../atoms/Modal/Modal'
import {
    AircraftInformationModule,
    AircraftInformationModuleFormType,
    AircraftInformationModuleType,
} from '../../../domain/aircraft-information.domain'
import { ProjectRolePermissions } from '../../../domain/project-role.domain'
import { ForProjectRole } from '../../Auth/ForProjectRole'

import { AircraftInformationModuleForm } from './AircraftInformationModuleForm'

export const AddModuleButtons: React.FC<{
    projectId: number
    className?: string
    onAdd: (module: AircraftInformationModule) => void
}> = props => {
    const { t } = useTranslation('aircraftInformation')
    const [addAircraftInformationModule, addAircraftInformationModuleMeta] =
        useAddAircraftInformationModuleMutation()

    const onSave = (newModule: AircraftInformationModuleFormType) =>
        addAircraftInformationModule({
            projectId: props.projectId,
            name: newModule.name,
            type: newModule.type,
        }).unwrap()

    return (
        <div className={classNames('flex items-center', props.className)}>
            <ForProjectRole
                permission={ProjectRolePermissions.AircraftInformationCreateEngineModule}
                projectId={props.projectId}
            >
                <Modal
                    body={({ close }) => {
                        return (
                            <AircraftInformationModuleForm
                                module={{
                                    name: '',
                                    type: AircraftInformationModuleType.Engine,
                                }}
                                isSaving={addAircraftInformationModuleMeta.isLoading}
                                onSave={module => onSave(module).then(close)}
                                onCancel={close}
                            />
                        )
                    }}
                >
                    <IconButton
                        isSubmit
                        path={mdiPlus}
                        size={0.75}
                        className='rounded-2xl transition duration-500 bg-indigo-100 mx-0.5 px-6 py-3 shadow-md text-indigo-800 font-medium text-sm hover:bg-indigo-400'
                    >
                        {t('AddEngine')}
                    </IconButton>
                </Modal>
            </ForProjectRole>
            <ForProjectRole
                permission={ProjectRolePermissions.AircraftInformationCreateLandingGearModule}
                projectId={props.projectId}
            >
                <Modal
                    body={({ close }) => {
                        return (
                            <AircraftInformationModuleForm
                                module={{
                                    name: '',
                                    type: AircraftInformationModuleType.LandingGear,
                                }}
                                isSaving={addAircraftInformationModuleMeta.isLoading}
                                onSave={module => onSave(module).then(close)}
                                onCancel={close}
                            />
                        )
                    }}
                >
                    <IconButton
                        isSubmit
                        path={mdiPlus}
                        size={0.75}
                        className='rounded-2xl transition duration-500 bg-ebony-300 mx-0.5 px-6 py-3 shadow-md text-ebony-800 font-medium text-sm hover:bg-ebony-500'
                    >
                        {t('AddLandingGear')}
                    </IconButton>
                </Modal>
            </ForProjectRole>
        </div>
    )
}
