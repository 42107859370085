import React from 'react'

type BaseAtomProps = {
    className?: string
    style?: React.CSSProperties
    children?: React.ReactNode
    id?: string
    onClick?: (event: React.MouseEvent<any, MouseEvent>) => void
}

export const BaseAtom = <T,>(atom: React.FC<T & BaseAtomProps>): React.FC<T & BaseAtomProps> => (
    props: T & BaseAtomProps,
) => atom(props)
