import { mdiDatabaseArrowRight, mdiDomain } from '@mdi/js'
import React from 'react'

import { useListCompaniesQuery } from '../../api/for-superadmin/company.api'
import { forCompanyApi } from '../../api/_base.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { Company } from '../../domain/company.domain'
import { authActions, selectCurrentCompanyId } from '../../state/auth.slice'
import { useAppDispatch, useAppSelector } from '../../store'

export const CompanySelector: React.FC<{
    value?: Company | undefined
    exclude?: Company[]
    className?: string
    iconClassName?: string
    placeholder?: string
    onChange: (value: Company | undefined) => void
}> = props => {
    return (
        <EntitySelector<Company>
            useResolver={useListCompaniesQuery}
            resolverParams={{}}
            getOptionLabel={project => project.name}
            defaultValue={props.value}
            exclude={props.exclude}
            placeholder='Select Company'
            className={props.className}
            iconClassName={props.iconClassName}
            icon={mdiDatabaseArrowRight}
            onChange={props.onChange}
        />
    )
}

export const SelectCompany: React.FC = () => {
    const currentCompany = useAppSelector(selectCurrentCompanyId)
    const dispatch = useAppDispatch()

    return (
        <div className='w-72'>
            <CompanySelector
                className='px-4'
                iconClassName='mr-4 text-atamblue-700'
                // value={currentCompany}
                onChange={company => {
                    dispatch(forCompanyApi.util.resetApiState())
                    dispatch(authActions.changeCompany(company))
                }}
            />
        </div>
    )
}
