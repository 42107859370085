import { z } from 'zod'

import { AccountTypeSchema } from './account-type.domain'
import { ProjectRoleSchema } from './project-role.domain'
import { ProjectAssignmentEntity, ProjectSchema } from './project.domain'

export const UserSchema = z.object({
    id: z.number(),
    name: z.string().min(1),
    code: z.string().min(4).max(4),
    email: z.string().email(),
    isActive: z.boolean(),
    accountType: AccountTypeSchema.pick({ id: true, name: true }),
    projectAssignments: z.array(
        z.object({
            project: ProjectSchema.pick({
                id: true,
                name: true,
                isArchived: true,
            }),
            projectRole: ProjectRoleSchema.pick({ id: true, name: true }),
        }),
    ),
    auth: z.object({ id: z.number() }).nullable(),
})
export type User = z.TypeOf<typeof UserSchema>

export const UserFormSchema = z.object({
    code: z.string().min(4, 'Must be 4 characters').max(4, 'Must be 4 characters'),
    name: z.string().min(1, 'Name requred'),
    email: z.string().email('Must be a valid email'),
    accountType: AccountTypeSchema.pick({ id: true, name: true }),
    projectAssignments: z.array(
        z.object({
            project: ProjectSchema.pick({
                id: true,
                name: true,
                isArchived: true,
            }),
            projectRole: ProjectRoleSchema.pick({ id: true, name: true }),
            entity: z.nativeEnum(ProjectAssignmentEntity),
        }),
    ),
})
export type UserFormType = z.TypeOf<typeof UserFormSchema>
