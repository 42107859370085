import { get } from 'lodash'
import { z } from 'zod'

export enum ProjectRolePermissions {
    ProjectEdit = 'ProjectEdit',
    ProjectDelete = 'ProjectDelete',
    ProjectView = 'ProjectView',
    ProjectArchive = 'ProjectArchive',
    ProjectUnarchive = 'ProjectUnarchive',
    ProjectViewSummary = 'ProjectViewSummary',
    ProjectSummaryExportSummaryDoc = 'ProjectSummaryExportSummaryDoc',
    ProjectSummaryExportSummaryXls = 'ProjectSummaryExportSummaryXls',
    ProjectSummaryExportSummaryPdf = 'ProjectSummaryExportSummaryPdf',
    ProjectSummaryExportSummaryStatic = 'ProjectSummaryExportSummaryStatic',
    ProjectSummaryExportSummaryEmail = 'ProjectSummaryExportSummaryEmail',

    AircraftInformationDateEdit = 'AircraftInformationDateEdit',
    AircraftInformationCreate = 'AircraftInformationCreate',
    AircraftInformationEdit = 'AircraftInformationEdit',
    AircraftInformationDelete = 'AircraftInformationDelete',
    AircraftInformationView = 'AircraftInformationView',
    AircraftInformationExportDoc = 'AircraftInformationExportDoc',
    AircraftInformationExportXls = 'AircraftInformationExportXls',
    AircraftInformationExportPdf = 'AircraftInformationExportPdf',
    AircraftInformationExportStatic = 'AircraftInformationExportStatic',
    AircraftInformationExportEmail = 'AircraftInformationExportEmail',
    AircraftInformationCreateEngineModule = 'AircraftInformationCreateEngineModule',
    AircraftInformationDeleteEngineModule = 'AircraftInformationDeleteEngineModule',
    AircraftInformationEditEngineModule = 'AircraftInformationEditEngineModule',
    AircraftInformationCreateLandingGearModule = 'AircraftInformationCreateLandingGearModule',
    AircraftInformationEditLandingGearModule = 'AircraftInformationEditLandingGearModule',
    AircraftInformationDeleteLandingGearModule = 'AircraftInformationDeleteLandingGearModule',
    AircraftInformationEditModuleBlock = 'AircraftInformationEditModuleBlock',

    OILCreate = 'OILCreate',
    OILEditAuditorStatus = 'OILEditAuditorStatus',
    OILEditAuditeeStatus = 'OILEditAuditeeStatus',
    OILEditSubject = 'OILEditSubject',
    OILEditObservationDescription = 'OILEditObservationDescription',
    OILAppendObservationDescription = 'OILAppendObservationDescription',
    OILAddComment = 'OILAddComment',
    OILList = 'OILList',
    OILView = 'OILView',
    OILViewHistory = 'OILViewHistory',
    OILMarkAsCritical = 'OILMarkAsCritical',
    OILTrash = 'OILTrash',
    OILUntrash = 'OILUntrash',
    OILViewTrashedItems = 'OILViewTrashedItems',
    OILViewUnderReviewItems = 'OILViewUnderReviewItems',
    OILExportItemsDoc = 'OILExportItemsDoc',
    OILExportItemsXls = 'OILExportItemsXls',
    OILExportItemsPdf = 'OILExportItemsPdf',
    OILExportItemsStatic = 'OILExportItemsStatic',
    OILExportItemsEmail = 'OILExportItemsEmail',

    RASViewSummary = 'RASViewSummary',
    RASListSubparts = 'RASListSubparts',
    RASEditSubpart = 'RASEditSubpart',
    RASAddSubjectRemark = 'RASAddSubjectRemark',
    RASAddSubpartRemark = 'RASAddSubparkRemark',
    RASAddSubpart = 'RASAddSubpart',
    RASExportXls = 'RASExportXls',
    RASExportStatic = 'RASExportStatic',
    RASExportPdf = 'RASExportPdf',
    RASSummaryExportXls = 'RASSummaryExportXls',
    RASSummaryExportStatic = 'RASSummaryExportStatic',
    RASSummaryExportPdf = 'RASSummaryExportPdf',

    PASViewSummary = 'PASViewSummary',
    PASListSubparts = 'PASListSubparts',
    PASEditSubpart = 'PASEditSubpart',
    PASAddSubjectRemark = 'PASAddSubjectRemark',
    PASAddSubpartRemark = 'PASAddSubparkRemark',
    PASAddSubpart = 'PASAddSubpart',
    PASExportXls = 'PASExportXls',
    PASExportStatic = 'PASExportStatic',
    PASExportPdf = 'PASExportPdf',
    PASSummaryExportXls = 'PASSummaryExportXls',
    PASSummaryExportStatic = 'PASSummaryExportStatic',
    PASSummaryExportPdf = 'PASSummaryExportPdf',
}
export const ProjectRolePermissionsSchema = z.nativeEnum(ProjectRolePermissions)

export type HasProjectRolePermissionsArgs = {
    isSuperadmin: boolean
    projectRole: Omit<ProjectRole, 'id'>
} & (
    | {
          permission: ProjectRolePermissions
      }
    | {
          anyPermission: ProjectRolePermissions[]
      }
    | {
          allPermissions: ProjectRolePermissions[]
      }
)
export const hasProjectRolePermissions = (args: HasProjectRolePermissionsArgs): boolean => {
    const permission = get(args, 'permission', undefined) as ProjectRolePermissions | undefined
    const anyPermission = get(args, 'anyPermission', undefined) as
        | ProjectRolePermissions[]
        | undefined
    const allPermissions = get(args, 'allPermissions', undefined) as
        | ProjectRolePermissions[]
        | undefined

    if (args.isSuperadmin) {
        return true
    }
    if (permission && args.projectRole.permissions.includes(permission)) {
        return true
    }
    if (anyPermission && anyPermission.some(p => args.projectRole.permissions.includes(p))) {
        return true
    }
    if (allPermissions && allPermissions.every(p => args.projectRole.permissions.includes(p))) {
        return true
    }
    return false
}

/** Schemas */
export const ProjectRoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    permissions: z.array(ProjectRolePermissionsSchema),
})
export type ProjectRole = z.TypeOf<typeof ProjectRoleSchema>

export const ProjectRoleFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    permissions: z
        .array(
            z.object({
                permission: ProjectRolePermissionsSchema,
            }),
        )
        .min(1, 'Must contain at least 1 permission'),
})
export type ProjectRoleFormType = z.TypeOf<typeof ProjectRoleFormSchema>
