import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { ForAuthenticated } from './components/Auth/ForAuthenticated'
import { ActivateAccountPage } from './pages/ActivateAccount.page'
import { AircraftInformationPage } from './pages/AircraftInformation.page'
import { CreateProjectPage } from './pages/CreateProject.page'
import { DashboardPage } from './pages/Dashboard.page'
import { EditProjectPage } from './pages/EditProject.page'
import { ForgotPasswordPage } from './pages/ForgotPassword.page'
import { ManageAccountTypesPage } from './pages/ManageAccountTypes.page'
import { ManageCompaniesPage } from './pages/ManageCompanies.page'
import { ManageProjectRolesPage } from './pages/ManageProjectRoles.page'
import { ManageSubjectsPage } from './pages/ManageSubjects.page'
import { ManageTemplatesPage } from './pages/ManageTemplates.page'
import { ManageUsersPage } from './pages/ManageUsers.page'
import { NotFoundPage } from './pages/NotFound.page'
import { PASPage } from './pages/PAS.page'
import { PASSummaryPage } from './pages/PASSummary.page'
import { POILPage } from './pages/POIL.page'
import { POILSummaryPage } from './pages/POILSummary.page'
import { RASPage } from './pages/RAS.page'
import { RASSummaryPage } from './pages/RASSummary.page'
import { ResetPasswordPage } from './pages/ResetPassword.page'
import { ROILPage } from './pages/ROIL.page'
import { ROILSummaryPage } from './pages/ROILSummary.page'
import { SummaryPage } from './pages/Summary.page'
import { routes } from './routes'

import './App.css'

export const App: React.FC = () => {
    return (
        <Routes>
            <Route element={<ForAuthenticated />}>
                <Route path={routes.AccountTypes.path} element={<ManageAccountTypesPage />} />
                <Route path={routes.ProjectRoles.path} element={<ManageProjectRolesPage />} />
                <Route path={routes.Subjects.path} element={<ManageSubjectsPage />} />
                <Route path={routes.Users.path} element={<ManageUsersPage />} />

                <Route path={routes.Companies.path} element={<ManageCompaniesPage />} />

                <Route path={routes.CreateProject.path} element={<CreateProjectPage />} />
                <Route path={routes.EditProject.path} element={<EditProjectPage />} />

                <Route path={routes.ProjectSummary.path} element={<SummaryPage />} />

                <Route path={routes.ROILSummary.path} element={<ROILSummaryPage />} />
                <Route path={routes.ROIL.path} element={<ROILPage />} />

                <Route path={routes.POILSummary.path} element={<POILSummaryPage />} />
                <Route path={routes.POIL.path} element={<POILPage />} />

                <Route path={routes.PAS.path} element={<PASPage />} />
                <Route path={routes.PASSummary.path} element={<PASSummaryPage />} />
                <Route path={routes.RAS.path} element={<RASPage />} />
                <Route path={routes.RASSummary.path} element={<RASSummaryPage />} />

                <Route
                    path={routes.ProjectAircraftInformation.path}
                    element={<AircraftInformationPage />}
                />

                <Route path={routes.Templates.path} element={<ManageTemplatesPage />} />
                <Route path={routes.Dashboard.path} element={<DashboardPage />} />
            </Route>

            <Route path={routes.ActivateAccount.path} element={<ActivateAccountPage />} />
            <Route path={routes.ForgotPassword.path} element={<ForgotPasswordPage />} />
            <Route path={routes.ResetPassword.path} element={<ResetPasswordPage />} />
            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    )
}
