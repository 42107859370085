import { mdiLabelVariant, mdiSend, mdiTrashCan } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useState, useMemo, useRef, useEffect } from 'react'

import { IconButton } from '../../atoms/IconButton'
import { OILComment } from '../../domain/oil.domain'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { Project, ProjectAssignmentEntity } from '../../domain/project.domain'
import { ForProjectRole } from '../Auth/ForProjectRole'
import { ForSuperadmin } from '../Auth/ForSuperadmin'

const AddCommentForm: React.FC<{
    projectId: Project['id']
    onAdd: (value: string) => void
}> = props => {
    const [content, setContent] = useState('')
    return (
        <ForProjectRole
            permission={ProjectRolePermissions.OILAddComment}
            projectId={props.projectId}
        >
            <form
                onSubmit={event => {
                    event.preventDefault()
                    if (content.length > 1) {
                        setContent('')
                        props.onAdd(content)
                    }
                }}
            >
                <div className='w-full flex p-2 items-center rounded-2xl mt-5 border-2 border-atamblue-300'>
                    <input
                        value={content}
                        type='text'
                        placeholder='Add a comment...'
                        className='bg-transparent focus:outline-none'
                        style={{ width: 'calc(100% - 24px)' }}
                        onChange={event => setContent(event.target.value)}
                    />
                    <IconButton isSubmit path={mdiSend} size={1} className='text-atamblue-300' />
                </div>
            </form>
        </ForProjectRole>
    )
}

const Comment: React.FC<{
    comment: OILComment
    isReadOnly: boolean
    onRemove: () => void
}> = props => {
    const createdAt = new Date(props.comment.createdAt)
    const isAuditor =
        props.comment.user.entity === ProjectAssignmentEntity.Auditor ||
        props.comment.user.entity === undefined

    return (
        <div
            className={classNames(
                'w-11/12 rounded-2xl border-2 mb-3 box-border overflow-hidden shrink-0',
                {
                    'self-start border-teal-700 rounded-bl-none': isAuditor,
                    'self-end border-green-500 rounded-br-none': !isAuditor,
                },
            )}
        >
            <div
                className={classNames('relative px-4 py-1 comment-content leading-4', {
                    'justify-end': !isAuditor,
                    'text-atamblue-300': props.comment.isDeleted,
                })}
            >
                {!props.comment.isDeleted ? props.comment.content : 'Comment was deleted'}
                {!props.comment.isDeleted && !props.isReadOnly ? (
                    <ForSuperadmin>
                        <IconButton
                            path={mdiTrashCan}
                            size={0.75}
                            className='absolute top-1 right-1 text-teal-700'
                            onClick={props.onRemove}
                        />
                    </ForSuperadmin>
                ) : null}
            </div>
            <div
                className={classNames('flex items-center comment-meta text-xs text-white px-4', {
                    'bg-teal-700': isAuditor,
                    'bg-green-500 justify-end': !isAuditor,
                })}
            >
                <div className='truncate' style={{ maxWidth: '40%' }}>
                    {props.comment.isDeleted || !props.comment.user
                        ? 'N/A'
                        : props.comment.user.name}
                </div>
                <Icon path={mdiLabelVariant} size={0.35} className='mx-1' />
                <div className=''>{format(createdAt, 'dd-MMM-yyyy')}</div>
                <Icon path={mdiLabelVariant} size={0.35} className='mx-1' />
                <div className=''>{format(createdAt, 'HH:mm')}</div>
            </div>
        </div>
    )
}

export const CommentsCell: React.FC<{
    value: OILComment[]
    projectId: Project['id']
    itemNumber: number
    isExpanded: boolean
    isReadOnly: boolean
    onAddComment: (content: string) => Promise<unknown>
    onRemoveComment: (commentId: number) => Promise<unknown>
}> = props => {
    const auditorComments = useMemo(
        () =>
            props.value.filter(comment =>
                comment.user
                    ? comment.user.entity === ProjectAssignmentEntity.Auditor ||
                      comment.user.entity === undefined
                    : true,
            ),
        [props.value],
    )
    const auditeeComments = useMemo(
        () =>
            props.value.filter(comment =>
                comment.user ? comment.user.entity === ProjectAssignmentEntity.Auditee : false,
            ),
        [props.value],
    )
    const commentsRef = useRef<HTMLDivElement>(null)
    const currentCommentsRef = commentsRef.current

    useEffect(() => {
        setTimeout(() => {
            if (currentCommentsRef) {
                currentCommentsRef.scrollTop = currentCommentsRef.scrollHeight
            }
        }, 0)
    })

    useEffect(() => {
        setTimeout(() => {
            if (currentCommentsRef) {
                currentCommentsRef.scrollTop = currentCommentsRef.scrollHeight
            }
        }, 0)
    }, [currentCommentsRef])

    console.log(
        'props.value',
        props.value,
        auditorComments.length > 0
            ? auditorComments[auditorComments.length - 1].content
            : 'No comments',
    )
    if (!props.isExpanded) {
        const latestAuditorComment =
            auditorComments.length > 0
                ? auditorComments[auditorComments.length - 1].content
                : 'No comments'
        const latestAuditeeComment =
            auditeeComments.length > 0
                ? auditeeComments[auditeeComments.length - 1].content
                : 'No comments'
        return (
            <div className='h-full grow flex justify-around items-stretch'>
                <div
                    className={classNames('w-1/2 flex justify-center items-center', {
                        'text-atamblue-300 ': auditorComments.length === 0,
                        'mr-1 border-b-4 min-w-0 border-teal-700': auditorComments.length > 0,
                    })}
                >
                    {latestAuditorComment}
                </div>
                <div
                    className={classNames('w-1/2 flex justify-center items-center', {
                        'text-atamblue-300 ': auditeeComments.length === 0,
                        'mr-1 border-b-4 min-w-0 border-green-500': auditeeComments.length > 0,
                    })}
                >
                    {latestAuditeeComment}
                </div>
            </div>
        )
    }
    return (
        <div className='relative w-full h-full'>
            <div
                className='absolute w-full h-full py-3 flex flex-col text-atamblue-900 overflow-y-auto'
                ref={commentsRef}
            >
                {props.value.map(comment => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        isReadOnly={props.isReadOnly}
                        onRemove={() => props.onRemoveComment(comment.id)}
                    />
                ))}
                {!props.isReadOnly ? (
                    <AddCommentForm projectId={props.projectId} onAdd={props.onAddComment} />
                ) : null}
            </div>
        </div>
    )
}
