import classNames from 'classnames'
import React from 'react'

export const DefaultCellValue: React.FC<{
    align?: 'left' | 'center' | 'right'
    className?: string
}> = props => {
    const alignmentClassName =
        props.align === 'left'
            ? 'justify-start'
            : props.align === 'right'
            ? 'justify-end'
            : 'justify-center'
    return (
        <div
            className={classNames(
                'h-full grow flex items-center truncate',
                alignmentClassName,
                props.className,
            )}
        >
            {props.children}
        </div>
    )
}
