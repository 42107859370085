import { mdiClose } from '@mdi/js'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useGetHistoryQuery } from '../../api/for-company/oil.api'
import { IconButton } from '../../atoms/IconButton'
import { LoadingSpinner } from '../../atoms/LoadingSpinner'
import { Tooltip } from '../../atoms/Tooltip'
import { isNil } from '../../common/utils'
import { OILHistory, OILHistoryAction, OILStatus } from '../../domain/oil.domain'

const statusesCodesNameMap = {
    [OILStatus.Open]: 'Open',
    [OILStatus.Closed]: 'Closed',
    [OILStatus.Critical]: 'Critical',
    [OILStatus.Pending]: 'Pending',
    [OILStatus.SideLetter]: 'Side Letter',
    [OILStatus.UnderReview]: 'Under Review',
}

const statusesClassMap = {
    [OILStatus.Open]: 'text-yellow-700',
    [OILStatus.Closed]: 'text-green-700',
    [OILStatus.Critical]: 'text-red-500',
    [OILStatus.Pending]: 'text-indigo-500',
    [OILStatus.SideLetter]: 'text-indigo-700',
    [OILStatus.UnderReview]: 'text-atamsky-700',
}

// const actionsMap: Record<
//     OILHistoryAction,
//     (historyItem: OILHistory) => { action: React.ReactNode; description: React.ReactNode }
// > = {
//     [OILHistoryAction.Create]: (historyItem: OILHistory) => {
//         const payload = historyItem.payload as any

//         return {
//             action: <span>Item created</span>,
//             description: (
//                 <div className='mb-2 pb-1 border-b border-ebony-400 border-dashed'>
//                     <u>Subject:</u> {payload.subject} | <u>Observation Description:</u>{' '}
//                     {payload.observationDescription} | <u>Auditor Status:</u>{' '}
//                     <span className={statusesClassMap[payload.auditorStatus as OILStatus]}>
//                         {statusesCodesNameMap[payload.auditorStatus as OILStatus]}
//                     </span>
//                     | <u>Auditee Status:</u>{' '}
//                     <span className={statusesClassMap[payload.auditeeStatus as OILStatus]}>
//                         {statusesCodesNameMap[payload.auditeeStatus as OILStatus]}
//                     </span>
//                 </div>
//             ),
//         }
//     },
//     [OILHistoryAction.MarkCritical]: () => {
//         return {
//             action: <span>Item marked as Critical Path Item</span>,
//             description: null,
//         }
//     },
//     [OILHistoryAction.UnmarkCritical]: () => {
//         return {
//             action: <span>Item unmarked as Critical Path Item</span>,
//             description: null,
//         }
//     },
//     [OILHistoryAction.EditAuditorStatus]: (historyItem) => {
//         return {
//             action: <span>Item edited</span>,
//             description: (
//                 <>
//                     <u>Auditor Status:</u>{' '}
//                     <span
//                         className={statusesClassMap[historyItem.payload.auditorStatus as OILStatus]}
//                     >
//                         {statusesCodesNameMap[historyItem.payload.auditorStatus as OILStatus]}
//                     </span>
//                 </>
//             ),
//         }
//     },
//     [OILHistoryAction.EditAuditeeStatus]: (historyItem: OILHistory) => {
//         return {
//             action: <span>Item edited</span>,
//             description: (
//                 <>
//                     <u>Auditee Status:</u>{' '}
//                     <span
//                         className={statusesClassMap[historyItem.payload.auditeeStatus as OILStatus]}
//                     >
//                         {statusesCodesNameMap[historyItem.payload.auditeeStatus as OILStatus]}
//                     </span>
//                 </>
//             ),
//         }
//     },
//     [OILHistoryAction.EditSubject]: (historyItem: OILHistory) => {
//         return {
//             action: <span>Item edited</span>,
//             description: (
//                 <>
//                     <u>Subject:</u> {historyItem.payload.subject}
//                 </>
//             ),
//         }
//     },
//     [OILHistoryAction.EditObservationDescription]: (historyItem: OILHistory) => {
//         return {
//             action: <span>Item edited</span>,
//             description: (
//                 <>
//                     <u>Observation Description:</u> {historyItem.payload.observationDescription}
//                 </>
//             ),
//         }
//     },
//     [OILHistoryAction.AddComment]: (historyItem: OILHistory) => {
//         return {
//             action: <span>Comment added</span>,
//             description: (
//                 <>
//                     <u>Comment:</u> {historyItem.payload.value}
//                 </>
//             ),
//         }
//     },
//     [OILHistoryAction.RemoveComment]: () => {
//         return {
//             action: <span>Comment removed</span>,
//             description: <></>,
//         }
//     },
//     [OILHistoryAction.Trash]: () => {
//         return {
//             action: <span>Item trashed</span>,
//             description: <></>,
//         }
//     },
//     [OILHistoryAction.Untrash]: () => {
//         return {
//             action: <span>Item untrashed</span>,
//             description: <></>,
//         }
//     },
// }

const getHistoryEntry = (
    historyItem: OILHistory,
): { action: React.ReactNode; description: React.ReactNode } => {
    switch (historyItem.action) {
        case OILHistoryAction.Create:
            return {
                action: <span>Item created</span>,
                description: (
                    <div className='mb-2 pb-1 border-b border-ebony-400 border-dashed'>
                        <u>Subject:</u> {historyItem.payload.subject} |{' '}
                        <u>Observation Description:</u> {historyItem.payload.observationDescription}{' '}
                        | <u>Auditor Status:</u>{' '}
                        <span className={statusesClassMap[historyItem.payload.auditorStatus]}>
                            {statusesCodesNameMap[historyItem.payload.auditorStatus]}
                        </span>
                        | <u>Auditee Status:</u>{' '}
                        <span className={statusesClassMap[historyItem.payload.auditeeStatus]}>
                            {statusesCodesNameMap[historyItem.payload.auditeeStatus]}
                        </span>
                    </div>
                ),
            }

        case OILHistoryAction.EditSubject:
            return {
                action: <span>Item edited</span>,
                description: (
                    <>
                        <u>Subject:</u> {historyItem.payload.subject}
                    </>
                ),
            }

        case OILHistoryAction.EditObservationDescription:
            return {
                action: <span>Item edited</span>,
                description: (
                    <>
                        <u>Observation Description:</u> {historyItem.payload.observationDescription}
                    </>
                ),
            }

        case OILHistoryAction.EditAuditorStatus:
            return {
                action: <span>Item edited</span>,
                description: (
                    <>
                        <u>Auditor Status:</u>{' '}
                        <span className={statusesClassMap[historyItem.payload.auditorStatus]}>
                            {statusesCodesNameMap[historyItem.payload.auditorStatus]}
                        </span>
                    </>
                ),
            }

        case OILHistoryAction.EditAuditeeStatus:
            return {
                action: <span>Item edited</span>,
                description: (
                    <>
                        <u>Auditee Status:</u>{' '}
                        <span className={statusesClassMap[historyItem.payload.auditeeStatus]}>
                            {statusesCodesNameMap[historyItem.payload.auditeeStatus]}
                        </span>
                    </>
                ),
            }

        case OILHistoryAction.Trash:
            return {
                action: <span>Item trashed</span>,
                description: <></>,
            }

        case OILHistoryAction.Untrash:
            return {
                action: <span>Item untrashed</span>,
                description: <></>,
            }

        case OILHistoryAction.MarkCritical:
            return {
                action: <span>Item marked as Critical Path Item</span>,
                description: null,
            }

        case OILHistoryAction.UnmarkCritical:
            return {
                action: <span>Item unmarked as Critical Path Item</span>,
                description: null,
            }

        case OILHistoryAction.AddComment:
            return {
                action: <span>Comment added</span>,
                description: (
                    <>
                        <u>Comment:</u> {historyItem.payload.comment}
                    </>
                ),
            }

        case OILHistoryAction.RemoveComment:
            return {
                action: <span>Comment removed</span>,
                description: <></>,
            }
    }
}

export const ViewHistory: React.FC<{
    projectId: number
    subjectId: number
    oilId: number
    onClose: () => void
}> = props => {
    const { data, isLoading } = useGetHistoryQuery({
        projectId: props.projectId,
        subjectId: props.subjectId,
        id: props.oilId,
    })
    const { t } = useTranslation(['oil', 'common'])

    const getBody = () => {
        if (isLoading) {
            return (
                <div>
                    Loading history... <LoadingSpinner />
                </div>
            )
        }

        if (data) {
            return data.results.map(log => {
                const date = format(new Date(log.createdAt), 'dd-MMM-yyyy')
                const username = log.user?.name
                const entry = getHistoryEntry(log)

                if (isNil(entry)) {
                    console.warn('Invalid log entry', log)
                    return null
                }

                return (
                    <div key={log.id}>
                        <div>
                            <span className='mr-1'>&bull;</span>
                            {entry.action} on <span className='text-atamsky-700'>{date}</span> by{' '}
                            <span
                                className={classNames({
                                    'text-atamsky-700': !!username,
                                    'text-gray-600': !username,
                                })}
                            >
                                {username ?? 'N/A'}
                            </span>
                            {entry.description ? ': ' : '.'}
                            {entry.description}
                        </div>
                    </div>
                )
            })
        }
    }
    return (
        <div className='relative w-full h-full bg-indigo-900 bg-opacity-90 shadow-xl rounded-2xl p-5 text-ebony-400 text-sm'>
            <Tooltip text={t('common:Close')} className='justify-end'>
                <IconButton
                    path={mdiClose}
                    size={0.75}
                    className='absolute -top-3 -right-3 hover:text-red-500'
                    onClick={props.onClose}
                />
            </Tooltip>

            <div className='overflow-y-scroll h-full'>{getBody()}</div>
        </div>
    )
}
