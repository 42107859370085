import { zodResolver } from '@hookform/resolvers/zod'
import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateItemMutation, useGetNewItemNumberQuery } from '../../api/for-company/oil.api'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import { IconButton } from '../../atoms/IconButton'
import { LoadingSpinner } from '../../atoms/LoadingSpinner'
import { Separator } from '../../atoms/Separator'
import { OILFormSchema, OILFormType } from '../../domain/oil.domain'

export const AddNewOIL: React.FC<{
    projectId: number
    subjectId: number
    onSave: () => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation(['subjects', 'common'])
    const { data: newItemNumber, isLoading: isLoadingNewItemNumber } = useGetNewItemNumberQuery({
        projectId: props.projectId,
        subjectId: props.subjectId,
    })
    const [addNewItem, { isLoading: isLoadingCreateItem }] = useCreateItemMutation()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: zodResolver(OILFormSchema) })

    const [dateEntered] = useState(Date.now())

    const onSubmit = (newOil: any /*OILFormType*/) => {
        return addNewItem({
            projectId: props.projectId,
            subjectId: props.subjectId,
            subject: newOil.subject,
            observationDescription: newOil.observationDescription,
        })
            .unwrap()
            .then(props.onSave)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex justify-center text-xl font-semibold text-atamsky-900'>
                {t('AddNewItem')}
            </div>
            <Separator className='w-32 h-px m-auto mt-2 mb-5 bg-atamsky-700' />
            <div className='flex items-stretch'>
                <div className='flex-none w-20 grid grid-cols-1 mr-5'>
                    <div className='w-20 mb-5 text-center font-medium text-atamblue-700'>
                        {t('ItemNBR')}
                    </div>
                    {isLoadingNewItemNumber ? (
                        <LoadingSpinner />
                    ) : (
                        <input
                            className='w-20 h-12 bg-ebony-100 border border-ebony-400 rounded-2xl text-center text-atamblue-500 focus:outline-none'
                            type='text'
                            value={`${newItemNumber?.itemNumber ?? 'N/A'}`}
                            disabled
                        />
                    )}
                </div>
                <div className='flex-none w-36 grid grid-cols-1 mx-5'>
                    <div className='w-36 mb-5 text-center font-medium text-atamblue-700'>
                        {t('DateEntered')}
                    </div>
                    <input
                        className='w-36 h-12 bg-ebony-100 border border-ebony-400 rounded-2xl text-center text-atamblue-500 focus:outline-none'
                        type='text'
                        value={format(dateEntered, 'dd-MMM-yyyy')}
                        disabled
                    />
                </div>
                <div className='flex-none w-64 grid grid-cols-1 mx-5'>
                    <div className='w-64 mb-5 text-center font-medium text-atamblue-700'>
                        Subject
                    </div>
                    <div>
                        <input
                            className='w-64 h-12 bg-ebony-100 border border-atamsky-300 rounded-2xl text-center text-atamblue-900 shadow-inner focus:outline-none'
                            type='text'
                            placeholder={'Enter Subject...'}
                            autoFocus
                            {...register('subject')}
                        />
                        {errors.subject ? (
                            <ErrorMessage>{errors.subject.message}</ErrorMessage>
                        ) : null}
                    </div>
                </div>
                <div className='grow grid grid-cols-1 ml-5'>
                    <div className='grow mb-5 text-center font-medium text-atamblue-700'>
                        {t('ObservationDescription')}
                    </div>
                    <div className='grow'>
                        <textarea
                            className='w-full h-64 bg-ebony-100 border border-atamsky-300 p-4 rounded-2xl text-atamblue-900 shadow-inner focus:outline-none'
                            placeholder={'Enter Description...'}
                            {...register('observationDescription')}
                        />
                        {errors.observationDescription ? (
                            <ErrorMessage>{errors.observationDescription.message}</ErrorMessage>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className='flex justify-end mt-5'>
                <IconButton
                    isSubmit
                    path={isLoadingCreateItem ? mdiLoading : mdiCheck}
                    spin={isLoadingCreateItem}
                    disabled={isLoadingCreateItem}
                    className='mr-10 text-atamblue-700 group hover:text-atamsky-900'
                    iconClassName='mr-2 text-green-300 group-hover:text-green-500'
                >
                    {t('AddItem')}
                </IconButton>
                <IconButton
                    onClick={props.onCancel}
                    path={mdiClose}
                    className='mr-10 text-atamblue-700 group hover:text-atamsky-900'
                    iconClassName='mr-2 text-red-400 group-hover:text-red-500'
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </form>
    )
}
