import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const Tooltip = BaseAtom<{ text: React.ReactNode }>(props => {
    return (
        <div
            className={classNames('flex relative group', props.className)}
            title={props.text as any}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
})
