import { mdiAccount } from '@mdi/js'
import React from 'react'

import { useListUsersQuery } from '../../api/for-company/users.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { User } from '../../domain/user.domain'

export const UserSelector: React.FC<{
    value?: User
    exclude?: User[]
    className?: string
    iconClassName?: string
    icon?: string
    onChange: (newUser: User | undefined) => void
}> = props => {
    return (
        <EntitySelector<User>
            useResolver={useListUsersQuery}
            getOptionLabel={projectRole => projectRole.name}
            defaultValue={props.value}
            exclude={props.exclude}
            placeholder='Select User'
            className={props.className}
            iconClassName={props.iconClassName}
            icon={props.icon}
            onChange={props.onChange}
        />
    )
}
