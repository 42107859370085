import { z } from 'zod'

import { ProjectAssignmentEntity, ProjectSubjectSchema } from './project.domain'
import { UserSchema } from './user.domain'

export enum OILStatus {
    UnderReview = 'UnderReview',
    Open = 'Open',
    Pending = 'Pending',
    Critical = 'Critical',
    Closed = 'Closed',
    SideLetter = 'SideLetter',
}
export const OILStatusSchema = z.nativeEnum(OILStatus)

export const OILCommentSchema = z.object({
    id: z.number(),
    content: z.string(),
    user: UserSchema.pick({
        id: true,
        name: true,
    }).merge(z.object({ entity: z.nativeEnum(ProjectAssignmentEntity) }).partial()),
    createdAt: z.date(),
    isDeleted: z.boolean(),
})
export type OILComment = z.TypeOf<typeof OILCommentSchema>

export const OILSchema = z.object({
    id: z.number(),
    itemNumber: z.number(),
    dateEntered: z.date(),
    subject: z.string(),
    observationDescription: z.string(),
    auditorStatus: OILStatusSchema,
    auditeeStatus: OILStatusSchema,
    comments: z.array(OILCommentSchema),
    isCritical: z.boolean(),
    isTrashed: z.boolean(),
})
export type OIL = z.TypeOf<typeof OILSchema>

export enum OILHistoryAction {
    Create = 'Create',
    EditSubject = 'EditSubject',
    EditObservationDescription = 'EditObservationDescription',
    EditAuditorStatus = 'EditAuditorStatus',
    EditAuditeeStatus = 'EditAuditeeStatus',
    Trash = 'Trash',
    Untrash = 'Untrash',
    MarkCritical = 'MarkCritical',
    UnmarkCritical = 'UnmarkCritical',
    AddComment = 'AddComment',
    RemoveComment = 'RemoveComment',
}

export const OILHistoryBaseSchema = z.object({
    id: z.number(),
    user: z.nullable(UserSchema.pick({ id: true, name: true })),
    createdAt: z.date(),
})
export const OILHistorySchema = z.union([
    OILHistoryBaseSchema.merge(
        z.object({
            action: z.literal(OILHistoryAction.Create),
            payload: OILSchema.pick({
                subject: true,
                observationDescription: true,
                auditorStatus: true,
                auditeeStatus: true,
            }),
        }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({
            action: z.literal(OILHistoryAction.EditSubject),
            payload: OILSchema.pick({
                subject: true,
            }),
        }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({
            action: z.literal(OILHistoryAction.EditObservationDescription),
            payload: OILSchema.pick({
                observationDescription: true,
            }),
        }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({
            action: z.literal(OILHistoryAction.EditAuditorStatus),
            payload: OILSchema.pick({
                auditorStatus: true,
            }),
        }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({
            action: z.literal(OILHistoryAction.EditAuditeeStatus),
            payload: OILSchema.pick({
                auditeeStatus: true,
            }),
        }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({ action: z.literal(OILHistoryAction.Trash), payload: z.null() }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({ action: z.literal(OILHistoryAction.Untrash), payload: z.null() }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({ action: z.literal(OILHistoryAction.MarkCritical), payload: z.null() }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({ action: z.literal(OILHistoryAction.UnmarkCritical), payload: z.null() }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({
            action: z.literal(OILHistoryAction.AddComment),
            payload: z.object({
                comment: z.string(),
            }),
        }),
    ),
    OILHistoryBaseSchema.merge(
        z.object({ action: z.literal(OILHistoryAction.RemoveComment), payload: z.null() }),
    ),
])
export type OILHistory = z.TypeOf<typeof OILHistorySchema>

export const OILFormSchema = z.object({
    subject: z.string().min(1, 'Subject required'),
    observationDescription: z.string().min(1, 'Observation description required'),
})
export type OILFormType = z.TypeOf<typeof OILFormSchema>

export const StatusCountSchema = z.object({
    [OILStatus.Open]: z.number(),
    [OILStatus.Pending]: z.number(),
    [OILStatus.Critical]: z.number(),
    [OILStatus.UnderReview]: z.number(),
    [OILStatus.Closed]: z.number(),
    [OILStatus.SideLetter]: z.number(),
})
export const OILSummarySchema = ProjectSubjectSchema.pick({ id: true, name: true }).merge(
    z.object({
        auditorStatus: StatusCountSchema,
        auditeeStatus: StatusCountSchema,
    }),
)
export type OILSummary = z.TypeOf<typeof OILSummarySchema>

export const pickSelectedOil = (oils: OIL[], selectedIndexes: number[]) => {
    return oils.filter((_, index) => selectedIndexes.includes(index)).map(oil => oil.id)
}
