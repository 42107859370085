import classNames from 'classnames'
import React, { useRef } from 'react'
import ReactDOM from 'react-dom'

export const Backdrop: React.FC<{ className?: string; onClick: () => void }> = props => {
    const containerRef = useRef(null)

    return (
        <>
            {ReactDOM.createPortal(
                <div
                    className={classNames('w-full h-full absolute top-0 left-0 z-30')}
                    ref={containerRef}
                    onClick={event => {
                        if (event.target === containerRef.current) {
                            props.onClick()
                        }
                    }}
                ></div>,
                document.getElementById('root') as HTMLElement,
            )}
            <div className={classNames('z-40', props.className)}>{props.children}</div>
        </>
    )
}
