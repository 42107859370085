import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCreateProjectMutation } from '../../api/for-company/projects.api'
import { ProjectFormType } from '../../domain/project.domain'
import { fileToBase64 } from '../../lib/files'
import { routes } from '../../routes'

import { ProjectForm } from './ProjectForm'
import { isCategoryEnabled, isSubpartEnabled } from './useProjectForm'

export const CreateProject: React.FC = () => {
    const [createProject, createProjectStatus] = useCreateProjectMutation()
    const navigate = useNavigate()

    const onCreate = async (newProject: ProjectFormType) => {
        console.log('create project', newProject)

        const auditorLogo =
            newProject.auditorLogo && newProject.auditorLogo instanceof File
                ? { content: await fileToBase64(newProject.auditorLogo) }
                : undefined
        const subjects = [...newProject.recordsSubjects, ...newProject.physicalSubjects]
        return createProject({
            name: newProject.name,
            startDate: newProject.startDate,
            endDate: newProject.endDate,
            auditorName: newProject.auditorName,
            auditorIcao: newProject.auditorIcao,
            auditorLogo,
            auditeeName: newProject.auditeeName,
            auditeeIcao: newProject.auditeeIcao,
            subjects: {
                create: subjects.map(newSubject => ({
                    ...newSubject,
                    categories: {
                        create: newSubject.categories
                            .filter(isCategoryEnabled)
                            .map(newCategory => ({
                                ...newCategory,
                                subparts: {
                                    create: newCategory.subparts.filter(isSubpartEnabled),
                                },
                            })),
                    },
                })),
            },
            assignments: {
                add: newProject.assignments.map(assignment => ({
                    userId: assignment.user.id,
                    projectRoleId: assignment.projectRole.id,
                    entity: assignment.entity,
                })),
                remove: [],
            },
            hasRAS: newProject.hasRAS,
            hasPAS: newProject.hasPAS,
            hasROIL: newProject.hasROIL,
            hasPOIL: newProject.hasPOIL,

            bulkAssignedUser: {
                recordsSubjects: newProject.bulkAssignedUser?.recordsSubjects?.id,
                physicalSubjects: newProject.bulkAssignedUser?.physicalSubjects?.id,
            },
        })
            .unwrap()
            .then(({ id }) => {
                navigate(routes.ProjectAircraftInformation.build({ projectId: id }))
            })
    }

    return <ProjectForm isEdit={false} isSaving={createProjectStatus.isLoading} onSave={onCreate} />
}
