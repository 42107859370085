import React from 'react'

import { useEditSubjectMutation } from '../../api/for-company/subjects.api'
import { Subject } from '../../domain/subject.domain'

import { SubjectForm } from './SubjectForm'

export const EditSubject: React.FC<{
    subject: Subject
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [editSubject, { isLoading }] = useEditSubjectMutation()

    return (
        <SubjectForm
            subject={props.subject}
            canEditType={false}
            isSaving={isLoading}
            onConfirm={editedSubject =>
                editSubject({
                    id: props.subject.id,
                    name: editedSubject.name,
                }).then(props.onSave)
            }
            onCancel={props.onCancel}
        />
    )
}
