import { Icon } from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'
import { Tooltip } from './Tooltip'

import './IconButton.scss'

type IconButtonProps = {
    path: string
    size?: number
    spin?: boolean
    title?: string
    iconClassName?: string
    labelClassName?: string
    isSubmit?: boolean
    disabled?: boolean
    edgedContent?: boolean
    tooltip?: React.ReactNode
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

export const IconButton = BaseAtom<IconButtonProps>(props => {
    const {
        path,
        spin,
        size,
        className,
        iconClassName,
        labelClassName,
        isSubmit,
        edgedContent = false,
        tooltip,
        onClick,
        children,
        ...restProps
    } = props

    const content = (
        <button
            {...restProps}
            className={classNames(
                'iconButton inline-flex items-center focus:outline-none',
                {
                    'justify-center': !edgedContent,
                    'cursor-pointer': !props.disabled,
                    'cursor-auto': props.disabled,
                },
                className,
            )}
            type={isSubmit ? 'submit' : 'button'}
            onClick={onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <Icon path={path} spin={spin} className={classNames(iconClassName)} size={size ?? 1} />
            <div className={labelClassName}>{children}</div>
        </button>
    )
    return tooltip ? <Tooltip text={tooltip}>{content}</Tooltip> : content
})
