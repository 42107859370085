import classNames from 'classnames'
import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemRender,
    Identifier,
    useAccordionContext,
} from './Accordion'

export const ActiveTab: React.FC = props => {
    const { isActive: _isActive } = useAccordionContext()

    let activeChild = null

    React.Children.forEach(props.children, (child: any) => {
        if (child && child.props) {
            if (_isActive(child.props.id)) {
                activeChild = child
            }
            return <AccordionItem id={child.props.id} render={child.props.render} />
        }
        return null
    })
    return activeChild
}

export const Tabs: React.FC<{ initial: Identifier; className?: string }> = props => {
    const headers = React.Children.map(props.children, (child: any) => {
        if (child && child.props) {
            return <AccordionItem id={child.props.id} render={child.props.render} />
        }
        return null
    })

    return (
        <Accordion initial={props.initial} multiple={false}>
            <div className={classNames('flex', props.className)}>{headers}</div>
            <ActiveTab>{props.children}</ActiveTab>
        </Accordion>
    )
}

export const Tab: React.FC<{
    id: Identifier
    render: AccordionItemRender
}> = props => {
    return <>{props.children}</>
}
