export default {
    common: {
        Apply: 'Apply',
        Save: 'Save',
        Confirm: 'Confirm',
        Cancel: 'Cancel',
        Resend: 'Resend',
        Close: 'Close',
        MaximumCharacters: 'Max. no. of characters: ',
    },

    login: {
        Welcome: 'Welcome',
        ResetPassword: 'Reset Password',
        ForgotPassword: 'Forgot Password?',
        YourEmail: 'Your E-mail',
        Password: 'Password',
        LoginEmailAddress: 'Your Login E-mail Address',
        AMessageWithInstructions:
            'A message with instructions on how to reset the password was sent to: ',
        CheckYourEmailMessage: 'Check your email for further instructions.',
        SpamNote: 'If you don’t see any message in your Inbox, check your Spam folder.',
        TypoNote: 'Also, check the above email address for any typo.',
        ResendMessageText: 'If you didn’t received any message, you can resend the message in:',
        NewPassword: 'New Password',
        ConfirmNewPassword: 'Confirm Your New Password',
        TermsAgreement:
            'By clicking the above button you agree with\nour Terms of Use and Privacy Policy.',
        ServerError: 'Server error:',
        Success: 'Authentication successful. Redirecting...',
        SignIn: 'Sign In',
    },

    sidebar: {
        Dashboard: 'Dashboard',
        CreateNewProject: 'Add New Project',
        OperatingProjects: 'Operating Projects',
        ArchivedProjects: 'Archived Projects',
        Users: 'Users',
        Roles: 'Roles',
        ProjectTemplates: 'Project Templates',
        OperatingProjectDetailsTitle: 'Operating Project Details',
        ArchivedProjectDetailsTitle: 'Archived Project Details',
        AircraftInformation: 'Aircraft Information',
        OpenItemListing: 'Open Item Listing',
        AuditStatus: 'Audit Status',
        BackToMainMenu: 'Main Menu',
        Records: 'Records',
        Physical: 'Physical',
        RecordsSummary: 'Records Summary',
        PhysicalSummary: 'Physical Summary',
        PinSidebar: 'Physical Summary',
        UnpinSidebar: 'Physical Summary',
    },

    toolbar: {
        StatusesOverview: 'Statuses Overview',
        AircraftInformation: 'Aircraft Information',
        SeeOnlineUsers: 'See Online Users',
        OnlineUsers: 'Online Users',
        UserRole: 'User Role:',
        UserCode: 'User Code:',
        MyProfile: 'My Profile',
        CreateNewUser: 'Create New User',
        Logout: 'Logout',
    },

    exportButtons: {
        WordExport: 'Export SideLetter Items to Word',
        ExcelExport: 'Export Items to Excel',
        PDFExport: 'Export Items to PDF',
        HTMLExport: 'Generate Static Page View',
        EmailExport: 'Export Items to Email',
    },

    dashboard: {
        Welcome: 'Welcome to ATAM',
        EmptyBoardMsg: 'There are no projects yet. Start by ',
        AddNewProjectLinkText: 'adding a new project.',
        ProjectName: 'Project Name',
        AuditorName: 'Auditor Name',
        AuditeeName: 'Auditee Name',
        TimeProgress: 'Time Progress',
        RecordsAudit: 'Records',
        PhysicalAudit: 'Physical',
        Progress: 'Progress',
        Start: 'Start',
        End: 'End',
        CompletedItems: 'Completed',
        TotalItems: 'Total',
        RoilCriticalPathItems: 'Records OIL Critical Path Items',
        PoilCriticalPathItems: 'Physical OIL Critical Path Items',
        OrderBy: 'Order by:',
        OpenItemListing: 'Open Item Listing',
        AuditStatus: 'Audit Status',
    },
    projects: {
        SearchTemplate: 'Search Template',
        ActiveProjects: 'Active Projects',
        ArchivedProjects: 'Archived Projects',
        ProjectName: 'Project Name',
        ProjectNameHint: 'Type your new project name...',
        StartDate: 'Start Date',
        EndDate: 'End Date',
        Templates: 'Templates',
        AuditorName: 'Auditor Organization Name',
        AuditorNameHint: 'Type Auditor Name...',
        AuditorICAO: 'Auditor ICAO Code',
        AuditorICAOHint: 'Type Auditor ICAO Code...',
        ChooseImage: 'Choose Image',
        AuditorLogo: 'Auditor Organization Logo',
        AuditorLogoInfo:
            'Maximum width and height: 220x48 pixels\nRecommended size: under 50kb\nRecomended file type: transparent.png',
        AuditeeName: 'Auditee Organization Name',
        AuditeeNameHint: 'Type Auditee Name...',
        AuditeeICAO: 'Auditee ICAO Code',
        AuditeeICAOHint: 'Type Auditee ICAO Code...',
        ProjectModules: 'Modules to include in this project:',
        Subjects: 'Subjects',
        AddSubject: 'Add subject',
        CreateNewSubject: 'Create New Subject',
        SaveTemplate: 'Save Template',
        Users: 'Users',
        AddUser: 'Add user',
        LaunchProject: 'Launch Project',
        EditProject: 'Edit Project',
        ConfirmUpdate: 'Confirm Update',
        Summary: 'Summary',
        AircraftInformation: 'Aircraft Information',
        SelectTemplate: 'Select Template...',
        errors: {
            RequiredName: 'Name is required',
            RequiredStartDate: 'Start date is required',
            RequiredEndDate: 'End date is required',
            StartDateGreaterThanEndDate: 'End date must be greater than start date',
            RequiredAuditorName: 'Auditor name is required',
            RequiredAuditorLogo: 'Auditor logo is required',
            RequiredAuditeeName: 'Auditee name is required',
        },
    },
    summary: {
        Dashboard: 'Dashboard',
        ArchiveProject: 'Archive Project',
        UnarchiveProject: 'Unarchive Project',

        ReviewSubject: 'Review Subject',
        AuditorStatus: 'Auditor Status',
        AuditeeStatus: 'Auditee Status',
        Total: 'Total',
        Cumulative: 'Cumulative',
        ArchiveProjectConfirmationMessage:
            'Are you sure that you want to archive the current project?',
        UnarchiveProjectConfirmationMessage:
            'Are you sure that you want to unarchive the current project?',
    },

    aircraftInformation: {
        DataPer: 'Data per',
        ToBeDefined: 'TBD',
        Label: 'Label',
        Value: 'Value',
        EditModuleData: 'Edit {{modalTitle}} data',
        AddEngine: 'Add Engine',
        AddLandingGear: 'Add Landing Gear',
        NewEngineModuleTitle: 'New Engine Module',
        NewLandingGearModuleTitle: 'New Landing Gear Module',
    },

    subjects: {
        CreateSubject: 'Create New Subject',
        NameYourSubject: 'Name Your Subject',
        Filters: 'Filters',
        AddStatus: 'Add Status',
        AddComment: 'Add a comment...',
        AddNewItem: 'Add New Item',
        SearchSubject: 'Search Subject...',
        AddSubject: 'Add Subject...',
        errors: {
            RequiredName: 'Name is required',
        },
        ItemNBR: 'Item NBR',
        DateEntered: 'Date Entered',
        ObservationDescription: 'Observation Description',
        AddItem: 'Add Item',
        IsPhysical: 'Is Physical?',
    },

    itemsListing: {
        ItemNBR: 'Item NBR',
    },

    templates: {
        CreateTemplate: 'Create New Template',
        NameYourTemplate: 'Name Your Template',
        errors: {
            RequiredName: 'Name is required',
        },
        TemplatesList: 'Templates List',
        TemplateName: 'Template Name',
        TemplateNameHint: 'Type the template name...',
        CreateNewSubject: 'Create New Subject',
        SaveTemplate: 'Save Template',
        DeleteTemplateConfirmationMessage: 'Are you sure that you want to delete this template?',
    },
    users: {
        CreateNewUser: 'Create New User',
        ShowInactiveUsers: 'Show Inactive Users',
        DeactivateUserConfirmationMessage: 'Are you sure that you want to deactivate this user?',
        EditUser: 'Edit User',
        ResendActivationEmail: 'Resend Activation Email',
        ActivateUser: 'Activate User',
        DeactivateUser: 'Deactivate User',
        UserCode: 'User Code',
        UserCodeHint: 'Type the user code...',
        UserName: 'User name',
        UserNameHint: 'Type the user name...',
        EmailAddress: 'Email Address',
        EmailAddressHint: 'Type the email...',
        RepresentingCompany: 'Representing company',
        RepresentingCompanyHint: 'Type the representing company name...',
        NewAccountSideNote:
            'An invitation link will be sent to the user email address. The link will guide the user to complete the registration process.\nAfter the process is complete, the account will become active',
        UserActions: 'User Actions',
    },
    roles: {
        Role: 'Role',
        RoleName: 'Role Name',
        RoleNameHint: 'Enter a new role name...',
        EntityType: 'Entity Type',
        EntityTypeHint: 'Select an entity type...',
        ASP: 'Administration and Settings Permissions',
        GAP: 'General App Permissions',
        PSP: 'Project Summary Permissions',
        AIP: 'Aircraft Information Permissions',
        OIL: 'OIL Permissions',
        CreateRole: 'Create Role',
        EditRole: 'Edit Role',
    },
}
