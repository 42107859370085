import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import React from 'react'
import { Controller } from 'react-hook-form'
import { z } from 'zod'

import { useAddRASSubpartMutation } from '../../api/for-company/ras.api'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import { FloatingButton } from '../../atoms/FloatingButton'
import { Form } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { Project, ProjectCategorySchema, ProjectSubject } from '../../domain/project.domain'
import { ForProjectRole } from '../Auth/ForProjectRole'

import { ProjectCategorySelector } from './CategorySelector'

const NewProjectSubpartSchema = z.object({
    name: z.string().min(1, 'Name required'),
    category: ProjectCategorySchema.pick({ id: true, name: true }),
})

type NewProjectSubpart = z.TypeOf<typeof NewProjectSubpartSchema>
export const AddRASSubpart: React.FC<{
    projectId: Project['id']
    subjectId: ProjectSubject['id']
}> = props => {
    const [addRASSubpart, addRASSubpartMeta] = useAddRASSubpartMutation()
    return (
        <ForProjectRole
            permission={ProjectRolePermissions.RASAddSubpart}
            projectId={props.projectId}
        >
            <Modal
                title='Add Subpart'
                body={({ close }) => {
                    const onSubmit = (newSubpart: NewProjectSubpart) => {
                        return addRASSubpart({
                            projectId: props.projectId,
                            subjectId: props.subjectId,
                            categoryId: newSubpart.category.id,
                            name: newSubpart.name,
                        })
                            .unwrap()
                            .then(close)
                    }

                    return (
                        <Form
                            schema={NewProjectSubpartSchema}
                            defaults={{ name: '' }}
                            onSubmit={onSubmit}
                        >
                            <div className='grid grid-cols-1 grid-rows-2 gap-4'>
                                <Controller
                                    name='category'
                                    render={({ field, fieldState: { error } }) => (
                                        <>
                                            <ProjectCategorySelector
                                                projectId={props.projectId}
                                                subjectId={props.subjectId}
                                                onChange={field.onChange}
                                            />
                                            {error ? (
                                                <ErrorMessage>{error.message}</ErrorMessage>
                                            ) : null}
                                        </>
                                    )}
                                />
                                <TextField
                                    name='name'
                                    label='Subpart name:'
                                    placeholder='Type subpart name'
                                    className='pr-4'
                                    inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                                    labelClassName='text-atamblue-700'
                                />
                            </div>
                            <div className='flex justify-center mt-10'>
                                <IconButton
                                    path={addRASSubpartMeta.isLoading ? mdiLoading : mdiCheck}
                                    spin={addRASSubpartMeta.isLoading}
                                    disabled={addRASSubpartMeta.isLoading}
                                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                                    isSubmit
                                >
                                    Add
                                </IconButton>

                                <IconButton
                                    path={mdiClose}
                                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                                    onClick={close}
                                >
                                    Cancel
                                </IconButton>
                            </div>
                        </Form>
                    )
                }}
            >
                <FloatingButton>
                    <span className='flex-none pr-2 text-2xl'>+</span>
                    <span className='flex-none'>Add New Subpart</span>
                </FloatingButton>
            </Modal>
        </ForProjectRole>
    )
}
