import { mdiStarCogOutline, mdiStarPlus } from '@mdi/js'
import React, { useState } from 'react'

import { useListAccountTypesQuery } from '../../api/for-company/account-types.api'
import { Card } from '../../atoms/Card'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { Paginator } from '../../common/Paginator'
import { AccountType, AccountTypePermissions } from '../../domain/account-type.domain'
import { ForAccountType } from '../Auth/ForAccountType'

import { CreateAccountType } from './CreateAccountType'
import { EditAccountType } from './EditAccountType'

const AccountTypeEntry: React.FC<{ accountType: AccountType }> = props => {
    const { accountType } = props

    return (
        <div>
            <div className='flex items-center py-2 border-b border-ebony-400 text-sm'>
                <div className='flex mx-2 relative'>
                    <Modal
                        fullwidth
                        fullheight
                        title='Edit Account Type'
                        body={({ close }) => (
                            <EditAccountType
                                accountType={accountType}
                                onSave={close}
                                onCancel={close}
                            />
                        )}
                    >
                        <Tooltip text='Edit Account Type'>
                            <IconButton
                                className='text-atamblue-300 hover:text-atamsky-900'
                                path={mdiStarCogOutline}
                                size={0.75}
                            />
                        </Tooltip>
                    </Modal>
                </div>
                <div className='grow'>{accountType.name}</div>
                <div className='w-1/3 flex justify-center'>{accountType.permissions.length}</div>
            </div>
        </div>
    )
}

export const AccountTypesList: React.FC = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const { data } = useListAccountTypesQuery({
        page,
        perPage,
    })

    const Columns = (
        <div className='flex items-center border-b border-atamblue-300 py-1 font-medium text-sm text-atamblue-900'>
            <div className='flex mx-2'>
                <Modal
                    fullwidth
                    fullheight
                    title='Create A New Account Type'
                    body={({ close }) => <CreateAccountType onSave={close} onCancel={close} />}
                >
                    <Tooltip text='Create A New Account Type'>
                        <IconButton
                            className='py-1 text-atamblue-300 hover:text-atamsky-900'
                            path={mdiStarPlus}
                            size={0.75}
                        />
                    </Tooltip>
                </Modal>
            </div>
            <div className='grow'>Account Type Name</div>
            <div className='w-1/3 text-center'>Permissions</div>
        </div>
    )

    if (!data) {
        return null
    }
    const Entries = data.results.map(accountType => (
        <AccountTypeEntry key={accountType.id} accountType={accountType} />
    ))

    return (
        <>
            <Card className='mb-1 p-3'>
                {Columns}
                {Entries}
            </Card>
            <Paginator
                currentPage={page}
                totalPages={data.totalPages}
                perPage={perPage}
                onJumpToPage={setPage}
                onChangePageSize={setPerPage}
            />
        </>
    )
}
