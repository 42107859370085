import classNames from 'classnames'
import React, { useState } from 'react'

import { useListUsersQuery } from '../../api/for-company/users.api'
import { Card } from '../../atoms/Card'
import { Tooltip } from '../../atoms/Tooltip'
import { Paginator } from '../../common/Paginator'
import { User } from '../../domain/user.domain'

import { UserHeaderMenu, UserMenu } from './UserMenus'

const UserEntry: React.FC<{ user: User }> = props => {
    const { user } = props
    const activeProjects =
        user.projectAssignments.filter(assignment => !assignment.project.isArchived).length ?? 0
    const archivedProjects =
        user.projectAssignments.filter(assignment => assignment.project.isArchived).length ?? 0

    return (
        <div
            className={classNames('border-r-4', {
                'bg-gray-300': !user.isActive,
                'border-red-700': !user.auth,
                'border-transparent': user.auth,
            })}
        >
            <div className='flex py-2 border-b border-ebony-400 text-sm'>
                <div className='flex mx-2 relative'>
                    <UserMenu
                        user={user}
                        onFetchList={() => console.log('onfetch list cell user')}
                    />
                </div>
                <div className='grow text-center'>{user.code}</div>
                <div className='w-1/4'>
                    <Tooltip text={user.name}>
                        <span className='truncate'>{user.name}</span>
                    </Tooltip>
                </div>
                <div className='w-1/4'>
                    <Tooltip text={user.email}>
                        <span className='truncate'>{user.email}</span>
                    </Tooltip>
                </div>
                <div className='w-1/8'>
                    <Tooltip text={user.accountType.name}>
                        <span className='mx-auto truncate'>{user.accountType.name}</span>
                    </Tooltip>
                </div>
                <div className='w-1/8 text-center'>{activeProjects}</div>
                <div className='w-1/8 text-center'>{archivedProjects}</div>
            </div>
        </div>
    )
}

export const UsersList: React.FC = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [showInactive, setShowInactive] = useState(false)
    const { data } = useListUsersQuery({
        page,
        perPage,
        showInactiveUsers: showInactive,
    })

    const Columns = (
        <div className='flex items-center border-b border-atamblue-300 py-1 font-medium text-sm text-atamblue-900'>
            <div className='flex mx-2'>
                <UserHeaderMenu
                    showInactiveUsers={showInactive}
                    onShowInactiveUsersChange={setShowInactive}
                    onFetchList={() => {
                        console.log('onFetchUser users')
                    }}
                />
            </div>
            <div className='grow text-center'>User Code</div>
            <div className='w-1/4'>User Name</div>
            <div className='w-1/4'>Email address</div>
            <div className='w-1/8 text-center'>Account Type</div>
            <div className='w-1/8 text-center'>Active Projects</div>
            <div className='w-1/8 text-center'>Archived Projects</div>
        </div>
    )

    if (!data) {
        return null
    }
    const Entries = data.results.map(user => <UserEntry key={user.id} user={user} />)

    return (
        <>
            <Card className='mb-1 p-3'>
                {Columns}
                {Entries}
            </Card>
            <Paginator
                currentPage={page}
                totalPages={data.totalPages}
                perPage={perPage}
                onJumpToPage={setPage}
                onChangePageSize={setPerPage}
            />
        </>
    )
}
