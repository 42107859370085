import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { BaseAtom } from './_BaseAtom'

export type ProgressRingProps = {
    size: number
    progress: number
    strokeWidth: number
    circularBackground: string
    circularProgress: string
    circleFill: string
    svgClassName?: string
    circularBackgroundClassName?: string
    circularProgressClassName?: string
    labelClassName?: string
    labelColor?: string
}

export const ProgressRing = BaseAtom<ProgressRingProps>(props => {
    const [offset, setOffset] = useState(0)
    const circleRef = useRef(null)

    const center = props.size / 2
    const radius = props.size / 2 - props.strokeWidth / 2
    const circumference = 2 * Math.PI * radius

    useEffect(() => {
        const progressOffset = ((100 - props.progress) / 100) * circumference
        setOffset(progressOffset)
    }, [setOffset, props.progress, circumference, offset])

    return (
        <>
            <svg width={props.size} height={props.size} className={classNames(props.svgClassName)}>
                <circle
                    className={classNames(props.circularBackgroundClassName)}
                    fill={props.circleFill}
                    stroke={props.circularBackground}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={props.strokeWidth}
                ></circle>
                <circle
                    className={classNames(props.circularProgressClassName)}
                    style={{ transition: 'stroke-dashoffset 850ms ease-in-out' }}
                    fill={props.circleFill}
                    ref={circleRef}
                    stroke={props.circularProgress}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={props.strokeWidth}
                    transform={`rotate(-90 ${props.size / 2} ${props.size / 2})`}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                ></circle>
                <text
                    className={classNames(props.labelClassName)}
                    fill={props.labelColor}
                    x={`${center}`}
                    y={`${center}`}
                    dx={'3%'}
                    dy={'6%'}
                    textAnchor={'middle'}
                >
                    {props.progress}%
                </text>
            </svg>
        </>
    )
})
