import { mdiFolderOpen, mdiFolderZip } from '@mdi/js'
import { isNil, omit } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
    useArchiveProjectMutation,
    useEditProjectMutation,
    useGetProjectByIdQuery,
    useUnarchiveProjectMutation,
} from '../../api/for-company/projects.api'
import { IconButton } from '../../atoms/IconButton'
import { ConfirmationModal } from '../../atoms/Modal/ConfirmationModal'
import { Separator } from '../../atoms/Separator'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { Project, ProjectFormType } from '../../domain/project.domain'
import { fileToBase64 } from '../../lib/files'
import { routes } from '../../routes'
import { ForProjectRole } from '../Auth/ForProjectRole'

import { ProjectForm } from './ProjectForm'

export const EditProject: React.FC<{
    projectId: Project['id']
}> = props => {
    const navigate = useNavigate()
    const { t } = useTranslation('summary')

    const { data: project } = useGetProjectByIdQuery({ id: props.projectId })

    const [archiveProject] = useArchiveProjectMutation()
    const [unarchiveProject] = useUnarchiveProjectMutation()
    const [editProject, { isLoading }] = useEditProjectMutation()

    const recordsSubjects = useMemo(
        () => (!project ? [] : project.subjects.filter(s => !s.isPhysical)),
        [project],
    )
    const physicalSubjects = useMemo(
        () => (!project ? [] : project.subjects.filter(s => s.isPhysical)),
        [project],
    )

    if (!project) {
        return null
    }

    const onSave = async (editedProject: ProjectFormType) => {
        const auditorLogo =
            editedProject.auditorLogo instanceof File
                ? { content: await fileToBase64(editedProject.auditorLogo) }
                : undefined

        const subjects = [...editedProject.recordsSubjects, ...editedProject.physicalSubjects]
        const payload = {
            id: project.id,
            name: editedProject.name,
            startDate: editedProject.startDate,
            endDate: editedProject.endDate,
            auditorName: editedProject.auditorName,
            auditorIcao: editedProject.auditorIcao,
            auditorLogo,
            auditeeName: editedProject.auditeeName,
            auditeeIcao: editedProject.auditeeIcao,
            subjects: {
                create: subjects
                    .filter(subject => isNil(subject.id))
                    .map(newSubject => ({
                        ...newSubject,
                        categories: {
                            create: newSubject.categories.map((newCategory: any) => ({
                                ...omit(newCategory, 'id'),
                                subparts: {
                                    create: newCategory.subparts,
                                },
                            })),
                        },
                    })),
                edit: subjects
                    .filter(subject => !isNil(subject.id))
                    .map(existingSubject => {
                        return {
                            id: existingSubject.id,
                            categories: {
                                create: existingSubject.categories
                                    .filter((category: any) => isNil(category.id))
                                    .map((newCategory: any) => ({
                                        ...omit(newCategory, 'id'),
                                        subparts: {
                                            create: newCategory.subparts.filter((subpart: any) =>
                                                isNil(subpart.id),
                                            ),
                                        },
                                    })),

                                edit: existingSubject.categories
                                    .filter((category: any) => !isNil(category.id))
                                    .map((existingCategory: any) => ({
                                        id: existingCategory.id,
                                        subparts: {
                                            create: existingCategory.subparts.filter(
                                                (subpart: any) => isNil(subpart.id),
                                            ),
                                        },
                                    })),
                            },
                        }
                    }),
            },

            assignments: editedProject.assignments.map(assignment => ({
                userId: assignment.user.id,
                projectRoleId: assignment.projectRole.id,
                entity: assignment.entity,
            })),
            bulkAssignedUser: editedProject.bulkAssignedUser,
            hasROIL: editedProject.hasROIL,
            hasPOIL: editedProject.hasPOIL,
            hasRAS: editedProject.hasRAS,
            hasPAS: editedProject.hasPAS,
        }

        return editProject(payload)
            .unwrap()
            .then(result => {
                toast.success('Project successfully edited', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                })
                return result
            })
    }

    return (
        <div key={props.projectId}>
            {!project?.isArchived ? (
                <ForProjectRole
                    permission={ProjectRolePermissions.ProjectArchive}
                    projectId={props.projectId}
                >
                    <ConfirmationModal
                        message={t('ArchiveProjectConfirmationMessage')}
                        onConfirm={() =>
                            archiveProject({
                                id: props.projectId,
                            }).then(() => navigate(routes.Dashboard.build()))
                        }
                    >
                        <IconButton
                            path={mdiFolderZip}
                            className='flex h-12 px-6 mb-1 py-2 rounded-2xl bg-green-200 p-4 shadow-md font-medium text-atamblue-700 focus:outline-none hover:bg-green-700 hover:text-white'
                            iconClassName='mr-2'
                        >
                            {t('ArchiveProject')}
                        </IconButton>
                    </ConfirmationModal>
                    <Separator className='w-1' />
                </ForProjectRole>
            ) : (
                <ForProjectRole
                    permission={ProjectRolePermissions.ProjectUnarchive}
                    projectId={props.projectId}
                >
                    <ConfirmationModal
                        message={t('UnarchiveProjectConfirmationMessage')}
                        onConfirm={() =>
                            unarchiveProject({
                                id: props.projectId,
                            })
                        }
                    >
                        <IconButton
                            path={mdiFolderOpen}
                            className='flex h-12 px-6 py-2 rounded-2xl bg-green-200 p-4 shadow-md font-medium text-atamblue-700 focus:outline-none hover:bg-green-700 hover:text-white'
                            iconClassName='mr-2'
                        >
                            {t('UnarchiveProject')}
                        </IconButton>
                    </ConfirmationModal>
                    <Separator className='w-1' />
                </ForProjectRole>
            )}
            <ProjectForm
                key={project.id}
                project={{
                    name: project.name,
                    startDate: new Date(project.startDate),
                    endDate: new Date(project.endDate),
                    auditorName: project.auditorName,
                    auditorIcao: project.auditorIcao,
                    auditorLogo: project.auditorLogo,
                    auditeeName: project.auditeeName,
                    auditeeIcao: project.auditeeIcao,
                    recordsSubjects,
                    physicalSubjects,
                    assignments: project.assignments,
                    hasROIL: project.hasROIL,
                    hasPOIL: project.hasPOIL,
                    hasRAS: project.hasRAS,
                    hasPAS: project.hasPAS,
                }}
                isEdit={true}
                isSaving={isLoading}
                onSave={onSave}
            />
        </div>
    )
}
