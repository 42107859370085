import { mdiFolderStar } from '@mdi/js'
import React from 'react'

import { useListTemplatesQuery } from '../../api/for-company/templates.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { Template } from '../../domain/template.domain'

export const TemplateSelector: React.FC<{
    isPhysical: boolean
    value?: Template
    exclude?: Template[]
    className?: string
    iconClassName?: string
    placeholder?: string
    onChange: (value: Template | undefined) => void
}> = props => {
    return (
        <EntitySelector<Template>
            useResolver={useListTemplatesQuery}
            resolverParams={{ isPhysical: props.isPhysical }}
            getOptionLabel={project => project.name}
            defaultValue={props.value}
            exclude={props.exclude}
            placeholder='Select Template'
            className={props.className}
            iconClassName={props.iconClassName}
            icon={mdiFolderStar}
            onChange={props.onChange}
        />
    )
}
