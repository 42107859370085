import {
    mdiPuzzleCheck,
    mdiFileTree,
    mdiCalendarSync,
    mdiAccountArrowRight,
    mdiCardAccountDetailsOutline,
    mdiFlag,
    mdiFilePercent,
    mdiCommentOutline,
    mdiLoading,
    mdiCheck,
    mdiClose,
    mdiSend,
    mdiTrashCan,
} from '@mdi/js'
import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    useAddRASRemarkMutation,
    useDeleteRASRemarkMutation,
    useEditRASMutation,
} from '../../api/for-company/ras.api'
import { CircledInitial } from '../../atoms/CircledInitial'
import { NumberInput, SlideNumberInput } from '../../atoms/Form/NumberInput'
import { TextInput } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { IconText } from '../../atoms/IconText'
import { RAS, RASStatus } from '../../domain/RAS.domain'
import { User } from '../../domain/user.domain'
import { useCurrentProfile } from '../../hooks/use-current-profile'
import { UserSelector } from '../Users/UsersSelector'

import { RASStatusSelector } from './RASStatusSelector'

export const EditRASEntry: React.FC<{
    projectId: number
    subjectId: number
    ras: RAS

    onSave: () => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation()
    const profile = useCurrentProfile()
    const [editRAS, editRASMeta] = useEditRASMutation()
    const [addRASRemark, addRASRemarkMeta] = useAddRASRemarkMutation()
    const [deleteRASRemark] = useDeleteRASRemarkMutation()
    const [poc, setPoc] = useState(props.ras.poc)
    const [ewd, setEwd] = useState(props.ras.ewd)
    const [user, setUser] = useState<User | undefined>((props.ras.user as User) ?? undefined)
    const [remark, setRemark] = useState('')
    const [status, setStatus] = useState(props.ras.status)
    const { projectId, subjectId } = props

    if (!profile) {
        return null
    }

    const onAddRemark = () => {
        if (remark.length < 1) {
            return
        }
        return addRASRemark({ projectId, subjectId, id: props.ras.id, content: remark })
            .unwrap()
            .then(() => setRemark(''))
    }

    const onDeleteRemark = (remarkId: number) => {
        return deleteRASRemark({ projectId, subjectId, id: props.ras.id, remarkId })
    }

    const onSave = async () => {
        const remarkSave = () =>
            remark.length > 0
                ? addRASRemark({ projectId, subjectId, id: props.ras.id, content: remark }).unwrap()
                : Promise.resolve()
        await remarkSave()

        editRAS({ projectId, subjectId, id: props.ras.id, poc, ewd, status, userId: user?.id })
            .unwrap()
            .then(props.onSave)
    }

    return (
        <div className='w-192 text-sm'>
            <div className='grid gap-x-6 gap-y-3 grid-cols-2 -m-5 mb-0 p-5 bg-white shadow-sm'>
                <div className='col-span-2 flex items-center'>
                    <IconText
                        path={mdiPuzzleCheck}
                        size={1}
                        className='text-atamblue-500 mr-3 shrink-0'
                    >
                        Subpart Name:
                    </IconText>
                    <div className='font-medium'>{props.ras.name}</div>
                </div>

                <div className='flex items-center'>
                    <IconText path={mdiFileTree} size={1} className='text-atamblue-500 mr-3'>
                        Category:
                    </IconText>
                    <div className='font-medium'>{props.ras.category.name}</div>
                </div>
                <div className='flex items-center'>
                    <IconText path={mdiCalendarSync} size={1} className='text-atamblue-500 mr-3'>
                        EWD (Estimated Working Days):
                    </IconText>
                    <NumberInput
                        className='w-24 ml-2 pt-1 pb-0.5 border-b border-atamblue-300 bg-white leading-4'
                        placeholder='EWD'
                        min={1}
                        value={ewd ?? undefined}
                        onChange={event => setEwd(+event.target.value)}
                    />
                </div>
            </div>

            <div className='grid gap-x-10 gap-y-5 grid-cols-2 mt-2'>
                <div className='flex'>
                    <IconText
                        path={mdiAccountArrowRight}
                        size={1}
                        className='text-atamblue-500 mr-3'
                    >
                        Assigned To:
                    </IconText>
                    <UserSelector value={user} className='grow' onChange={setUser} />
                </div>
                <div className='flex items-center'>
                    <IconText
                        path={mdiCardAccountDetailsOutline}
                        size={1}
                        className='text-atamblue-500 mr-3'
                    >
                        User Code:
                    </IconText>
                    <div className='font-medium'>{user?.code ?? '-'}</div>
                </div>

                <div className='flex'>
                    <IconText path={mdiFlag} size={1} className='text-atamblue-500 mr-3'>
                        Status:
                    </IconText>
                    <div className='w-40'>
                        <RASStatusSelector
                            value={status}
                            onChange={newStatus => {
                                setStatus(newStatus)
                                if (newStatus === RASStatus.NotStarted) {
                                    setPoc(0)
                                }
                                if (newStatus === RASStatus.Completed) {
                                    setPoc(100)
                                }
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className='flex items-center mb-1'>
                        <IconText path={mdiFilePercent} size={1} className='text-atamblue-500 mr-3'>
                            POC (Percent Of Completion):
                        </IconText>
                        <div className='font-medium'>{poc}%</div>
                    </div>
                    <SlideNumberInput value={poc} min={0} max={100} step={1} onChange={setPoc} />
                </div>
            </div>

            <div className='mt-5 border-b border-atamblue-300 pb-2'>
                <IconText path={mdiCommentOutline} size={1} className='text-atamblue-500 mr-3 '>
                    Remarks:
                </IconText>
            </div>
            <div className='max-h-screen30 overflow-y-auto pb-3 border-b border-atamblue-300'>
                {props.ras.remarks.map(remark => (
                    <div
                        key={remark.id}
                        className='grid gap-x-2 grid-cols-2 mt-2 mr-3'
                        style={{ gridTemplateColumns: 'auto 1fr' }}
                    >
                        <div></div>
                        <div className='flex justify-between text-xs'>
                            <div>{remark.user?.name ?? 'N/A'}</div>
                            <div className='text-atamblue-300'>
                                {format(new Date(remark.createdAt), 'dd-LLL-yyyy HH:mm')}
                            </div>
                        </div>
                        <div>
                            <CircledInitial
                                content={remark.user?.code ?? '?'}
                                className='border-teal-700'
                            />
                        </div>
                        <div className='grow flex my-auto'>
                            <div
                                className={classNames(
                                    'grow p-2 shadow-md rounded-xl border border-teal-700',
                                    {
                                        'text-gray-500 italic': remark.isDeleted,
                                    },
                                )}
                            >
                                {remark.isDeleted ? 'Deleted' : remark.content}
                            </div>
                            {profile.isSuperadmin || remark.user?.id === profile.id ? (
                                <IconButton
                                    path={mdiTrashCan}
                                    size={0.8}
                                    className={classNames(
                                        'text-atamblue-500 hover:text-atamblue-700',
                                        {
                                            invisible: remark.isDeleted,
                                        },
                                    )}
                                    onClick={() => onDeleteRemark(remark.id)}
                                />
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
            <form
                onSubmit={event => {
                    event.preventDefault()
                    onAddRemark()
                }}
            >
                <div className='flex mt-5 items-center'>
                    <CircledInitial content={profile.code} className='border-atamblue-300' />

                    <TextInput
                        className='w-full ml-2 pt-1 pb-0.5 shadow-inner rounded-xl border border-atamblue-300 bg-white leading-4'
                        placeholder='Write a remark...'
                        value={remark}
                        disabled={addRASRemarkMeta.isLoading || editRASMeta.isLoading}
                        onChange={event => setRemark(event.target.value)}
                        maxLength={90}
                    />
                    <IconButton
                        path={addRASRemarkMeta.isLoading ? mdiLoading : mdiSend}
                        spin={addRASRemarkMeta.isLoading}
                        isSubmit
                        className='ml-2 text-atamblue-300 hover:text-atamsky-900'
                    />
                </div>
            </form>

            <div className='flex justify-center mt-5'>
                <IconButton
                    path={editRASMeta.isLoading ? mdiLoading : mdiCheck}
                    spin={editRASMeta.isLoading}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    onClick={onSave}
                >
                    {t('Apply')}
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </div>
    )
}
