import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { BaseAtom } from './_BaseAtom'

export const ErrorMessage = BaseAtom(props => {
    return (
        <div className={classNames('text-sm text-red-500', props.className)}>{props.children}</div>
    )
})

export const ErrorBanner = BaseAtom(props => {
    const [isShown, setShown] = useState(true)
    const childrenCount = React.Children.count(props.children)

    useEffect(() => {
        setShown(true)
    }, [setShown, childrenCount])

    return childrenCount > 0 && isShown ? (
        <div
            className={classNames(
                'flex justify-between rounded border border-red-400 bg-red-200 text-red-500 p-2',
                props.className,
            )}
        >
            {props.children}
            <div onClick={() => setShown(false)}>
                <Icon path={mdiClose} size={1} className='ml-5' />
            </div>
        </div>
    ) : null
})
