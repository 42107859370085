import React from 'react'

import { useListAccountTypesQuery } from '../../api/for-company/account-types.api'
import { EntitySelectorField } from '../../atoms/Form/EntitySelector'
import { AccountType } from '../../domain/account-type.domain'

export const AccountTypeSelector: React.FC<{ name: string }> = props => {
    return (
        <EntitySelectorField<AccountType>
            name={props.name}
            useResolver={useListAccountTypesQuery}
            getOptionLabel={accountType => accountType.name}
            placeholder='Select Account Type'
        />
    )
}
