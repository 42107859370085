import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxField } from '../../atoms/Form/CheckboxInput'
import { Form } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Subject, SubjectFormSchema, SubjectFormType } from '../../domain/subject.domain'

const newSubject = (isTypeStrict?: 'records' | 'physical') => ({
    name: '',
    isPhysical: isTypeStrict === undefined ? false : isTypeStrict === 'records' ? false : true,
})

export const SubjectForm: React.FC<{
    subject?: Subject
    canEditType: boolean
    isTypeStrict?: 'records' | 'physical'
    isSaving: boolean
    onConfirm: (subject: SubjectFormType) => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation()

    return (
        <Form
            schema={SubjectFormSchema}
            defaults={props.subject ?? newSubject(props.isTypeStrict)}
            onSubmit={props.onConfirm}
        >
            <div className='grid grid-cols-4 gap-4 text-sm'>
                <TextField
                    name='name'
                    autoFocus
                    placeholder='Type subject name'
                    className='col-span-3'
                    inputClassName='border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                />
                {props.canEditType && props.isTypeStrict === undefined ? (
                    <CheckboxField
                        name='isPhysical'
                        className='items-center hover:text-atamsky-900'
                    >
                        Is physical?
                    </CheckboxField>
                ) : null}
            </div>
            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    Save
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('common:Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
