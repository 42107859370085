import { z } from 'zod'

export const CompanySchema = z.object({
    id: z.string(),
    name: z.string(),
    code: z.string(),
    isActive: z.boolean(),
})
export type Company = z.TypeOf<typeof CompanySchema>

export const CompanyFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    code: z.string().min(1, 'Code required'),
    isActive: z.boolean({ required_error: 'Is active required' }),
})
export type CompanyFormType = z.TypeOf<typeof CompanyFormSchema>
