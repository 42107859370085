import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_API_URL

// TODO: handle 401 errors automatically
export const forCompanyApi = createApi({
    reducerPath: 'forCompanyApi',
    baseQuery: (args, api, extraOptions) => {
        const state = api.getState() as any

        const companyId = state.auth.companyId

        if (!companyId) {
            throw new Error('Company Id not set')
        }

        return fetchBaseQuery({
            baseUrl: `${baseUrl}v1/company/${companyId}`,
            prepareHeaders: headers => {
                const { token } = state.auth
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`)
                }
                return headers
            },
        })(args, api, extraOptions)
    },
    // keepUnusedDataFor: 0,
    endpoints: () => ({}),
    tagTypes: [
        'Profile',
        'AccountTypeList',
        'AircraftInformation',
        'OILList',
        'MyProjectsList',
        'ProjectsList',
        'ProjectRoleList',
        'PAS',
        'PASSummary',
        'RAS',
        'RASSummary',
        'ROILSummary',
        'POILSummary',
        'SubjectsList',
        'TemplatesList',
        'UsersList',
        'DashboardStats',
    ],
})

export const forSuperadminApi = createApi({
    reducerPath: 'forSuperadminApi',
    baseQuery: (args, api, extraOptions) => {
        const state = api.getState() as any

        return fetchBaseQuery({
            baseUrl: `${baseUrl}v1/superadmin`,
            headers: {
                Authorization: `Bearer ${state.auth.token}`,
            },
            prepareHeaders: headers => {
                const { token } = state.auth
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`)
                }
                return headers
            },
        })(args, api, extraOptions)
    },
    // keepUnusedDataFor: 0,
    endpoints: () => ({}),
    tagTypes: ['CompanyList', 'Profile'],
})
