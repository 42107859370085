import { AircraftInformationModuleType } from '../../../domain/aircraft-information.domain'

export const moduleMap = {
    [AircraftInformationModuleType.Airframe]: {
        columns: 3,
        mainColor: 'text-teal-800',
        mainColorFaded: 'text-teal-400',
        gridSeparatorColor: 'border-r border-teal-400',
        gridTone: 'odd:bg-teal-100',
        onFocusBg: 'group hover:bg-teal-400',
        onFocusText: 'group-hover:text-teal-800',
    },
    [AircraftInformationModuleType.APU]: {
        columns: 3,
        mainColor: 'text-green-800',
        mainColorFaded: 'text-green-300',
        gridSeparatorColor: 'border-r border-green-300',
        gridTone: 'odd:bg-green-100',
        onFocusBg: 'group hover:bg-green-300',
        onFocusText: 'group-hover:text-green-800',
    },
    [AircraftInformationModuleType.Engine]: {
        columns: 3,
        mainColor: 'text-indigo-800',
        mainColorFaded: 'text-indigo-400',
        gridSeparatorColor: 'border-r border-indigo-400',
        gridTone: 'odd:bg-indigo-100',
        onFocusBg: 'group hover:bg-indigo-400',
        onFocusText: 'group-hover:text-indigo-800',
    },
    [AircraftInformationModuleType.LandingGear]: {
        columns: 2,
        mainColor: 'text-ebony-800',
        mainColorFaded: 'text-ebony-500',
        gridSeparatorColor: 'border-r border-ebony-500',
        gridTone: 'odd:bg-ebony-300',
        onFocusBg: 'group hover:bg-ebony-500',
        onFocusText: 'group-hover:text-ebony-800',
    },
}
