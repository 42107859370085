import React from 'react'

import { OILStatus } from '../../domain/oil.domain'

import { OILModal } from './common/OILModal'
import { StatusFilterInput } from './common/StatusFilter'
import { StatusIcon } from './common/StatusIcon'

export const StatusCell: React.FC<{
    itemNumber: number
    auditorStatus: OILStatus
    auditeeStatus: OILStatus
    isExpanded: boolean
    isReadOnly: boolean
    onAuditorChange: (value: OILStatus) => Promise<unknown>
    onAuditeeChange: (value: OILStatus) => Promise<unknown>
}> = props => {
    return (
        <div className='h-full grow flex justify-around items-center py-0.5'>
            <div className='w-1/2 flex justify-center'>
                <OILModal
                    className='bg-opacity-100'
                    body={({ close }) => (
                        <StatusFilterInput
                            activeStatuses={[props.auditorStatus]}
                            onToggle={newStatus => props.onAuditorChange(newStatus).then(close)}
                        />
                    )}
                    render={({ open }) => {
                        return (
                            <StatusIcon
                                status={props.auditorStatus}
                                size='lg'
                                isClickable={props.isExpanded && !props.isReadOnly}
                                onClick={open}
                            />
                        )
                    }}
                />
            </div>
            <div className='w-1/2 flex justify-center'>
                <OILModal
                    className='bg-opacity-100'
                    body={({ close }) => (
                        <StatusFilterInput
                            activeStatuses={[props.auditeeStatus]}
                            onToggle={newStatus => props.onAuditeeChange(newStatus).then(close)}
                        />
                    )}
                    render={({ open }) => {
                        return (
                            <StatusIcon
                                status={props.auditeeStatus}
                                size='lg'
                                isClickable={props.isExpanded && !props.isReadOnly}
                                onClick={open}
                            />
                        )
                    }}
                />
            </div>
        </div>
    )
}
