import { mdiLoading, mdiClose, mdiCheck } from '@mdi/js'
import React, { useState } from 'react'

import {
    DefaultsFor,
    ExternalErrors,
    Form,
    FORM_ERROR,
    ExternalFormError,
} from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import { User, UserFormSchema, UserFormType } from '../../domain/user.domain'
import { useTranslation } from '../../lib/i18n'
import { AccountTypeSelector } from '../AccountTypes/AccountTypeSelector'

import { ProjectsAssignmentsField } from './ProjectsAssignmentsField'

const newUser: DefaultsFor<typeof UserFormSchema> = {
    code: '',
    name: '',
    email: '',
}

export const UserForm: React.FC<{
    user?: User
    isSaving: boolean
    onSave: (user: UserFormType) => Promise<void>
    onCancel: () => void
}> = props => {
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<User>>({})
    const { t } = useTranslation('users')

    const onSubmit = (user: UserFormType) =>
        props.onSave(user).catch(error => {
            if (error.data?.name === 'UserEmailExists') {
                setExternalErrors({ email: 'Email already exists' })
                return
            }
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        })

    return (
        <Form
            schema={UserFormSchema}
            defaults={props.user ?? newUser}
            externalErrors={externalErrors}
            onSubmit={onSubmit}
        >
            <ExternalFormError />

            <div className='grid grid-cols-3 gap-8'>
                <TextField
                    name='code'
                    label={t('UserCode')}
                    placeholder={t('UserCodeHint')}
                    className='pr-4'
                    inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                    maxLength={4}
                />
                <TextField
                    name='name'
                    label={t('UserName')}
                    placeholder={t('UserNameHint')}
                    className='pr-4'
                    inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                />
                <TextField
                    name='email'
                    label={t('EmailAddress')}
                    placeholder={t('EmailAddressHint')}
                    className='pr-4'
                    inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                />
                <div>
                    <div className='text-atamblue-700'>Account Type:</div>
                    <AccountTypeSelector name='accountType' />
                </div>
            </div>

            <Separator className='w-full h-1 my-10 bg-ebony-400 '></Separator>
            <ProjectsAssignmentsField name='projectAssignments' />

            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    {t('Apply')}
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
