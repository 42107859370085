import { z } from 'zod'

import { UserSchema } from '../../domain/user.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

export const usersApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listUsers: buildQuery({
                input: z.any(),
                output: ListResponseSchema(
                    UserSchema.pick({
                        id: true,
                        code: true,
                        name: true,
                        email: true,
                        isActive: true,
                        auth: true,
                        accountType: true,
                        projectAssignments: true,
                    }),
                ),
                query: queryParams => ({
                    url: `/user${serializeQueryParams(queryParams)}`,
                    method: 'GET',
                }),
                providesTags: ['UsersList'],
            }),
            getUser: buildQuery({
                input: UserSchema.pick({ id: true }),
                output: UserSchema.pick({ id: true, code: true, name: true, email: true }),
                query: ({ id }) => ({
                    url: `/user/${id}`,
                    method: 'GET',
                }),
            }),
            createUser: buildMutation({
                input: UserSchema.pick({
                    code: true,
                    name: true,
                    email: true,
                    isActive: true,
                }).merge(
                    z.object({
                        accountTypeId: z.number(),
                        projectAssignments: z.object({
                            add: z.array(
                                z.object({
                                    projectId: z.number(),
                                    projectRoleId: z.number(),
                                }),
                            ),
                            remove: z.array(z.number()),
                        }),
                    }),
                ),
                output: UserSchema.pick({ id: true }),
                query: body => ({
                    url: '/user',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['UsersList'],
            }),
            editUser: buildMutation({
                input: UserSchema.pick({ id: true }).merge(
                    UserSchema.pick({
                        code: true,
                        name: true,
                        email: true,
                        isActive: true,
                    })
                        .merge(
                            z.object({
                                accountTypeId: z.number(),
                                projectAssignments: z.object({
                                    add: z.array(
                                        z.object({
                                            projectId: z.number(),
                                            projectRoleId: z.number(),
                                        }),
                                    ),
                                    remove: z.array(z.number()),
                                }),
                            }),
                        )
                        .partial(),
                ),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/user/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['UsersList'],
            }),
            activate: buildMutation({
                input: UserSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/user/${id}`,
                    method: 'PUT',
                    body: {
                        isActive: true,
                    },
                }),
                invalidatesTags: ['UsersList'],
            }),
            deactivate: buildMutation({
                input: UserSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/user/${id}`,
                    method: 'PUT',
                    body: {
                        isActive: false,
                    },
                }),
                invalidatesTags: ['UsersList'],
            }),
            resendActivation: buildMutation({
                input: UserSchema.pick({ email: true }),
                output: emptyContent(),
                query: body => ({
                    url: `/user/send-activation`,
                    method: 'POST',
                    body,
                }),
            }),
        }
    },
})

export const {
    useListUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useEditUserMutation,
    useActivateMutation,
    useDeactivateMutation,
    useResendActivationMutation,
} = usersApi
