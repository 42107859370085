import React from 'react'

import { Pill } from '../../Pill'

export const BooleanActiveFilter: React.FC<{
    label: string
    value: boolean
    className?: string
    onClear: () => void
}> = props => {
    if (!props.value) {
        return null
    }
    return (
        <Pill className={props.className} onClose={props.onClear}>
            <div className='text-atamblue-300 mr-1'>{props.label}</div>
        </Pill>
    )
}
