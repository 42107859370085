import { mdiFilter } from '@mdi/js'
import React, { useState, useEffect } from 'react'

import { IconButton } from '../../../atoms/IconButton'
import { Pill } from '../../../atoms/Pill'
import { BaseFilterInput } from '../../../atoms/Table/Filters/_BaseFilter'
import { setToggledInArray } from '../../../atoms/Table/utils'
import { OILStatus } from '../../../domain/oil.domain'

import { StatusIcon } from './StatusIcon'

const Statuses = [
    OILStatus.Open,
    OILStatus.Closed,
    OILStatus.Critical,
    OILStatus.Pending,
    OILStatus.SideLetter,
    OILStatus.UnderReview,
]

export const StatusFilterInput: React.FC<{
    activeStatuses: OILStatus[]
    onToggle: (status: OILStatus) => void
}> = props => {
    return (
        <div className='flex py-1'>
            {Statuses.map(status => (
                <StatusIcon
                    key={status}
                    status={status}
                    size='lg'
                    isActive={props.activeStatuses.includes(status)}
                    isClickable={true}
                    onClick={() => props.onToggle(status)}
                />
            ))}
        </div>
    )
}

export type StatusFilterValue = {
    auditorStatus: OILStatus[]
    auditeeStatus: OILStatus[]
}

export const StatusFilter: React.FC<{
    value?: StatusFilterValue
    className?: string
    style?: React.CSSProperties
    onChange: (newValue: StatusFilterValue) => void
}> = props => {
    const [auditorStatus, setAuditorStatus] = useState<OILStatus[]>([])
    const [auditeeStatus, setAuditeeStatus] = useState<OILStatus[]>([])

    const toggleAuditorStatus = (status: OILStatus) => setToggledInArray(setAuditorStatus)(status)()
    const toggleAuditeeStatus = (status: OILStatus) => setToggledInArray(setAuditeeStatus)(status)()

    useEffect(() => {
        setAuditorStatus(props.value?.auditorStatus ?? [])
        setAuditeeStatus(props.value?.auditeeStatus ?? [])
    }, [props.value])

    return (
        <div className='flex w-full'>
            <div className='w-1/2'>
                <BaseFilterInput
                    icon={
                        <IconButton
                            className='text-atamblue-300 hover:text-atamsky-900 focus:outline-none'
                            path={mdiFilter}
                            title='Filter by text'
                            size={1}
                        />
                    }
                    onApply={() => props.onChange({ auditorStatus, auditeeStatus })}
                    onCancel={() => {
                        setAuditorStatus(props.value?.auditorStatus ?? [])
                    }}
                >
                    <StatusFilterInput
                        activeStatuses={auditorStatus}
                        onToggle={toggleAuditorStatus}
                    />
                </BaseFilterInput>
            </div>
            <div className='w-1/2'>
                <BaseFilterInput
                    icon={
                        <IconButton
                            className='text-atamblue-300 hover:text-atamsky-900 focus:outline-none'
                            path={mdiFilter}
                            title='Filter by text'
                            size={1}
                        />
                    }
                    onApply={() => props.onChange({ auditorStatus, auditeeStatus })}
                    onCancel={() => {
                        setAuditorStatus(props.value?.auditorStatus ?? [])
                    }}
                >
                    <StatusFilterInput
                        activeStatuses={auditeeStatus}
                        onToggle={toggleAuditeeStatus}
                    />
                </BaseFilterInput>
            </div>
        </div>
    )
}

export const StatusActiveFilter: React.FC<{
    value: StatusFilterValue
    onClear: () => void
}> = props => {
    return (
        <Pill className='flex' onClose={props.onClear}>
            {props.value.auditorStatus.length > 0 ? (
                <div className='flex items-center'>
                    <div className='mr-1'>Auditor Status:</div>
                    {Statuses.filter(status => props.value.auditorStatus.includes(status)).map(
                        status => (
                            <StatusIcon key={status} status={status} size='sm' />
                        ),
                    )}
                </div>
            ) : null}
            {props.value.auditeeStatus.length > 0 ? (
                <div className='flex items-center'>
                    <div className='mr-1'>Auditee Status:</div>
                    {Statuses.filter(status => props.value.auditeeStatus.includes(status)).map(
                        status => (
                            <StatusIcon key={status} status={status} size='sm' />
                        ),
                    )}
                </div>
            ) : null}
        </Pill>
    )
}
