import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { z } from 'zod'

import { CredentialsSchema } from '../domain/auth.domain'

import { emptyContent, makeBuilders } from './common'

const baseUrl = process.env.REACT_APP_API_URL

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: (args, api, extraOptions) => {
        const state = api.getState() as any

        return fetchBaseQuery({
            baseUrl: `${baseUrl}v1`,
            headers: {
                Authorization: `Bearer ${state.auth.token}`,
            },
            prepareHeaders: headers => {
                const { token } = state.auth
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`)
                }
                return headers
            },
        })(args, api, extraOptions)
    },
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            login: buildMutation({
                input: CredentialsSchema,
                output: z.object({ token: z.string() }),
                query: body => ({
                    url: '/login',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Profile'],
            }),
            logout: buildMutation({
                input: z.void(),
                output: emptyContent(),
                query: () => ({
                    url: '/logout',
                    method: 'POST',
                }),
            }),

            activate: buildMutation({
                input: z.object({ payload: z.string(), password: z.string() }),
                output: z.object({ token: z.string() }),
                query: body => ({
                    url: '/activate',
                    method: 'POST',
                    body,
                }),
            }),
            getActivateInfo: buildQuery({
                input: z.object({ payload: z.string() }),
                output: z.object({ email: z.string() }),
                query: ({ payload }) => ({
                    url: `/activate/${payload}`,
                    method: 'GET',
                }),
            }),

            forgotPassword: buildMutation({
                input: z.object({ companyCode: z.string(), email: z.string() }),
                output: emptyContent(),
                query: body => ({
                    url: '/forgot-password',
                    method: 'POST',
                    body,
                }),
            }),

            getResetPasswordInfo: buildQuery({
                input: z.object({ payload: z.string() }),
                output: z.object({ email: z.string() }),
                query: ({ payload }) => ({
                    url: `/reset-password/${payload}`,
                    method: 'GET',
                }),
            }),
            resetPassword: buildMutation({
                input: z.object({ payload: z.string(), password: z.string() }),
                output: emptyContent(),
                query: body => ({
                    url: '/reset-password',
                    method: 'POST',
                    body,
                }),
            }),
        }
    },
    tagTypes: ['Profile'],
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useActivateMutation,
    useGetActivateInfoQuery,
    useForgotPasswordMutation,
    useGetResetPasswordInfoQuery,
    useResetPasswordMutation,
} = authApi
