import {
    mdiAccountConvert,
    mdiDialpad,
    mdiDomain,
    mdiDomainOff,
    mdiDomainPlus,
    mdiDotsHorizontal,
    mdiLoading,
} from '@mdi/js'
import { Icon } from '@mdi/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    useDisableCompanyMutation,
    useEnableCompanyMutation,
} from '../../api/for-superadmin/company.api'
import { Backdrop } from '../../atoms/Backdrop'
import { IconButton } from '../../atoms/IconButton'
import { Menu, MenuItem } from '../../atoms/Menu'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { Company } from '../../domain/company.domain'
import { ForSuperadmin } from '../Auth/ForSuperadmin'

import { CreateCompany } from './CreateCompany'
import { EditCompany } from './EditCompany'

export const CompanyHeaderMenu: React.FC = () => {
    const { t } = useTranslation('company')
    return (
        <Menu
            body={() => (
                <Tooltip text='Items Menu'>
                    <Icon
                        className='rotate-180 text-atamblue-300 hover:text-atamsky-900 hover:rotate-0'
                        path={mdiDialpad}
                        size={0.75}
                    />
                </Tooltip>
            )}
            className='w-56 bg-ebony-100 bg-opacity-90'
            render={({ close }) => (
                <>
                    <ForSuperadmin>
                        <Modal
                            fullwidth
                            body={() => <CreateCompany onSave={close} onCancel={close} />}
                            onClose={close}
                        >
                            <MenuItem icon={mdiDomainPlus}>{t('CreateNewCompany')}</MenuItem>
                        </Modal>
                    </ForSuperadmin>
                </>
            )}
        />
    )
}

export const CompanyMenu: React.FC<{
    company: Company
}> = props => {
    const { t } = useTranslation('companies')
    const [enableCompany, enableCompanyMeta] = useEnableCompanyMutation()
    const [disableCompany, disableCompanyMeta] = useDisableCompanyMutation()
    const [isShown, setShown] = useState(false)

    return (
        <div className='relative flex items-center'>
            <Tooltip text={t('CompanyActions')}>
                <IconButton
                    className='text-atamblue-300 hover:text-atamsky-900'
                    path={mdiDotsHorizontal}
                    size={0.75}
                    onClick={() => setShown(true)}
                />
            </Tooltip>
            {isShown ? (
                <Backdrop
                    className='absolute flex left-0 border border-atamsky-300 rounded-2xl bg-ebony-100 bg-opacity-90 shadow-lg'
                    onClick={() => setShown(false)}
                >
                    <ForSuperadmin>
                        <Modal
                            fullwidth
                            body={({ close }) => (
                                <EditCompany
                                    id={props.company.id}
                                    onSave={close}
                                    onCancel={close}
                                />
                            )}
                            onClose={() => setShown(false)}
                        >
                            <IconButton
                                path={mdiAccountConvert}
                                edgedContent={true}
                                size={1}
                                className='w-32 m-3 text-atamblue-700 group hover:text-atamsky-900'
                                iconClassName='mx-4 text-atamblue-300 group-hover:text-atamsky-900'
                            >
                                {t('EditCompany')}
                            </IconButton>
                        </Modal>
                    </ForSuperadmin>

                    {!props.company.isActive ? (
                        <ForSuperadmin>
                            <IconButton
                                size={1}
                                path={enableCompanyMeta.isLoading ? mdiLoading : mdiDomain}
                                spin={enableCompanyMeta.isLoading}
                                disabled={enableCompanyMeta.isLoading}
                                edgedContent={true}
                                className='w-44 m-3 text-atamblue-700 group hover:text-atamsky-900'
                                iconClassName='mx-4 text-atamblue-300 group-hover:text-atamsky-900'
                                onClick={() =>
                                    enableCompany({ id: props.company.id }).then(() => {
                                        setShown(false)
                                    })
                                }
                            >
                                {t('ActivateCompany')}
                            </IconButton>
                        </ForSuperadmin>
                    ) : (
                        <ForSuperadmin>
                            <IconButton
                                size={1}
                                path={disableCompanyMeta.isLoading ? mdiLoading : mdiDomainOff}
                                spin={disableCompanyMeta.isLoading}
                                disabled={disableCompanyMeta.isLoading}
                                edgedContent={true}
                                className='w-44 m-3 text-atamblue-700 group hover:text-atamsky-900'
                                iconClassName='mx-4 text-atamblue-300 group-hover:text-atamsky-900'
                                onClick={() =>
                                    disableCompany({ id: props.company.id }).then(() => {
                                        setShown(false)
                                    })
                                }
                            >
                                {t('DeactivateCompany')}
                            </IconButton>
                        </ForSuperadmin>
                    )}
                </Backdrop>
            ) : null}
        </div>
    )
}
