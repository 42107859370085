import {
    mdiCheckboxBlankOutline,
    mdiCheckboxOutline,
    mdiAccountPlus,
    mdiTicketAccount,
    mdiAccountCheck,
    mdiAccountConvert,
    mdiAccountRemove,
    mdiDotsHorizontal,
    mdiEmailSend,
    mdiLoading,
    mdiAccountSettings,
} from '@mdi/js'
import { Icon } from '@mdi/react'
import { noop } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    useActivateMutation,
    useDeactivateMutation,
    useResendActivationMutation,
} from '../../api/for-company/users.api'
import { Backdrop } from '../../atoms/Backdrop'
import { IconButton } from '../../atoms/IconButton'
import { Menu, MenuItem } from '../../atoms/Menu'
import { ConfirmationModal } from '../../atoms/Modal/ConfirmationModal'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { AccountTypePermissions } from '../../domain/account-type.domain'
import { User } from '../../domain/user.domain'
import { ForAccountType } from '../Auth/ForAccountType'

import { CreateUser } from './CreateUser'
import { EditUser } from './EditUser'

export const UserHeaderMenu: React.FC<{
    showInactiveUsers?: boolean
    onShowInactiveUsersChange?: (value: boolean) => void
    onFetchList: () => void
}> = props => {
    const { t } = useTranslation('users')
    return (
        <Menu
            body={() => (
                <Tooltip text='Items Menu' className='justify-center'>
                    <Icon
                        className='text-atamblue-300 hover:text-atamsky-900 hover:rotate-0'
                        path={mdiAccountSettings}
                        size={0.75}
                    />
                </Tooltip>
            )}
            className='w-56 bg-ebony-100 bg-opacity-90'
            render={({ close }) => (
                <>
                    <ForAccountType permission={AccountTypePermissions.UserCreate}>
                        <Modal
                            fullwidth
                            body={() => (
                                <CreateUser
                                    onSave={() => {
                                        close()
                                        props.onFetchList()
                                    }}
                                    onCancel={close}
                                />
                            )}
                            onClose={close}
                        >
                            <MenuItem icon={mdiAccountPlus}>{t('CreateNewUser')}</MenuItem>
                        </Modal>
                    </ForAccountType>

                    <MenuItem
                        icon={mdiTicketAccount}
                        // onClick={noop}
                        onClick={() => {
                            props.onShowInactiveUsersChange &&
                                props.onShowInactiveUsersChange(!props.showInactiveUsers)
                            close()
                        }}
                    >
                        {t('ShowInactiveUsers')}
                        <Icon
                            //TODO de verificat daca nu arata mai ok un togggit pullle switch in loc de toate checkbox-urile
                            path={
                                props.showInactiveUsers
                                    ? mdiCheckboxOutline
                                    : mdiCheckboxBlankOutline
                            }
                            size={0.75}
                            className='ml-2'
                        />
                    </MenuItem>
                </>
            )}
        />
    )
}

export const UserMenu: React.FC<{
    user: User
    onFetchList: () => void
}> = props => {
    const { t } = useTranslation('users')

    const [activateUser, activateUserMeta] = useActivateMutation()
    const [deactivateUser] = useDeactivateMutation()
    const [resendActivationEmail, resendActivationEmailMeta] = useResendActivationMutation()
    const [isShown, setShown] = useState(false)

    return (
        <div className='relative flex justify-center items-center'>
            <Tooltip text={t('UserActions')}>
                <IconButton
                    className='text-atamblue-300 hover:text-atamsky-900'
                    path={mdiDotsHorizontal}
                    size={0.75}
                    onClick={() => setShown(true)}
                />
            </Tooltip>
            {isShown ? (
                <Backdrop
                    className='absolute flex left-5 -mt-3 border border-atamsky-300 rounded-xl bg-ebony-100 bg-opacity-90 shadow-lg'
                    onClick={() => setShown(false)}
                >
                    <ForAccountType permission={AccountTypePermissions.UserEdit}>
                        <Modal
                            fullwidth
                            body={({ close }) => (
                                <EditUser
                                    user={props.user}
                                    onSave={() => {
                                        props.onFetchList()
                                        close()
                                    }}
                                    onCancel={close}
                                />
                            )}
                            onClose={() => setShown(false)}
                        >
                            <MenuItem icon={mdiAccountConvert} className='whitespace-nowrap'>
                                {t('EditUser')}
                            </MenuItem>
                        </Modal>
                    </ForAccountType>

                    {!props.user.isActive ? (
                        <ForAccountType permission={AccountTypePermissions.UserActivate}>
                            <MenuItem
                                icon={activateUserMeta.isLoading ? mdiLoading : mdiAccountCheck}
                                iconSpin={activateUserMeta.isLoading}
                                disabled={activateUserMeta.isLoading}
                                className='whitespace-nowrap'
                                onClick={() =>
                                    activateUser({ id: props.user.id }).then(() => {
                                        setShown(false)
                                    })
                                }
                            >
                                {t('ActivateUser')}
                            </MenuItem>
                        </ForAccountType>
                    ) : (
                        <ForAccountType permission={AccountTypePermissions.UserDeactivate}>
                            <ConfirmationModal
                                message={t('DeactivateUserConfirmationMessage')}
                                onConfirm={() =>
                                    deactivateUser({
                                        id: props.user.id,
                                    }).then(() => {
                                        setShown(false)
                                    })
                                }
                            >
                                <MenuItem icon={mdiAccountRemove} className='whitespace-nowrap'>
                                    {t('DeactivateUser')}
                                </MenuItem>
                            </ConfirmationModal>
                        </ForAccountType>
                    )}
                    {!props.user.auth ? (
                        <div>
                            <MenuItem
                                icon={
                                    resendActivationEmailMeta.isLoading ? mdiLoading : mdiEmailSend
                                }
                                iconSpin={resendActivationEmailMeta.isLoading}
                                disabled={activateUserMeta.isLoading}
                                className='whitespace-nowrap'
                                onClick={() =>
                                    resendActivationEmail({ email: props.user.email }).then(() => {
                                        setShown(false)
                                    })
                                }
                            >
                                {t('ResendActivationEmail')}
                            </MenuItem>
                        </div>
                    ) : null}
                </Backdrop>
            ) : null}
        </div>
    )
}
