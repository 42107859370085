import {
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiFolderStar,
    mdiMinusCircleOutline,
    mdiPencil,
    mdiPuzzlePlusOutline,
    mdiDeleteEmpty,
    mdiUnfoldLessHorizontal,
    mdiUnfoldMoreHorizontal,
    mdiFilePlus,
} from '@mdi/js'
import classNames from 'classnames'
import { differenceWith } from 'lodash'
import React, { useState, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import Select from 'react-select'

import { styles } from '../../atoms/Form/EntitySelector'
import { FormWatcher } from '../../atoms/Form/FormWatcher'
import { ToggleSwitch, ToggleSwitchField } from '../../atoms/Form/ToggleSwitch'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { ProjectAssignment } from '../../domain/project.domain'
import { Template } from '../../domain/template.domain'
import { User } from '../../domain/user.domain'
import { CategoryForm } from '../Subjects/CategoryForm'
import { SubjectForm } from '../Subjects/SubjectForm'
import { SubjectSelector } from '../Subjects/SubjectsSelector'
import { SubpartForm } from '../Subjects/SubpartForm'
import { SaveTemplate } from '../Templates/SaveTemplate'
import { TemplateSelector } from '../Templates/TemplateSelector'

import {
    getSubjectsSubparts,
    hasSubjectSubpartEnabled,
    FormCategory,
    FormSubject,
    FormSubpart,
    useCategories,
    useSubjects,
    useSubparts,
    getSubjectSubparts,
    isCategoryEnabled,
    isSubpartEnabled,
    isSubjectEnabled,
} from './useProjectForm'

const ToggleExpandField: React.FC<{ name: string }> = props => {
    return (
        <Controller
            name={props.name}
            render={({ field: { value: isExpanded, onChange } }) => (
                <IconButton
                    path={isExpanded ? mdiDotsVertical : mdiDotsHorizontal}
                    onClick={() => onChange(!isExpanded)}
                />
            )}
        />
    )
}

const UserBulkAssign: React.FC<{ prefix: string }> = props => {
    const assignedUsers = useWatch({ name: `assignments` }).map(
        (assignment: ProjectAssignment) => assignment.user,
    )

    return (
        <Controller
            name={`bulkAssignedUser.${props.prefix}`}
            render={({ field: { value, onChange } }) => (
                <div className='flex items-center'>
                    Bulk assign:{' '}
                    <Select<User>
                        value={value}
                        options={assignedUsers}
                        getOptionLabel={user => user.name}
                        getOptionValue={user => `${user.id}`}
                        styles={styles as any}
                        className='w-64'
                        onChange={onChange}
                    />
                </div>
            )}
        />
    )
}

const SaveTemplateButton: React.FC<{
    prefix: 'recordsSubjects' | 'physicalSubjects'
    currentTemplate: Template | undefined
    label: string
}> = props => {
    const { currentTemplate, label, prefix } = props
    const formWatch = useWatch({ name: prefix })
    const subjects = useMemo(() => formWatch ?? [], [formWatch]) as FormSubject[]
    const currentSubparts = useMemo(() => getSubjectsSubparts(subjects), [subjects])

    const isDifferent = useMemo(() => {
        const templateSubparts = getSubjectsSubparts(currentTemplate?.subjects ?? [])
        const diff = differenceWith(currentSubparts, templateSubparts, (subpart1, subpart2) => {
            if (subpart1.id === subpart2.id) {
                return subpart1.isSelected === subpart2.isSelected
            }
            return false
        })

        return !currentTemplate || diff.length > 0
    }, [currentTemplate, currentSubparts])

    return (
        <Modal
            title={`Save template (${subjects.length} subjects)`}
            body={({ close }) => (
                <SaveTemplate
                    subparts={
                        getSubjectsSubparts(subjects).filter(subpart => subpart.isSelected) as any
                    }
                    onSave={close}
                    onCancel={close}
                />
            )}
        >
            <IconButton
                path={mdiFolderStar}
                size={1}
                className='group'
                iconClassName='text-atamblue-300 group-hover:text-atamsky-900'
                labelClassName='ml-2 group-hover:text-atamsky-900'
                disabled={currentSubparts.length === 0 || !isDifferent}
            >
                {label}
            </IconButton>
        </Modal>
    )
}

const ProjectSubparts: React.FC<{
    prefix: 'recordsSubjects' | 'physicalSubjects'
    subjectIndex: number
    categoryIndex: number
}> = props => {
    const { subjectIndex, categoryIndex, prefix } = props
    const { control } = useFormContext()
    const { getPath, getCollectionPath, create, edit, remove } = useSubparts({
        control,
        subjectIndex,
        categoryIndex,
        prefix,
    })
    const subparts = useWatch({
        name: getCollectionPath(prefix, subjectIndex, categoryIndex),
    }) as FormSubpart[]

    return (
        <>
            {subparts.map((subpart, index) => (
                <FormWatcher
                    key={subpart.name}
                    name={`${getPath(prefix, subjectIndex, categoryIndex, index)}`}
                >
                    {(subpart: FormSubpart) => {
                        const isCategorySubpartSelected = isCategoryEnabled({
                            subparts,
                        } as FormCategory)
                        return (
                            <div
                                className={classNames(
                                    'grid grid-cols-[minmax(100px,_1fr)_50px] items-center px-3 my-1 py-2 bg-atamblue-100 rounded-xl shadow text-sm border',
                                    {
                                        'text-gray-500 bg-gray-200': subpart.isNew
                                            ? !isCategorySubpartSelected
                                            : !subpart.isSelected,
                                        'bg-atamblue-100': subpart.isNew
                                            ? isCategorySubpartSelected
                                            : subpart.isSelected,
                                    },
                                )}
                            >
                                <div>{subpart.name}</div>
                                {subpart.isNew ? (
                                    <div className='flex justify-between items-center'>
                                        <Modal
                                            title='Edit subpart'
                                            domElementClassName='flex'
                                            body={({ close }) => (
                                                <SubpartForm
                                                    subpart={subpart}
                                                    isSaving={false}
                                                    onConfirm={newSubpart => {
                                                        edit(index, {
                                                            ...subpart,
                                                            ...newSubpart,
                                                            isSelected: true,
                                                        })
                                                        close()
                                                    }}
                                                    onCancel={close}
                                                />
                                            )}
                                        >
                                            <IconButton
                                                path={mdiPencil}
                                                size={0.9}
                                                className='text-atamblue-300 hover:text-atamsky-900'
                                            />
                                        </Modal>
                                        <IconButton
                                            path={mdiDeleteEmpty}
                                            size={0.9}
                                            className='text-atamblue-300 hover:text-atamsky-900'
                                            onClick={() => remove(index)}
                                        />
                                    </div>
                                ) : (
                                    <ToggleSwitchField
                                        className='justify-end'
                                        size={1.25}
                                        name={`${getPath(
                                            prefix,
                                            subjectIndex,
                                            categoryIndex,
                                            index,
                                        )}.isSelected`}
                                    />
                                )}
                            </div>
                        )
                    }}
                </FormWatcher>
            ))}

            <Modal
                title='Create subpart'
                body={({ close }) => (
                    <SubpartForm
                        subpart={undefined}
                        isSaving={false}
                        onConfirm={newSubpart => {
                            create({ ...newSubpart, isSelected: true })
                            close()
                        }}
                        onCancel={close}
                    />
                )}
            >
                <Tooltip text={`Create new subpart`} className='flex items-center mr-2'>
                    <IconButton
                        path={mdiPuzzlePlusOutline}
                        size={0.75}
                        className='text-atamblue-300 hover:text-atamsky-900'
                        iconClassName='mr-2'
                    >
                        Create Subpart
                    </IconButton>
                </Tooltip>
            </Modal>
        </>
    )
}

const ProjectCategories: React.FC<{
    prefix: 'recordsSubjects' | 'physicalSubjects'
    subjectIndex: number
}> = props => {
    const { subjectIndex, prefix } = props
    const { control } = useFormContext()
    const { categories, getPath, toggleAllSubparts, create, edit, remove } = useCategories({
        control,
        subjectIndex,
        prefix,
    })

    return (
        <>
            {categories.map((c, index) => (
                <FormWatcher key={c.categoryKey} name={getPath(prefix, subjectIndex, index)}>
                    {(category: FormCategory) => {
                        const hasSelectedSubparts = isCategoryEnabled(category)
                        return (
                            <>
                                <div
                                    className={classNames(
                                        'grid grid-cols-[30px_minmax(100px,_1fr)_50px] items-center px-3 my-1 py-2 bg-atamblue-100 rounded-xl shadow text-sm border',
                                        {
                                            'text-gray-500 bg-gray-200': !hasSelectedSubparts,
                                            'bg-atamblue-100': hasSelectedSubparts,
                                        },
                                    )}
                                >
                                    <ToggleExpandField
                                        name={`${getPath(prefix, subjectIndex, index)}.isExpanded`}
                                    />
                                    <div>{category.name}</div>
                                    {category.isNew ? (
                                        <div className='flex justify-between items-center'>
                                            <Modal
                                                title='Edit subpart'
                                                domElementClassName='flex'
                                                body={({ close }) => (
                                                    <CategoryForm
                                                        category={category}
                                                        isSaving={false}
                                                        onConfirm={newCategory => {
                                                            edit(index, category, newCategory)
                                                            close()
                                                        }}
                                                        onCancel={close}
                                                    />
                                                )}
                                            >
                                                <IconButton
                                                    path={mdiPencil}
                                                    size={0.9}
                                                    className='text-atamblue-300 hover:text-atamsky-900'
                                                />
                                            </Modal>
                                            <IconButton
                                                path={mdiDeleteEmpty}
                                                size={0.9}
                                                className='text-atamblue-300 hover:text-atamsky-900'
                                                onClick={() => remove(index)}
                                            />
                                        </div>
                                    ) : (
                                        <ToggleSwitch
                                            size={1.25}
                                            className='justify-end'
                                            value={hasSelectedSubparts}
                                            onChange={isSelected =>
                                                toggleAllSubparts(index, category, isSelected)
                                            }
                                        />
                                    )}
                                </div>

                                {category.isExpanded ? (
                                    <div className='ml-10'>
                                        <ProjectSubparts
                                            prefix={prefix}
                                            subjectIndex={props.subjectIndex}
                                            categoryIndex={index}
                                        />
                                    </div>
                                ) : null}
                            </>
                        )
                    }}
                </FormWatcher>
            ))}

            <Modal
                title='Create category'
                body={({ close }) => (
                    <CategoryForm
                        category={undefined}
                        isSaving={false}
                        onConfirm={newCategory => {
                            create(newCategory)
                            close()
                        }}
                        onCancel={close}
                    />
                )}
            >
                <Tooltip text={`Create category`} className='flex items-center mr-2'>
                    <IconButton
                        path={mdiFilePlus}
                        size={0.75}
                        className='text-atamblue-300 hover:text-atamsky-900'
                        iconClassName='mr-2'
                    >
                        Create Category
                    </IconButton>
                </Tooltip>
            </Modal>
        </>
    )
}

export const ProjectSubjectsField: React.FC<{
    prefix: 'recordsSubjects' | 'physicalSubjects'
    title: string
    icon: string
    isPhysical: boolean
    templateSelectorLabel: string
    createSubjectButtonLabel: string
    saveTemplateButtonLabel: string
    readOnlySubjects?: string[]
}> = props => {
    const { prefix } = props
    const [collapsed, setCollapsed] = useState(false)
    const [currentTemplate, setCurrentTemplate] = useState<Template | undefined>()
    const { control } = useFormContext()
    const {
        subjects,
        setTemplate,
        getPath,
        toggleAllSubparts,
        add,
        create,
        edit,
        remove,
    } = useSubjects({
        control,
        prefix: prefix,
    })

    const header = (
        <div className='flex justify-between items-center mb-3 text-atamblue-700'>
            <div className='font-medium'>{props.title}</div>
            <div className='flex grow ml-16'>
                <div className='max-w-2xl grow ml-auto mr-4 flex justify-end items-center'>
                    <span className='text-sm'>{props.templateSelectorLabel}</span>
                    <TemplateSelector
                        isPhysical={props.isPhysical}
                        className='ml-4 grow'
                        onChange={template => {
                            if (template) {
                                setTemplate(template)
                                setCurrentTemplate(template)
                            }
                        }}
                    />
                </div>
                {collapsed ? (
                    <Tooltip text='Expand section'>
                        <IconButton
                            path={mdiUnfoldMoreHorizontal}
                            size={1}
                            className='text-atamblue-300 hover:text-atamsky-900'
                            onClick={() => setCollapsed(collapsed => !collapsed)}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip text='Collapse section'>
                        <IconButton
                            path={mdiUnfoldLessHorizontal}
                            size={1}
                            className='text-atamblue-300 hover:text-atamsky-900'
                            onClick={() => setCollapsed(collapsed => !collapsed)}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    )

    const subjectsList = subjects.map((s, index) => (
        <FormWatcher key={s.subjectKey} name={getPath(prefix, index)}>
            {(subject: FormSubject) => {
                const subparts = getSubjectSubparts(subject)
                const enabledSubparts = subparts.filter(isSubpartEnabled)
                return (
                    <>
                        <div
                            className={classNames(
                                'grid grid-cols-[30px_minmax(100px,_1fr)_100px_minmax(200px,300px)_50px] items-center px-3 my-1 py-2 bg-atamblue-150 rounded-xl shadow text-md border',
                                {
                                    'text-gray-500': !isSubjectEnabled(subject),
                                },
                            )}
                        >
                            <ToggleExpandField name={`${getPath(prefix, index)}.isExpanded`} />
                            <div>{subject.name}</div>
                            {subject.isNew ? (
                                <div></div>
                            ) : (
                                <IconButton
                                    path={mdiMinusCircleOutline}
                                    size={1}
                                    className='text-red-400 hover:text-red-500'
                                    iconClassName='mr-1'
                                    onClick={() => remove(index)}
                                >
                                    Remove
                                </IconButton>
                            )}
                            <div className='text-center'>
                                {!subject.isExpanded
                                    ? `${enabledSubparts.length} subparts selected`
                                    : null}
                            </div>
                            {subject.isNew ? (
                                <div className='flex justify-between items-center'>
                                    <Modal
                                        title='Edit subject'
                                        domElementClassName='flex'
                                        body={({ close }) => (
                                            <SubjectForm
                                                canEditType={false}
                                                subject={subject as any}
                                                isSaving={false}
                                                onConfirm={newSubject => {
                                                    edit(index, subject, newSubject)
                                                    close()
                                                }}
                                                onCancel={close}
                                            />
                                        )}
                                    >
                                        <IconButton
                                            path={mdiPencil}
                                            size={0.9}
                                            className='text-atamblue-300 hover:text-atamsky-900'
                                        />
                                    </Modal>
                                    <IconButton
                                        path={mdiDeleteEmpty}
                                        size={0.9}
                                        className='text-atamblue-300 hover:text-atamsky-900'
                                        onClick={() => remove(index)}
                                    />
                                </div>
                            ) : (
                                <ToggleSwitch
                                    size={1.25}
                                    className='justify-end'
                                    value={hasSubjectSubpartEnabled(subject)}
                                    onChange={isSelected =>
                                        toggleAllSubparts(index, subject, isSelected)
                                    }
                                />
                            )}
                        </div>

                        {subject.isExpanded ? (
                            <div className='ml-10 mb-3'>
                                <ProjectCategories prefix={prefix} subjectIndex={index} />
                            </div>
                        ) : null}
                    </>
                )
            }}
        </FormWatcher>
    ))

    const addExistingSubject = (
        <SubjectSelector
            key={`add-subject-${subjects.length}`}
            isPhysical={props.isPhysical}
            exclude={subjects}
            className='w-1/2'
            placeholder='Add existing subject'
            onChange={selectedSubject => {
                if (selectedSubject) {
                    const newSubject = {
                        ...selectedSubject,
                        isExpanded: true,
                        categories: selectedSubject.categories.map(category => ({
                            ...category,
                            isExpanded: true,
                            subparts: category.subparts.map(subpart => ({
                                ...subpart,
                                isSelected: true,
                            })),
                        })),
                    }
                    add(newSubject)
                }
            }}
        />
    )

    const footer = (
        <div className='flex justify-between items-center mt-10 text-atamblue-700'>
            <Modal
                title={props.createSubjectButtonLabel}
                body={({ close }) => (
                    <SubjectForm
                        canEditType={false}
                        subject={undefined}
                        isSaving={false}
                        onConfirm={newSubject => {
                            create({ ...newSubject, isPhysical: props.isPhysical })
                            close()
                        }}
                        onCancel={close}
                    />
                )}
            >
                <IconButton
                    path={props.icon}
                    size={1}
                    className='group'
                    iconClassName='text-atamblue-300 group-hover:text-atamsky-900'
                    labelClassName='ml-2 group-hover:text-atamsky-900'
                >
                    {props.createSubjectButtonLabel}
                </IconButton>
            </Modal>

            <UserBulkAssign prefix={props.prefix} />

            <SaveTemplateButton
                prefix={props.prefix}
                currentTemplate={currentTemplate}
                label={props.saveTemplateButtonLabel}
            />
        </div>
    )

    return (
        <div>
            {header}

            {collapsed ? null : (
                <>
                    <div className='max-h-[512px] overflow-y-auto'>{subjectsList}</div>
                    {addExistingSubject}
                    {footer}
                </>
            )}
            {/* <DebugForm name={`subjects`} className='mt-10' /> */}
        </div>
    )
}
