import { z } from 'zod'

import { ProjectCategorySchema, ProjectSubpartSchema } from '../../domain/project.domain'
import { RASStatus } from '../../domain/RAS.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders } from '../common'

export const RASSchema = z.object({
    id: z.number(),
    name: z.string(),
    status: z.nativeEnum(RASStatus),
    poc: z.number(),
    ewd: z.number(),
    user: z.any(),
})

export const rasApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listRASSummary: buildQuery({
                input: z.object({ projectId: z.number() }),
                output: z.any(),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/ras/summary`,
                    method: 'GET',
                }),
                providesTags: ['RASSummary'],
            }),
            editRASSubjectRemark: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    remark: z.string(),
                }),
                output: emptyContent(),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/remark`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['RASSummary'],
            }),
            listRAS: buildQuery({
                input: z.object({ projectId: z.number(), subjectId: z.number() }),
                output: z.any(),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/ras/${subjectId}`,
                    method: 'GET',
                }),
                providesTags: ['RAS'],
            }),
            editRAS: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(z.object({ id: z.number() }))
                    .merge(RASSchema.pick({ status: true, poc: true, ewd: true }))
                    .partial()
                    .merge(z.object({ userId: z.number() }))
                    .partial(),
                output: z.any(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['RAS', 'RASSummary', 'DashboardStats'],
            }),
            addRASRemark: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(z.object({ id: z.number() }))
                    .merge(z.object({ content: z.string() })),
                output: z.any(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/${id}/remark`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['RAS'],
            }),
            deleteRASRemark: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    id: z.number(),
                    remarkId: z.number(),
                }),
                output: z.any(),
                query: ({ projectId, subjectId, id, remarkId }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/${id}/remark/${remarkId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['RAS'],
            }),
            listRASCategoriesBySubjectId: buildQuery({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: ListResponseSchema(ProjectCategorySchema),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/category`,
                    method: 'GET',
                }),
            }),
            addRASSubpart: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    categoryId: z.number(),
                    name: z.string(),
                }),
                output: ProjectSubpartSchema.pick({ id: true }),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/subpart`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['RAS', 'DashboardStats'],
            }),
            bulkAssignRASSubject: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                    userId: z.number(),
                }),
                output: emptyContent(),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/bulk-assign`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['RAS'],
            }),

            exportRASXlsx: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/export-xlsx`,
                    method: 'POST',
                }),
            }),
            exportRASPdf: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/export-pdf`,
                    method: 'POST',
                }),
            }),
            exportRASHtml: buildMutation({
                input: z.object({
                    projectId: z.number(),
                    subjectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/ras/${subjectId}/export-html`,
                    method: 'POST',
                }),
            }),

            exportRASSummaryXlsx: buildMutation({
                input: z.object({
                    projectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/ras/export-xlsx`,
                    method: 'POST',
                }),
            }),
            exportRASSummaryPdf: buildMutation({
                input: z.object({
                    projectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/ras/export-pdf`,
                    method: 'POST',
                }),
            }),
            exportRASSummaryHtml: buildMutation({
                input: z.object({
                    projectId: z.number(),
                }),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/ras/export-html`,
                    method: 'POST',
                }),
            }),
        }
    },
})

export const {
    useListRASSummaryQuery,
    useEditRASSubjectRemarkMutation,
    useListRASQuery,
    useEditRASMutation,
    useAddRASRemarkMutation,
    useDeleteRASRemarkMutation,
    useListRASCategoriesBySubjectIdQuery,
    useAddRASSubpartMutation,
    useBulkAssignRASSubjectMutation,

    useExportRASXlsxMutation,
    useExportRASPdfMutation,
    useExportRASHtmlMutation,

    useExportRASSummaryXlsxMutation,
    useExportRASSummaryPdfMutation,
    useExportRASSummaryHtmlMutation,
} = rasApi
