import React from 'react'

export const SummaryPage: React.FC = () => {
    // const urlParams = useParams<{ projectId: string }>()
    // const projectId = parseInt(urlParams.projectId ?? '0', 10)

    // useEffect(() => {
    //     if (company) {
    //         start({ companyId: company.id, id: projectId }).catch(noop)
    //     }
    // }, [start, company?.id, projectId])

    // if (!company) {
    //     return <ErrorMessage>No company selected</ErrorMessage>
    // }

    // if (isPending) {
    //     return <PageLoadingSpinner />
    // }
    // if (isSuccess && results) {
    //     return (
    //         <div>
    //             <Header summary={results} />
    //             {/* <Summary summary={results} /> */}
    //         </div>
    //     )
    // }
    return null
}
