import { createSelector } from '@reduxjs/toolkit'
import { z } from 'zod'

import { UserProfileSchema } from '../../domain/profile.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, makeBuilders } from '../common'

export const userProfileApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            getProfile: buildQuery({
                input: z.void(),
                output: UserProfileSchema,
                query: () => ({
                    url: '/profile',
                    method: 'GET',
                }),
                providesTags: ['Profile'],
            }),
            editProfile: buildMutation({
                input: UserProfileSchema.pick({ name: true, email: true }).partial(),
                output: emptyContent(),
                query: body => ({
                    url: '/profile',
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['Profile', 'UsersList'],
            }),
        }
    },
})

export const { useGetProfileQuery, useEditProfileMutation } = userProfileApi

export const selectProfile = createSelector(
    userProfileApi.endpoints.getProfile.select(),
    profile => profile.data,
)
