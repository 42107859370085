import {
    mdiLoading,
    mdiCheck,
    mdiClose,
    mdiDotsVertical,
    mdiDotsHorizontal,
    mdiMinusCircleOutline,
} from '@mdi/js'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useWatch, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmptyResponse } from '../../atoms/EmptyResponse'
import { CheckboxField } from '../../atoms/Form/CheckboxInput'
import { ExternalErrors, Form, ExternalFormError, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { ToggleSwitch, ToggleSwitchField } from '../../atoms/Form/ToggleSwitch'
import { IconButton } from '../../atoms/IconButton'
import { Template, TemplateFormSchema, TemplateFormType } from '../../domain/template.domain'
import { SubjectSelector } from '../Subjects/SubjectsSelector'

type TemplateSubpart = { id: number; name: string; isSelected?: boolean }
type TemplateCategory = {
    id: number
    name: string
    subparts: TemplateSubpart[]
    isExpanded: boolean
}
type TemplateSubject = {
    id: number
    name: string
    categories: TemplateCategory[]
    isExpanded: boolean
    isPhysical: boolean
    _count: { templates: number }
}

const TemplateSubpartView: React.FC<{
    subjectIndex: number
    categoryIndex: number
    subpartIndex: number
}> = props => {
    const index = props.subpartIndex
    const subpart = useWatch({
        name: `subjects.${props.subjectIndex}.categories.${props.categoryIndex}.subparts.${index}`,
    }) as TemplateSubpart
    return (
        <div>
            <div
                className={classNames(
                    'flex justify-between items-center px-3 my-1 py-2 rounded-xl shadow text-sm border',
                    {
                        'text-gray-500 bg-gray-200': !subpart.isSelected,
                        'bg-atamblue-100': subpart.isSelected,
                    },
                )}
            >
                {subpart.name}
                <ToggleSwitchField
                    size={1.25}
                    name={`subjects.${props.subjectIndex}.categories.${props.categoryIndex}.subparts.${index}.isSelected`}
                />
            </div>
        </div>
    )
}

const TemplateSubparts: React.FC<{ subjectIndex: number; categoryIndex: number }> = props => {
    const { control } = useFormContext()
    const { fields } = useFieldArray({
        control,
        name: `subjects.${props.subjectIndex}.categories.${props.categoryIndex}.subparts`,
        keyName: 'key',
    })
    const subparts = (fields as unknown) as ({
        key: string
    } & TemplateSubpart)[]

    return (
        <div>
            {subparts.map((subpart, index) => (
                <TemplateSubpartView
                    key={subpart.id}
                    subjectIndex={props.subjectIndex}
                    categoryIndex={props.categoryIndex}
                    subpartIndex={index}
                />
            ))}
        </div>
    )
}

const TemplateCategoryView: React.FC<{
    subjectIndex: number
    categoryIndex: number
    onToggleExpanded: (index: number, category: TemplateCategory) => void
    onToggleSelected: (index: number, category: TemplateCategory) => void
}> = props => {
    const index = props.categoryIndex
    const category = useWatch({
        name: `subjects.${props.subjectIndex}.categories.${index}`,
    }) as TemplateCategory

    const anySubpartsSelected = category.subparts.some(subpart => subpart.isSelected)

    return (
        <div key={category.id}>
            <div className='flex justify-between items-center px-3 my-1 py-2 bg-atamblue-100 rounded-xl shadow text-sm border'>
                <div className='flex items-center'>
                    <IconButton
                        path={category.isExpanded ? mdiDotsVertical : mdiDotsHorizontal}
                        size={1}
                        className='mr-2'
                        onClick={() =>
                            props.onToggleExpanded(index, {
                                ...category,
                                isExpanded: !category.isExpanded,
                            })
                        }
                    />
                    {category.name}
                </div>
                <ToggleSwitch
                    value={anySubpartsSelected}
                    size={1.25}
                    onChange={isSelected => {
                        props.onToggleSelected(index, {
                            ...category,
                            isExpanded: isSelected,
                            subparts: category.subparts.map(subpart => ({
                                ...subpart,
                                isSelected,
                            })),
                        })
                    }}
                />
            </div>

            {category.isExpanded ? (
                <div className='ml-10'>
                    <TemplateSubparts subjectIndex={props.subjectIndex} categoryIndex={index} />
                </div>
            ) : null}
        </div>
    )
}

const TemplateSubjectView: React.FC<{
    subjectIndex: number
    onToggleExpanded: (index: number, category: TemplateSubject) => void
    onToggleSelected: (index: number, category: TemplateSubject) => void
    onRemove: (index: number) => void
}> = props => {
    const index = props.subjectIndex
    const { control } = useFormContext()
    const subject = useWatch({ name: `subjects.${index}` }) as TemplateSubject
    const { fields, update } = useFieldArray({
        control,
        name: `subjects.${props.subjectIndex}.categories`,
        keyName: 'key',
    })
    const categories = (fields as unknown) as ({
        key: string
    } & TemplateCategory)[]

    const subparts = subject.categories.flatMap(category => category.subparts)
    const selectedSubparts = subparts.filter(subpart => subpart.isSelected)
    const anySubpartsSelected = selectedSubparts.length > 0

    return (
        <div key={subject.id} className='mb-3'>
            <div className='grid grid-cols-[30px_minmax(100px,_1fr)_300px_minmax(200px,300px)_30px] items-center px-3 my-1 py-2 bg-atamblue-150 rounded-xl shadow text-md border'>
                <IconButton
                    path={subject.isExpanded ? mdiDotsVertical : mdiDotsHorizontal}
                    size={1}
                    className='mr-2'
                    onClick={() =>
                        props.onToggleExpanded(index, {
                            ...subject,
                            isExpanded: !subject.isExpanded,
                        })
                    }
                />
                <div>{subject.name}</div>
                <IconButton
                    path={mdiMinusCircleOutline}
                    size={1}
                    className='text-red-400 hover:text-red-500'
                    iconClassName='mr-1'
                    onClick={() => props.onRemove(index)}
                >
                    Remove
                </IconButton>
                <div>
                    {!subject.isExpanded ? `${selectedSubparts.length} subparts selected` : null}
                </div>
                <ToggleSwitch
                    value={anySubpartsSelected}
                    size={1.25}
                    onChange={isSelected => {
                        props.onToggleSelected(index, {
                            ...subject,
                            isExpanded: isSelected,
                            categories: subject.categories.map(category => ({
                                ...category,
                                // isExpanded: isSelected,
                                subparts: category.subparts.map(subpart => ({
                                    ...subpart,
                                    isSelected,
                                })),
                            })),
                        })
                    }}
                />
            </div>
            {subject.isExpanded ? (
                <div className='ml-10'>
                    {categories.map((category, index) => {
                        return (
                            <TemplateCategoryView
                                key={category.id}
                                subjectIndex={props.subjectIndex}
                                categoryIndex={index}
                                onToggleExpanded={update}
                                onToggleSelected={update}
                            />
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}

const TemplateFields: React.FC = () => {
    const { control } = useFormContext()
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'subjects',
        keyName: 'key',
    })

    const subjects = (fields as unknown) as ({
        key: string
    } & TemplateSubject)[]
    const isPhysical = useWatch({ name: `isPhysical` })

    return (
        <>
            <div className='flex'>
                <TextField
                    name='name'
                    placeholder='Type template name'
                    className='w-2/3 mr-8'
                    inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                />

                <CheckboxField name='isPhysical' disabled={subjects.length > 0}>
                    Is physical?
                </CheckboxField>
            </div>
            <div>
                <div className='overflow-y-auto max-h-screen50 my-5'>
                    {subjects.length === 0 ? (
                        <EmptyResponse>No subjects added</EmptyResponse>
                    ) : null}
                    {subjects.map((subject, index) => {
                        return (
                            <TemplateSubjectView
                                key={subject.id}
                                subjectIndex={index}
                                onToggleExpanded={update}
                                onToggleSelected={update}
                                onRemove={remove}
                            />
                        )
                    })}
                </div>

                <SubjectSelector
                    placeholder='Add subject'
                    key={`add-subject-${subjects.length}`}
                    isPhysical={isPhysical}
                    exclude={subjects}
                    className='w-1/2'
                    onChange={newSubject => {
                        if (newSubject && !subjects.find(s => s.id === newSubject.id)) {
                            append({
                                ...newSubject,
                                isExpanded: true,
                                categories: newSubject.categories.map(category => ({
                                    ...category,
                                    isExpanded: true,
                                    subparts: category.subparts.map(subpart => ({
                                        ...subpart,
                                        isSelected: true,
                                    })),
                                })),
                            })
                        }
                    }}
                />
            </div>
        </>
    )
}

export const TemplateForm: React.FC<{
    template?: Template
    isSaving: boolean
    onSave: (template: any) => Promise<void>
    onCancel: () => void
}> = props => {
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<TemplateFormType>>()
    const { t } = useTranslation('templates')

    const onSubmit = (template: TemplateFormType) => {
        props.onSave(template).catch(error => {
            if (error.data?.name === 'TemplateNameExists') {
                setExternalErrors({ name: 'Name already exists' })
                return
            }
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        })
    }

    return (
        <Form
            schema={TemplateFormSchema}
            defaults={props.template ?? { isPhysical: false }}
            externalErrors={externalErrors}
            onSubmit={onSubmit}
        >
            <div className='flex flex-col'>
                <ExternalFormError />

                <TemplateFields />

                <div className='flex justify-center mt-10'>
                    <IconButton
                        path={props.isSaving ? mdiLoading : mdiCheck}
                        spin={props.isSaving}
                        className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                        iconClassName='m-2 text-green-300 group-hover:text-green-500'
                        isSubmit
                    >
                        {t('Apply')}
                    </IconButton>

                    <IconButton
                        path={mdiClose}
                        className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                        iconClassName='m-2 text-red-400 group-hover:text-red-500'
                        onClick={props.onCancel}
                    >
                        {t('Cancel')}
                    </IconButton>
                </div>
            </div>
        </Form>
    )
}
