import { mdiLoading, mdiFileExcel } from '@mdi/js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '../IconButton'
import { Tooltip } from '../Tooltip'

export const ExportXlsButton: React.FC<{ isLoading: boolean; onClick: () => void }> = props => {
    const { t } = useTranslation('exportButtons')
    return (
        <Tooltip
            text={t('ExcelExport')}
            className='cursor-pointer relative'
            onClick={props.onClick}
        >
            <IconButton
                isSubmit
                path={props.isLoading ? mdiLoading : mdiFileExcel}
                spin={props.isLoading}
                size={1}
                disabled={props.isLoading}
                className='rounded-2xl transition duration-500 bg-white p-2.5 shadow-md mx-0.5 cursor-pointer text-green-900 hover:bg-green-900 hover:text-white'
            ></IconButton>
        </Tooltip>
    )
}
