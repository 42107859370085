import React from 'react'

import { useGetMyProjectsQuery } from '../../api/for-company/projects.api'
import { hasProjectRolePermissions, ProjectRolePermissions } from '../../domain/project-role.domain'
import { Project } from '../../domain/project.domain'
import { selectCurrentCompanyId, selectIsSuperadmin } from '../../state/auth.slice'
import { useAppSelector } from '../../store'

export type WithProjectRoleRenderProps = (renderProps: {
    hasPermission: (permission: ProjectRolePermissions) => boolean
}) => React.ReactNode

export const WithProjectRole = (props: {
    projectId: Project['id']
    children: WithProjectRoleRenderProps
}): JSX.Element | null => {
    const companyId = useAppSelector(selectCurrentCompanyId)
    const isSuperadmin = useAppSelector(selectIsSuperadmin)
    const { data } = useGetMyProjectsQuery()

    if (!data) {
        return null
    }

    const currentProject = data.results.find(p => p.id === Number(props.projectId))
    if (!currentProject) {
        return null
    }

    const hasPermission = (permission: ProjectRolePermissions) =>
        hasProjectRolePermissions({
            isSuperadmin,
            projectRole: currentProject.assignment?.projectRole,
            permission,
        })

    if (companyId) {
        return <>{props.children({ hasPermission })}</>
    } else {
        return null
    }
}

export type ForProjectRoleProps = {
    permission: ProjectRolePermissions | undefined
    projectId: Project['id']
    fallback?: React.ReactNode
}
export const ForProjectRole: React.FC<ForProjectRoleProps> = props => {
    const companyId = useAppSelector(selectCurrentCompanyId)

    if (companyId) {
        return (
            <WithProjectRole projectId={props.projectId}>
                {({ hasPermission }) => {
                    return !props.permission || hasPermission(props.permission)
                        ? props.children
                        : props.fallback ?? null
                }}
            </WithProjectRole>
        )
    }

    return null
}
