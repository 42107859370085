import classNames from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ErrorMessage } from '../ErrorMessage'

import { FieldHint } from './FieldHint'

export type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string
    inputClassName?: string
    labelClassName?: string
    label?: string
    error?: { message?: string }
}
// TODO: figure out how to optimize number of rerenders while displaying currentChars / maxChars
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
    const { className, inputClassName, labelClassName, label, error, value, ...inputProps } = props
    const { t } = useTranslation('common')

    const length = (value as string)?.length ?? 0
    return (
        <div
            className={classNames(className, {
                'bg-ebony-300': props.disabled,
            })}
        >
            {label ? <div className={labelClassName}>{label}</div> : null}
            <input
                type='text'
                {...inputProps}
                className={classNames(
                    'bg-transparent p-2 w-full focus:outline-none',
                    {
                        'text-atamblue-300': props.disabled,
                    },
                    inputClassName,
                )}
                value={value}
                ref={ref}
            />
            {error ? <ErrorMessage className='pt-1'>{error.message}</ErrorMessage> : null}
            {props.maxLength ? (
                <FieldHint
                    message={t('MaximumCharacters') + `${length}/${props.maxLength}`}
                    className='ml-2 mt-2 text-atamblue-500'
                />
            ) : null}
        </div>
    )
})

export const TextField: React.FC<TextInputProps & { name: string }> = props => {
    const { name, ...otherProps } = props

    return (
        <Controller
            defaultValue=''
            name={name}
            render={({ field, fieldState }) => (
                <TextInput {...otherProps} {...field} error={fieldState.error} />
            )}
        />
    )
}
