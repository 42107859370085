import React from 'react'

export type TextareaInputProps = Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'value' | 'onChange'
> & {
    value?: string
    onChange: (newValue: string) => void
}
export const TextareaInput: React.FC<TextareaInputProps> = props => {
    const { value, onChange, ...textareaProps } = props
    return (
        <div>
            <textarea
                {...textareaProps}
                value={value}
                onChange={event => onChange(event.target.value)}
            />
            {props.maxLength ? (
                <div className='text-right text-gray-500'>
                    {(value && value.length) ?? 0} / {props.maxLength}
                </div>
            ) : null}
        </div>
    )
}
