import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

export const IconText: React.FC<{
    path: string
    size?: number
    className?: string
    iconClassName?: string
}> = props => {
    const size = props.size ?? 1
    return (
        <div className={classNames('flex items-center', props.className)}>
            <Icon
                path={props.path}
                size={size}
                className={classNames('mr-2', props.iconClassName)}
            />
            {props.children}
        </div>
    )
}
