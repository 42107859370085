import classNames from 'classnames'
import React from 'react'

import { IconButton } from '../IconButton'

export type ExportButtonProps = {
    icon: string
    spin?: boolean
    disabled?: boolean
    isSubmit?: boolean
    className?: string
    pill?: string
    pillClassName?: string
    onClick?: () => void
}

export const ExportButton: React.FC<ExportButtonProps> = props => {
    return (
        <div className='flex relative group cursor-pointer' onClick={props.onClick}>
            {props.pill ? (
                <div
                    className={classNames(
                        'flex justify-center items-center w-6 h-6 absolute -top-1.5 left-8 rounded-2lg transition duration-500 border-2 text-center font-medium text-sm shadow-md',
                        props.pillClassName,
                    )}
                >
                    {props.pill}
                </div>
            ) : null}
            <IconButton
                isSubmit={props.isSubmit}
                path={props.icon}
                spin={props.spin}
                size={1}
                disabled={props.disabled}
                className={classNames(
                    'iconButton inline-flex items-center focus:outline-none justify-center cursor-pointer rounded-2xl transition duration-500 p-2.5 shadow-md mx-0.5 ',
                    props.className,
                )}
            ></IconButton>
        </div>
    )
}
