import React, { useState } from 'react'

import { Backdrop } from '../Backdrop'

type BaseModalBodyProps = {
    close: () => void
}

type BaseModalRenderProps = {
    open: () => void
}

export type BaseModalProps = {
    body: (props: BaseModalBodyProps) => JSX.Element
    render: (props: BaseModalRenderProps) => JSX.Element
}
export const BaseModal: React.FC<BaseModalProps> = props => {
    const [isShown, setShown] = useState(false)

    const open = () => setShown(true)
    const close = () => setShown(false)

    const modal = (
        <Backdrop className='absolute flex justify-center items-center' onClick={close}>
            {props.body({ close })}
        </Backdrop>
    )
    return (
        <>
            {isShown ? modal : null}
            {props.render({ open })}
        </>
    )
}
