import { mdiLoading, mdiCheck, mdiClose, mdiCalendarToday } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useState } from 'react'

import { DateField } from '../../../atoms/Form/DateInput'
import { ExternalErrors, Form, FORM_ERROR } from '../../../atoms/Form/Form'
import { NumberField } from '../../../atoms/Form/NumberInput'
import { TextField } from '../../../atoms/Form/TextInput'
import { IconButton } from '../../../atoms/IconButton'
import { Separator } from '../../../atoms/Separator'
import {
    AircraftInformationBlock,
    AircraftInformationBlockFormSchema,
    AircraftInformationBlockFormType,
    AircraftInformationBlockType,
} from '../../../domain/aircraft-information.domain'
import { Project } from '../../../domain/project.domain'
import { useTranslation } from '../../../lib/i18n'

export const AircraftInformationBlockForm: React.FC<{
    projectId: Project['id']
    block: AircraftInformationBlock
    moduleName: string
    isSaving: boolean
    onSave: (editedBlock: AircraftInformationBlockFormType) => Promise<void>
    onCancel: () => void
}> = props => {
    const { t } = useTranslation(['aircraftInformation', 'common'])
    const [externalErrors, setExternalErrors] =
        useState<ExternalErrors<AircraftInformationBlockFormType>>()

    const onSubmit = (editedBlock: AircraftInformationBlockFormType) => {
        return props.onSave(editedBlock).catch(() => {
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        })
    }

    return (
        <Form
            schema={AircraftInformationBlockFormSchema}
            defaults={props.block}
            externalErrors={externalErrors}
            onSubmit={onSubmit}
        >
            <div className='flex justify-center text-xl font-semibold text-atamsky-900'>
                {t('EditModuleData', { modalTitle: props.moduleName })}
            </div>
            <Separator className='w-32 h-px m-auto mt-2 mb-5 bg-atamsky-700' />
            <div className='grid grid-cols-2 gap-8'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='mb-4 font-medium text-center text-atamblue-700'>
                        {t('Label')}:
                    </div>
                    <TextField
                        name='name'
                        maxLength={40}
                        disabled={!props.block.isEditable}
                        className='w-80 h-12 bg-ebony-100 border border-atamsky-300 rounded-2xl text-atamblue-900 text-center focus:outline-none'
                        inputClassName='h-12 p-3 leading-5 text-center'
                    />
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <div className='mb-4 font-medium text-center text-atamblue-700'>
                        {t('Value')}:
                    </div>
                    {props.block.type === AircraftInformationBlockType.Number ? (
                        <div className='flex items-center'>
                            <NumberField
                                name='value'
                                className='w-80 h-12 mr-3 bg-ebony-100 border border-atamsky-300 rounded-2xl text-center focus:outline-none'
                                inputClassName='w-80 h-12 bg-transparent p-3 leading-5 text-center focus:outline-none'
                            />
                            {props.block.params.unit}
                        </div>
                    ) : props.block.type === AircraftInformationBlockType.Date ? (
                        <DateField
                            name='value'
                            icon={
                                <Icon
                                    path={mdiCalendarToday}
                                    size={1}
                                    className='mt-2 mr-2 text-atamsky-900'
                                />
                            }
                            className='w-80 h-12 flex justify-center bg-ebony-100 border border-atamsky-300 rounded-2xl text-center focus:outline-none'
                        />
                    ) : (
                        <TextField
                            name='value'
                            maxLength={40}
                            className='w-80 h-12 mr-3 bg-ebony-100 border border-atamsky-300 rounded-2xl text-center focus:outline-none'
                            inputClassName='h-12 p-3 leading-5 text-center'
                        />
                    )}
                </div>
            </div>
            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    disabled={props.isSaving}
                    className='mr-10 text-atamblue-700 group hover:text-atamsky-900'
                    iconClassName='mr-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    {t('common:Save')}
                </IconButton>
                <IconButton
                    onClick={props.onCancel}
                    path={mdiClose}
                    className='ml-10 text-atamblue-700 group hover:text-atamsky-900'
                    iconClassName='mr-2 text-red-400 group-hover:text-red-500'
                >
                    {t('common:Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
