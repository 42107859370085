import { useGetProfileQuery as useGetUserProfileQuery } from '../api/for-company/profile.api'
import { useGetProfileQuery as useGetSuperadminProfileQuery } from '../api/for-superadmin/profile.api'
import { selectIsSuperadmin } from '../state/auth.slice'
import { useAppSelector } from '../store'

export const useCurrentProfile = () => {
    const isSuperadmin = useAppSelector(selectIsSuperadmin)
    const { data: superadminProfile } = useGetSuperadminProfileQuery(undefined, {
        skip: !isSuperadmin,
    })
    const { data: userProfile } = useGetUserProfileQuery(undefined, {
        skip: isSuperadmin,
    })

    return isSuperadmin ? superadminProfile : userProfile
}
