import { z } from 'zod'

import { AccountTypeSchema } from '../../domain/account-type.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

const accountTypesApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listAccountTypes: buildQuery({
                input: z.any(),
                output: ListResponseSchema(AccountTypeSchema),
                query: query => ({
                    url: `/account-type${serializeQueryParams(query)}`,
                    method: 'GET',
                }),
                providesTags: ['AccountTypeList'],
            }),
            createAccountType: buildMutation({
                input: AccountTypeSchema.pick({ name: true, permissions: true }),
                output: AccountTypeSchema.pick({ id: true }),
                query: body => ({
                    url: '/account-type',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['AccountTypeList'],
            }),
            editAccountType: buildMutation({
                input: AccountTypeSchema.pick({ id: true }).merge(
                    AccountTypeSchema.pick({ name: true, permissions: true }).partial(),
                ),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/account-type/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['AccountTypeList'],
            }),
        }
    },
})

export const {
    useListAccountTypesQuery,
    useCreateAccountTypeMutation,
    useEditAccountTypeMutation,
} = accountTypesApi
