import { mdiMenuOpen } from '@mdi/js'
import classNames from 'classnames'
import React, { useState } from 'react'

import { IconButton } from '../../../atoms/IconButton'
import { Sidebar, SidebarStatus } from '../../../atoms/Sidebar/Sidebar'
import { Tooltip } from '../../../atoms/Tooltip'
import { selectCurrentCompany } from '../../../state/auth.slice'
import { useAppSelector } from '../../../store'
import { Toolbar } from '../Toolbar'

import { PrimaryNavigation } from './PrimaryNavigation'

export const Navigation: React.FC = props => {
    const company = useAppSelector(selectCurrentCompany)
    const [sidebarStatus, setSidebarStatus] = useState<SidebarStatus>('pinned')
    const isCollapsed = sidebarStatus === 'collapsed'
    const isPinned = sidebarStatus === 'pinned'

    return (
        <div className='flex h-screen'>
            <Toolbar
                className={classNames({
                    'pl-16': !isPinned,
                    'pl-72': isPinned,
                })}
            />

            <Sidebar
                status={sidebarStatus}
                className={classNames(
                    'z-50 shrink-0 bg-white bg-opacity-90 shadow-2xl transition-all duration-300 flex flex-col justify-between',
                    {
                        'w-14': isCollapsed,
                        'w-72': !isCollapsed,
                    },
                )}
                onMouseEnter={() => setSidebarStatus(old => (old === 'pinned' ? old : 'expanded'))}
                onMouseLeave={() => setSidebarStatus(old => (old === 'pinned' ? old : 'collapsed'))}
            >
                <div className='w-72 relative text-atamblue-700'>
                    <Tooltip text={isPinned ? 'Unpin Sidebar' : 'Pin Sidebar'}>
                        <IconButton
                            path={mdiMenuOpen}
                            className='absolute top-4 right-0 pr-4 text-atamblue-300 hover:text-atamsky-900'
                            iconClassName={classNames('transition-all duration-100', {
                                'rotate-180': !isPinned,
                            })}
                            style={{ zIndex: 99999 }}
                            onClick={() =>
                                setSidebarStatus(old => (old === 'pinned' ? 'collapsed' : 'pinned'))
                            }
                        />
                    </Tooltip>
                    <PrimaryNavigation />
                </div>

                {company ? (
                    <div className='flex flex-col justify-center items-center mb-3 text-gray-500 text-center'>
                        <div className='font-medium'>{company.code}</div>
                        {!isCollapsed ? <div>{company.name}</div> : null}
                    </div>
                ) : null}
            </Sidebar>

            <div
                className={classNames('main-content mt-14 grow p-4 duration-100', {
                    'ml-14': !isPinned,
                    'ml-72': isPinned,
                })}
            >
                {props.children}
            </div>
        </div>
    )
}
