import React from 'react'

import { ActivateAccount } from '../components/Auth/ActivateAccount'
import { UnauthenticatedLayout } from '../layouts/Unauthenticated.layout'

export const ActivateAccountPage: React.FC = () => {
    return (
        <UnauthenticatedLayout>
            <ActivateAccount />
        </UnauthenticatedLayout>
    )
}
