import classNames from 'classnames'
import React from 'react'

import { BaseModal, BaseModalProps } from '../../../atoms/Modal/BaseModal'
import { Separator } from '../../../atoms/Separator'

export type OILModalProps = BaseModalProps & {
    title?: React.ReactNode
    noPadding?: boolean
    className?: string
}

export const OILModal: React.FC<OILModalProps> = props => {
    return (
        <BaseModal
            body={({ close }) => (
                <div
                    className={classNames(
                        'bg-white bg-opacity-90 shadow-xl border border-atamsky-300 rounded-2xl text-sm',
                        props.className,
                    )}
                >
                    {props.title ? (
                        <>
                            <div className='mt-3 text-center font-medium'>{props.title}</div>
                            <Separator className='w-32 h-px m-auto mt-2 mb-5 bg-atamsky-700' />
                        </>
                    ) : null}
                    {props.body({ close })}
                </div>
            )}
            render={props.render}
        ></BaseModal>
    )
}
