import Icon from '@mdi/react'
import classNames from 'classnames'
import React, { useState } from 'react'

import { Backdrop } from './Backdrop'

export const Menu: React.FC<{
    body: () => JSX.Element
    className?: string
    render: (args: { close: () => void }) => JSX.Element
}> = props => {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <div
                className={classNames('flex justify-center relative cursor-pointer', {
                    'bg-ebony-200': isOpen,
                })}
                onClick={() => setOpen(true)}
            >
                {props.body()}
                {isOpen ? (
                    <div
                        className={classNames(
                            'absolute top-0 left-0 flex flex-col border border-atamsky-300 rounded-2xl shadow-xl py-3 z-40',
                            props.className,
                        )}
                    >
                        {props.render({
                            close: () => {
                                setTimeout(() => {
                                    setOpen(false)
                                }, 0)
                            },
                        })}
                    </div>
                ) : null}
            </div>
            {isOpen ? <Backdrop onClick={() => setOpen(false)} /> : null}
        </>
    )
}

export const MenuItem: React.FC<{
    icon?: string
    iconSpin?: boolean
    className?: string
    disabled?: boolean
    onClick?: () => void
}> = props => (
    <div
        className={classNames(
            'flex items-center cursor-pointer p-2 group hover:text-atamsky-900',
            props.className,
        )}
        onClick={props.onClick}
    >
        <div className='w-8 text-atamblue-300 group-hover:text-atamsky-900'>
            {props.icon ? <Icon path={props.icon} className='mx-auto' size={0.75} /> : null}
        </div>
        {props.children}
    </div>
)
