import MUIModal from '@material-ui/core/Modal'
import classNames from 'classnames'
import React from 'react'

type ModalBodyProps = {
    close: () => void
}

type ModalProps = {
    body: (props: ModalBodyProps) => JSX.Element
    title?: string
    description?: string
    domElementClassName?: string
    modalBodyClassName?: string
    disableBackdropClick?: boolean
    fullwidth?: boolean
    fullheight?: boolean
    onClose?: () => void
}
export const Modal: React.FC<ModalProps> = props => {
    const { body, title, description, children } = props
    const [isOpened, setOpened] = React.useState(false)
    const fullwidth = props.fullwidth ?? false
    const fullheight = props.fullheight ?? false
    const onOpenModal = () => setOpened(true)
    const onCloseModal = () => {
        setOpened(false)
        if (props.onClose) {
            props.onClose()
        }
    }

    const modalBody = (
        <div
            className={classNames(
                'p-5 rounded-2xl bg-ebony-50 bg-opacity-90 border-2 border-atamsky-300 focus:outline-none',
                props.modalBodyClassName,
                {
                    'w-11/12 ml-20': fullwidth,
                    'h-full max-h-screen90 overflow-y-auto': fullheight,
                },
            )}
        >
            {title ? (
                <>
                    <div className='text-center font-medium text-atamblue-900'>{title}</div>
                    <div className='w-32 h-px m-auto mt-2 mb-5 bg-atamsky-700'></div>
                </>
            ) : null}
            {body({ close: onCloseModal })}
        </div>
    )

    return (
        <>
            <div
                className={classNames('inline-block', props.domElementClassName)}
                onClick={onOpenModal}
            >
                {children}
            </div>
            <MUIModal
                className='flex justify-center items-center w-screen h-screen'
                BackdropProps={{
                    className: 'bg-black bg-opacity-25',
                    style: {
                        backgroundColor: '',
                    },
                }}
                disableBackdropClick={props.disableBackdropClick}
                open={isOpened}
                onClose={onCloseModal}
                aria-labelledby={title}
                aria-describedby={description}
            >
                {modalBody}
            </MUIModal>
        </>
    )
}
