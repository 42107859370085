import { mdiCheck, mdiClose, mdiCommentEditOutline, mdiLoading } from '@mdi/js'
import classNames from 'classnames'
import React from 'react'
import { z } from 'zod'

import { Form } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'

const EditSummaryRemarkFormSchema = z.object({
    remark: z.string().min(1, 'Remark cannot be empty'),
})

export const SummaryRemarksCell: React.FC<{
    value: string
    reviewSubject?: string
    isLoading?: boolean
    isReadOnly?: boolean
    onChange: (value: string) => Promise<unknown>
}> = props => {
    return (
        <>
            {props.isReadOnly ? null : (
                <div className='flex w-full items-center group'>
                    <Modal
                        body={({ close }) => (
                            <Form
                                schema={EditSummaryRemarkFormSchema}
                                defaults={{ remark: props.value }}
                                onSubmit={changes => props.onChange(changes.remark).then(close)}
                            >
                                <div className='flex flex-col items-center '>
                                    <div className='font-medium text-sm'>
                                        <span className='text-atamsky-900'>
                                            {props.reviewSubject}
                                        </span>{' '}
                                        Summary Remark
                                    </div>
                                    <TextField
                                        name='remark'
                                        className='w-full'
                                        inputClassName='border-b border-atamblue-300 text-sm placeholder-atamblue-300 focus:outline-none'
                                        placeholder='Write a remark...'
                                        maxLength={90}
                                    />
                                    <div className='flex justify-center text-atamsky-900 px-32 mt-4'>
                                        <IconButton
                                            isSubmit
                                            path={props.isLoading ? mdiLoading : mdiCheck}
                                            spin={props.isLoading}
                                            disabled={props.isLoading}
                                            className='mr-10 group text-atamblue-700 hover:text-atamsky-900'
                                            iconClassName='text-green-300 group-hover:text-green-500 mr-2'
                                        >
                                            Save
                                        </IconButton>
                                        <IconButton
                                            onClick={close}
                                            path={mdiClose}
                                            className='ml-10 group text-atamblue-700 hover:text-atamsky-900'
                                            iconClassName='text-red-400 group-hover:text-red-500 mr-2'
                                        >
                                            Cancel
                                        </IconButton>
                                    </div>
                                </div>
                            </Form>
                        )}
                    >
                        <Tooltip text={'Add/Edit Remark'}>
                            <IconButton
                                path={mdiCommentEditOutline}
                                iconClassName={classNames(
                                    'hidden group-hover:inline-block text-atamblue-300',
                                )}
                            />
                        </Tooltip>
                    </Modal>

                    <Tooltip text={props.value} className='p-2 leading-4 truncate'>
                        {props.value}
                    </Tooltip>
                </div>
            )}
        </>
    )
}
