import { z } from 'zod'

import { CompanySchema } from '../../domain/company.domain'
import { forSuperadminApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders } from '../common'

const companyApi = forSuperadminApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listCompanies: buildQuery({
                input: z.object({ name: z.string() }).partial(),
                output: ListResponseSchema(CompanySchema),
                query: args => {
                    const query = args.name ? `?name=${args.name}` : ''
                    return { url: `/company${query}`, method: 'GET' }
                },
                providesTags: ['CompanyList'],
            }),
            fetchCompanyById: buildQuery({
                input: CompanySchema.pick({ id: true }),
                output: CompanySchema,
                query: ({ id }) => `/company/${id}`,
                keepUnusedDataFor: 0,
            }),
            createCompany: buildMutation({
                input: CompanySchema.pick({ name: true, code: true, isActive: true }),
                output: CompanySchema.pick({ id: true }),
                query: body => ({
                    url: '/company',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['CompanyList'],
            }),
            editCompany: buildMutation({
                input: CompanySchema.pick({ id: true }).merge(
                    CompanySchema.pick({ name: true, code: true, isActive: true }).partial(),
                ),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/company/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['CompanyList'],
            }),
            enableCompany: buildMutation({
                input: CompanySchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/company/${id}`,
                    method: 'PUT',
                    body: { isActive: true },
                }),
                invalidatesTags: ['CompanyList'],
            }),
            disableCompany: buildMutation({
                input: CompanySchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/company/${id}`,
                    method: 'PUT',
                    body: { isActive: false },
                }),
                invalidatesTags: ['CompanyList'],
            }),
        }
    },
})

export const {
    useListCompaniesQuery,
    useFetchCompanyByIdQuery,
    useCreateCompanyMutation,
    useEditCompanyMutation,
    useEnableCompanyMutation,
    useDisableCompanyMutation,
} = companyApi
