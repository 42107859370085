import { mdiFile } from '@mdi/js'
import React from 'react'

import { useListRASCategoriesBySubjectIdQuery } from '../../api/for-company/ras.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { ProjectCategory } from '../../domain/project.domain'

export const ProjectCategorySelector: React.FC<{
    projectId: number
    subjectId: number
    value?: ProjectCategory
    exclude?: ProjectCategory[]
    className?: string
    iconClassName?: string
    onChange: (newProjectCategory: ProjectCategory | undefined) => void
}> = props => {
    return (
        <EntitySelector<ProjectCategory>
            useResolver={useListRASCategoriesBySubjectIdQuery}
            resolverParams={{
                projectId: props.projectId,
                subjectId: props.subjectId,
            }}
            getOptionLabel={category => category.name}
            defaultValue={props.value}
            exclude={props.exclude}
            placeholder='Select Category'
            className={props.className}
            iconClassName={props.iconClassName}
            icon={mdiFile}
            onChange={props.onChange}
        />
    )
}
