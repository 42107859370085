import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import {
    DeepPartial,
    FormProvider,
    Path,
    UnpackNestedValue,
    useForm,
    useFormContext,
} from 'react-hook-form'
import { z } from 'zod'

import { ErrorBanner } from '../ErrorMessage'

export const FORM_ERROR = 'FORM_ERROR'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefaultsFor<S extends z.ZodType<any, any, any>> = UnpackNestedValue<
    DeepPartial<z.TypeOf<S>>
>

export type ExternalErrors<T> = Partial<Record<keyof T | typeof FORM_ERROR, string>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormProps<S extends z.ZodType<any, any, any>> = React.PropsWithChildren<{
    schema: S
    defaults?: DefaultsFor<S>
    externalErrors?: ExternalErrors<z.TypeOf<S>>
    onSubmit: (fields: z.TypeOf<S>) => void
}>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Form = <S extends z.ZodType<any, any, any>>(props: FormProps<S>) => {
    const methods = useForm<z.TypeOf<S>>({
        defaultValues: props.defaults,
        resolver: zodResolver(props.schema),
        shouldFocusError: false,
    })

    const { externalErrors } = props
    const {
        clearErrors,
        setError,
        formState: { errors },
    } = methods

    useEffect(() => {
        const errorFields = Object.keys(errors)
        if (errorFields.length > 0) {
            const els = document.getElementsByName(errorFields[0])
            if (els.length > 0) {
                const el = els[0]
                const headerOffset = 56
                const elementPosition = el.getBoundingClientRect().top
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                })
            }
        }
    }, [errors])

    useEffect(() => {
        clearErrors()
        if (!externalErrors) {
            return
        }
        Object.entries(externalErrors).forEach(([field, error]) => {
            setError(field as Path<z.TypeOf<S>>, {
                type: 'external',
                message: error,
            })
        })
    }, [externalErrors, clearErrors, setError])

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={event => {
                    event.stopPropagation()
                    methods.handleSubmit(props.onSubmit)(event)
                }}
            >
                {props.children}
            </form>
        </FormProvider>
    )
}

export const ExternalFormError: React.FC = () => {
    const {
        formState: { errors },
    } = useFormContext()

    if (!errors[FORM_ERROR]) {
        return null
    }
    return <ErrorBanner className='mb-5'>{errors[FORM_ERROR].message}</ErrorBanner>
}
