import React from 'react'

import { ForAccountType } from '../components/Auth/ForAccountType'
import { ListProjectRoles } from '../components/ProjectRoles/ListProjectRoles'
import { AccountTypePermissions } from '../domain/account-type.domain'

import { Page } from './shared/Page'

export const ManageProjectRolesPage: React.FC = () => {
    return (
        <ForAccountType permission={AccountTypePermissions.ProjectRoleList}>
            <Page>
                <ListProjectRoles />
            </Page>
        </ForAccountType>
    )
}
