import { mdiLoading } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const LoadingSpinner = BaseAtom<{ center?: boolean }>(props => (
    <div className={classNames({ 'w-full flex justify-center': props.center })}>
        <Icon path={mdiLoading} size={1} spin className={props.className} />
    </div>
))
