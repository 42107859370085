import { z } from 'zod'

import { OILHistorySchema, OILSchema, OILSummarySchema } from '../../domain/oil.domain'
import { ProjectSchema } from '../../domain/project.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

export const oilApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listOIL: buildQuery({
                input: z.any(),
                output: ListResponseSchema(OILSchema),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}${serializeQueryParams(args)}`,
                    method: 'GET',
                }),
                providesTags: ['OILList'],
            }),
            createItem: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ subject: true, observationDescription: true })),
                output: OILSchema.pick({ id: true }),
                query: ({ projectId, subjectId, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['OILList', 'ROILSummary', 'POILSummary', 'DashboardStats'],
            }),
            getNewItemNumber: buildQuery({
                input: z.object({ projectId: z.number(), subjectId: z.number() }),
                output: OILSchema.pick({ itemNumber: true }),
                query: ({ projectId, subjectId }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/new-item-number`,
                    method: 'GET',
                }),
            }),
            editOILSubject: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true, subject: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/edit-subject`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['OILList'],
            }),
            editObservationDescription: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true, observationDescription: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/edit-observation-description`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['OILList'],
            }),
            editAuditorStatus: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true, auditorStatus: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/edit-auditor-status`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['OILList', 'ROILSummary', 'POILSummary', 'DashboardStats'],
            }),
            editAuditeeStatus: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true, auditeeStatus: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/edit-auditee-status`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['OILList', 'ROILSummary', 'POILSummary', 'DashboardStats'],
            }),
            markAsCritical: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/mark-critical`,
                    method: 'PUT',
                }),
                invalidatesTags: ['OILList', 'DashboardStats'],
            }),
            unmarkAsCritical: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/unmark-critical`,
                    method: 'PUT',
                }),
                invalidatesTags: ['OILList', 'DashboardStats'],
            }),
            trash: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/trash`,
                    method: 'PUT',
                }),
                invalidatesTags: ['OILList', 'DashboardStats'],
            }),
            untrash: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/untrash`,
                    method: 'PUT',
                }),
                invalidatesTags: ['OILList', 'DashboardStats'],
            }),
            addComment: buildMutation({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number(), content: z.string() })
                    .merge(OILSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/add-comment`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['OILList'],
            }),
            removeComment: buildMutation({
                input: z
                    .object({
                        projectId: z.number(),
                        subjectId: z.number(),
                        commentId: z.number(),
                    })
                    .merge(OILSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, subjectId, id, commentId, ...body }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/remove-comment/${commentId}`,
                    method: 'DELETE',
                    body,
                }),
                invalidatesTags: ['OILList'],
            }),
            getHistory: buildQuery({
                input: z
                    .object({ projectId: z.number(), subjectId: z.number() })
                    .merge(OILSchema.pick({ id: true })),
                output: ListResponseSchema(OILHistorySchema),
                query: ({ projectId, subjectId, id }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/${id}/history`,
                    method: 'GET',
                }),
            }),

            exportROILHtml: buildMutation({
                input: z.any(),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/export-html`,
                    method: 'post',
                    body: args,
                }),
            }),
            exportROILPdf: buildMutation({
                input: z.any(),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/export-pdf`,
                    method: 'post',
                    body: args,
                }),
            }),
            exportROILXlsx: buildMutation({
                input: z.any(),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/export-xlsx`,
                    method: 'post',
                    body: args,
                }),
            }),
            exportPOILHtml: buildMutation({
                input: z.any(),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/export-html`,
                    method: 'post',
                    body: args,
                }),
            }),
            exportPOILPdf: buildMutation({
                input: z.any(),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/export-pdf`,
                    method: 'post',
                    body: args,
                }),
            }),
            exportPOILXlsx: buildMutation({
                input: z.any(),
                output: z.object({ path: z.string() }),
                query: ({ projectId, subjectId, ...args }) => ({
                    url: `/project/${projectId}/oil/${subjectId}/export-xlsx`,
                    method: 'post',
                    body: args,
                }),
            }),

            getROILSummary: buildQuery({
                input: ProjectSchema.pick({ id: true }),
                output: ListResponseSchema(OILSummarySchema),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/summary`,
                    method: 'GET',
                }),
                providesTags: ['ROILSummary'],
            }),
            exportROILSummarySideLetter: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/summary/export-side-letter`,
                    method: 'post',
                }),
            }),
            exportROILSummaryHtml: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/summary/export-html`,
                    method: 'post',
                }),
            }),
            exportROILSummaryPdf: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/summary/export-pdf`,
                    method: 'post',
                }),
            }),
            exportROILSummaryXlsx: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/summary/export-xlsx`,
                    method: 'post',
                }),
            }),
            exportROILProjectXlsx: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/export-xlsx`,
                    method: 'post',
                }),
            }),
            exportROILProjectBySubjectXlsx: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/roil/export-xlsx-by-subject`,
                    method: 'post',
                }),
            }),

            getPOILSummary: buildQuery({
                input: ProjectSchema.pick({ id: true }),
                output: ListResponseSchema(OILSummarySchema),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/summary`,
                    method: 'GET',
                }),
                providesTags: ['POILSummary'],
            }),
            exportPOILSummarySideLetter: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/summary/export-side-letter`,
                    method: 'post',
                }),
            }),
            exportPOILSummaryHtml: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/summary/export-html`,
                    method: 'post',
                }),
            }),
            exportPOILSummaryPdf: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/summary/export-pdf`,
                    method: 'post',
                }),
            }),
            exportPOILSummaryXlsx: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/summary/export-xlsx`,
                    method: 'post',
                }),
            }),
            exportPOILProjectXlsx: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/export-xlsx`,
                    method: 'post',
                }),
            }),
            exportPOILProjectBySubjectXlsx: buildMutation({
                input: ProjectSchema.pick({ id: true }),
                output: z.object({ path: z.string() }),
                query: ({ id }) => ({
                    url: `/project/${id}/poil/export-xlsx-by-subject`,
                    method: 'post',
                }),
            }),
        }
    },
})

export const {
    useListOILQuery,
    useCreateItemMutation,
    useGetNewItemNumberQuery,
    useEditOILSubjectMutation,
    useEditObservationDescriptionMutation,
    useEditAuditorStatusMutation,
    useEditAuditeeStatusMutation,
    useMarkAsCriticalMutation,
    useUnmarkAsCriticalMutation,
    useTrashMutation,
    useUntrashMutation,
    useRemoveCommentMutation,
    useAddCommentMutation,
    useGetHistoryQuery,

    useGetROILSummaryQuery,
    useExportROILHtmlMutation,
    useExportROILPdfMutation,
    useExportROILXlsxMutation,
    useExportROILSummaryHtmlMutation,
    useExportROILSummaryPdfMutation,
    useExportROILSummaryXlsxMutation,
    useExportROILSummarySideLetterMutation,
    useExportROILProjectXlsxMutation,
    useExportROILProjectBySubjectXlsxMutation,

    useGetPOILSummaryQuery,
    useExportPOILHtmlMutation,
    useExportPOILPdfMutation,
    useExportPOILXlsxMutation,
    useExportPOILSummaryHtmlMutation,
    useExportPOILSummaryPdfMutation,
    useExportPOILSummaryXlsxMutation,
    useExportPOILSummarySideLetterMutation,
    useExportPOILProjectXlsxMutation,
    useExportPOILProjectBySubjectXlsxMutation,
} = oilApi
