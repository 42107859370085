import { isBefore } from 'date-fns'
import { z } from 'zod'

import { FormSubject } from '../components/Projects/useProjectForm'

import { ProjectRolePermissionsSchema, ProjectRoleSchema } from './project-role.domain'

export enum ProjectAssignmentEntity {
    Auditor = 'Auditor',
    Auditee = 'Auditee',
    Unknown = 'Unknown',
}

export const ProjectAssignmentSchema = z.object({
    user: z.object({ id: z.number(), name: z.string() }),
    projectRole: z.object({
        id: z.number(),
        name: z.string(),
        permissions: z.array(ProjectRolePermissionsSchema),
    }),
    entity: z.nativeEnum(ProjectAssignmentEntity),
})
export type ProjectAssignment = z.TypeOf<typeof ProjectAssignmentSchema>

export const ProjectSubpartSchema = z.object({
    id: z.number(),
    name: z.string(),
    order: z.number(),
})
export type ProjectSubpart = z.TypeOf<typeof ProjectSubpartSchema>

export const ProjectCategorySchema = z.object({
    id: z.number(),
    name: z.string(),
    order: z.number(),
    subparts: z.array(ProjectSubpartSchema),
})
export type ProjectCategory = z.TypeOf<typeof ProjectCategorySchema>

export const ProjectSubjectSchema = z.object({
    id: z.number(),
    name: z.string(),
    isPhysical: z.boolean(),
    categories: z.array(ProjectCategorySchema),
    remark: z.string().nullable(),
})
export type ProjectSubject = z.TypeOf<typeof ProjectSubjectSchema>

export const ProjectSchema = z.object({
    id: z.number(),
    name: z.string(),
    startDate: z.date(),
    endDate: z.date(),
    auditorName: z.string(),
    auditorIcao: z.string(),
    auditorLogo: z.string().optional(),
    auditeeName: z.string(),
    auditeeIcao: z.string(),
    isArchived: z.boolean(),
    subjects: z.array(ProjectSubjectSchema),
    assignments: z.array(ProjectAssignmentSchema),
    hasROIL: z.boolean(),
    hasPOIL: z.boolean(),
    hasRAS: z.boolean(),
    hasPAS: z.boolean(),
})
export type Project = z.TypeOf<typeof ProjectSchema>

export const ProjectFormSchema = z
    .object({
        name: z.string().min(1, 'Name required'),
        startDate: z.date({ required_error: 'Start date required' }),
        endDate: z.date({ required_error: 'End date required' }),
        auditorName: z.string().min(1, 'Auditor name required'),
        auditorIcao: z.string().min(1, 'Auditor ICAO required'),
        auditorLogo: z.any().optional(),
        auditeeName: z.string().min(1, 'Auditee name required'),
        auditeeIcao: z.string().min(1, 'Auditee ICAO required'),
        recordsSubjects: z.array(z.any()),
        physicalSubjects: z.array(z.any()),
        assignments: z
            .array(
                z.object({
                    user: ProjectSchema.pick({
                        id: true,
                        name: true,
                    }),
                    projectRole: ProjectRoleSchema.pick({ id: true, name: true }),
                    entity: z.nativeEnum(ProjectAssignmentEntity),
                }),
            )
            .min(1, 'Must add at least 1 user'),
        hasROIL: z.boolean(),
        hasPOIL: z.boolean(),
        hasRAS: z.boolean(),
        hasPAS: z.boolean(),
        bulkAssignedUser: z
            .object({
                recordsSubjects: z.object({ id: z.number() }),
                physicalSubjects: z.object({ id: z.number() }),
            })
            .partial()
            .optional(),
    })
    .refine(values => isBefore(values.startDate, values.endDate), {
        path: ['startDate'],
        message: 'Start date must occur before end date',
    })
    .refine(values => (!values.hasROIL && !values.hasRAS) || values.recordsSubjects.length > 0, {
        path: ['recordsSubjects'],
        message: 'Must have at least 1 subject',
    })
    .refine(values => (!values.hasPOIL && !values.hasPAS) || values.physicalSubjects.length > 0, {
        path: ['physicalSubjects'],
        message: 'Must have at least 1 subject',
    })
export type ProjectFormType = Omit<
    z.TypeOf<typeof ProjectFormSchema>,
    'recordsSubjects' | 'physicalSubjects'
> & {
    recordsSubjects: FormSubject[]
    physicalSubjects: FormSubject[]
}
