import { mdiMagnify } from '@mdi/js'
import React, { useState, useEffect } from 'react'

import { IconButton } from '../../IconButton'
import { Pill } from '../../Pill'

import { BaseFilterInput } from './_BaseFilter'

export const TextFilter: React.FC<{
    value?: string
    onChange: (newValue: string) => void
}> = props => {
    const [filter, setFilter] = useState<string>(props.value ?? '')

    useEffect(() => {
        setFilter(props.value ?? '')
    }, [props.value])

    return (
        <BaseFilterInput
            icon={
                <IconButton
                    className='text-atamblue-300 hover:text-atamsky-900 focus:outline-none'
                    path={mdiMagnify}
                    title='Filter by text'
                    size={1}
                />
            }
            onApply={() => props.onChange(filter)}
        >
            <input
                className='w-48 text-atamblue-700 text-sm focus:outline-none'
                placeholder={'Type your search...'}
                autoFocus
                value={filter}
                onChange={event => setFilter(event.target.value)}
            />
        </BaseFilterInput>
    )
}

export const TextActiveFilter: React.FC<{
    label: string
    value: string
    className?: string
    onClear: () => void
}> = props => {
    return (
        <Pill className={props.className} onClose={props.onClear}>
            <div className='text-atamblue-300 mr-1'>{props.label}:</div>
            <div className='text-atamblue-700'>{props.value}</div>
        </Pill>
    )
}
