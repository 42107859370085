import React from 'react'

import { useEditProjectRoleMutation } from '../../api/for-company/project-roles.api'
import { ProjectRole } from '../../domain/project-role.domain'

import { ProjectRoleForm } from './ProjectRoleForm'

export const EditProjectRole: React.FC<{
    projectRole: ProjectRole
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [editProjectRole, { isLoading }] = useEditProjectRoleMutation()

    const onSave = (projectRole: Omit<ProjectRole, 'id'>) =>
        editProjectRole({ ...projectRole, id: props.projectRole.id })
            .unwrap()
            .then(props.onSave)

    return (
        <ProjectRoleForm
            projectRole={props.projectRole}
            isSaving={isLoading}
            onSave={onSave}
            onCancel={props.onCancel}
        />
    )
}
