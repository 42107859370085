import { mdiClose } from '@mdi/js'
import classNames from 'classnames'
import React from 'react'

import { Card } from '../../atoms/Card'
import { IconButton } from '../../atoms/IconButton'
import { BaseModal } from '../../atoms/Modal/BaseModal'
import { RASStatus } from '../../domain/RAS.domain'

import { RASStatusView } from './RASStatusView'

const statuses = [
    RASStatus.NotStarted,
    // RASStatus.Pending,
    RASStatus.AwaitingData,
    RASStatus.AwaitingOILData,
    RASStatus.UnderReview,
    RASStatus.Completed,
    RASStatus.NotApplicable,
]

export const RASStatusSelector: React.FC<{
    value: RASStatus
    short?: boolean
    className?: string
    onChange: (newStatus: RASStatus) => void
}> = props => {
    return (
        <BaseModal
            body={({ close }) => (
                <Card className='px-4 py-2 border border-atamsky-300'>
                    <div className='flex items-center justify-around'>
                        {statuses.map(status => (
                            <RASStatusView
                                key={status}
                                status={status}
                                short
                                className='mx-1 cursor-pointer'
                                onClick={() => {
                                    props.onChange(status)
                                    close()
                                }}
                            />
                        ))}
                        <IconButton
                            path={mdiClose}
                            size={0.75}
                            className='ml-3 text-red-500'
                            onClick={close}
                        />
                    </div>
                </Card>
            )}
            render={({ open }) => (
                <RASStatusView
                    status={props.value}
                    short={props.short}
                    className={classNames('cursor-pointer', props.className)}
                    onClick={open}
                />
            )}
        />
    )
}
