import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { useGetResetPasswordInfoQuery, useResetPasswordMutation } from '../../api/for-public.api'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import { ExternalErrors, ExternalFormError, Form, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { LoadingSpinner } from '../../atoms/LoadingSpinner'
import { useTranslation } from '../../lib/i18n'
import { routes } from '../../routes'

const ResetPasswordSchema = z
    .object({
        password: z.string().min(1, 'Password Required'),
        confirmPassword: z.string().min(1, 'Confirm Password Required'),
    })
    .refine(({ password, confirmPassword }) => password === confirmPassword, {
        message: 'Passwords must match',
        path: ['password'],
    })
type ResetPasswordFormType = z.TypeOf<typeof ResetPasswordSchema>

export const ResetPassword: React.FC = () => {
    const { t } = useTranslation('login')
    const { token } = routes.ResetPassword.useParams()

    const { data, isLoading, isError } = useGetResetPasswordInfoQuery({ payload: token })
    const [resetPassword, resetPasswordMeta] = useResetPasswordMutation()
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<ResetPasswordFormType>>({})

    const onSubmit = (fields: ResetPasswordFormType) =>
        resetPassword({ payload: token, password: fields.password })
            .unwrap()
            .catch(() => setExternalErrors({ [FORM_ERROR]: 'Unknown error' }))

    if (isError) {
        return <ErrorMessage>Invalid token</ErrorMessage>
    }

    if (isLoading || !data) {
        return null
    }

    return (
        <Form schema={ResetPasswordSchema} externalErrors={externalErrors} onSubmit={onSubmit}>
            <h2 className='mb-8 text-center text-3xl font-semibold text-atamblue-900'>
                {t('Reset Your Password')}
            </h2>

            <div className='my-8 text-lg text-atamblue-500'>
                Your login email address is: <span className='text-blue-700'>{data.email}</span>
            </div>

            <ExternalFormError />

            {resetPasswordMeta.isSuccess ? (
                <div className='text-center'>
                    <div className='mb-3'>Password successfully changed.</div>
                    <Link to={routes.Dashboard.build()} className='text-atamsky-500'>
                        Go to login
                    </Link>
                </div>
            ) : (
                <div className='mt-16 formFields flex flex-col w-full'>
                    <TextField
                        name='password'
                        type='password'
                        inputClassName='appearance-none bg-transparent leading-tigh focus:outline-none mb-3 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        placeholder={t('NewPassword')}
                    />
                    <TextField
                        name='confirmPassword'
                        type='password'
                        inputClassName='appearance-none bg-transparent leading-tigh focus:outline-none mb-3 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        placeholder={t('ConfirmNewPassword')}
                    />

                    <button
                        disabled={resetPasswordMeta.isLoading}
                        className='h-12 m-auto my-8 px-24 py-2 rounded-2xl transition duration-500  bg-atamsky-900 shadow-md text-white hover:bg-atamsky-700 focus:outline-none'
                        type='submit'
                    >
                        {resetPasswordMeta.isLoading ? <LoadingSpinner className='mr-2' /> : null}
                        {t('ResetPassword')}
                    </button>
                </div>
            )}
        </Form>
    )
}
