import {
    mdiUnfoldMoreHorizontal,
    mdiUnfoldLessHorizontal,
    mdiAccount,
    mdiMinusCircleOutline,
} from '@mdi/js'
import React, { useEffect, useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Card } from '../../atoms/Card'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import { Tooltip } from '../../atoms/Tooltip'
import { ProjectRole } from '../../domain/project-role.domain'
import { ProjectAssignmentEntity } from '../../domain/project.domain'
import { User } from '../../domain/user.domain'
import { ProjectRoleSelector } from '../ProjectRoles/ProjectRoleSelector'
import { UserSelector } from '../Users/UsersSelector'

import { ProjectAssignmentEntitySelector } from './ProjectAssignmentEntitySelector'

type ProjectAssignment = {
    user: User
    projectRole: ProjectRole
    entity: ProjectAssignmentEntity
}

const isAssignmentValid = (assignment: Partial<ProjectAssignment>): boolean =>
    !!assignment.user && !!assignment.projectRole && !!assignment.entity

export const ProjectAssignmentsField: React.FC<{ name: string; isEdit: boolean }> = props => {
    const [collapsed, setCollapsed] = useState(false)
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: props.name,
        keyName: 'key',
    })

    const error = errors[props.name]

    const [assignment, setAssignment] = useState<Partial<ProjectAssignment>>({})
    const setAssignmentField = (field: Partial<ProjectAssignment>) =>
        setAssignment(old => ({ ...old, ...field }))

    useEffect(() => {
        if (isAssignmentValid(assignment)) {
            append(assignment)
            setAssignment({})
        }
    }, [assignment, append])

    const assignments = fields as unknown as (ProjectAssignment & {
        key: string
    })[]

    const content = (
        <>
            <div className='flex justify-between text-atamblue-700'>
                <div className='font-medium'>Assign Project Users ({assignments.length})</div>
                {collapsed ? (
                    <Tooltip text='Expand section'>
                        <IconButton
                            path={mdiUnfoldMoreHorizontal}
                            size={1}
                            className='text-atamblue-300 hover:text-atamsky-900'
                            onClick={() => setCollapsed(collapsed => !collapsed)}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip text='Collapse section'>
                        <IconButton
                            path={mdiUnfoldLessHorizontal}
                            size={1}
                            className='text-atamblue-300 hover:text-atamsky-900'
                            onClick={() => setCollapsed(collapsed => !collapsed)}
                        />
                    </Tooltip>
                )}
            </div>
            {collapsed ? null : (
                <div>
                    {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
                    {assignments.map((assignment, index) => (
                        <div key={assignment.key} className='grid grid-cols-10 gap-4 items-center'>
                            <UserSelector
                                value={assignment.user}
                                exclude={assignments
                                    .filter(a => a.user.id !== assignment.user.id)
                                    .map(a => a.user)}
                                className='block col-span-3'
                                icon={mdiAccount}
                                onChange={newUser => {
                                    update(index, {
                                        ...assignment,
                                        user: newUser,
                                    })
                                }}
                            />
                            <ProjectRoleSelector
                                value={assignment.projectRole}
                                className='block col-span-3'
                                onChange={newProjectRole => {
                                    update(index, {
                                        ...assignment,
                                        projectRole: newProjectRole,
                                    })
                                }}
                            />
                            <ProjectAssignmentEntitySelector
                                value={assignment.entity}
                                className='block col-span-3'
                                onChange={newEntity => {
                                    update(index, { ...assignment, entity: newEntity })
                                }}
                            />

                            <IconButton
                                path={mdiMinusCircleOutline}
                                size={0.75}
                                className='text-atamblue-300 hover:text-atamsky-900'
                                onClick={() => {
                                    remove(index)
                                }}
                            />
                        </div>
                    ))}

                    <Separator className='w-full h-1 my-5'></Separator>

                    <div className='grid grid-cols-10 gap-4 items-center'>
                        <UserSelector
                            key={`new-assignment-user-${fields.length}`}
                            exclude={assignments.filter(a => a.user.id).map(a => a.user)}
                            className='block col-span-3'
                            icon={mdiAccount}
                            onChange={user => {
                                setAssignmentField({ user })
                            }}
                        />

                        <ProjectRoleSelector
                            key={`new-assignment-project-role-${fields.length}`}
                            className='block col-span-3'
                            onChange={projectRole => {
                                setAssignmentField({ projectRole })
                            }}
                        />

                        <ProjectAssignmentEntitySelector
                            key={`new-assignment-entity-${fields.length}`}
                            className='block col-span-3'
                            onChange={entity => {
                                setAssignmentField({ entity })
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    )
    return !props.isEdit ? (
        <Card className='mt-3 p-6'>{content}</Card>
    ) : (
        <Card className='mt-3 p-6'>{content}</Card>
    )
}
