import classNames from 'classnames'
import React from 'react'

import { CircledInitial, CircledText } from '../../atoms/CircledInitial'
import { Tooltip } from '../../atoms/Tooltip'
import { RASStatus } from '../../domain/RAS.domain'

export const RASStatusView: React.FC<{
    status: RASStatus
    short?: boolean
    className?: string
    onClick?: () => void
}> = props => {
    const classMap = {
        [RASStatus.NotStarted]: 'border-red-500 text-atamblue-700',
        // [RASStatus.Pending]: 'border-purple-500 text-atamblue-700',
        [RASStatus.AwaitingData]: 'border-orange-500 text-atamblue-700',
        [RASStatus.AwaitingOILData]: 'border-atamblue-500 text-atamblue-700',
        [RASStatus.UnderReview]: 'border-blue-500 text-atamblue-700',
        [RASStatus.Completed]: 'border-green-500 text-atamblue-700',
        [RASStatus.NotApplicable]: 'border-gray-500 text-atamblue-700',
    }
    const shortTextMap = {
        [RASStatus.NotStarted]: 'NS',
        // [RASStatus.Pending]: 'P',
        [RASStatus.AwaitingData]: 'AD',
        [RASStatus.AwaitingOILData]: 'AOD',
        [RASStatus.UnderReview]: 'UR',
        [RASStatus.Completed]: 'C',
        [RASStatus.NotApplicable]: 'NA',
    }

    const longTextMap = {
        [RASStatus.NotStarted]: 'Not Started',
        // [RASStatus.Pending]: 'Pending',
        [RASStatus.AwaitingData]: 'Awaiting Data',
        [RASStatus.AwaitingOILData]: 'Awaiting OIL Data',
        [RASStatus.UnderReview]: 'Under Review',
        [RASStatus.Completed]: 'Completed',
        [RASStatus.NotApplicable]: 'Not Applicable',
    }
    const short = props.short ?? false
    return short ? (
        <Tooltip text={longTextMap[props.status]}>
            <CircledInitial
                content={shortTextMap[props.status]}
                className={classNames(classMap[props.status], props.className)}
                onClick={props.onClick}
            />
        </Tooltip>
    ) : (
        <CircledText
            className={classNames(classMap[props.status], props.className)}
            onClick={props.onClick}
        >
            {longTextMap[props.status]}
        </CircledText>
    )
}
