import { mdiLoading, mdiFileWord } from '@mdi/js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '../IconButton'
import { Tooltip } from '../Tooltip'

export const ExportDocButton: React.FC<{ isLoading: boolean; onClick: () => void }> = props => {
    const { t } = useTranslation('exportButtons')
    return (
        <Tooltip text={t('WordExport')} className='cursor-pointer relative' onClick={props.onClick}>
            <div className='flex justify-center items-center w-6 h-6 absolute -top-1.5 left-8 rounded-2lg transition duration-500 bg-white border-2 border-indigo-700 text-center text-atamblue-700 font-medium text-sm shadow-md'>
                SL
            </div>
            <IconButton
                isSubmit
                path={props.isLoading ? mdiLoading : mdiFileWord}
                spin={props.isLoading}
                size={1.33}
                disabled={props.isLoading}
                className='rounded-2xl transition duration-500 bg-white p-2.5 shadow-md mx-0.5 text-indigo-700 hover:bg-indigo-700 hover:text-white'
            ></IconButton>
        </Tooltip>
    )
}
