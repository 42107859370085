import { mdiToggleSwitch, mdiToggleSwitchOff } from '@mdi/js'
import { Icon } from '@mdi/react'
import classNames from 'classnames'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ErrorMessage } from '../ErrorMessage'

export type ToggleSwitchProps = {
    value: boolean
    className?: string
    size?: number
    error?: { message: string }
    onChange: (value: boolean) => void
}
export const ToggleSwitch: React.FC<ToggleSwitchProps> = props => {
    return (
        <div
            className={classNames('flex items-center cursor-pointer', props.className)}
            onClick={() => {
                props.onChange && props.onChange(!props.value)
            }}
        >
            <Icon
                path={props.value ? mdiToggleSwitch : mdiToggleSwitchOff}
                size={props.size ?? 1}
                className={classNames('transition duration-300', {
                    'text-red-500': !props.value,
                    'text-green-500': props.value,
                })}
            />
            {props.children}
            {props.error ? (
                <ErrorMessage className='pt-1'>{props.error.message}</ErrorMessage>
            ) : null}
        </div>
    )
}

export const ToggleSwitchField: React.FC<{
    name: string
    initialValue?: boolean
    className?: string
    size?: number
}> = props => {
    const { name, ...otherProps } = props
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext()
    const inputValue = useWatch({ control, name }) as boolean
    const error = errors[name]

    return (
        <ToggleSwitch
            {...otherProps}
            error={error}
            value={inputValue}
            onChange={newValue => setValue(name, newValue)}
        />
    )
}
// <Field
//     name={props.name}
//     initialValue={props.initialValue}
//     render={({ input, meta }) => {
//         const hasError = meta.touched && (!!meta.error || !!meta.submitError)
//         const errorMessage = hasError ? meta.error || meta.submitError : undefined
//         return (
//             <div className={props.className}>
//                 <ToggleSwitch
//                     value={input.value}
//                     size={props.size ?? 1}
//                     onChange={input.onChange}
//                 >
//                     {props.children}
//                 </ToggleSwitch>
//                 {errorMessage ? (
//                     <ErrorMessage className='pt-1'>{errorMessage}</ErrorMessage>
//                 ) : null}
//             </div>
//         )
//     }}
// />
// )
