import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Form } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'

export const SubpartFormSchema = z.object({ name: z.string() })
export type SubpartFormType = z.TypeOf<typeof SubpartFormSchema>

export const SubpartForm: React.FC<{
    subpart?: SubpartFormType
    isSaving: boolean
    onConfirm: (subpart: SubpartFormType) => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation()

    return (
        <Form schema={SubpartFormSchema} defaults={props.subpart} onSubmit={props.onConfirm}>
            <div className='w-full text-sm'>
                <TextField
                    name='name'
                    autoFocus
                    maxLength={100}
                    placeholder='Type subpart name'
                    inputClassName='border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                />
            </div>
            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    Save
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('common:Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
