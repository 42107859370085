import {
    mdiLoading,
    mdiAirplaneTakeoff,
    mdiCalendarToday,
    mdiCalendar,
    mdiArchivePlus,
    mdiAirplanePlus,
} from '@mdi/js'
import Icon from '@mdi/react'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Card } from '../../atoms/Card'
import { CheckboxField } from '../../atoms/Form/CheckboxInput'
import { DateField } from '../../atoms/Form/DateInput'
import { FileField } from '../../atoms/Form/FileInput'
import {
    DefaultsFor,
    ExternalErrors,
    Form,
    FORM_ERROR,
    ExternalFormError,
} from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Project, ProjectFormSchema, ProjectFormType } from '../../domain/project.domain'

import { ProjectAssignmentsField } from './ProjectAssignmentField'
import { ProjectSubjectsField } from './ProjectSubjectsField'

const newProject: DefaultsFor<typeof ProjectFormSchema> = {
    name: '',
    auditorName: '',
    auditorIcao: '',
    auditeeName: '',
    auditeeIcao: '',
    hasROIL: false,
    hasPOIL: false,
    hasRAS: false,
    hasPAS: false,
    recordsSubjects: [],
    physicalSubjects: [],
}

const ProjectInformationForm: React.FC<{
    isEdit: boolean
}> = props => {
    const { t } = useTranslation('projects')

    const content = (
        <>
            <ExternalFormError />

            <div className='grid grid-cols-4 gap-8'>
                <TextField
                    name='name'
                    label={t('ProjectName')}
                    placeholder={t('ProjectNameHint')}
                    className='col-span-2'
                    inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 font-medium placeholder-font-medium focus:outline-none'
                    labelClassName='ml-2 text-atamblue-700 text-sm font-medium'
                />
                <div className='col-span-2 grid grid-cols-2 gap-6'>
                    <DateField
                        name='startDate'
                        label={t('StartDate')}
                        icon={
                            <Icon
                                path={mdiCalendarToday}
                                size={1}
                                className='mt-2 mr-2 text-atamsky-900'
                            />
                        }
                        className='flex px-2 border-b border-yellow-700 text-sm leading-6'
                        labelClassName='ml-2 text-atamblue-700 text-sm'
                    />
                    <DateField
                        name='endDate'
                        label={t('EndDate')}
                        icon={
                            <Icon
                                path={mdiCalendar}
                                size={1}
                                className='mt-2 mr-2 text-atamsky-900'
                            />
                        }
                        className='flex px-2 border-b border-green-700 text-sm leading-6'
                        labelClassName='ml-2 text-atamblue-700 text-sm'
                    />
                </div>

                <TextField
                    name='auditorName'
                    label={t('AuditorName')}
                    placeholder={t('AuditorNameHint')}
                    inputClassName=' font-medium text-green-700 border-b border-atamblue-300 placeholder-green-300 focus:outline-none'
                    labelClassName='ml-2 text-atamblue-700 text-sm'
                />
                <TextField
                    name='auditorIcao'
                    label={t('AuditorICAO')}
                    placeholder={t('AuditorICAOHint')}
                    inputClassName=' font-medium text-green-700 border-b border-atamblue-300 placeholder-green-300 uppercase focus:outline-none'
                    labelClassName='ml-2 text-atamblue-700 text-sm'
                    maxLength={4}
                />
                <div className='col-span-2 flex'>
                    <FileField
                        name='auditorLogo'
                        accept='image/*'
                        label={t('AuditorLogo')}
                        placeholder={t('ChooseImage')}
                        buttonClassName='w-55 h-12 bg-ebony-200 hover:bg-atamsky-200 mt-2 rounded-2xl border border-atamblue-500 border-dashed text-base text-atamblue-500 font-medium box-content shadow-md focus:outline-none'
                        // buttonStyle={{ width: '220px', height: '48px' }}
                        labelClassName='text-atamblue-700 text-sm'
                    />
                    <div className='mt-6 text-xs whitespace-pre-line ml-4 text-atamblue-700 leading-4 self-center'>
                        {t('AuditorLogoInfo')}
                    </div>
                </div>
                <TextField
                    name='auditeeName'
                    label={t('AuditeeName')}
                    placeholder={t('AuditeeNameHint')}
                    inputClassName=' font-medium text-teal-700 border-b border-atamblue-300 placeholder-teal-400 focus:outline-none'
                    labelClassName='text-atamblue-700 text-sm'
                />

                <TextField
                    name='auditeeIcao'
                    label={t('AuditeeICAO')}
                    placeholder={t('AuditeeICAOHint')}
                    inputClassName=' font-medium text-teal-700 border-b border-atamblue-300 placeholder-teal-400 uppercase focus:outline-none'
                    labelClassName='text-atamblue-700 text-sm'
                    maxLength={4}
                />
                <div className='col-span-2 flex flex-col text-atamblue-700'>
                    <div className='mb-2 text-sm'>{t('ProjectModules')}</div>
                    <div className='grid grid-cols-2 gap-2 items-center font-medium'>
                        <CheckboxField name='hasRAS' className='hover:text-atamsky-900'>
                            Records Audit Status
                        </CheckboxField>
                        <CheckboxField name='hasPAS' className='hover:text-atamsky-900'>
                            Physical Audit Status
                        </CheckboxField>
                        <CheckboxField name='hasROIL' className='hover:text-atamsky-900'>
                            Records OIL
                        </CheckboxField>
                        <CheckboxField name='hasPOIL' className='hover:text-atamsky-900'>
                            Physical OIL
                        </CheckboxField>
                    </div>
                </div>
            </div>
        </>
    )
    return !props.isEdit ? (
        <Card className='p-6'>{content}</Card>
    ) : (
        <Card className='p-6'>{content}</Card>
    )
}

const ProjectRecordsSubjectsField: React.FC<{
    isEdit: boolean
    readOnlySubjects?: string[]
}> = props => {
    const content = (
        <ProjectSubjectsField
            prefix='recordsSubjects'
            title='Records Subjects List'
            icon={mdiArchivePlus}
            isPhysical={false}
            templateSelectorLabel='Load a Records Subjects Template:'
            createSubjectButtonLabel='Create New Records Subject'
            saveTemplateButtonLabel='Save Records Template'
            readOnlySubjects={props.readOnlySubjects}
        />
    )
    return <Card className='mt-3 p-6'>{content}</Card>
}

const ProjectPhysicalSubjectsField: React.FC<{
    isEdit: boolean
    readOnlySubjects?: string[]
}> = props => {
    const content = (
        <ProjectSubjectsField
            prefix='physicalSubjects'
            title='Physical Subjects List'
            icon={mdiAirplanePlus}
            isPhysical={true}
            templateSelectorLabel='Load a Physical Subjects Template:'
            createSubjectButtonLabel='Create New Physical Subject'
            saveTemplateButtonLabel='Save Physical Template'
            readOnlySubjects={props.readOnlySubjects}
        />
    )
    return <Card className='mt-3 p-6'>{content}</Card>
}

const ProjectSubjectsForm: React.FC<{ isEdit: boolean; readOnlySubjects?: string[] }> = props => {
    const { getValues } = useFormContext()
    const values = getValues()

    return (
        <>
            {values.hasROIL || values.hasRAS ? (
                <ProjectRecordsSubjectsField
                    isEdit={props.isEdit}
                    readOnlySubjects={props.readOnlySubjects}
                />
            ) : null}
            {values.hasPOIL || values.hasPAS ? (
                <ProjectPhysicalSubjectsField
                    isEdit={props.isEdit}
                    readOnlySubjects={props.readOnlySubjects}
                />
            ) : null}
        </>
    )
}

export const ProjectForm: React.FC<{
    project?: ProjectFormType
    isEdit: boolean
    isSaving: boolean
    onSave: (project: ProjectFormType) => Promise<void>
}> = props => {
    const { t } = useTranslation('projects')
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<Project>>({})
    const existingSubjects = [
        ...(props.project?.recordsSubjects ?? []),
        ...(props.project?.physicalSubjects ?? []),
    ].map(({ name }: any) => name)

    const onSubmit = async (project: ProjectFormType) => {
        try {
            return props.onSave(project)
        } catch (error) {
            if (error.data?.name === 'ProjectNameExists') {
                setExternalErrors({ name: 'Name already exists' })
                return
            }
            setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        }
    }

    return (
        <Form
            schema={ProjectFormSchema}
            defaults={props.project ?? newProject}
            externalErrors={externalErrors}
            onSubmit={onSubmit}
        >
            <ProjectInformationForm isEdit={props.isEdit} />

            <ProjectAssignmentsField name='assignments' isEdit={props.isEdit} />

            <ProjectSubjectsForm isEdit={props.isEdit} readOnlySubjects={existingSubjects} />

            <div className='flex flex-col justify-center items-center my-10'>
                <IconButton
                    isSubmit
                    path={props.isSaving ? mdiLoading : mdiAirplaneTakeoff}
                    spin={props.isSaving}
                    disabled={props.isSaving}
                    className='flex h-12 px-10 py-2 rounded-2xl bg-atamsky-900 shadow-md text-white hover:bg-atamsky-700 focus:outline-none'
                    iconClassName='mr-2'
                    size={1}
                >
                    {props.isEdit ? t('ConfirmUpdate') : t('LaunchProject')}
                </IconButton>
            </div>
        </Form>
    )
}
