import { mdiPencil, mdiDeleteEmpty } from '@mdi/js'
import classNames from 'classnames'
import React from 'react'

import {
    useDeleteAircraftInformationModuleMutation,
    useEditAircraftInformationModuleMutation,
} from '../../../api/for-company/aircraft-information.api'
import { Card } from '../../../atoms/Card'
import { IconButton } from '../../../atoms/IconButton'
import { ConfirmationModal } from '../../../atoms/Modal/ConfirmationModal'
import { Modal } from '../../../atoms/Modal/Modal'
import { range } from '../../../common/utils'
import {
    AircraftInformationModule,
    AircraftInformationModuleType,
} from '../../../domain/aircraft-information.domain'
import { ProjectRolePermissions } from '../../../domain/project-role.domain'
import { Project } from '../../../domain/project.domain'
import { ForProjectRole } from '../../Auth/ForProjectRole'

import { AircraftInfoBlock } from './AircraftInformationBlock'
import { AircraftInformationModuleForm } from './AircraftInformationModuleForm'

export const AircraftInfoModule: React.FC<{
    projectId: Project['id']
    module: AircraftInformationModule
    readOnly: boolean
    mainColor: string
    mainColorFaded: string
    gridSeparatorColor: string
    gridTone: string
    onFocusBg: string
    onFocusText: string
    columns: number
}> = props => {
    const [
        editAircraftInformationModule,
        editAircraftInformationModuleMeta,
    ] = useEditAircraftInformationModuleMutation()
    const [deleteAircraftInformationModule] = useDeleteAircraftInformationModuleMutation()
    const lines = Math.round(props.module.blocks.length / props.columns)

    return (
        <div
            className={classNames('mb-3 pr-3 last:pr-0', {
                'w-1/2': ![AircraftInformationModuleType.LandingGear].includes(props.module.type),
                'w-1/3': [AircraftInformationModuleType.LandingGear].includes(props.module.type),
            })}
        >
            <Card className='overflow-hidden'>
                <div
                    className={classNames(
                        'py-3 font-medium leading-6 text-center align-middle relative group',
                        props.mainColor,
                    )}
                >
                    {props.module.name}
                    {props.module.isEditable ? (
                        <div className='hidden group-hover:flex absolute top-3 right-3'>
                            {!props.readOnly ? (
                                <>
                                    <ForProjectRole
                                        permission={
                                            props.module.type ===
                                            AircraftInformationModuleType.Engine
                                                ? ProjectRolePermissions.AircraftInformationEditEngineModule
                                                : props.module.type ===
                                                  AircraftInformationModuleType.LandingGear
                                                ? ProjectRolePermissions.AircraftInformationEditLandingGearModule
                                                : undefined
                                        }
                                        projectId={props.projectId}
                                    >
                                        <Modal
                                            body={({ close }) => (
                                                <AircraftInformationModuleForm
                                                    module={props.module}
                                                    isSaving={
                                                        editAircraftInformationModuleMeta.isLoading
                                                    }
                                                    onSave={editedModule =>
                                                        editAircraftInformationModule({
                                                            projectId: props.projectId,
                                                            id: props.module.id,
                                                            name: editedModule.name,
                                                        }).then(close)
                                                    }
                                                    onCancel={close}
                                                />
                                            )}
                                        >
                                            <IconButton
                                                path={mdiPencil}
                                                size={0.75}
                                                className='mr-2 hover:text-atamsky-900'
                                            />
                                        </Modal>
                                    </ForProjectRole>
                                    <ForProjectRole
                                        permission={
                                            props.module.type ===
                                            AircraftInformationModuleType.Engine
                                                ? ProjectRolePermissions.AircraftInformationDeleteEngineModule
                                                : props.module.type ===
                                                  AircraftInformationModuleType.LandingGear
                                                ? ProjectRolePermissions.AircraftInformationDeleteLandingGearModule
                                                : undefined
                                        }
                                        projectId={props.projectId}
                                    >
                                        <ConfirmationModal
                                            message={'Are you sure you want to delete this module?'}
                                            onConfirm={() =>
                                                deleteAircraftInformationModule({
                                                    projectId: props.projectId,
                                                    id: props.module.id,
                                                })
                                            }
                                        >
                                            <IconButton
                                                path={mdiDeleteEmpty}
                                                size={0.75}
                                                className='hover:text-atamsky-900'
                                            />
                                        </ConfirmationModal>
                                    </ForProjectRole>
                                </>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                <div>
                    {range(lines).map(i => (
                        <div className={classNames('flex justify-center', props.gridTone)} key={i}>
                            {range(props.columns).map(j => {
                                const blockIndex = i * props.columns + j
                                return (
                                    <AircraftInfoBlock
                                        projectId={props.projectId}
                                        readOnly={props.readOnly}
                                        key={blockIndex}
                                        block={props.module.blocks[blockIndex]}
                                        module={props.module}
                                        mainColor={props.mainColor}
                                        mainColorFaded={props.mainColorFaded}
                                        onFocusText={props.onFocusText}
                                        gridSeparatorColor={props.gridSeparatorColor}
                                        showGridSeparator={(j + 1) % props.columns !== 0}
                                        className={classNames(
                                            'relative',
                                            {
                                                'w-1/2': [
                                                    AircraftInformationModuleType.LandingGear,
                                                ].includes(props.module.type),
                                                'w-1/3': ![
                                                    AircraftInformationModuleType.LandingGear,
                                                ].includes(props.module.type),
                                            },
                                            props.onFocusBg,
                                            'py-3',
                                        )}
                                    />
                                )
                            })}
                        </div>
                    ))}
                </div>
            </Card>
        </div>
    )
}
