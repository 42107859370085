import React, { useState } from 'react'

import {
    useExportROILHtmlMutation,
    useExportROILPdfMutation,
    useExportROILXlsxMutation,
    useListOILQuery,
} from '../../api/for-company/oil.api'
import { pickSelectedOil } from '../../domain/oil.domain'

import { ListOIL } from './OIL'

export const ListROIL: React.FC<{ projectId: number; subjectId: number }> = props => {
    const { projectId, subjectId } = props
    const [args, setArgs] = useState<Record<string, unknown>>({})

    const { data } = useListOILQuery({ projectId, subjectId, ...args })
    const [exportHtml, exportHtmlMeta] = useExportROILHtmlMutation()
    const [exportPdf, exportPdfMeta] = useExportROILPdfMutation()
    const [exportXlsx, exportXlsxMeta] = useExportROILXlsxMutation()

    if (!data) {
        return null
    }

    return (
        <ListOIL
            projectId={props.projectId}
            subjectId={props.subjectId}
            data={data}
            exportHtml={selectedOil =>
                exportHtml({
                    projectId,
                    subjectId,
                    selectedOil: pickSelectedOil(data.results, selectedOil),
                    ...args,
                }).unwrap()
            }
            exportHtmlMeta={exportHtmlMeta}
            exportPdf={selectedOil =>
                exportPdf({
                    projectId,
                    subjectId,
                    selectedOil: pickSelectedOil(data.results, selectedOil),
                    ...args,
                }).unwrap()
            }
            exportPdfMeta={exportPdfMeta}
            exportXlsx={selectedOil =>
                exportXlsx({
                    projectId,
                    subjectId,
                    selectedOil: pickSelectedOil(data.results, selectedOil),
                    ...args,
                }).unwrap()
            }
            exportXlsxMeta={exportXlsxMeta}
            onChange={setArgs}
        />
    )
}
