import classNames from 'classnames'
import React from 'react'

import { CircledInitial } from '../../../atoms/CircledInitial'
import { OILStatus } from '../../../domain/oil.domain'

const StatusesMap = {
    [OILStatus.Open]: {
        tooltip: 'Open',
        label: 'O',
        normalClass: 'text-atamblue-900 mx-0.5 border-yellow-700',
        activeClass: 'bg-yellow-700 hover:text-atamblue-900',
        clickableClass: 'cursor-pointer border-yellow-700 hover:text-white hover:bg-yellow-600',
    },
    [OILStatus.Closed]: {
        tooltip: 'Closed',
        label: 'C',
        normalClass: 'text-atamblue-900 mx-0.5 border-green-700',
        activeClass: 'bg-green-700 hover:text-atamblue-900',
        clickableClass: 'cursor-pointer border-green-700 hover:text-white hover:bg-green-400',
    },
    [OILStatus.Critical]: {
        tooltip: 'Critical',
        label: 'CR',
        normalClass: 'text-atamblue-900 mx-0.5 border-red-500',
        activeClass: 'bg-red-500 hover:text-atamblue-900',
        clickableClass: 'cursor-pointer border-red-500 hover:text-white hover:bg-red-400',
    },
    [OILStatus.Pending]: {
        tooltip: 'Pending',
        label: 'P',
        normalClass: 'text-atamblue-900 mx-0.5 border-indigo-500',
        activeClass: 'bg-indigo-500 hover:text-atamblue-900',
        clickableClass: 'cursor-pointer border-indigo-500 hover:text-white hover:bg-indigo-300',
    },
    [OILStatus.SideLetter]: {
        tooltip: 'Side Letter',
        label: 'SL',
        normalClass: 'text-atamblue-900 mx-0.5 border-indigo-700',
        activeClass: 'bg-indigo-700 hover:text-atamblue-900',
        clickableClass: 'cursor-pointer border-indigo-700 hover:text-white hover:bg-indigo-500',
    },
    [OILStatus.UnderReview]: {
        tooltip: 'Under Review',
        label: 'UR',
        normalClass: 'text-atamblue-900 mx-0.5 border-atamsky-700',
        activeClass: 'bg-atamsky-700 hover:text-atamblue-900',
        clickableClass: 'cursor-pointer border-atamsky-700 hover:text-white hover:bg-atamsky-500',
    },
}

export const StatusIcon: React.FC<{
    status: OILStatus
    size: 'lg' | 'md' | 'sm'
    isActive?: boolean
    isClickable?: boolean
    className?: string
    onClick?: () => void
}> = props => {
    const status = StatusesMap[props.status]
    return (
        <CircledInitial
            tooltip={status.tooltip}
            content={status.label}
            size={props.size}
            className={classNames(
                status.normalClass,
                {
                    [status.activeClass]: props.isActive,
                    [status.clickableClass]: props.isClickable,
                },
                props.className,
            )}
            onClick={() => {
                if (props.isClickable) {
                    props.onClick && props.onClick()
                }
            }}
        />
    )
}
