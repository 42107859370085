import React from 'react'

export type Identifier = string | number

const contextNotInitializedError = (name: string) => () => {
    throw new Error(`Context not initialized for ${name}`)
}
export type AccordionContextProps = {
    active: Identifier[]
    setActive: (id: Identifier) => void
    setInactive: (id: Identifier) => void
    toggleActive: (id: Identifier) => void
    isActive: (id: Identifier) => boolean
}
const AccordionContext = React.createContext<AccordionContextProps>({
    active: [],
    setActive: contextNotInitializedError('AccordionContext:setActive'),
    setInactive: contextNotInitializedError('AccordionContext:setInactive'),
    toggleActive: contextNotInitializedError('AccordionContext:toggleActive'),
    isActive: contextNotInitializedError('AccordionContext:isActive'),
})
export const useAccordionContext = () => React.useContext(AccordionContext)

export const Accordion: React.FC<{ initial?: Identifier; multiple?: boolean }> = props => {
    const [active, setActiveItems] = React.useState<Identifier[]>(
        props.initial ? [props.initial] : [],
    )

    const setActive = (id: Identifier) => {
        if (!props.multiple) {
            setActiveItems(() => [id])
        } else {
            setActiveItems(old => {
                if (old.includes(id)) {
                    return old
                } else {
                    return [...old, id]
                }
            })
        }
    }

    const setInactive = (id: Identifier) => {
        if (!props.multiple) {
            setActiveItems(() => [])
        } else {
            setActiveItems(old => old.filter(i => i !== id))
        }
    }

    const toggleActive = (id: Identifier) => {
        if (!props.multiple) {
            setActiveItems(old => (old.includes(id) ? [] : [id]))
        } else {
            setActiveItems(old => {
                if (old.includes(id)) {
                    return old.filter(i => i !== id)
                } else {
                    return [...old, id]
                }
            })
        }
    }

    const isActive = (id: Identifier): boolean => active.includes(id)

    return (
        <AccordionContext.Provider
            value={{ active, setActive, setInactive, toggleActive, isActive }}
        >
            {props.children}
        </AccordionContext.Provider>
    )
}

export type AccordionItemRender = (props: {
    active: boolean
    setActive: () => void
    setInactive: () => void
    toggleActive: () => void
}) => React.ReactNode
export const AccordionItem: React.FC<{
    id: Identifier
    render: AccordionItemRender
}> = props => {
    const {
        isActive: _isActive,
        setActive: _setActive,
        setInactive: _setInactive,
        toggleActive: _toggleActive,
    } = React.useContext(AccordionContext)

    const active = _isActive(props.id)
    const setActive = () => _setActive(props.id)
    const setInactive = () => _setInactive(props.id)
    const toggleActive = () => _toggleActive(props.id)

    return (
        <>
            <div>{props.render({ active, setActive, setInactive, toggleActive })}</div>
            {active ? <div>{props.children}</div> : null}
        </>
    )
}
