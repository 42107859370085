import { mdiClipboardEditOutline, mdiClipboardPlus } from '@mdi/js'
import React, { useState } from 'react'

import { useListProjectRolesQuery } from '../../api/for-company/project-roles.api'
import { Card } from '../../atoms/Card'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { Paginator } from '../../common/Paginator'
import { AccountTypePermissions } from '../../domain/account-type.domain'
import { ProjectRole } from '../../domain/project-role.domain'
import { ForAccountType } from '../Auth/ForAccountType'

import { CreateProjectRole } from './CreateProjectRole'
import { EditProjectRole } from './EditProjectRole'

const ProjectRoleEntry: React.FC<{ projectRole: ProjectRole }> = props => {
    const { projectRole } = props

    return (
        <div>
            <div className='flex items-center py-2 border-b border-ebony-400 text-sm'>
                <div className='flex mx-2 relative'>
                    <Modal
                        fullwidth
                        fullheight
                        title='Edit Project Role'
                        body={({ close }) => (
                            <EditProjectRole
                                projectRole={projectRole}
                                onSave={close}
                                onCancel={close}
                            />
                        )}
                    >
                        <Tooltip text='Edit Project Role'>
                            <IconButton
                                className='text-atamblue-300 hover:text-atamsky-900'
                                path={mdiClipboardEditOutline}
                                size={0.75}
                            />
                        </Tooltip>
                    </Modal>
                </div>
                <div className='grow'>{projectRole.name}</div>
                <div className='w-1/3 flex justify-center'>{projectRole.permissions.length}</div>
            </div>
        </div>
    )
}

export const ListProjectRoles: React.FC = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const { data } = useListProjectRolesQuery({
        page,
        perPage,
    })

    const Columns = (
        <div className='flex items-center border-b border-atamblue-300 py-1 font-medium text-sm text-atamblue-900'>
            <div className='flex mx-2'>
                <Modal
                    fullwidth
                    fullheight
                    title='Create A New Project Role'
                    body={({ close }) => <CreateProjectRole onSave={close} onCancel={close} />}
                >
                    <Tooltip text='Create A New Project Role'>
                        <IconButton
                            className='py-1 text-atamblue-300 hover:text-atamsky-900'
                            path={mdiClipboardPlus}
                            size={0.75}
                        />
                    </Tooltip>
                </Modal>
            </div>
            <div className='grow'>Project Role Name</div>
            <div className='w-1/3 text-center'>Permissions</div>
        </div>
    )

    if (!data) {
        return null
    }
    const Entries = data.results.map(projectRole => (
        <ProjectRoleEntry key={projectRole.id} projectRole={projectRole} />
    ))

    return (
        <>
            <Card className='mb-1 p-3'>
                {Columns}
                {Entries}
            </Card>
            <Paginator
                currentPage={page}
                totalPages={data.totalPages}
                perPage={perPage}
                onJumpToPage={setPage}
                onChangePageSize={setPerPage}
            />
        </>
    )
}
