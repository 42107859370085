import {
    mdiAccountStar,
    mdiBarcodeScan,
    mdiEmail,
    mdiAccountConvert,
    mdiAccountPlus,
    mdiPower,
} from '@mdi/js'
import Icon from '@mdi/react'
import Tippy from '@tippyjs/react/headless'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLogoutMutation } from '../../api/for-public.api'
import { CircledInitial } from '../../atoms/CircledInitial'
import { IconButton } from '../../atoms/IconButton'
import { Modal } from '../../atoms/Modal/Modal'
import { Separator } from '../../atoms/Separator'
import { AccountTypePermissions } from '../../domain/account-type.domain'
import { useCurrentProfile } from '../../hooks/use-current-profile'
import { authActions } from '../../state/auth.slice'
import { useAppDispatch } from '../../store'
import { ForAccountType } from '../Auth/ForAccountType'
import { CreateUser } from '../Users/CreateUser'
import { EditProfile } from '../Users/EditProfile'

export type Profile = { name: string; email: string; code: string; isSuperadmin: boolean }

const Dropdown: React.FC<{ profile: Profile; onLogout: () => void }> = props => {
    const { t } = useTranslation('toolbar')
    const [shown, setShown] = useState(false)

    const show = () => setShown(true)
    const hide = () => setShown(false)

    const { profile } = props

    return (
        <Tippy
            interactive
            placement='bottom-end'
            visible={shown}
            onClickOutside={hide}
            render={() => (
                <div className='flex flex-col -mt-2.4 p-4 bg-white rounded-b-2xl shadow-md z-0'>
                    <div className='flex py-1 text-atamblue-500'>
                        <Icon path={mdiAccountStar} size={1} />
                        <div className='mx-2'>{t('UserRole')}</div>
                        <div className='truncate'>{profile.isSuperadmin ? 'Superadmin' : ''}</div>
                    </div>
                    <div className='flex py-1 text-atamblue-500'>
                        <Icon path={mdiBarcodeScan} size={1} />
                        <div className='mx-2'>{t('UserCode')}</div>
                        <div className='truncate'>{profile.code}</div>
                    </div>
                    <div className='flex py-1 text-atamblue-500'>
                        <Icon path={mdiEmail} size={1} />
                        <div className='mx-2'>{profile.email}</div>
                    </div>
                    <Separator className='w-full h-px my-2 bg-ebony-400' />
                    <Modal body={({ close }) => <EditProfile onSave={close} onCancel={close} />}>
                        <IconButton
                            path={mdiAccountConvert}
                            size={1}
                            iconClassName='mr-2'
                            className='py-1 text-atamblue-700 hover:text-atamsky-900'
                        >
                            {t('MyProfile')}
                        </IconButton>
                    </Modal>
                    <ForAccountType permission={AccountTypePermissions.UserCreate}>
                        <Modal
                            fullwidth
                            body={({ close }) => <CreateUser onSave={close} onCancel={close} />}
                        >
                            <IconButton
                                path={mdiAccountPlus}
                                size={1}
                                iconClassName='mr-2'
                                className='py-1 text-atamblue-700 hover:text-atamsky-900'
                            >
                                {t('CreateNewUser')}
                            </IconButton>
                        </Modal>
                    </ForAccountType>
                    <div>
                        <IconButton
                            path={mdiPower}
                            size={1}
                            iconClassName='mr-2'
                            className='py-1 text-atamblue-700 hover:text-red-500'
                            onClick={props.onLogout}
                        >
                            {t('Logout')}
                        </IconButton>
                    </div>
                </div>
            )}
        >
            <div
                onClick={show}
                className='flex items-center px-5 font-medium text-atamblue-700 transition duration-500 group hover:text-atamsky-900 cursor-pointer	'
            >
                <div className='max-w-xxs leading-4 text-right'>{profile.name}</div>
                <CircledInitial
                    content={profile.code ?? ''}
                    size='lg'
                    className='ml-3 border-atamblue-300 text-sm transition duration-500 group-hover:bg-atamsky-900 group-hover:border-atamsky-900 group-hover:text-ebony-100'
                ></CircledInitial>
            </div>
        </Tippy>
    )
}

export const CurrentUser: React.FC = () => {
    const profile = useCurrentProfile()
    const [logout] = useLogoutMutation()
    const dispatch = useAppDispatch()

    if (!profile) {
        return null
    }

    const onLogout = async () => {
        await logout(undefined)
        dispatch(authActions.logoutRaw())
    }
    return <Dropdown profile={profile} onLogout={onLogout} />
}
