export const toggleInArray = <T>(value: T) => (forceAppear?: boolean) => (arr: T[]): T[] => {
    const shouldAppear = forceAppear !== undefined ? forceAppear : !arr.includes(value)
    if (shouldAppear) {
        return [...arr, value]
    } else {
        return arr.filter(item => item !== value)
    }
}
export const setToggledInArray = <T>(setter: (cb: (old: T[]) => T[]) => void) => (value: T) => (
    forceAppear?: boolean,
) => {
    setter(toggleInArray(value)(forceAppear))
}
export const setToggledAllInArray = <T>(setter: (cb: (old: T[]) => T[]) => void) => (
    maxLength: number,
) => (generator: () => T[]) => {
    setter(arr => (arr.length === maxLength ? [] : generator()))
}
