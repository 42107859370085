import React from 'react'

import { useCreateSubjectMutation } from '../../api/for-company/subjects.api'

import { SubjectForm } from './SubjectForm'

export const CreateSubject: React.FC<{
    isTypeStrict?: 'records' | 'physical'
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [createSubject, { isLoading }] = useCreateSubjectMutation()

    return (
        <SubjectForm
            canEditType={true}
            isTypeStrict={props.isTypeStrict}
            isSaving={isLoading}
            onConfirm={newSubject => createSubject(newSubject).then(props.onSave)}
            onCancel={props.onCancel}
        />
    )
}
