import { mdiStarCircle } from '@mdi/js'
import React from 'react'

import { useListProjectRolesQuery } from '../../api/for-company/project-roles.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { ProjectRole } from '../../domain/project-role.domain'

export const ProjectRoleSelector: React.FC<{
    value?: ProjectRole
    exclude?: ProjectRole[]
    className?: string
    iconClassName?: string
    onChange: (newProjectRole: ProjectRole | undefined) => void
}> = props => {
    return (
        <EntitySelector<ProjectRole>
            useResolver={useListProjectRolesQuery}
            getOptionLabel={projectRole => projectRole.name}
            defaultValue={props.value}
            exclude={props.exclude}
            placeholder='Select Project Role'
            className={props.className}
            iconClassName={props.iconClassName}
            icon={mdiStarCircle}
            onChange={props.onChange}
        />
    )
}
