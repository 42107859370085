import React from 'react'

import {
    useEditCompanyMutation,
    useFetchCompanyByIdQuery,
} from '../../api/for-superadmin/company.api'
import { LoadingSpinner } from '../../atoms/LoadingSpinner'
import { ServerError } from '../../domain/common'
import { Company, CompanyFormType } from '../../domain/company.domain'

import { CompanyForm } from './CompanyForm'

export const EditCompany: React.FC<{
    id: Company['id']
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [editCompany, editCompanyStatus] = useEditCompanyMutation()
    const fetchCompanyStatus = useFetchCompanyByIdQuery({ id: props.id })

    if (fetchCompanyStatus.isLoading) {
        return <LoadingSpinner center />
    }

    const onSave = (editedCompany: CompanyFormType) => {
        editCompany({
            name: editedCompany.name,
            code: editedCompany.code,
            id: props.id,
        }).then(props.onSave)
    }

    if (fetchCompanyStatus.isSuccess && fetchCompanyStatus.data) {
        return (
            <div>
                <CompanyForm
                    company={fetchCompanyStatus.data}
                    isSaving={editCompanyStatus.isLoading}
                    error={editCompanyStatus.error as ServerError}
                    onSave={onSave}
                    onCancel={props.onCancel}
                />
            </div>
        )
    }
    return null
}
