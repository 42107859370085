import { mdiAccountTie } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'
import Select from 'react-select'

import { ErrorMessage } from '../../atoms/ErrorMessage'
import { styles } from '../../atoms/Form/EntitySelector'
import { ProjectAssignmentEntity } from '../../domain/project.domain'

type ProjectAssignmentEntityOption = {
    id: ProjectAssignmentEntity
    name: string
}

const AssignmentEntities: Record<ProjectAssignmentEntity, ProjectAssignmentEntityOption> = {
    [ProjectAssignmentEntity.Auditor]: { id: ProjectAssignmentEntity.Auditor, name: 'Auditor' },
    [ProjectAssignmentEntity.Auditee]: { id: ProjectAssignmentEntity.Auditee, name: 'Auditee' },
    [ProjectAssignmentEntity.Unknown]: { id: ProjectAssignmentEntity.Unknown, name: 'Unknown' },
}

export const ProjectAssignmentEntitySelector: React.FC<{
    value?: ProjectAssignmentEntity
    error?: { message: string }
    className?: string
    onChange: (newEntity: ProjectAssignmentEntity | undefined) => void
}> = props => {
    return (
        <div className={classNames('flex items-center', props.className)}>
            <Icon path={mdiAccountTie} size={1} className='mr-2 text-atamblue-300' />
            <Select<ProjectAssignmentEntityOption>
                defaultValue={props.value ? AssignmentEntities[props.value] : undefined}
                getOptionLabel={entity => entity.name}
                getOptionValue={entity => entity.id}
                options={Object.values(AssignmentEntities)}
                placeholder='Select entity'
                defaultOptions
                classNamePrefix='react-select'
                className='grow'
                styles={styles as any}
                onChange={newEntity => props.onChange(newEntity?.id)}
            />
            {props.error ? (
                <ErrorMessage className='pt-1'>{props.error.message}</ErrorMessage>
            ) : null}
        </div>
    )
}
