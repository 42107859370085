import classNames from 'classnames'
import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useDisableBodyScroll } from '../../common/useDisableBodyScroll'

type InfoDrawerBodyProps = {
    close: () => void
}

type InfoDrawerRenderProps = {
    open: () => void
}

export const InfoDrawer: React.FC<{
    body: (props: InfoDrawerBodyProps) => JSX.Element | null
    render: (props: InfoDrawerRenderProps) => JSX.Element
}> = props => {
    const containerRef = useRef(null)
    const [isShown, setShown] = useState(false)
    useDisableBodyScroll(isShown)

    const open = () => setShown(true)
    const close = () => setShown(false)

    const modal = ReactDOM.createPortal(
        <div
            className={classNames(
                'w-full max-h-screen flex justify-end bg-black bg-opacity-25 fixed top-0 left-0 z-30 overflow-y-scroll',
            )}
            ref={containerRef}
            onClick={event => {
                if (event.target === containerRef.current) {
                    close()
                }
            }}
        >
            {props.body({ close })}
        </div>,
        document.getElementById('root') as HTMLElement,
    )
    return (
        <>
            {isShown ? modal : null}
            {props.render({ open })}
        </>
    )
}
