import { z } from 'zod'

import { CategorySchema } from './subject.domain'
import { UserSchema } from './user.domain'

export enum RASStatus {
    NotStarted = 'NotStarted',
    NotApplicable = 'NotApplicable',
    AwaitingData = 'AwaitingData',
    AwaitingOILData = 'AwaitingOILData',
    UnderReview = 'UnderReview',
    Completed = 'Completed',
}

export const RASRemarkSchema = z.object({
    id: z.number(),
    content: z.string(),
    user: UserSchema.pick({ id: true, name: true, code: true }),
    createdAt: z.date(),
    isDeleted: z.boolean(),
})
export type RASRemark = z.TypeOf<typeof RASRemarkSchema>

export const RASSchema = z.object({
    id: z.number(),
    name: z.string(),
    status: z.nativeEnum(RASStatus),
    poc: z.number(),
    ewd: z.number(),
    remarks: z.array(RASRemarkSchema),
    category: CategorySchema.pick({ id: true, name: true }),
    user: z.nullable(UserSchema.pick({ id: true, name: true, code: true })),
})
export type RAS = z.TypeOf<typeof RASSchema>

export enum RASSummaryStatus {
    NotStarted = 'NotStarted',
    NotApplicable = 'NotApplicable',
    AwaitingData = 'AwaitingData',
    AwaitingOILData = 'AwaitingOILData',
    UnderReview = 'UnderReview',
    Completed = 'Completed',
}

export const RASSummarySchema = z.object({
    id: z.number(),
    name: z.string(),
    status: z.nativeEnum(RASSummaryStatus),
    poc: z.number(),
    remark: z.string(),
})
export type RASSummary = z.TypeOf<typeof RASSummarySchema>
