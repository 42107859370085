import { mdiFolderOpen } from '@mdi/js'
import React from 'react'

import { useListProjectsQuery } from '../../api/for-company/projects.api'
import { EntitySelector } from '../../atoms/Form/EntitySelector'
import { Project } from '../../domain/project.domain'

export const ProjectSelector: React.FC<{
    value?: Project
    exclude?: Project[]
    className?: string
    iconClassName?: string
    onChange: (newProject: Project | undefined) => void
}> = props => {
    return (
        <EntitySelector<Project>
            useResolver={useListProjectsQuery}
            resolverParams={{ isArchived: false }}
            getOptionLabel={project => project.name}
            defaultValue={props.value}
            exclude={props.exclude}
            placeholder='Select Project'
            className={props.className}
            iconClassName={props.iconClassName}
            icon={mdiFolderOpen}
            onChange={props.onChange}
        />
    )
}
