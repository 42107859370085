import { z } from 'zod'

import {
    AircraftInformationSchema,
    AircraftInformationModuleSchema,
    AircraftInformationBlockSchema,
} from '../../domain/aircraft-information.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, makeBuilders } from '../common'

export const withProjectId = () => z.object({ projectId: z.number() })
export const withId = () => z.object({ id: z.number() })

export const aircraftInformationApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            getAircraftInformation: buildQuery({
                input: withProjectId(),
                output: AircraftInformationSchema,
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/aircraft-information`,
                    method: 'GET',
                }),
                providesTags: ['AircraftInformation'],
            }),
            editAircraftInformation: buildMutation({
                input: withProjectId().merge(
                    AircraftInformationSchema.pick({ dataPer: true }).partial(),
                ),
                output: emptyContent(),
                query: ({ projectId, ...body }) => ({
                    url: `/project/${projectId}/aircraft-information`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['AircraftInformation'],
            }),
            addAircraftInformationModule: buildMutation({
                input: withProjectId().merge(
                    AircraftInformationModuleSchema.pick({ name: true, type: true }),
                ),
                output: withId(),
                query: ({ projectId, ...body }) => ({
                    url: `/project/${projectId}/aircraft-information-module`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['AircraftInformation'],
            }),
            editAircraftInformationModule: buildMutation({
                input: withProjectId()
                    .merge(AircraftInformationModuleSchema.pick({ id: true }))
                    .merge(AircraftInformationModuleSchema.pick({ name: true }).partial()),
                output: withId(),
                query: ({ projectId, id, ...body }) => ({
                    url: `/project/${projectId}/aircraft-information-module/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['AircraftInformation'],
            }),
            deleteAircraftInformationModule: buildMutation({
                input: withProjectId().merge(AircraftInformationModuleSchema.pick({ id: true })),
                output: emptyContent(),
                query: ({ projectId, id }) => ({
                    url: `/project/${projectId}/aircraft-information-module/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['AircraftInformation'],
            }),
            editAircraftInformationBlock: buildMutation({
                input: withProjectId()
                    .merge(AircraftInformationBlockSchema.pick({ id: true }))
                    .merge(
                        AircraftInformationBlockSchema.pick({ name: true, value: true }).partial(),
                    ),
                output: emptyContent(),
                query: ({ projectId, id, ...body }) => ({
                    url: `/project/${projectId}/aircraft-information-block/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['AircraftInformation'],
            }),
            exportAircraftInformationHtml: buildMutation({
                input: withProjectId(),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/aircraft-information/export-html`,
                    method: 'POST',
                }),
            }),
            exportAircraftInformationPdf: buildMutation({
                input: withProjectId(),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/aircraft-information/export-pdf`,
                    method: 'POST',
                }),
            }),
            exportAircraftInformationXlsx: buildMutation({
                input: withProjectId(),
                output: z.object({ path: z.string() }),
                query: ({ projectId }) => ({
                    url: `/project/${projectId}/aircraft-information/export-xlsx`,
                    method: 'POST',
                }),
            }),
        }
    },
})

export const {
    useGetAircraftInformationQuery,
    useEditAircraftInformationMutation,
    useAddAircraftInformationModuleMutation,
    useEditAircraftInformationModuleMutation,
    useDeleteAircraftInformationModuleMutation,
    useEditAircraftInformationBlockMutation,
    useExportAircraftInformationHtmlMutation,
    useExportAircraftInformationPdfMutation,
    useExportAircraftInformationXlsxMutation,
} = aircraftInformationApi
