import { z } from 'zod'

import { CategorySchema, SubjectSchema, SubpartSchema } from './subject.domain'

export const TemplateSubpartSchema = SubpartSchema.merge(
    z.object({
        isSelected: z.boolean(),
    }),
)
export type TemplateSubpart = z.TypeOf<typeof TemplateSubpartSchema>

export const TemplateCategorySchema = CategorySchema.merge(
    z.object({
        subparts: z.array(TemplateSubpartSchema),
    }),
)
export type TemplateCategory = z.TypeOf<typeof TemplateCategorySchema>

export const TemplateSubjectSchema = SubjectSchema.merge(
    z.object({
        categories: z.array(TemplateCategorySchema),
    }),
)
export type TemplateSubject = z.TypeOf<typeof TemplateSubjectSchema>

export const TemplateSchema = z.object({
    id: z.number(),
    name: z.string(),
    isPhysical: z.boolean(),
    subjects: z.array(TemplateSubjectSchema),
})
export type Template = z.TypeOf<typeof TemplateSchema>

export const TemplateFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    isPhysical: z.boolean(),
    subjects: z.array(
        z.object({
            categories: z.array(
                z.object({
                    subparts: z.array(z.object({ id: z.number(), isSelected: z.boolean() })),
                }),
            ),
        }),
    ),
})
export type TemplateFormType = z.TypeOf<typeof TemplateFormSchema>

export const SaveTemplateFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
})
export type SaveTemplateFormType = z.TypeOf<typeof SaveTemplateFormSchema>

/** Helpers */
export const getCategorySubparts = (
    category: Pick<TemplateCategory, 'subparts'>,
): TemplateSubpart[] => category.subparts
export const getSubjectSubparts = (subject: TemplateSubject): TemplateSubpart[] =>
    subject.categories.flatMap(getCategorySubparts)
export const getSubjectsSubparts = (subjects: TemplateSubject[]): TemplateSubpart[] =>
    subjects.flatMap(getSubjectSubparts)

export const hasSubjectSubpartSelected = (subject: TemplateSubject): boolean =>
    getSubjectSubparts(subject).some(subpart => subpart.isSelected)
export const hasCategorySubpartSelected = (category: Pick<TemplateCategory, 'subparts'>): boolean =>
    getCategorySubparts(category).some(subpart => subpart.isSelected)

// export const areTemplatesIdentical = (template1: Template, template2: Template): boolean =>
