import { mdiLoading, mdiCloudUpload } from '@mdi/js'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '../IconButton'
import { Tooltip } from '../Tooltip'

export const ExportHtmlButton: React.FC<{ isLoading: boolean; onClick: () => void }> = props => {
    const { t } = useTranslation('exportButtons')
    return (
        <Tooltip text={t('HTMLExport')} className='cursor-pointer relative' onClick={props.onClick}>
            <IconButton
                isSubmit
                path={props.isLoading ? mdiLoading : mdiCloudUpload}
                spin={props.isLoading}
                size={1}
                disabled={props.isLoading}
                className='rounded-2xl transition duration-500 bg-white p-2.5 shadow-md mx-0.5 cursor-pointer text-atamsky-900 hover:bg-atamsky-900 hover:text-white'
            ></IconButton>
        </Tooltip>
    )
}
