import classNames from 'classnames'
import React from 'react'

import { Separator } from '../Separator'

import { useSidebarContext } from './Sidebar'

export const NavSeparator: React.FC<{ className?: string }> = props => {
    const { opened } = useSidebarContext()
    return (
        <Separator
            className={classNames(
                'mx-4 my-3 border-mini border-atamblue-200 transition-all duration-300',
                {
                    'w-6': !opened,
                    'w-64': opened,
                },
                props.className,
            )}
        />
    )
}
