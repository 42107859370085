// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (...args: any): void => {}
export const notImplemented = (): never => {
    throw new Error(`Provider not found`)
}

export const random = (min: number, max: number): number => Math.random() * (max - min) + min
export const randomInt = (min: number, max: number): number => Math.floor(random(min, max))

export const range = (size: number, startAt = 0): ReadonlyArray<number> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [...(Array(size).keys() as any)].map(i => i + startAt)

export const isNil = <T>(value: T | undefined | null): value is null | undefined =>
    value === null || value === undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contextProxy = (provider: string): any =>
    new Proxy(
        {},
        {
            get: function () {
                throw new Error(`Wrap the component in ${provider}`)
            },
        },
    )
