import classNames from 'classnames'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
    useEditRASSubjectRemarkMutation,
    useExportRASSummaryHtmlMutation,
    useExportRASSummaryPdfMutation,
    useExportRASSummaryXlsxMutation,
    useListRASSummaryQuery,
} from '../../api/for-company/ras.api'
import { CircledInitial, CircledText } from '../../atoms/CircledInitial'
import { ExportHtmlButton } from '../../atoms/Export/ExportHtmlButton'
import { ExportPdfButton } from '../../atoms/Export/ExportPdfButton'
import { ExportXlsButton } from '../../atoms/Export/ExportXlsButton'
import { ProgressBar } from '../../atoms/ProgressBar'
import { DefaultCellValue } from '../../atoms/Table/DefaultCell'
import { Table, TableColumn } from '../../atoms/Table/Table'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { RASSummaryStatus, RASSummary } from '../../domain/RAS.domain'
import { openFile } from '../../lib/open-file'
import { routes } from '../../routes'
import { ForProjectRole } from '../Auth/ForProjectRole'

import { SummaryRemarksCell } from './SummaryRemarks'

const RASSummaryStatusView: React.FC<{ status: RASSummaryStatus; short?: boolean }> = props => {
    const classMap = {
        [RASSummaryStatus.NotStarted]: 'border-red-500 text-atamblue-700',
        [RASSummaryStatus.NotApplicable]: 'border-gray-500 text-atamblue-700',
        [RASSummaryStatus.AwaitingData]: 'border-orange-500 text-atamblue-700',
        [RASSummaryStatus.AwaitingOILData]: 'border-atamblue-500 text-atamblue-700',
        [RASSummaryStatus.UnderReview]: 'border-blue-500 text-atamblue-700',
        [RASSummaryStatus.Completed]: 'border-green-500 text-atamblue-700',
    }
    const shortTextMap = {
        [RASSummaryStatus.NotStarted]: 'NS',
        [RASSummaryStatus.NotApplicable]: 'NA',
        [RASSummaryStatus.AwaitingData]: 'ED',
        [RASSummaryStatus.AwaitingOILData]: 'EOD',
        [RASSummaryStatus.UnderReview]: 'UR',
        [RASSummaryStatus.Completed]: 'C',
    }

    const longTextMap = {
        [RASSummaryStatus.NotStarted]: 'Not Started',
        [RASSummaryStatus.NotApplicable]: 'Not Applicable',
        [RASSummaryStatus.AwaitingData]: 'Awaiting Data',
        [RASSummaryStatus.AwaitingOILData]: 'Awaiting OIL Data',
        [RASSummaryStatus.UnderReview]: 'Under Review',
        [RASSummaryStatus.Completed]: 'Completed',
    }
    const short = props.short ?? false
    return short ? (
        <CircledInitial
            content={shortTextMap[props.status]}
            tooltip={longTextMap[props.status]}
            className={classNames('tex-center', classMap[props.status])}
        />
    ) : (
        <CircledText className={classNames('', classMap[props.status])}>
            <span className='truncate'>{longTextMap[props.status]}</span>
        </CircledText>
    )
}

const ListSummaryRASColumns = ({
    projectId,
}: {
    projectId: number
}): TableColumn<RASSummary, void>[] => [
    {
        id: 'reviewSubjects',
        Header: () => <div className='grow py-2 text-left'>Review Subjects</div>,
        Cell: ({ value }) => (
            <DefaultCellValue align='left'>
                <Link
                    to={routes.RAS.build({
                        projectId: projectId,
                        subjectId: value.id,
                    })}
                >
                    {value.name}
                </Link>
            </DefaultCellValue>
        ),
        width: 35,
    },
    {
        id: 'status',
        Header: () => <div className='grow py-2'>Status</div>,
        Cell: ({ value }) => {
            return <RASSummaryStatusView status={value.status} />
        },
        width: 17,
    },
    {
        id: 'poc',
        Header: () => <div className='py-2 text-left'>Percentage Completed</div>,
        Cell: ({ value }) => {
            if (value.status === RASSummaryStatus.NotApplicable) {
                return <div className='flex justify-center items-center'>-</div>
            }
            return (
                <DefaultCellValue>
                    <ProgressBar
                        progress={Math.round(value.poc)}
                        barHeight='0.75rem'
                        barProgressBg='#33c2ff'
                    />
                </DefaultCellValue>
            )
        },
        width: 18,
    },
    {
        id: 'summaryRemarks',
        Header: () => <div className='py-2'>Remarks</div>,
        Cell: ({ value }) => {
            const [editRemark, editRemarkMeta] = useEditRASSubjectRemarkMutation()
            return (
                <DefaultCellValue align='left'>
                    <SummaryRemarksCell
                        reviewSubject={value.name}
                        value={value.remark}
                        isLoading={editRemarkMeta.isLoading}
                        onChange={remark => editRemark({ projectId, subjectId: value.id, remark })}
                    />
                </DefaultCellValue>
            )
        },
        width: 30,
    },
]

export const ListSummaryRAS: React.FC<{ projectId: number }> = props => {
    const { projectId } = props
    const { data } = useListRASSummaryQuery({ projectId })
    const columns = useMemo(() => ListSummaryRASColumns({ projectId }), [projectId])
    const [exportXlsx, exportXlsxMeta] = useExportRASSummaryXlsxMutation()
    const [exportPdf, exportPdfMeta] = useExportRASSummaryPdfMutation()
    const [exportHtml, exportHtmlMeta] = useExportRASSummaryHtmlMutation()

    if (!data) {
        return null
    }

    return (
        <Table
            headerClassName='p-3'
            rowClassName={() => 'px-3 py-0.5'}
            columns={columns}
            data={data}
            tableActions={() => (
                <div className='flex items-center ml-12 mb-2'>
                    <ForProjectRole
                        permission={ProjectRolePermissions.RASExportXls}
                        projectId={projectId}
                    >
                        <ExportXlsButton
                            isLoading={exportXlsxMeta.isLoading}
                            onClick={() =>
                                exportXlsx({ projectId })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                                    .catch(noop)
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.RASExportPdf}
                        projectId={projectId}
                    >
                        <ExportPdfButton
                            isLoading={exportPdfMeta.isLoading}
                            onClick={() =>
                                exportPdf({ projectId })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                                    .catch(noop)
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.RASExportStatic}
                        projectId={projectId}
                    >
                        <ExportHtmlButton
                            isLoading={exportHtmlMeta.isLoading}
                            onClick={() =>
                                exportHtml({ projectId })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                                    .catch(noop)
                            }
                        />
                    </ForProjectRole>
                </div>
            )}
        ></Table>
    )
}
