import React from 'react'

import { selectIsSuperadmin } from '../../state/auth.slice'
import { useAppSelector } from '../../store'

export const ForSuperadmin: React.FC = props => {
    const isSuperadmin = useAppSelector(selectIsSuperadmin)

    if (isSuperadmin) {
        return <>{props.children}</>
    } else {
        return null
    }
}
