import { z } from 'zod'

import { makeRoute, stringAsPositiveNumber } from './common/make-routes'

export const routes = {
    ActivateAccount: makeRoute('/activate/:token', z.object({ token: z.string() })),
    ForgotPassword: makeRoute('/forgot-password', z.object({})),
    ResetPassword: makeRoute('/reset-password/:token', z.object({ token: z.string() })),

    Dashboard: makeRoute('/', z.object({})),
    CreateProject: makeRoute('/create-project', z.object({})),
    EditProject: makeRoute(
        '/edit-project/:projectId',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),
    Projects: makeRoute('/projects', z.object({})),
    Project: makeRoute('/projects/:projectId', z.object({ projectId: stringAsPositiveNumber() })),
    ProjectSummary: makeRoute(
        '/projects/:projectId/summary',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),
    ProjectAircraftInformation: makeRoute(
        '/projects/:projectId/aircraft-information',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),

    ROIL: makeRoute(
        '/projects/:projectId/roil/:subjectId',
        z.object({ projectId: stringAsPositiveNumber(), subjectId: stringAsPositiveNumber() }),
    ),
    ROILSummary: makeRoute(
        '/projects/:projectId/roil/summary',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),

    POIL: makeRoute(
        '/projects/:projectId/poil/:subjectId',
        z.object({ projectId: stringAsPositiveNumber(), subjectId: stringAsPositiveNumber() }),
    ),
    POILSummary: makeRoute(
        '/projects/:projectId/poil/summary',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),

    RAS: makeRoute(
        '/projects/:projectId/ras/:subjectId',
        z.object({ projectId: stringAsPositiveNumber(), subjectId: stringAsPositiveNumber() }),
    ),
    RASSummary: makeRoute(
        '/projects/:projectId/ras/summary',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),

    PAS: makeRoute(
        '/projects/:projectId/pas/:subjectId',
        z.object({ projectId: stringAsPositiveNumber(), subjectId: stringAsPositiveNumber() }),
    ),
    PASSummary: makeRoute(
        '/projects/:projectId/pas/summary',
        z.object({ projectId: stringAsPositiveNumber() }),
    ),

    Settings: makeRoute('/settings', z.object({})),
    Users: makeRoute('/settings/users', z.object({})),
    AccountTypes: makeRoute('/settings/account-types', z.object({})),
    ProjectRoles: makeRoute('/settings/project-roles', z.object({})),
    Subjects: makeRoute('/settings/subjects', z.object({})),
    Templates: makeRoute('/settings/templates', z.object({})),

    Companies: makeRoute('/companies', z.object({})),
}
