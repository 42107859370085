import {
    mdiDotsHorizontal,
    mdiDeleteEmpty,
    mdiAirplaneSearch,
    mdiArchiveSearch,
    mdiFolderStarMultiple,
    mdiFolderEdit,
} from '@mdi/js'
import Icon from '@mdi/react'
import { noop } from 'lodash'
import React, { useState } from 'react'

import { useListTemplatesQuery } from '../../api/for-company/templates.api'
import { Backdrop } from '../../atoms/Backdrop'
import { Card } from '../../atoms/Card'
import { IconButton } from '../../atoms/IconButton'
import { MenuItem } from '../../atoms/Menu'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { Paginator } from '../../common/Paginator'
import { AccountTypePermissions } from '../../domain/account-type.domain'
import { Template } from '../../domain/template.domain'
import { ForAccountType } from '../Auth/ForAccountType'

import { CreateTemplate } from './CreateTemplate'
import { DeleteTemplate } from './DeleteTemplate'
import { EditTemplate } from './EditTemplate'

const TemplateEntry: React.FC<{ template: Template }> = props => {
    const { template } = props
    const [isShown, setShown] = useState(false)

    return (
        <div>
            <div className='flex py-2 border-b border-ebony-400 text-sm'>
                <div className='flex mx-2 relative'>
                    <Tooltip text={`${template.name} template actions`} className='justify-center'>
                        <IconButton
                            size={0.75}
                            className='text-atamblue-300 hover:text-atamsky-900'
                            path={mdiDotsHorizontal}
                            onClick={() => setShown(true)}
                        />
                    </Tooltip>
                    {isShown ? (
                        <Backdrop
                            className='absolute flex left-5 -mt-3 border border-atamsky-300 rounded-xl bg-ebony-100 bg-opacity-90 shadow-lg'
                            onClick={() => setShown(false)}
                        >
                            <ForAccountType permission={AccountTypePermissions.TemplateEdit}>
                                <Modal
                                    fullwidth
                                    title='Edit Template'
                                    body={({ close }) => (
                                        <EditTemplate
                                            template={props.template}
                                            onSave={close}
                                            onCancel={close}
                                        />
                                    )}
                                    onClose={() => setShown(false)}
                                >
                                    <MenuItem icon={mdiFolderEdit} className='whitespace-nowrap'>
                                        Edit Template
                                    </MenuItem>
                                </Modal>
                            </ForAccountType>
                            <ForAccountType permission={AccountTypePermissions.TemplateDelete}>
                                <DeleteTemplate template={props.template} onDone={noop}>
                                    <MenuItem icon={mdiDeleteEmpty} className='whitespace-nowrap'>
                                        Delete Template
                                    </MenuItem>
                                </DeleteTemplate>
                            </ForAccountType>
                        </Backdrop>
                    ) : null}
                </div>
                <div className='grow'>{template.name}</div>
                <div className='w-1/3 flex justify-center'>
                    {template.isPhysical ? (
                        <div className='flex items-center'>
                            <Icon
                                path={mdiAirplaneSearch}
                                size={0.75}
                                className='text-atamblue-300 mr-2'
                            />
                            Physical
                        </div>
                    ) : (
                        <div className='flex items-center'>
                            <Icon
                                path={mdiArchiveSearch}
                                size={0.75}
                                className='text-atamblue-300 mr-2'
                            />
                            Records
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const ListTemplates: React.FC = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const { data } = useListTemplatesQuery({
        page,
        perPage,
    })

    const Columns = (
        <div className='flex items-center border-b border-atamblue-300 py-1 font-medium text-sm text-atamblue-900'>
            <div className='flex mx-2'>
                <Modal
                    fullwidth
                    // fullheight
                    title='Create A New Template'
                    body={({ close }) => <CreateTemplate onSave={close} onCancel={close} />}
                >
                    <Tooltip text={`Create a new template`} className='flex items-center'>
                        <IconButton
                            path={mdiFolderStarMultiple}
                            size={0.75}
                            className='py-1 text-atamblue-300 hover:text-atamsky-900'
                        />
                    </Tooltip>
                </Modal>
            </div>
            <div className='grow'>Template Name</div>
            <div className='w-1/3 text-center'>Template Type</div>
        </div>
    )

    if (!data) {
        return null
    }
    const Entries = data.results.map(template => (
        <TemplateEntry key={template.id} template={template} />
    ))

    return (
        <>
            <Card className='mb-1 p-3'>
                {Columns}
                {Entries}
            </Card>
            <Paginator
                currentPage={page}
                totalPages={data.totalPages}
                perPage={perPage}
                onJumpToPage={setPage}
                onChangePageSize={setPerPage}
            />
        </>
    )
}
