import React from 'react'

import { ResetPassword } from '../components/Auth/ResetPassword'
import { UnauthenticatedLayout } from '../layouts/Unauthenticated.layout'

export const ResetPasswordPage: React.FC = () => {
    return (
        <UnauthenticatedLayout>
            <ResetPassword />
        </UnauthenticatedLayout>
    )
}
