import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import React from 'react'

import { useAsync } from '../../common/useAsync'
import { useTranslation } from '../../lib/i18n'
import { IconButton } from '../IconButton'

import { Modal } from './Modal'

export const ConfirmationModal: React.FC<{
    message: string | JSX.Element
    onConfirm: () => Promise<unknown>
    onCancel?: () => void
}> = props => {
    const { start, isPending } = useAsync(props.onConfirm)

    const { t } = useTranslation('common')

    return (
        <Modal
            body={bodyProps => (
                <div>
                    <div className='flex justify-center mb-5 text-atamblue-700'>
                        {props.message}
                    </div>
                    <div className='flex justify-center text-atamblue-700'>
                        <IconButton
                            onClick={() => {
                                start(undefined).then(bodyProps.close)
                            }}
                            path={isPending ? mdiLoading : mdiCheck}
                            spin={isPending}
                            size={0.75}
                            disabled={isPending}
                            className='mr-10 group text-atamblue-700 hover:text-atamsky-900'
                            iconClassName='text-green-300 group-hover:text-green-500 mr-2'
                        >
                            {t('Confirm')}
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                bodyProps.close()
                                props.onCancel && props.onCancel()
                            }}
                            path={mdiClose}
                            size={0.75}
                            className='ml-10 group text-atamblue-700 hover:text-atamsky-900'
                            iconClassName='text-red-400 group-hover:text-red-500 mr-2'
                        >
                            {t('Cancel')}
                        </IconButton>
                    </div>
                </div>
            )}
            onClose={props.onCancel}
        >
            {props.children}
        </Modal>
    )
}
