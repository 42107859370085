import { configureStore, isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { forCompanyApi, forSuperadminApi } from './api/_base.api'
import { authApi } from './api/for-public.api'
import { authSlice } from './state/auth.slice'
import { sidebarSlice } from './state/sidebar.slice'

const unauthorizedHandlerMiddleware: Middleware = () => next => action => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 401) {
            next(authSlice.actions.logoutByInactivity())
        }
    }
    return next(action)
}

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [forCompanyApi.reducerPath]: forCompanyApi.reducer,
        [forSuperadminApi.reducerPath]: forSuperadminApi.reducer,

        auth: authSlice.reducer,
        sidebar: sidebarSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(forCompanyApi.middleware)
            .concat(forSuperadminApi.middleware)
            .concat(unauthorizedHandlerMiddleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
