import React from 'react'

import { Separator } from '../../atoms/Separator'
import { selectCurrentCompanyId } from '../../state/auth.slice'
import { useAppSelector } from '../../store'

import { Breadcrumbs } from './Breadcrumbs'

export const Page: React.FC<{
    title?: string
    logo?: string
    breadcrumbs?: React.ReactNode
}> = props => {
    const companyId = useAppSelector(selectCurrentCompanyId)
    return (
        <div key={companyId}>
            <div className='flex justify-between items-center mt-2 mb-5'>
                <div className='flex items-center'>
                    {props.breadcrumbs ? props.breadcrumbs : <Breadcrumbs />}
                </div>
                <div className='flex items-center'>
                    {props.logo ? (
                        <>
                            <img
                                src={props.logo}
                                style={{ maxWidth: '150px', maxHeight: '40px' }}
                            />
                            <Separator className='w-0.5 h-10 mx-2 bg-atamblue-300' />
                        </>
                    ) : null}
                    <div className='font-medium text-atamblue-700'>
                        Aircraft Technical Audit Monitor
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    )
}
