import cn from 'classnames'
import React from 'react'
import ReactDOM from 'react-dom'

import { forCompanyApi } from '../../api/_base.api'
import { AccountTypePermissions } from '../../domain/account-type.domain'
import { authActions, selectCurrentCompanyId } from '../../state/auth.slice'
import { useAppDispatch, useAppSelector } from '../../store'
import { ForAccountType } from '../Auth/ForAccountType'
import { ForSuperadmin } from '../Auth/ForSuperadmin'
import { CompanySelector, SelectCompany } from '../Company/CompanySelector'

import { CurrentUser } from './CurrentUser'

// const OnlineUsers: React.FC = () => {

//     const [shown, setShown] = useState(false)
//     const [users, setUsers] = useState<User[]>([])
//     const [isPending, setPending] = useState(true)
//     const [error, setError] = useState(true)

//     useEffect(() => {
//         getOnlineUsers({
//             onConnect: () => {
//                 setPending(false)
//                 setError(false)
//             },
//             onError: () => {
//                 setPending(false)
//                 setError(true)
//             },
//             onConnectedUser: (users: User[]) => {
//                 const otherUsers = users.filter(u => u.id !== currentUser?.id)
//                 setUsers(oldUsers => [
//                     ...oldUsers.filter(u => !otherUsers.find(_u => _u.id === u.id)),
//                     ...otherUsers,
//                 ])
//             },
//             onDisconnectedUser: (userIds: User['id'][]) =>
//                 setUsers(oldUsers => oldUsers.filter(u => !userIds.includes(u.id))),
//         })
//     }, [getOnlineUsers, currentUser])

//     const show = () => {
//         !error && setShown(true)
//     }
//     const hide = () => setShown(false)

//     const { t } = useTranslation('toolbar')

//     return (
//         <Tippy
//             interactive
//             placement='bottom-end'
//             visible={shown}
//             onClickOutside={hide}
//             render={() => (
//                 <div className='w-72 max-h-screen50 flex flex-col -mt-2.4 bg-white rounded-b-2xl text-sm font-medium shadow-md z-0 overflow-y-auto'>
//                     <div className='bg-atamblue-300 font-medium text-atamblue-700 p-4'>
//                         {t('OnlineUsers')}: {users.length}
//                     </div>
//                     <div className='my-3'>
//                         {users.map(user => (
//                             <div key={user.id} className='flex px-4 py-1 text-atamblue-700'>
//                                 <CircledInitial
//                                     size='md'
//                                     content={user.code}
//                                     className='bg-atamsky-900 text-ebony-100 px-2'
//                                 ></CircledInitial>
//                                 <div className='w-48 flex flex-col content-center px-2'>
//                                     <div className='truncate'>{user.name}</div>
//                                     <div className='flex text-atamblue-300'>
//                                         <Icon
//                                             path={mdiAccountStarOutline}
//                                             size={0.75}
//                                             className='mr-1'
//                                         />
//                                         <div className='truncate'>{user.accountType.name}</div>
//                                     </div>
//                                 </div>
//                                 <Icon
//                                     path={
//                                         isPending
//                                             ? mdiLoading
//                                             : error
//                                             ? mdiCloseNetwork
//                                             : mdiAccountNetwork
//                                     }
//                                     spin={isPending}
//                                     size={1}
//                                     className='ml-auto text-green-700'
//                                 />
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             )}
//         >
//             <div onClick={show} className='flex items-center ml-auto'>
//                 <Tooltip text={t('SeeOnlineUsers')} className='cursor-pointer relative'>
//                     <IconButton
//                         path={isPending ? mdiLoading : error ? mdiCloseNetwork : mdiAccountNetwork}
//                         spin={isPending}
//                         className={cn('w-16 transition duration-500', {
//                             'text-atamblue-300 hover:text-atamsky-900': !error,
//                             'text-red-400 hover:text-red-900': error,
//                         })}
//                     />
//                 </Tooltip>
//             </div>
//         </Tippy>
//     )
// }

export const ToolbarActions: React.FC = props => {
    const domNode = document.getElementById('toolbar-actions')
    if (!domNode) {
        return null
    }
    return ReactDOM.createPortal(props.children, domNode)
}

export const Toolbar: React.FC<{ className?: string }> = props => {
    return (
        <div
            className={cn(
                'fixed top-0 w-full h-14 z-10 flex items-center bg-white shadow-md',
                props.className,
            )}
        >
            {/* Moved into Sidebar */}
            {/* <ForSuperadmin>
                <SelectCompany />
            </ForSuperadmin> */}

            <div id='toolbar-actions' className='flex items-center'></div>

            <div className='grow'></div>

            <ForAccountType
                permission={AccountTypePermissions.OnlineUsersView}
                fallback={<div className='ml-auto'></div>}
            >
                {/* <OnlineUsers /> */}
            </ForAccountType>

            <CurrentUser />
        </div>
    )
}
