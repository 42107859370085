import classNames from 'classnames'
import React, { useRef, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { ErrorMessage } from '../ErrorMessage'

export const FileField: React.FC<
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> & {
        name: string
        buttonStyle?: React.CSSProperties
        buttonClassName?: string
        className?: string
        label?: string
        labelClassName?: string
        placeholder?: string
    }
> = props => {
    const {
        name,
        buttonClassName,
        buttonStyle,
        className,
        label,
        labelClassName,
        placeholder,
        ...inputProps
    } = props
    const {
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const value = getValues()[name] as File | undefined
    const error = errors[name]

    const imagePreview = useMemo(() => {
        if (value) {
            if (typeof value === 'string') {
                return value
            }
            return URL.createObjectURL(value)
        }
    }, [value])

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click()
        }
    }
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.currentTarget
        if (!files || files.length === 0) {
            return
        }

        setValue(name, files[0], { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    }

    return (
        <div className={className}>
            <div className={labelClassName}>{label}</div>
            <input
                {...inputProps}
                type='file'
                className='hidden'
                onChange={onFileChange}
                ref={fileInputRef}
            />
            <button
                className={classNames(
                    'flex justify-center items-center overflow-hidden',
                    buttonClassName,
                )}
                style={buttonStyle}
                onClick={onClick}
            >
                {imagePreview ? (
                    <img className='max-w-full max-h-full' src={imagePreview} />
                ) : (
                    placeholder
                )}
            </button>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
        </div>
    )
}
