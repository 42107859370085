import { mdiArchiveCheck, mdiArchiveStar, mdiCommentOutline, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import Select from 'react-select'

import { useAssignedProject } from '../../api/for-company/projects.api'
import {
    useBulkAssignRASSubjectMutation,
    useExportRASHtmlMutation,
    useExportRASPdfMutation,
    useExportRASXlsxMutation,
    useListRASQuery,
} from '../../api/for-company/ras.api'
import { Badge } from '../../atoms/Badge'
import { EmptyResponse } from '../../atoms/EmptyResponse'
import { ExportHtmlButton } from '../../atoms/Export/ExportHtmlButton'
import { ExportPdfButton } from '../../atoms/Export/ExportPdfButton'
import { ExportXlsButton } from '../../atoms/Export/ExportXlsButton'
import { styles } from '../../atoms/Form/EntitySelector'
import { IconButton } from '../../atoms/IconButton'
import { Menu, MenuItem } from '../../atoms/Menu'
import { Modal } from '../../atoms/Modal/Modal'
import { DefaultCellValue } from '../../atoms/Table/DefaultCell'
import { Table, TableColumn } from '../../atoms/Table/Table'
import { Tooltip } from '../../atoms/Tooltip'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { Project, ProjectSubject } from '../../domain/project.domain'
import { RAS, RASStatus } from '../../domain/RAS.domain'
import { openFile } from '../../lib/open-file'
import { ForProjectRole } from '../Auth/ForProjectRole'

import { AddRASSubpart } from './AddRASSubpart'
import { EditRASEntry } from './EditRAS'
import { RASStatusView } from './RASStatusView'

const getRASColumns = ({
    projectId,
    subjectId,
}: {
    projectId: number
    subjectId: number
    // eslint-disable-next-line @typescript-eslint/ban-types
}): TableColumn<RAS, {}>[] => [
    {
        id: 'menu',
        Header: () => {
            const [bulkAssign] = useBulkAssignRASSubjectMutation()
            const project = useAssignedProject({ projectId })

            if (!project) {
                return null
            }

            return (
                <Menu
                    body={() => (
                        <Tooltip text='Menu' className='justify-center'>
                            <Icon
                                className='text-atamblue-300 hover:text-atamsky-900 hover:rotate-0'
                                path={mdiArchiveCheck}
                                size={0.75}
                            />
                        </Tooltip>
                    )}
                    className='bg-ebony-100 bg-opacity-90'
                    render={({ close }) => (
                        <>
                            <ForProjectRole
                                permission={ProjectRolePermissions.RASListSubparts}
                                projectId={projectId}
                            >
                                <MenuItem icon={mdiArchiveStar}>
                                    Bulk assign:{' '}
                                    <Select<{ id: number; name: string }>
                                        value={undefined}
                                        options={project.assignments}
                                        getOptionLabel={user => user.name}
                                        getOptionValue={user => `${user.id}`}
                                        styles={styles as any}
                                        className='w-64'
                                        onChange={user => {
                                            if (!user) {
                                                close()
                                                return
                                            }
                                            bulkAssign({
                                                projectId,
                                                subjectId,
                                                userId: user.id,
                                            }).then(close)
                                        }}
                                    />
                                </MenuItem>
                            </ForProjectRole>
                        </>
                    )}
                />
            )
        },
        Cell: ({ value }) => (
            <DefaultCellValue>
                <Modal
                    modalBodyClassName='overflow-hidden'
                    body={({ close }) => (
                        <EditRASEntry
                            projectId={projectId}
                            subjectId={subjectId}
                            ras={value}
                            onSave={close}
                            onCancel={close}
                        />
                    )}
                >
                    <IconButton
                        className='text-atamblue-300 hover:text-atamsky-900'
                        path={mdiPencil}
                        size={0.75}
                    />
                </Modal>
            </DefaultCellValue>
        ),
        width: 4,
    },
    {
        id: 'subpart-name',
        Header: () => <div className='py-2 text-left'>Subpart Name</div>,
        Cell: ({ value }) => <DefaultCellValue align='left'>{value.name}</DefaultCellValue>,
        width: 26,
    },
    {
        id: 'category-name',
        Header: () => <div className='py-2'>Category Name</div>,
        Cell: ({ value }) => <DefaultCellValue>{value.category.name}</DefaultCellValue>,
        width: 15,
    },
    {
        id: 'status',
        Header: () => <div className='py-2'>Status</div>,
        Cell: ({ value }) => {
            return (
                <DefaultCellValue>
                    <RASStatusView status={value.status} short />
                </DefaultCellValue>
            )
        },
        width: 15,
    },
    {
        id: 'poc',
        Header: () => <div className='py-2'>POC</div>,
        Cell: ({ value }) => (
            <DefaultCellValue>
                {value.status === RASStatus.NotApplicable ? '-' : `${value.poc}%`}
            </DefaultCellValue>
        ),
        width: 5,
    },
    {
        id: 'asignee',
        Header: () => <div className='py-2'>Assigned To</div>,
        Cell: ({ value }) => (
            <DefaultCellValue>
                {value.status === RASStatus.NotApplicable ? '-' : `${value.user?.code ?? '-'}`}
            </DefaultCellValue>
        ),
        width: 10,
    },
    {
        id: 'remarks',
        Header: () => <div className='py-2'>Remarks</div>,
        Cell: ({ value }) => {
            const existingRemarks = value.remarks.filter(remark => !remark.isDeleted)
            return (
                <DefaultCellValue align='left'>
                    <div className='flex items-center py-1 truncate text-ellipsis overflow-hidden'>
                        <Badge
                            icon={mdiCommentOutline}
                            className='text-atamblue-300'
                            contentClassName='pl-1 bg-white text-xs'
                        >
                            {existingRemarks.length}
                        </Badge>
                        <div className='ml-3'>
                            {existingRemarks.length > 0
                                ? existingRemarks[existingRemarks.length - 1].content
                                : null}
                        </div>
                    </div>
                </DefaultCellValue>
            )
        },
        width: 25,
    },
]

export const ListRAS: React.FC<{
    projectId: Project['id']
    subjectId: ProjectSubject['id']
}> = props => {
    const { projectId, subjectId } = props
    const { data: RASList } = useListRASQuery({
        projectId: props.projectId,
        subjectId: props.subjectId,
    })
    const [exportXlsx, exportXlsxMeta] = useExportRASXlsxMutation()
    const [exportPdf, exportPdfMeta] = useExportRASPdfMutation()
    const [exportHtml, exportHtmlMeta] = useExportRASHtmlMutation()

    const rasColumns = useMemo(() => getRASColumns({ projectId, subjectId }), [
        projectId,
        subjectId,
    ])
    if (!RASList) {
        return null
    }

    return (
        <>
            <Table
                headerClassName='py-3'
                rowClassName={() => 'py-0.5'}
                columns={rasColumns}
                data={RASList}
                emptyFallback={() => <EmptyResponse>No subparts</EmptyResponse>}
                tableActions={() => (
                    <div className='flex items-center ml-12 mb-2'>
                        <ForProjectRole
                            permission={ProjectRolePermissions.RASExportXls}
                            projectId={projectId}
                        >
                            <ExportXlsButton
                                isLoading={exportXlsxMeta.isLoading}
                                onClick={() =>
                                    exportXlsx({ projectId, subjectId })
                                        .unwrap()
                                        .then(({ path }) => openFile(path))
                                        .catch(noop)
                                }
                            />
                        </ForProjectRole>
                        <ForProjectRole
                            permission={ProjectRolePermissions.RASExportPdf}
                            projectId={projectId}
                        >
                            <ExportPdfButton
                                isLoading={exportPdfMeta.isLoading}
                                onClick={() =>
                                    exportPdf({ projectId, subjectId })
                                        .unwrap()
                                        .then(({ path }) => openFile(path))
                                        .catch(noop)
                                }
                            />
                        </ForProjectRole>
                        <ForProjectRole
                            permission={ProjectRolePermissions.RASExportStatic}
                            projectId={projectId}
                        >
                            <ExportHtmlButton
                                isLoading={exportHtmlMeta.isLoading}
                                onClick={() =>
                                    exportHtml({ projectId, subjectId })
                                        .unwrap()
                                        .then(({ path }) => openFile(path))
                                        .catch(noop)
                                }
                            />
                        </ForProjectRole>
                    </div>
                )}
            />

            <AddRASSubpart projectId={projectId} subjectId={subjectId} />
        </>
    )
}
