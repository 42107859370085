import React from 'react'

import { useDeleteTemplateMutation } from '../../api/for-company/templates.api'
import { ConfirmationModal } from '../../atoms/Modal/ConfirmationModal'
import { Template } from '../../domain/template.domain'

export const DeleteTemplate: React.FC<{ template: Template; onDone: () => void }> = props => {
    const [deleteTemplate] = useDeleteTemplateMutation()

    return (
        <ConfirmationModal
            message={
                <>
                    Are you sure you want to delete template
                    <span className='ml-2 text-atamsky-700'>{props.template.name}</span>?
                </>
            }
            onConfirm={() => deleteTemplate({ id: props.template.id }).then(props.onDone)}
            onCancel={props.onDone}
        >
            {props.children}
        </ConfirmationModal>
    )
}
