import { mdiDialpad } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

import { Menu } from '../Menu'
import { Tooltip } from '../Tooltip'

export const HeaderMenu: React.FC<{
    body: (args: { close: () => void }) => JSX.Element
    className?: string
}> = props => {
    return (
        <Menu
            body={() => (
                <Tooltip text='Items Menu'>
                    <Icon
                        className='rotate-180 text-atamblue-300 hover:text-atamsky-900 hover:rotate-0'
                        path={mdiDialpad}
                        size={0.75}
                    />
                </Tooltip>
            )}
            className={classNames('bg-ebony-100 bg-opacity-90', props.className)}
            render={props.body}
        />
    )
}
