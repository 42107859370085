import { z } from 'zod'

export const SubpartSchema = z.object({
    id: z.number(),
    name: z.string(),
    order: z.number(),
    categoryId: z.number(),
})
export type Subpart = z.TypeOf<typeof SubpartSchema>

export const CategorySchema = z.object({
    id: z.number(),
    name: z.string(),
    subjectId: z.number(),
    order: z.number(),
    subparts: z.array(SubpartSchema),
})
export type Category = z.TypeOf<typeof CategorySchema>

export const SubjectSchema = z.object({
    id: z.number(),
    name: z.string(),
    isPhysical: z.boolean(),
    categories: z.array(CategorySchema),
    _count: z.object({ templates: z.number() }),
})
export type Subject = z.TypeOf<typeof SubjectSchema>

export const SubjectFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    isPhysical: z.boolean(),
})
export type SubjectFormType = z.TypeOf<typeof SubjectFormSchema>
