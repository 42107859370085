import { z } from 'zod'

import { AccountTypeSchema } from './account-type.domain'
import { CompanySchema } from './company.domain'

const BaseProfileSchema = z.object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    code: z.string(),
})
export const UserProfileSchema = BaseProfileSchema.merge(
    z.object({
        isSuperadmin: z.literal(false),
        companyId: z.string(),
        accountType: AccountTypeSchema.pick({ name: true, permissions: true }),
        company: CompanySchema.pick({
            id: true,
            name: true,
            code: true,
        }),
    }),
)
export type UserProfile = z.TypeOf<typeof UserProfileSchema>

export const SuperadminProfileSchema = BaseProfileSchema.merge(
    z.object({
        isSuperadmin: z.literal(true),
        companyId: z.null(),
    }),
)
export type SuperadminProfile = z.TypeOf<typeof SuperadminProfileSchema>

export const ProfileSchema = z.union([UserProfileSchema, SuperadminProfileSchema])
export type Profile = z.TypeOf<typeof ProfileSchema>
