import { mdiCheck, mdiClose } from '@mdi/js'
import Tippy from '@tippyjs/react/headless'
import classNames from 'classnames'
import React, { useState } from 'react'

import { Card } from '../../Card'
import { IconButton } from '../../IconButton'
import { Separator } from '../../Separator'

type BaseFilterInputProps = {
    icon: JSX.Element
    applyButton?: JSX.Element
    cancelButton?: JSX.Element
    className?: string
    style?: React.CSSProperties
    onApply: () => void
    onCancel?: () => void
}

export const BaseFilterInput: React.FC<BaseFilterInputProps> = props => {
    const [shown, setShown] = useState(false)
    const applyButton = props.applyButton ?? null
    const cancelButton = props.cancelButton ?? null

    const show = () => setShown(true)
    const hide = () => setShown(false)

    return (
        <div
            className={classNames('flex flex-col items-center mt-2 z-10', props.className)}
            style={props.style}
        >
            <Tippy
                interactive
                placement='bottom'
                render={() => (
                    <Card className='px-4 border border-atamsky-300'>
                        <div className='flex items-center justify-around'>
                            {props.children}
                            <IconButton
                                path={mdiCheck}
                                isSubmit
                                className='w-12 h-12 text-green-300 hover:text-green-500'
                                onClick={() => {
                                    props.onApply()
                                    hide()
                                }}
                            >
                                {applyButton}
                            </IconButton>
                            <Separator className='w-px h-8 bg-atamsky-500' />
                            <IconButton
                                path={mdiClose}
                                isSubmit
                                className='w-12 h-12 text-red-400 hover:text-red-500'
                                onClick={() => {
                                    props.onCancel && props.onCancel()
                                    hide()
                                }}
                            >
                                {cancelButton}
                            </IconButton>
                        </div>
                    </Card>
                )}
                visible={shown}
                onClickOutside={hide}
            >
                <div onClick={show}>{props.icon}</div>
            </Tippy>
        </div>
    )
}
