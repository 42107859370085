import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AccountTypePermissions } from '../domain/account-type.domain'
import { Company } from '../domain/company.domain'
import { Profile } from '../domain/profile.domain'
import { RootState } from '../store'

const TOKEN_LS_KEY = 'auth_token'

export type AuthSlice = {
    token: string | undefined
    isSuperadmin: boolean
    companyId: Company['id'] | undefined
    company: Pick<Company, 'id' | 'code' | 'name'> | undefined
    accountType: { name: string; permissions: AccountTypePermissions[] }
    logoutReason: 'inactivity' | 'user-action' | undefined
}

const loginThunk = createAsyncThunk<string, string>('auth/login', async token => {
    localStorage.setItem(TOKEN_LS_KEY, token)
    return token
})

const logoutThunk = createAsyncThunk('auth/logout', async () => {
    localStorage.removeItem(TOKEN_LS_KEY)
})

const initialState = ((): AuthSlice => {
    const token = localStorage.getItem(TOKEN_LS_KEY) ?? undefined
    const companyId = token ? token.split(':')[0] : undefined
    const isSuperadmin = companyId === 'superadmin'
    return {
        token,
        isSuperadmin,
        companyId: isSuperadmin ? undefined : companyId,
        accountType: { name: '', permissions: [] },
        logoutReason: undefined,
        company: undefined,
    }
})()

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
            const companyId = action.payload ? action.payload.split(':')[0] : undefined
            const isSuperadmin = companyId === 'superadmin'
            state.isSuperadmin = isSuperadmin
            state.companyId = isSuperadmin ? undefined : companyId
            state.logoutReason = undefined
        },
        logoutRaw: state => {
            state.token = undefined
            state.isSuperadmin = false
            state.companyId = undefined
            state.logoutReason = 'user-action'
        },
        logoutByInactivity: state => {
            state.token = undefined
            state.isSuperadmin = false
            state.companyId = undefined
            state.logoutReason = 'inactivity'
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setProfile: (state, action: PayloadAction<Profile>) => {
            state.logoutReason = undefined
            if (!action.payload.isSuperadmin) {
                state.accountType = action.payload.accountType
                state.company = action.payload.company
            }
        },
        changeCompany: (state, action: PayloadAction<Company | undefined>) => {
            state.companyId = action.payload?.id
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.token = action.payload
            })
            .addCase(logoutThunk.fulfilled, state => {
                state.token = undefined
                state.isSuperadmin = false
                state.companyId = undefined
            })
    },
})

export const authActions = {
    ...authSlice.actions,
    login: loginThunk,
    logout: logoutThunk,
}

const getAuthSlice = (state: RootState) => state.auth

export const selectToken = createSelector(getAuthSlice, auth => auth.token)
export const selectIsSuperadmin = createSelector(getAuthSlice, auth => auth.isSuperadmin)
export const selectCurrentCompanyId = createSelector(getAuthSlice, auth => auth.companyId)
export const selectAccountType = createSelector(getAuthSlice, auth => auth.accountType)
export const selectLogoutReason = createSelector(getAuthSlice, auth => auth.logoutReason)

export const selectCurrentCompany = createSelector(getAuthSlice, auth => auth.company)
