import {
    mdiChevronDoubleLeft,
    mdiChevronDoubleRight,
    mdiChevronLeft,
    mdiChevronRight,
} from '@mdi/js'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import Select from 'react-select'

import { IconAction } from '../atoms/IconAction'

import { noop } from './utils'

const range = (min: number, max: number) => {
    return Array.from({ length: max - min + 1 }, (x, i) => i + min)
}

const perPageOptions = [
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 500, value: 500 },
]
const defaultPerPage = perPageOptions[0]
export const PageSelector: React.FC<{
    value: number
    onChange: (newPerPage: number) => void
}> = props => {
    const value = useMemo(() => perPageOptions.find(option => option.value === props.value), [
        props.value,
    ])
    return (
        <div className='flex items-center'>
            <div className='mr-3'>Show per page:</div>
            <Select
                isSearchable={false}
                defaultValue={defaultPerPage}
                value={value}
                options={perPageOptions}
                className='w-20'
                onChange={newPerPage => props.onChange(newPerPage?.value ?? defaultPerPage.value)}
            />
        </div>
    )
}

export const Paginator: React.FC<{
    currentPage: number
    totalPages: number
    perPage: number
    maxPageOptions?: number
    onJumpToPage: (page: number) => void
    onChangePageSize: (perPage: number) => void
}> = props => {
    const { currentPage, totalPages, onJumpToPage } = props
    const maxPageOptions = props.maxPageOptions ?? 3

    const min = Math.max(currentPage - maxPageOptions, 1)
    const max = Math.min(currentPage + maxPageOptions, totalPages)
    const pageOptions = range(min, max)
    const iconsStyle =
        'flex-none w-12 h-12 flex items-center justify-center cursor-pointer hover:text-atamsky-900'

    if (props.totalPages < 2) {
        return null
    }

    return (
        <div className='flex justify-end items-center mr-5 text-sm text-atamblue-300'>
            <PageSelector
                value={props.perPage}
                onChange={newPageSize => {
                    props.onJumpToPage(1)
                    props.onChangePageSize(newPageSize)
                }}
            />
            <div className='ml-5 flex'>
                {1 < currentPage ? (
                    <>
                        <IconAction
                            className={iconsStyle}
                            onClick={() => onJumpToPage(1)}
                            path={mdiChevronDoubleLeft}
                            size={0.75}
                        />

                        <IconAction
                            className={iconsStyle}
                            onClick={() => onJumpToPage(currentPage - 1)}
                            path={mdiChevronLeft}
                            size={0.75}
                        />
                    </>
                ) : null}
                {1 < min ? (
                    <div className='flex-none w-12 h-12 flex items-center justify-center'>...</div>
                ) : null}
                {pageOptions.map(pageNumber => {
                    const isCurrentPage = pageNumber === currentPage
                    return (
                        <div
                            key={pageNumber}
                            className={classNames(
                                'flex-none w-12 h-12 flex items-center justify-center',
                                {
                                    'font-bold text-atamblue-700': isCurrentPage,
                                    'cursor-pointer': !isCurrentPage,
                                    'hover:text-atamsky-900': !isCurrentPage,
                                },
                            )}
                            onClick={() => (!isCurrentPage ? onJumpToPage(pageNumber) : noop)}
                        >
                            {pageNumber}
                        </div>
                    )
                })}
                {max < totalPages ? (
                    <div className='flex-none w-12 h-12 flex items-center justify-center'>...</div>
                ) : null}
                {currentPage < totalPages ? (
                    <>
                        <IconAction
                            className={iconsStyle}
                            onClick={() => onJumpToPage(currentPage + 1)}
                            path={mdiChevronRight}
                            size={0.75}
                        />

                        <IconAction
                            className={iconsStyle}
                            onClick={() => onJumpToPage(totalPages)}
                            path={mdiChevronDoubleRight}
                            size={0.75}
                        />
                    </>
                ) : null}
            </div>
        </div>
    )
}
