import { mdiCalendar, mdiCalendarToday, mdiFilter } from '@mdi/js'
import Icon from '@mdi/react'
import { format } from 'date-fns'
import React, { useState, useEffect } from 'react'

import { DateInput } from '../../Form/DateInput'
import { IconButton } from '../../IconButton'
import { Pill } from '../../Pill'

import { BaseFilterInput } from './_BaseFilter'

export type DateInterval = { from: Date | undefined; to: Date | undefined }
export type StrictDateInterval = { from: Date; to: Date }

export const DateFilter: React.FC<{
    value?: DateInterval
    className?: string
    style?: React.CSSProperties
    onChange: (date: StrictDateInterval) => void
}> = props => {
    const filterValue = props.value ?? { from: undefined, to: undefined }

    const [from, setFrom] = useState<Date | undefined>(filterValue.from)
    const [to, setTo] = useState<Date | undefined>(filterValue.to)

    useEffect(() => {
        setFrom(filterValue.from)
        setTo(filterValue.to)
    }, [filterValue.from, filterValue.to])

    return (
        <BaseFilterInput
            icon={
                <IconButton
                    className='text-atamblue-300 hover:text-atamsky-900 focus:outline-none'
                    path={mdiFilter}
                    title='Filter by date'
                    size={1}
                />
            }
            onApply={() => {
                if (from !== undefined && to !== undefined) {
                    props.onChange({ from, to })
                }
            }}
        >
            <div className='grid grid-cols-2 gap-6 py-2 text-sm'>
                <DateInput
                    value={from}
                    label={'From'}
                    icon={
                        <Icon path={mdiCalendarToday} size={1} className='mt-2 text-atamblue-500' />
                    }
                    className='flex px-2 border-b border-atamsky-900'
                    labelClassName='text-atamsky-900'
                    onChange={setFrom}
                />
                <DateInput
                    minDate={from !== undefined ? new Date(from) : undefined}
                    value={to}
                    label={'To'}
                    icon={<Icon path={mdiCalendar} size={1} className='mt-2 text-atamblue-500' />}
                    className='flex px-2 border-b border-atamsky-900'
                    labelClassName='text-atamsky-900'
                    onChange={setTo}
                />
            </div>
        </BaseFilterInput>
    )
}

export const DateActiveFilter: React.FC<{
    label: string
    value: StrictDateInterval
    onClear: () => void
}> = props => {
    return (
        <Pill onClose={props.onClear}>
            <div className='text-atamblue-300 mr-1'>{props.label}</div>
            <div className='text-atamblue-700'>{format(props.value.from, 'dd-MMM-yyyy')}</div>
            <div className='text-atamblue-300 mx-1'>to</div>
            <div className='text-atamblue-700'>{format(props.value.to, 'dd-MMM-yyyy')}</div>
        </Pill>
    )
}
