import React from 'react'

import logo from '../assets/logo-with-tagline.svg'

import './Unauthenticated.css'

export const UnauthenticatedLayout: React.FC = props => {
    return (
        <div className='unauthenticated-bg bg-right bg-cover min-h-screen text-atamblue-900'>
            <header className='header flex flex-col'>
                <img src={logo} className='logo pointer-events-none h-48 mt-16 mb-10' alt='logo' />
            </header>
            <div className='max-w-lg mx-auto flex flex-col items-center bg-white bg-opacity-75 p-10 rounded-2xl'>
                {props.children}
            </div>
        </div>
    )
}
