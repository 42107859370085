import { useCallback } from 'react'
import { Control, useFieldArray, useFormContext } from 'react-hook-form'

import {
    //     hasCategorySubpartSelected,
    //     hasSubjectSubpartSelected,
    Template,
    TemplateCategory,
    TemplateSubject,
    TemplateSubpart,
} from '../../domain/template.domain'

const getSubjectsPath = <T,>(prefix: T) => prefix
const getSubjectPath = <T,>(prefix: T, subjectIndex: number) =>
    `${getSubjectsPath(prefix)}.${subjectIndex}` as const
const getCategoriesPath = <T,>(prefix: T, subjectIndex: number) =>
    `${getSubjectPath(prefix, subjectIndex)}.categories` as const
const getCategoryPath = <T,>(prefix: T, subjectIndex: number, categoryIndex: number) =>
    `${getCategoriesPath(prefix, subjectIndex)}.${categoryIndex}` as const
const getSubpartsPath = <T,>(prefix: T, subjectIndex: number, categoryIndex: number) =>
    `${getCategoryPath(prefix, subjectIndex, categoryIndex)}.subparts` as const
const getSubpartPath = <T,>(
    prefix: T,
    subjectIndex: number,
    categoryIndex: number,
    subpartIndex: number,
) => `${getSubpartsPath(prefix, subjectIndex, categoryIndex)}.${subpartIndex}` as const

export type FormSubpart = {
    id: number
    name: string
    order: number
    subpartKey?: string
    isSelected?: boolean
    isNew?: boolean
}
export type FormCategory = {
    id: number
    name: string
    order: number
    categoryKey?: string
    isExpanded?: boolean
    isNew?: boolean
    subparts: FormSubpart[]
}
export type FormSubject = {
    id: number
    name: string
    isPhysical: boolean
    subjectKey?: string
    isExpanded?: boolean
    isNew?: boolean
    categories: FormCategory[]
}
type ProjectForm = any
type ProjectFormControl = Control<ProjectForm>

export const getCategorySubparts = (category: FormCategory): FormSubpart[] => category.subparts
export const getSubjectSubparts = (subject: FormSubject): FormSubpart[] =>
    subject.categories.flatMap(getCategorySubparts)
export const getSubjectsSubparts = (subjects: FormSubject[]): FormSubpart[] =>
    subjects.flatMap(getSubjectSubparts)

export const isSubpartNew = (subpart: FormSubpart): boolean => !!subpart.isNew
export const isSubpartSelected = (subpart: FormSubpart): boolean =>
    !subpart.isNew && !!subpart.isSelected
export const isSubpartEnabled = (subpart: FormSubpart): boolean =>
    !!subpart.isNew || !!subpart.isSelected

export const hasSubjectSubpartEnabled = (subject: FormSubject): boolean =>
    getSubjectSubparts(subject).some(isSubpartEnabled)
export const hasCategorySubpartSelected = (category: FormCategory): boolean =>
    getCategorySubparts(category).some(isSubpartSelected)
export const hasCategorySubpartEnabled = (category: FormCategory): boolean =>
    getCategorySubparts(category).some(isSubpartEnabled)

export const isCategoryEnabled = (category: FormCategory): boolean => {
    const subparts = getCategorySubparts(category)
    return subparts.length > 0 && (subparts.every(isSubpartNew) || subparts.some(isSubpartSelected))
}

export const isSubjectEnabled = (subject: FormSubject): boolean => {
    const subparts = getSubjectSubparts(subject)
    return subparts.length > 0 && (subparts.every(isSubpartNew) || subparts.some(isSubpartSelected))
}

export const useSubparts = <T,>({
    control,
    subjectIndex,
    categoryIndex,
    prefix,
}: {
    control: ProjectFormControl
    subjectIndex: number
    categoryIndex: number
    prefix: T
}) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: getSubpartsPath(prefix, subjectIndex, categoryIndex) as never,
        keyName: 'subpartKey',
        shouldUnregister: false,
    })
    const subparts = (fields as unknown) as FormSubpart[]
    return {
        subparts,
        getPath: getSubpartPath,
        getCollectionPath: getSubpartsPath,
        create: useCallback(
            (subpart: Omit<FormSubpart, 'id' | 'order' | 'categoryId' | 'subpartKey'>) => {
                append({ ...subpart, isNew: true })
            },
            [append],
        ),
        edit: useCallback(
            (
                subpartIndex: number,
                subpart: Omit<FormSubpart, 'id' | 'order' | 'categoryId' | 'subpartKey'>,
            ) => {
                update(subpartIndex, subpart)
            },
            [update],
        ),
        remove: useCallback(
            (subpartIndex: number) => {
                remove(subpartIndex)
            },
            [remove],
        ),
    }
}

export const useCategories = <T,>({
    control,
    subjectIndex,
    prefix,
}: {
    control: ProjectFormControl
    subjectIndex: number
    prefix: T
}) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: getCategoriesPath(prefix, subjectIndex) as never,
        keyName: 'categoryKey',
        shouldUnregister: false,
    })
    const categories = (fields as unknown) as FormCategory[]
    return {
        categories,
        getPath: getCategoryPath,
        toggleAllSubparts: (index: number, c: FormCategory, isSelected: boolean) => {
            update(index, {
                ...c,
                isExpanded: isSelected,
                subparts: c.subparts.map(s => {
                    if (s.isNew) {
                        return s
                    }
                    return {
                        ...s,
                        isSelected,
                    }
                }),
            })
        },
        create: useCallback(
            (category: Pick<FormCategory, 'name'>) => {
                append({ ...category, isExpanded: true, subparts: [], isNew: true })
            },
            [append],
        ),
        edit: useCallback(
            (
                categoryIndex: number,
                oldCategory: FormCategory,
                category: Pick<FormCategory, 'name'>,
            ) => {
                update(categoryIndex, { ...oldCategory, ...category })
            },
            [update],
        ),
        remove: useCallback(
            (categoryIndex: number) => {
                remove(categoryIndex)
            },
            [remove],
        ),
    }
}

export const useSubjects = ({
    control,
    prefix,
}: {
    control: ProjectFormControl
    prefix: string
}) => {
    const { fields, append, remove, update, replace } = useFieldArray({
        control,
        name: getSubjectsPath(prefix) as never,
        keyName: 'subjectKey',
        shouldUnregister: false,
    })
    const subjects = (fields as unknown) as FormSubject[]
    return {
        subjects,
        getPath: getSubjectPath,
        toggleAllSubparts: (index: number, s: FormSubject, isSelected: boolean) => {
            update(index, {
                ...s,
                isExpanded: isSelected,
                categories: s.categories.map(c => ({
                    ...c,
                    isExpanded: isSelected,
                    subparts: c.subparts.map(s => ({
                        ...s,
                        isSelected,
                    })),
                })),
            })
        },
        create: useCallback(
            (subject: Pick<FormSubject, 'name' | 'isPhysical'>) => {
                append({ ...subject, categories: [], isNew: true, isExpanded: true })
            },
            [append],
        ),
        add: useCallback(
            (subject: Pick<FormSubject, 'id' | 'name' | 'isPhysical' | 'categories'>) => {
                append(subject)
            },
            [append],
        ),
        edit: useCallback(
            (subjectIndex: number, oldSubject: FormSubject, subject: Pick<FormSubject, 'name'>) => {
                update(subjectIndex, { ...oldSubject, ...subject })
            },
            [update],
        ),
        remove: useCallback(
            (subjectIndex: number) => {
                remove(subjectIndex)
            },
            [remove],
        ),
        setTemplate: useCallback(
            (template: Template) => {
                replace(
                    template.subjects.map(subject => {
                        const isExpanded = hasSubjectSubpartEnabled(subject)
                        return {
                            ...subject,
                            isExpanded,
                            categories: subject.categories.map(category => {
                                const isExpanded = hasCategorySubpartEnabled(category)
                                return {
                                    ...category,
                                    isExpanded,
                                }
                            }),
                        }
                    }),
                )
            },
            [replace],
        ),
    }
}
