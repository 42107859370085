import React from 'react'
import { useSelector } from 'react-redux'

import { selectProjectById } from '../api/for-company/projects.api'
import { ForProjectRole } from '../components/Auth/ForProjectRole'
import { ToolbarActions } from '../components/Drawers/Toolbar'
import { AircraftInformationDrawer } from '../components/Projects/AircraftInformation/AircraftInformationDrawer'
import { ListRAS } from '../components/RAS/ListRAS'
import { ProjectRolePermissions } from '../domain/project-role.domain'
import { routes } from '../routes'
import { useAppSelector } from '../store'

import { Page } from './shared/Page'

export const RASPage: React.FC = () => {
    const { projectId, subjectId } = routes.RAS.useParams()
    const project = useSelector(selectProjectById(projectId))

    if (!project) {
        return null
    }

    return (
        <ForProjectRole permission={ProjectRolePermissions.RASListSubparts} projectId={projectId}>
            <Page logo={project.auditorLogo}>
                <ListRAS projectId={projectId} subjectId={subjectId} />
            </Page>
            <ToolbarActions>
                <ForProjectRole
                    permission={ProjectRolePermissions.AircraftInformationView}
                    projectId={projectId}
                >
                    <AircraftInformationDrawer projectId={projectId} />
                </ForProjectRole>
            </ToolbarActions>
        </ForProjectRole>
    )
}
