import { zodResolver } from '@hookform/resolvers/zod'
import cn from 'classnames'
import { noop } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { useLoginMutation } from '../../api/for-public.api'
import { LoadingSpinner } from '../../atoms/LoadingSpinner'
import { ServerError } from '../../domain/common'
import { useTranslation } from '../../lib/i18n'
import { routes } from '../../routes'
import { authActions, selectLogoutReason } from '../../state/auth.slice'
import { useAppSelector } from '../../store'

const LoginFormSchema = z.object({
    companyCode: z.string().min(1, 'Required'),
    email: z.string().email('A valid email address is required'),
    password: z.string().min(1, 'Required'),
})
type LoginForm = z.TypeOf<typeof LoginFormSchema>

const getErrorMessage = (error: ServerError): string => {
    if (error.data.name === 'InvalidCredentials') {
        return 'Invalid credentials'
    }
    return ''
}

export const Login: React.FC = () => {
    const { t } = useTranslation('login')
    const dispatch = useDispatch()

    const logoutReason = useAppSelector(selectLogoutReason)
    const [login, { isLoading, isSuccess, error }] = useLoginMutation()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>({ resolver: zodResolver(LoginFormSchema) })

    const onLogin = async (credentials: LoginForm) => {
        try {
            const { token } = await login(credentials).unwrap()
            return dispatch(authActions.setToken(token))
        } catch (error) {
            noop()
        }
    }

    return (
        <form className='w-full p-8' onSubmit={handleSubmit(onLogin)}>
            <h2 className='text-3xl font-semibold text-center mb-12'>{t('Welcome')}</h2>

            {error ? (
                <div className='errorMessage text-red-500'>
                    {t('ServerError')} {getErrorMessage(error as any)}
                </div>
            ) : logoutReason ? (
                <div className='errorMessage text-red-500'>
                    {logoutReason === 'inactivity' ? 'You have been logged out' : null}
                </div>
            ) : null}

            {isSuccess ? (
                <div>{t('Success')}</div>
            ) : (
                <div className='formFields flex flex-col'>
                    <input
                        className='appearance-none bg-transparent leading-5 focus:outline-none mb-1 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        type='text'
                        placeholder={t('CompanyCode')}
                        autoFocus
                        {...register('companyCode')}
                    />
                    {errors.companyCode ? (
                        <div className='errorMessage text-sm text-red-500'>
                            {errors.companyCode.message}
                        </div>
                    ) : null}
                    <input
                        className='appearance-none bg-transparent leading-5 focus:outline-none mb-1 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        type='email'
                        placeholder={t('YourEmail')}
                        {...register('email')}
                    />
                    {errors.email ? (
                        <div className='errorMessage text-sm text-red-500'>
                            {errors.email.message}
                        </div>
                    ) : null}
                    <input
                        className='appearance-none bg-transparent leading-5 focus:outline-none mb-1 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                        type='password'
                        placeholder={t('Password')}
                        {...register('password')}
                    />
                    {errors.password ? (
                        <div className='errorMessage text-sm text-red-500'>
                            {errors.password.message}
                        </div>
                    ) : null}

                    <div className='flex'>
                        <Link
                            to={routes.ForgotPassword.build()}
                            className='mt-2 mb-6 transition duration-500 ml-auto text-sm text-atamblue-500 hover:text-atamsky-900'
                        >
                            {t('ForgotPassword')}
                        </Link>
                    </div>

                    <button
                        disabled={isLoading}
                        className={cn(
                            'w-3/4 h-12 m-auto transition duration-500 py-2 rounded-2xl bg-atamsky-900 shadow-md text-white hover:bg-atamsky-700 focus:outline-none',
                            {
                                'opacity-50': isLoading,
                            },
                        )}
                    >
                        {isLoading ? <LoadingSpinner className='mr-2' center /> : t('SignIn')}
                    </button>
                </div>
            )}
        </form>
    )
}
