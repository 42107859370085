import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useGetProfileQuery as useGetUserProfileQuery } from '../../api/for-company/profile.api'
import { useGetProfileQuery as useGetSuperadminProfileQuery } from '../../api/for-superadmin/profile.api'
import { ServerError } from '../../domain/common'
import { LoginPage } from '../../pages/Login.page'
import { ServerErrorPage } from '../../pages/ServerError.page'
import { authActions, selectToken } from '../../state/auth.slice'
import { useAppDispatch, useAppSelector } from '../../store'
import { Navigation } from '../Drawers/Navigation/Navigation'

const ProfileManager: React.FC = props => {
    const token = useAppSelector(selectToken)
    const dispatch = useAppDispatch()

    const companyId = token ? token.split(':')[0] : ''
    const isSuperadmin = companyId === 'superadmin'

    const userProfile = useGetUserProfileQuery(undefined, { skip: isSuperadmin })
    const superadminProfile = useGetSuperadminProfileQuery(undefined, { skip: !isSuperadmin })

    const profile = superadminProfile.data ? superadminProfile.data : userProfile.data
    const isSuccess = superadminProfile.isSuccess || userProfile.isSuccess
    const error = (superadminProfile.error ?? userProfile.error) as ServerError | undefined
    const isGeneralServerError = error && error.status === 'FETCH_ERROR'

    useEffect(() => {
        if (profile) {
            dispatch(authActions.setProfile(profile))
        }
    }, [dispatch, profile])

    if (isSuccess) {
        return <>{props.children}</>
    }
    if (isGeneralServerError) {
        return <ServerErrorPage />
    }
    return null
}

export const ForAuthenticated: React.FC = () => {
    const token = useAppSelector(selectToken)

    useEffect(() => {
        if (token) {
            localStorage.setItem('auth_token', token)
        }
    }, [token])

    if (token) {
        return (
            <ProfileManager>
                <Navigation>
                    <Outlet />
                </Navigation>
            </ProfileManager>
        )
    } else {
        return <LoginPage />
    }
}
