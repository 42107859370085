import React from 'react'

import { useCreateCompanyMutation } from '../../api/for-superadmin/company.api'
import { ServerError } from '../../domain/common'
import { CompanyFormType } from '../../domain/company.domain'

import { CompanyForm } from './CompanyForm'

export const CreateCompany: React.FC<{
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [createCompany, createCompanyStatus] = useCreateCompanyMutation()

    const onSave = (company: CompanyFormType) => createCompany(company).unwrap().then(props.onSave)

    return (
        <div>
            <CompanyForm
                isSaving={createCompanyStatus.isLoading}
                error={createCompanyStatus.error as ServerError}
                onSave={onSave}
                onCancel={props.onCancel}
            />
        </div>
    )
}
