import React from 'react'

import { useCreateTemplateMutation } from '../../api/for-company/templates.api'
import { TemplateFormType } from '../../domain/template.domain'

import { TemplateForm } from './TemplateForm'

export const CreateTemplate: React.FC<{
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [createTemplate, { isLoading }] = useCreateTemplateMutation()

    const onSave = (editedTemplate: TemplateFormType) => {
        return createTemplate({
            name: editedTemplate.name,
            subparts: {
                add: editedTemplate.subjects
                    .flatMap(subject => subject.categories.flatMap(category => category.subparts))
                    .filter(subpart => subpart.isSelected)
                    .map(subpart => subpart.id),
                remove: [],
            },
        })
            .unwrap()
            .then(props.onSave)
    }

    return <TemplateForm isSaving={isLoading} onSave={onSave} onCancel={props.onCancel} />
}
