import classNames from 'classnames'
import { noop, omit, range } from 'lodash'
import React, { useState, useEffect, useReducer } from 'react'

import { ListResponse } from '../../api/common'
import { Paginator } from '../../common/Paginator'
import { Card } from '../Card'

import { ActiveFilters } from './ActiveFilters'
import { setToggledInArray, setToggledAllInArray } from './utils'

export type TableHeaderArgs<F> = {
    page: number
    setPage: (perPage: number) => void
    perPage: number
    setPerPage: (perPage: number) => void
    orderDirection?: 'asc' | 'desc'
    orderBy?: keyof F
    setOrderBy: (orderBy: keyof F) => void
    filters: Partial<F>
    setFilter: (value: Partial<F>) => void

    allCellsExpanded: boolean
    allCellsSelected: boolean
    toggleExpandAllCells: () => void
    toggleSelectAllCells: () => void
}
export type TableCellArgs<T> = {
    value: T
    isExpanded: boolean
    isSelected: boolean
    toggleExpanded: (expansion?: boolean) => void
    toggleSelected: (selected?: boolean) => void
}

export type TableHeaderSortArgs = {
    direction?: 'asc' | 'desc'
    toggleDirection: () => void
}
export type TableHeaderFilterArgs<F> = {
    filters: Partial<F>
    setFilter: (value: Partial<F>) => void
}
export type TableHeaderActiveFilterArgs = {
    value: unknown
    clearFilter: () => void
}
export type TableColumn<T, F> = {
    id: string
    Header: React.FC<TableHeaderArgs<F>>
    Sort?: React.FC<TableHeaderSortArgs>
    Cell: React.FC<TableCellArgs<T>>
    Filter?: React.FC<TableHeaderFilterArgs<F>>
    ActiveFilter?: React.FC<TableHeaderActiveFilterArgs>
    width: number
}

export type PSF<F> = {
    page: number
    perPage: number
    orderBy?: keyof F
    orderDirection?: 'asc' | 'desc'
} & Partial<F>
type PSFActions<F> =
    | { type: 'ChangePage'; payload: number }
    | { type: 'ChangePerPage'; payload: number }
    | { type: 'ChangeOrder'; payload: keyof F }
    | { type: 'ChangeFilter'; payload: Partial<F> }
    | { type: 'ClearFilter'; payload: keyof F }

type PSFReducer<F> = (state: PSF<F>, action: PSFActions<F>) => PSF<F>

const psfReducer = <F,>(state: PSF<F>, action: PSFActions<F>): PSF<F> => {
    switch (action.type) {
        case 'ChangePage':
            return { ...state, page: action.payload }
        case 'ChangePerPage':
            return { ...state, perPage: action.payload }
        case 'ChangeFilter':
            return { ...state, ...action.payload }
        case 'ChangeOrder':
            // eslint-disable-next-line no-case-declarations
            const orderDirection =
                state.orderBy === action.payload
                    ? state.orderDirection === 'asc'
                        ? ('desc' as const)
                        : ('asc' as const)
                    : ('asc' as const)
            return { ...state, orderBy: action.payload, orderDirection }
        case 'ClearFilter':
            return omit(state, action.payload) as PSF<F>
        default:
            return state
    }
}

export type TableState<F> = {
    expandedCells: number[]
    selectedCells: number[]
    psf: PSF<F>
}

// TODO: add an indicator on isLoading and isFetching
export const Table = <T, F>(props: {
    columns: TableColumn<T, F>[]
    data: ListResponse<T>
    className?: string
    headerClassName?: string
    rowClassName?: (
        entry: T,
        state: { isExpanded: boolean; isSelected: boolean },
        index: number,
    ) => string
    tableActions?: (props: TableState<F>) => React.ReactNode
    additionalFilters?: {
        [K in keyof Partial<F>]: (args: { value: F[K]; clearFilter: () => void }) => React.ReactNode
    }
    emptyFallback?: () => React.ReactNode
    onRowClick?: (entry: T, index: number) => void
    onChange?: (psf: PSF<F>) => void
}): React.ReactElement<unknown, 'Table'> => {
    const [showFilters, setShowFilters] = useState(false)
    const [expandedCells, setExpandedCells] = useState<number[]>([])
    const [selectedCells, setSelectedCells] = useState<number[]>([])
    const [psf, localDispatch] = useReducer<PSFReducer<F>>(psfReducer, {
        page: 1,
        perPage: 10,
    } as any)

    const toggleExpandedCell = setToggledInArray(setExpandedCells)
    const toggleSelectedCell = setToggledInArray(setSelectedCells)
    const resetExpandedCells = () => setExpandedCells(() => [])
    const resetSelectedCells = () => setSelectedCells(() => [])
    const allCellsExpanded = expandedCells.length === props.data.results.length
    const allCellsSelected = selectedCells.length === props.data.results.length
    const toggleExpandAllCells = () =>
        setToggledAllInArray(setExpandedCells)(props.data.results.length)(() =>
            range(0, props.data.results.length),
        )
    const toggleSelectAllCells = () =>
        setToggledAllInArray(setSelectedCells)(props.data.results.length)(() =>
            range(0, props.data.results.length),
        )

    const { onChange } = props

    useEffect(() => {
        onChange && onChange(psf)
    }, [onChange, psf])

    const { page, perPage, orderBy, orderDirection, ...filters } = psf

    return (
        <div className={props.className}>
            <div className='flex'>
                <ActiveFilters<T, F>
                    columns={props.columns}
                    filters={filters as any}
                    additionalFilters={props.additionalFilters ?? ({} as any)}
                    showFilters={showFilters}
                    onShowFiltersChange={setShowFilters}
                    onClearFilter={name => {
                        localDispatch({ type: 'ClearFilter', payload: name })
                    }}
                />

                <div className='ml-auto'>
                    {props.tableActions
                        ? props.tableActions({ expandedCells, selectedCells, psf })
                        : null}
                </div>
            </div>

            {/* Render Headers */}
            <Card className={classNames('mb-1', props.headerClassName)}>
                <div className='flex'>
                    {props.columns.map(column => {
                        const columnDirection = orderBy === column.id ? orderDirection : undefined
                        const toggleSortDirection = () =>
                            localDispatch({ type: 'ChangeOrder', payload: column.id as any })
                        return (
                            <div
                                key={column.id}
                                style={{
                                    width: `${column.width}%`,
                                    minWidth: `${column.width}%`,
                                    maxWidth: `${column.width}%`,
                                }}
                                className={classNames(
                                    'px-2 grow-0 flex items-center text-sm font-medium leading-4 text-atamblue-700',
                                )}
                            >
                                <div className='grow text-center'>
                                    <column.Header
                                        page={page}
                                        setPage={newPage =>
                                            localDispatch({
                                                type: 'ChangePage',
                                                payload: newPage,
                                            })
                                        }
                                        perPage={perPage}
                                        setPerPage={newPerPage =>
                                            localDispatch({
                                                type: 'ChangePerPage',
                                                payload: newPerPage,
                                            })
                                        }
                                        filters={filters as any}
                                        orderDirection={orderDirection}
                                        orderBy={orderBy}
                                        setOrderBy={orderBy =>
                                            localDispatch({
                                                type: 'ChangeOrder',
                                                payload: orderBy,
                                            })
                                        }
                                        setFilter={value =>
                                            localDispatch({
                                                type: 'ChangeFilter',
                                                payload: value,
                                            })
                                        }
                                        allCellsExpanded={allCellsExpanded}
                                        allCellsSelected={allCellsSelected}
                                        toggleExpandAllCells={toggleExpandAllCells}
                                        toggleSelectAllCells={toggleSelectAllCells}
                                    />
                                </div>
                                {column.Sort && (
                                    <column.Sort
                                        direction={columnDirection}
                                        toggleDirection={toggleSortDirection}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>

                {/* Render filters */}
                <div className='flex'>
                    {showFilters
                        ? props.columns.map(column => {
                              return (
                                  <div
                                      key={column.id}
                                      style={{
                                          width: `${column.width}%`,
                                          minWidth: `${column.width}%`,
                                          maxWidth: `${column.width}%`,
                                      }}
                                      className='px-2 grow-0 text-center'
                                  >
                                      {column.Filter && (
                                          <column.Filter
                                              filters={filters as any}
                                              setFilter={value =>
                                                  localDispatch({
                                                      type: 'ChangeFilter',
                                                      payload: value,
                                                  })
                                              }
                                          />
                                      )}
                                  </div>
                              )
                          })
                        : null}
                </div>
            </Card>

            {/* Render entries */}
            <div>
                {props.data.results.length > 0
                    ? props.data.results.map((entry, index) => {
                          const isExpanded = expandedCells.includes(index)
                          const isSelected = selectedCells.includes(index)

                          return (
                              <Card
                                  key={index}
                                  className={classNames(
                                      'my-1 flex items-stretch text-sm leading-4 text-atamblue-900',
                                      props.rowClassName &&
                                          props.rowClassName(
                                              entry,
                                              { isSelected, isExpanded },
                                              index,
                                          ),
                                  )}
                                  onClick={() => props.onRowClick && props.onRowClick(entry, index)}
                              >
                                  {props.columns.map(column => (
                                      <div
                                          key={column.id}
                                          style={{
                                              width: `${column.width}%`,
                                              minWidth: `${column.width}%`,
                                              maxWidth: `${column.width}%`,
                                          }}
                                          className={classNames('px-2 flex justify-center')}
                                      >
                                          {
                                              <column.Cell
                                                  value={entry}
                                                  isExpanded={isExpanded}
                                                  isSelected={isSelected}
                                                  toggleExpanded={toggleExpandedCell(index)}
                                                  toggleSelected={toggleSelectedCell(index)}
                                              />
                                          }
                                          {column.Sort && (
                                              <div className='invisible'>
                                                  <column.Sort
                                                      direction={'asc'}
                                                      toggleDirection={noop}
                                                  />
                                              </div>
                                          )}
                                      </div>
                                  ))}
                              </Card>
                          )
                      })
                    : props.emptyFallback
                    ? props.emptyFallback()
                    : null}
            </div>
            <Paginator
                currentPage={psf.page}
                totalPages={props.data.totalPages}
                perPage={psf.perPage}
                onJumpToPage={newPage => {
                    localDispatch({ type: 'ChangePage', payload: newPage })
                    resetExpandedCells()
                    resetSelectedCells()
                }}
                onChangePageSize={newPerPage => {
                    localDispatch({ type: 'ChangePerPage', payload: newPerPage })
                }}
            />
        </div>
    )
}
