import React from 'react'

import { AccountTypesList } from '../components/AccountTypes/ListAccountTypes'
import { ForAccountType } from '../components/Auth/ForAccountType'
import { AccountTypePermissions } from '../domain/account-type.domain'

import { Page } from './shared/Page'

export const ManageAccountTypesPage: React.FC = () => {
    return (
        <ForAccountType permission={AccountTypePermissions.AccountTypeList}>
            <Page>
                <AccountTypesList />
            </Page>
        </ForAccountType>
    )
}
