import Icon from '@mdi/react'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Select, { Styles } from 'react-select'

import { ErrorMessage } from '../ErrorMessage'

// eslint-disable-next-line @typescript-eslint/ban-types
export const styles: Partial<Styles<{}, false>> = {
    control: provided => ({
        ...provided,
        backgroundColor: 'transparent',
        border: 'none',
    }),

    container: (provided, state) => ({
        ...provided,
        borderBottom: state.isDisabled ? 'none' : 'solid 1px #96c4d7',
        lineHeight: '36px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display,
        width: '3rem',
        color: state.isFocused ? '#00a7e1' : '#96c4d7',
        '&:hover': { color: '#00a7e1' },
        textAlign: 'center',
    }),

    input: provided => ({
        ...provided,
        color: '#0072a3',
    }),

    placeholder: provided => ({
        ...provided,
        color: '#64A8C6',
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#96c4d7' : '#0072a3',
        backgroundColor: state.isFocused ? '#a6e1f3' : '#fff',
        padding: '0.75rem 0.5rem',
        lineHeight: '1.25rem',
    }),

    singleValue: provided => ({
        ...provided,
        color: '#0072a3',
    }),

    loadingIndicator: provided => ({
        ...provided,
        color: '#a6e1f3',
    }),
}

const defaultGetOptionValue = <T extends { id: string | number }>(entity: T) => `${entity.id}`

export type EntitySelectorProps<T> = {
    useResolver: any
    resolverParams?: any
    getOptionLabel: (entity: T) => string
    getOptionValue?: (entity: T) => string
    isOptionDisabled?: (option: T, selected: ReadonlyArray<T>) => boolean
    defaultValue?: T
    exclude?: T[]
    placeholder?: string
    error?: any
    className?: string
    iconClassName?: string
    icon?: string
    onChange: (newValue: T | undefined) => void
}
export const EntitySelector = <T extends { id: string | number }>(
    props: EntitySelectorProps<T>,
): React.ReactElement<any, any> | null => {
    const [searchTerm, setSearchTerm] = useState('')
    const resolverParams = props.resolverParams ?? {}
    const { isLoading, data } = props.useResolver({ ...resolverParams, name: searchTerm })

    const isOptionSelected = (option: T, selected: ReadonlyArray<T>) => {
        return selected.map(s => s.id).includes(option.id)
    }
    const isOptionDisabled = props.isOptionDisabled
        ? props.isOptionDisabled
        : (option: T) => (props.exclude ?? []).map(e => e.id).includes(option.id)

    const options = data?.results ?? []

    const getOptionValue = props.getOptionValue ?? defaultGetOptionValue

    return (
        <div className={classNames('flex items-center', props.className)}>
            {props.icon ? (
                <Icon
                    path={props.icon}
                    size={1}
                    className={classNames('mr-2 text-atamblue-300', props.iconClassName)}
                />
            ) : null}
            <Select<T>
                defaultValue={props.defaultValue}
                getOptionLabel={props.getOptionLabel}
                getOptionValue={getOptionValue}
                options={options}
                isLoading={isLoading}
                isOptionSelected={isOptionSelected}
                isOptionDisabled={isOptionDisabled}
                placeholder={props.placeholder}
                defaultOptions
                classNamePrefix='react-select'
                className='grow'
                styles={styles as any}
                onInputChange={setSearchTerm}
                onChange={selectedEntity => props.onChange(selectedEntity ?? undefined)}
            />
            {props.error ? (
                <ErrorMessage className='pt-1'>{props.error.message}</ErrorMessage>
            ) : null}
        </div>
    )
}

export const EntitySelectorField = <T extends { id: string | number }>(
    props: Omit<EntitySelectorProps<T>, 'onChange'> & { name: string },
): React.ReactElement<any, any> | null => {
    const { name, ...selectorProps } = props
    const {
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext()

    const value = getValues()[name]

    const onChange = (newValue: T | undefined) => {
        setValue(name, newValue, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    }

    return (
        <EntitySelector<T>
            {...selectorProps}
            defaultValue={value}
            error={errors[name]}
            onChange={onChange}
        />
    )
}
