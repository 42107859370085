import React from 'react'
import { Link } from 'react-router-dom'

import { UnauthenticatedLayout } from '../layouts/Unauthenticated.layout'

export const NotFoundPage: React.FC = () => {
    return (
        <UnauthenticatedLayout>
            <div>Page does not exist</div>
            <Link to='/' className='mt-3 text-atamsky-700'>
                Go to main page
            </Link>
        </UnauthenticatedLayout>
    )
}
