import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { useActivateMutation, useGetActivateInfoQuery } from '../../api/for-public.api'
import { ExternalErrors, ExternalFormError, Form, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { LoadingSpinner } from '../../atoms/LoadingSpinner'
import { routes } from '../../routes'
import { authActions } from '../../state/auth.slice'
import { useAppDispatch } from '../../store'

const ActivateAccountFormSchema = z
    .object({
        password: z.string().min(1, 'Password Required'),
        confirmPassword: z.string().min(1, 'Confirm Password Required'),
    })
    .refine(({ password, confirmPassword }) => password === confirmPassword, {
        message: 'Passwords must match',
        path: ['password'],
    })
type ActivateAccountFormType = z.TypeOf<typeof ActivateAccountFormSchema>

export const ActivateAccount: React.FC = () => {
    const navigate = useNavigate()
    const { token } = routes.ActivateAccount.useParams()
    const dispatch = useAppDispatch()
    const { data, isError: payloadNotFound } = useGetActivateInfoQuery({ payload: token })
    const [activateAccount, { isSuccess, isLoading }] = useActivateMutation()
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<ActivateAccountFormType>>(
        {},
    )

    if (!data) {
        return <div>Token not found</div>
    }

    if (payloadNotFound) {
        return <div>Token not found</div>
    }

    const onActivate = (args: ActivateAccountFormType) =>
        activateAccount({
            payload: token,
            password: args.password,
        })
            .unwrap()
            .then(({ token }) => dispatch(authActions.setToken(token)))
            .then(() => navigate(routes.Dashboard.build()))
            .catch(() => {
                setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
            })

    return (
        <Form
            schema={ActivateAccountFormSchema}
            externalErrors={externalErrors}
            onSubmit={onActivate}
        >
            <h2 className='mb-2 text-center text-3xl font-semibold'>Welcome</h2>
            <div className='text-center'>Get started with your account.</div>
            <div>Set your password and you&apos;re ready to get started.</div>

            <div className='my-8 text-lg'>
                Your login email address is: <span className='text-blue-700'>{data.email}</span>
            </div>

            <ExternalFormError />
            {isSuccess ? (
                <div>Account activated. Redirecting...</div>
            ) : (
                <div className='formFields flex flex-col w-full'>
                    <TextField
                        name='password'
                        type='password'
                        placeholder='Password'
                        inputClassName='appearance-none bg-transparent leading-tigh focus:outline-none mb-3 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                    />
                    <TextField
                        name='confirmPassword'
                        type='password'
                        placeholder='Confirm password'
                        inputClassName='appearance-none bg-transparent leading-tigh focus:outline-none mb-3 h-12 border-b border-atamblue-300 p-5 placeholder-atamblue-500'
                    />

                    <button
                        disabled={isLoading}
                        className='h-12 m-auto my-8 px-24 py-2 rounded-2xl transition duration-500 bg-atamsky-900 shadow-md text-white hover:bg-atamsky-700 focus:outline-none'
                    >
                        {isLoading ? <LoadingSpinner className='mr-2' /> : null} Get Started
                    </button>

                    <div className='text-center'>
                        By clicking the above button you agreee with our Terms of Use and Privacy
                        Policy
                    </div>
                </div>
            )}
        </Form>
    )
}
