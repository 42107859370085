import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateTemplateMutation } from '../../api/for-company/templates.api'
import { ExternalErrors, Form, FORM_ERROR, ExternalFormError } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { Subpart } from '../../domain/subject.domain'
import {
    TemplateFormType,
    SaveTemplateFormType,
    SaveTemplateFormSchema,
} from '../../domain/template.domain'

export const SaveTemplate: React.FC<{
    subparts: Subpart[]
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<TemplateFormType>>({})
    const [createTemplate, { isLoading }] = useCreateTemplateMutation()
    const { t } = useTranslation()

    const onSubmit = (template: SaveTemplateFormType) => {
        console.log('onsavetemplate', { ...template, subparts: props.subparts })

        // return createTemplate({
        //     name: template.name,
        //     subparts: {
        //         add: props.subparts.map(({ id }) => id),
        //         remove: [],
        //     },
        // })
        //     .unwrap()
        //     .then(props.onSave)
        //     .catch(error => {
        //         if (error.data?.name === 'TemplateNameExists') {
        //             setExternalErrors({ name: 'Name already exists' })
        //             return
        //         }
        //         setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
        //     })
    }

    return (
        <Form schema={SaveTemplateFormSchema} externalErrors={externalErrors} onSubmit={onSubmit}>
            <ExternalFormError />

            <TextField
                name='name'
                label={t('Template name')}
                placeholder={'Type the new template name'}
                className='col-span-2'
                inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                labelClassName='text-atamblue-700'
            />

            <div className='flex justify-center mt-10'>
                <IconButton
                    path={isLoading ? mdiLoading : mdiCheck}
                    spin={isLoading}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    {t('Apply')}
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
