import React from 'react'

import { useCreateProjectRoleMutation } from '../../api/for-company/project-roles.api'
import { ProjectRole } from '../../domain/project-role.domain'

import { ProjectRoleForm } from './ProjectRoleForm'

export const CreateProjectRole: React.FC<{ onSave: () => void; onCancel: () => void }> = props => {
    const [createProjectRole, { isLoading }] = useCreateProjectRoleMutation()

    const onSave = (projectRole: Omit<ProjectRole, 'id'>) =>
        createProjectRole(projectRole).unwrap().then(props.onSave)

    return <ProjectRoleForm isSaving={isLoading} onSave={onSave} onCancel={props.onCancel} />
}
