import { mdiLoading, mdiClose, mdiCheck } from '@mdi/js'
import React from 'react'

import { Form } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { ToggleSwitchField } from '../../atoms/Form/ToggleSwitch'
import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import { ServerError } from '../../domain/common'
import { CompanyFormSchema, CompanyFormType } from '../../domain/company.domain'
import { useTranslation } from '../../lib/i18n'

export const CompanyForm: React.FC<{
    company?: CompanyFormType
    isSaving: boolean
    error: ServerError | undefined
    onSave: (company: CompanyFormType) => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation('companies')

    return (
        <Form
            schema={CompanyFormSchema}
            defaults={props.company ?? { isActive: false }}
            onSubmit={props.onSave}
        >
            <>
                <div className='grid grid-cols-3 gap-8'>
                    <div className=''>
                        <TextField
                            name='name'
                            label={t('CompanyName')}
                            placeholder={t('CompanyNameHint')}
                            className='pr-4'
                            inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                            labelClassName='text-atamblue-700'
                        />
                    </div>
                    <div className=''>
                        <TextField
                            name='code'
                            label={t('CompanyCode')}
                            placeholder={t('CompanyCodeHint')}
                            className='pr-4'
                            inputClassName='text-atamblue-900 border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                            labelClassName='text-atamblue-700'
                        />
                    </div>
                    <div className=''>
                        <ToggleSwitchField name='isActive' className='pr-4'>
                            <div className='ml-2 text-atamblue-700'>{t('IsActive')}</div>
                        </ToggleSwitchField>
                    </div>
                </div>
                <Separator className='w-full h-1 my-10 bg-ebony-400 ' />

                <div className='flex justify-center mt-10'>
                    <IconButton
                        path={props.isSaving ? mdiLoading : mdiCheck}
                        spin={props.isSaving}
                        className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                        iconClassName='m-2 text-green-300 group-hover:text-green-500'
                        isSubmit
                    >
                        {t('Apply')}
                    </IconButton>

                    <IconButton
                        path={mdiClose}
                        className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                        iconClassName='m-2 text-red-400 group-hover:text-red-500'
                        onClick={props.onCancel}
                    >
                        {t('Cancel')}
                    </IconButton>
                </div>
            </>
        </Form>
    )
}
