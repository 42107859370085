import { mdiAirplaneCheck, mdiMinus, mdiPlus, mdiTableSync } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Project } from '../../domain/project.domain'
import { Subject } from '../../domain/subject.domain'
import { routes } from '../../routes'
import { AccordionItem } from '../Containers/Accordion'
import { Tooltip } from '../Tooltip'

export type SidebarStatus = 'collapsed' | 'expanded' | 'pinned'

const SidebarContext = React.createContext({
    opened: false,
    closed: false,
    isExpanded: false,
    isCollapsed: false,
    isPinned: true,
})

export const useSidebarContext = () => React.useContext(SidebarContext)

export const Sidebar: React.FC<{
    status: SidebarStatus
    className?: string
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
}> = props => {
    const isCollapsed = props.status === 'collapsed'
    const isExpanded = props.status === 'expanded'
    const isPinned = props.status === 'pinned'
    const opened = !isCollapsed
    const closed = isCollapsed
    return (
        <SidebarContext.Provider value={{ opened, closed, isCollapsed, isExpanded, isPinned }}>
            <div
                className={classNames('fixed h-screen overflow-hidden', props.className)}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            >
                {props.children}
            </div>
        </SidebarContext.Provider>
    )
}

export const SidebarChild: React.FC<{
    whenOpened: React.ReactNode
    whenClosed: React.ReactNode
}> = props => {
    const { opened } = useSidebarContext()
    if (opened) {
        return <>{props.whenOpened}</>
    }
    return <>{props.whenClosed}</>
}

export type SidebarItemProps = {
    active?: boolean
    className?: string
    icon: string
    iconClassName?: string
    label: string
    onClick?: () => void
}
export const SidebarItem: React.FC<SidebarItemProps> = props => {
    // const { opened } = useSidebarContext()
    return (
        <div
            className={classNames(
                'flex py-1.5 hover:bg-atamsky-200 cursor-pointer',
                {
                    'text-atamsky-900': props.active,
                },
                props.className,
            )}
            onClick={props.onClick}
        >
            {props.label.length < 27 ? (
                <>
                    <Icon
                        path={props.icon}
                        size={1}
                        className={classNames('min-h-full mr-4', props.iconClassName)}
                    />

                    <span>{props.label}</span>
                </>
            ) : (
                <Tooltip text={props.label} className='truncate'>
                    <Icon
                        path={props.icon}
                        size={1}
                        className={classNames('shrink-0 min-h-full mr-4', props.iconClassName)}
                    />
                    <span className='truncate'>{props.label}</span>
                </Tooltip>
            )}
        </div>
    )
}

export type SidebarLinkProps = {
    className?: string
    icon: string
    iconClassName?: string
    label: string
    href: string
}
export const SidebarLink: React.FC<SidebarLinkProps> = props => {
    const { href, ...itemProps } = props
    return (
        <NavLink to={href}>
            {({ isActive }) => (
                <SidebarItem
                    {...itemProps}
                    active={isActive}
                    className={classNames('px-4 ', props.className)}
                />
            )}
        </NavLink>
    )
}

export const SidebarContainer: React.FC<{
    id: string
    customIcon?: boolean
    icon: string
    label: string
    className?: string
    iconClassName?: string
    labelClassName?: string
    actionIconClassName?: string
}> = props => {
    const { opened } = useSidebarContext()
    return (
        <AccordionItem
            id={props.id}
            render={({ active, toggleActive }) => (
                <div
                    className={classNames(
                        'flex items-center cursor-pointer px-4 py-1 ',
                        props.className,
                    )}
                    onClick={toggleActive}
                >
                    {props.customIcon ? (
                        <img
                            src={props.icon}
                            className={classNames('w-6 h-6 mr-4', props.iconClassName)}
                        />
                    ) : (
                        <Icon
                            path={props.icon}
                            size={1}
                            className={classNames('min-h-full mr-4', props.iconClassName)}
                        />
                    )}
                    <div
                        className={classNames(
                            'flex justify-between items-center grow truncate leading-5 uppercase font-medium text-sm',
                            props.labelClassName,
                        )}
                    >
                        {props.label}
                        <Icon
                            path={active ? mdiMinus : mdiPlus}
                            size={0.7}
                            className={classNames(props.actionIconClassName)}
                        />
                    </div>
                </div>
            )}
        >
            {props.children}
        </AccordionItem>
    )
}

export type SidebarTabItemProps = {
    active?: boolean
    className?: string
    icon: string
    iconClassName?: string
    label: string
    onClick?: () => void
}
export const SidebarTabItem: React.FC<SidebarItemProps> = props => {
    return (
        <SidebarChild
            whenOpened={
                <div
                    className={classNames(
                        'flex py-1.5 cursor-pointer text-atamblue-300 hover:text-atamsky-900 transition-all duration-300',
                        {
                            'border-b-4 border-atamsky-900 text-atamsky-900': props.active,
                            'border-b border-atamblue-300': !props.active,
                        },

                        props.className,
                    )}
                    onClick={props.onClick}
                >
                    <Icon
                        path={props.icon}
                        size={1}
                        className={classNames('shrink-0 min-h-full mr-4', props.iconClassName)}
                    />

                    <span className={classNames('truncate', props.iconClassName)}>
                        {props.label}
                    </span>
                </div>
            }
            whenClosed={
                <div
                    className='w-6 flex py-1.5 border-b-4 border-atamsky-900 text-atamsky-900'
                    onClick={props.onClick}
                >
                    <Icon
                        path={props.icon}
                        size={1}
                        className={classNames('shrink-0 min-h-full mr-4', props.iconClassName)}
                    />
                </div>
            }
        />
    )
}
