import { mdiSort, mdiSortAscending, mdiSortDescending } from '@mdi/js'
import React from 'react'

import { IconButton } from '../IconButton'

export const SortingToggle: React.FC<{
    direction?: 'asc' | 'desc'
    onToggle: () => void
}> = props => {
    const icon =
        props.direction === 'asc'
            ? mdiSortAscending
            : props.direction === 'desc'
            ? mdiSortDescending
            : mdiSort
    const tooltip = props.direction == 'asc' ? 'Sort descending' : 'Sort ascending'
    return (
        <IconButton
            path={icon}
            size={0.75}
            tooltip={tooltip}
            className='cursor-pointer text-atamblue-300 hover:text-atamsky-900'
            onClick={props.onToggle}
        />
    )
}
