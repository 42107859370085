import { differenceWith } from 'lodash'
import React from 'react'

import { useEditUserMutation } from '../../api/for-company/users.api'
import { User, UserFormType } from '../../domain/user.domain'

import { UserForm } from './UserForm'

export const EditUser: React.FC<{
    user: User
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [editUser, { isLoading }] = useEditUserMutation()

    const onSave = (editedUser: UserFormType) => {
        const projectsToAdd = differenceWith(
            editedUser.projectAssignments,
            props.user.projectAssignments,
            (oldAssignment, newAssignment) => oldAssignment.project.id === newAssignment.project.id,
        ).map(assignment => ({
            projectId: assignment.project.id,
            projectRoleId: assignment.projectRole.id,
            entity: assignment.entity,
        }))
        const projectsToRemove = differenceWith(
            props.user.projectAssignments,
            editedUser.projectAssignments,
            (oldAssignment, newAssignment) => oldAssignment.project.id === newAssignment.project.id,
        ).map(assignment => assignment.project.id)

        return editUser({
            id: props.user.id,
            name: editedUser.name,
            code: editedUser.code,
            email: editedUser.email,
            accountTypeId: editedUser.accountType.id,
            projectAssignments: {
                add: projectsToAdd,
                remove: projectsToRemove,
            },
        })
            .unwrap()
            .then(props.onSave)
    }

    return (
        <div>
            <UserForm
                user={props.user}
                isSaving={isLoading}
                onSave={onSave}
                onCancel={props.onCancel}
            />
        </div>
    )
}
