import React from 'react'

import { UnauthenticatedLayout } from '../layouts/Unauthenticated.layout'

export const ServerErrorPage: React.FC = () => {
    return (
        <UnauthenticatedLayout>
            <div>General error. Please retry later.</div>
        </UnauthenticatedLayout>
    )
}
