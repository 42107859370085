import { mdiPencil, mdiPlusCircleOutline } from '@mdi/js'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'

import { useEditAircraftInformationBlockMutation } from '../../../api/for-company/aircraft-information.api'
import { IconButton } from '../../../atoms/IconButton'
import { Modal } from '../../../atoms/Modal/Modal'
import { Tooltip } from '../../../atoms/Tooltip'
import { isNil } from '../../../common/utils'
import {
    AircraftInformationBlock,
    AircraftInformationBlockFormType,
    AircraftInformationBlockType,
    AircraftInformationModule,
} from '../../../domain/aircraft-information.domain'
import { ProjectRolePermissions } from '../../../domain/project-role.domain'
import { Project } from '../../../domain/project.domain'
import { useTranslation } from '../../../lib/i18n'
import { ForProjectRole } from '../../Auth/ForProjectRole'

import { AircraftInformationBlockForm } from './AircraftInformationBlockForm'

export const AircraftInfoBlock: React.FC<{
    projectId: Project['id']
    readOnly: boolean
    block: AircraftInformationBlock
    module: AircraftInformationModule
    mainColor: string
    mainColorFaded: string
    onFocusText: string
    gridSeparatorColor: string
    showGridSeparator: boolean
    className?: string
}> = props => {
    const { t } = useTranslation('aircraftInformation')
    const [
        editAircraftInformationBlock,
        editAircraftInformationBlockMeta,
    ] = useEditAircraftInformationBlockMutation()

    if (!props.block) {
        return <div className={props.className}></div>
    }

    const onEdit = (editedBlock: AircraftInformationBlockFormType) => {
        return editAircraftInformationBlock({
            projectId: props.projectId,
            id: props.block.id,
            name: editedBlock.name,
            value: editedBlock.value,
        }).unwrap()
    }

    return (
        <div className={props.className}>
            {props.block.name ? (
                <>
                    <div
                        className={classNames('flex flex-col px-2 text-atamblue-900 group', {
                            [props.gridSeparatorColor]: props.showGridSeparator,
                        })}
                    >
                        <Tooltip text={props.block.name}>
                            <span className='pb-1 text-xs truncate'>{props.block.name}</span>
                        </Tooltip>
                        <div className='font-medium text-center truncate'>
                            {props.block.value ? (
                                props.block.type === AircraftInformationBlockType.Date ? (
                                    format(new Date(props.block.value), 'dd-MMM-yyyy')
                                ) : props.block.type === AircraftInformationBlockType.Number ? (
                                    `${props.block.value} ${props.block.params.unit}`
                                ) : (
                                    props.block.value
                                )
                            ) : (
                                <span className={classNames(props.mainColor)}>
                                    {t('ToBeDefined')}
                                </span>
                            )}
                        </div>
                    </div>
                    {!props.readOnly ? (
                        <ForProjectRole
                            permission={ProjectRolePermissions.AircraftInformationEditModuleBlock}
                            projectId={props.projectId}
                        >
                            <Tooltip text='Edit Data'>
                                <Modal
                                    body={({ close }) => (
                                        <AircraftInformationBlockForm
                                            projectId={props.projectId}
                                            moduleName={props.module.name}
                                            block={props.block}
                                            isSaving={editAircraftInformationBlockMeta.isLoading}
                                            onSave={editedBlock => onEdit(editedBlock).then(close)}
                                            onCancel={close}
                                        />
                                    )}
                                >
                                    <IconButton
                                        path={mdiPencil}
                                        size={0.75}
                                        className={classNames(
                                            'hidden group-hover:inline-block pt-4 pl-4 pr-4 my-auto absolute right-0 bottom-0 cursor-pointer',
                                            props.mainColor,
                                        )}
                                        iconClassName={'text-center'}
                                    ></IconButton>
                                </Modal>
                            </Tooltip>
                        </ForProjectRole>
                    ) : null}
                </>
            ) : !props.readOnly ? (
                <ForProjectRole
                    permission={ProjectRolePermissions.AircraftInformationEditModuleBlock}
                    projectId={props.projectId}
                    fallback={
                        <div
                            style={{ minHeight: '3rem' }}
                            className={classNames(
                                'w-full h-full flex justify-center items-center py-3 cursor-pointer',
                                props.mainColorFaded,
                                props.onFocusText,
                                {
                                    [props.gridSeparatorColor]: props.showGridSeparator,
                                },
                            )}
                        />
                    }
                >
                    <Modal
                        domElementClassName={'w-full'}
                        body={({ close }) => (
                            <AircraftInformationBlockForm
                                projectId={props.projectId}
                                moduleName={props.module.name}
                                block={props.block}
                                isSaving={editAircraftInformationBlockMeta.isLoading}
                                onSave={editedBlock => onEdit(editedBlock).then(close)}
                                onCancel={close}
                            />
                        )}
                    >
                        <IconButton
                            path={mdiPlusCircleOutline}
                            size={1}
                            className={classNames(
                                'w-full h-full flex justify-center items-center py-3 cursor-pointer',
                                props.mainColorFaded,
                                props.onFocusText,
                                {
                                    [props.gridSeparatorColor]: props.showGridSeparator,
                                },
                            )}
                        />
                    </Modal>
                </ForProjectRole>
            ) : (
                <div style={{ minHeight: '3rem' }} className='w-full h-full'></div>
            )}
        </div>
    )
}
