import { mdiMinusCircleOutline } from '@mdi/js'
import React, { useState, useEffect } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { IconButton } from '../../atoms/IconButton'
import { Separator } from '../../atoms/Separator'
import { ProjectRole } from '../../domain/project-role.domain'
import { Project, ProjectAssignmentEntity } from '../../domain/project.domain'
import { ProjectRoleSelector } from '../ProjectRoles/ProjectRoleSelector'
import { ProjectAssignmentEntitySelector } from '../Projects/ProjectAssignmentEntitySelector'
import { ProjectSelector } from '../Projects/ProjectSelector'

type ProjectAssignment = {
    project: Project
    projectRole: ProjectRole
    entity: ProjectAssignmentEntity
}

const isAssignmentValid = (assignment: Partial<ProjectAssignment>): boolean =>
    !!assignment.project && !!assignment.projectRole && !!assignment.entity

export const ProjectsAssignmentsField: React.FC<{ name: string }> = props => {
    const { control } = useFormContext()
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: props.name,
        keyName: 'key',
    })

    const [assignment, setAssignment] = useState<Partial<ProjectAssignment>>({})
    const setAssignmentField = (field: Partial<ProjectAssignment>) =>
        setAssignment(old => ({ ...old, ...field }))

    useEffect(() => {
        if (isAssignmentValid(assignment)) {
            append(assignment)
            setAssignment({})
        }
    }, [assignment, append])

    const assignments = (fields as unknown) as (ProjectAssignment & {
        key: string
    })[]

    return (
        <>
            Assigned projects ({fields.length})
            <div>
                {assignments.map((assignment, index) => {
                    return (
                        <div key={assignment.key} className='grid grid-cols-10 gap-4 items-center'>
                            <ProjectSelector
                                value={assignment.project}
                                exclude={assignments
                                    .filter(a => a.project.id !== assignment.project.id)
                                    .map(a => a.project)}
                                className='block col-span-3'
                                onChange={newProject => {
                                    update(index, {
                                        ...assignment,
                                        project: newProject,
                                    })
                                }}
                            />
                            <ProjectRoleSelector
                                value={assignment.projectRole}
                                className='block col-span-3'
                                onChange={newProjectRole => {
                                    update(index, {
                                        ...assignment,
                                        projectRole: newProjectRole,
                                    })
                                }}
                            />
                            <ProjectAssignmentEntitySelector
                                value={assignment.entity}
                                className='block col-span-3'
                                onChange={newEntity => {
                                    update(index, {
                                        ...assignment,
                                        entity: newEntity,
                                    })
                                }}
                            />

                            <IconButton
                                path={mdiMinusCircleOutline}
                                size={1}
                                className='text-atamblue-300'
                                onClick={() => {
                                    remove(index)
                                }}
                            />
                        </div>
                    )
                })}

                <Separator className='w-full h-1 my-5'></Separator>

                <div className='grid grid-cols-10 gap-4 items-center'>
                    <ProjectSelector
                        key={`new-assignment-project-${fields.length}`}
                        exclude={assignments.filter(a => a.project.id).map(a => a.project)}
                        className='block col-span-3'
                        onChange={project => {
                            setAssignmentField({ project })
                        }}
                    />

                    <ProjectRoleSelector
                        key={`new-assignment-project-role-${fields.length}`}
                        className='block col-span-3'
                        onChange={projectRole => {
                            setAssignmentField({ projectRole })
                        }}
                    />

                    <ProjectAssignmentEntitySelector
                        key={`new-assignment-entity-${fields.length}`}
                        className='block col-span-3'
                        onChange={entity => {
                            setAssignmentField({ entity })
                        }}
                    />
                </div>
            </div>
        </>
    )
}
