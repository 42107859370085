import classNames from 'classnames'
import React, { useCallback } from 'react'

import { BasicTransition } from './Transition'

const FlipBookContext = React.createContext<any>({})

export const FlipBookBack: React.FC<{ className?: string }> = props => {
    const { goBack } = React.useContext(FlipBookContext)
    return (
        <div
            onClick={() => {
                goBack()
            }}
            className={props.className}
        >
            {props.children}
        </div>
    )
}

export const FlipBookAnchor: React.FC<{
    page: () => React.ReactNode
    className?: string
}> = props => {
    const { render } = React.useContext(FlipBookContext)
    return (
        <div
            className={props.className}
            onClick={() => {
                render(props.page)
            }}
        >
            {props.children}
        </div>
    )
}

type FlipBookPageType = () => React.ReactNode
type FlipBookState = {
    stack: FlipBookPageType[]
    pointer: number
    direction: 'forward' | 'back' | undefined
}
type FlipBookActions =
    | { type: 'GoForward'; payload: FlipBookPageType }
    | { type: 'GoBack' }
    | { type: 'AnimationFinished' }

const flipBookReducer = (state: FlipBookState, action: FlipBookActions): FlipBookState => {
    switch (action.type) {
        case 'GoForward':
            return {
                ...state,
                stack: [...state.stack.slice(0, state.pointer + 1), action.payload],
                direction: 'forward',
            }

        case 'GoBack':
            return {
                ...state,
                direction: 'back',
            }

        case 'AnimationFinished':
            return {
                ...state,
                pointer:
                    state.direction === 'forward'
                        ? state.pointer + 1
                        : Math.max(0, state.pointer - 1),
                direction: undefined,
            }

        default:
            return state
    }
}

export const FlipBook: React.FC = props => {
    const [state, dispatch] = React.useReducer(flipBookReducer, {
        stack: [() => props.children],
        pointer: 0,
        direction: undefined,
    })

    const render = useCallback((content: FlipBookPageType) => {
        dispatch({ type: 'GoForward', payload: content })
    }, [])
    const goBack = useCallback(() => {
        dispatch({ type: 'GoBack' })
    }, [])
    const finishAnimation = useCallback(() => {
        dispatch({ type: 'AnimationFinished' })
    }, [])

    const backRender = state.pointer > 0 ? state.stack[state.pointer - 1]() : null
    const currentRender = state.stack[state.pointer]()
    const forwardRender =
        state.pointer < state.stack.length - 1 ? state.stack[state.pointer + 1]() : null

    return (
        <FlipBookContext.Provider
            value={{
                render,
                goBack,
            }}
        >
            <div className='relative'>
                <BasicTransition
                    show={state.direction !== undefined}
                    className='absolute w-full transition-all duration-300'
                    from={classNames({
                        '-translate-x-full': state.direction === 'back',
                        'translate-x-full': state.direction === 'forward',
                    })}
                    to='translate-x-0'
                    onTransitionEnd={finishAnimation}
                >
                    {state.direction === 'forward' ? forwardRender : backRender}
                </BasicTransition>
                <BasicTransition
                    show={state.direction !== undefined}
                    className='absolute w-full transition-all duration-300'
                    from='translate-x-0'
                    to={classNames({
                        'translate-x-full': state.direction === 'back',
                        '-translate-x-full': state.direction === 'forward',
                    })}
                >
                    {currentRender}
                </BasicTransition>
                {!state.direction ? currentRender : null}
                {/* Find better way to prevent MyProjects unsubscription in @rtk/query */}
                {backRender ? <div className='hidden'>{backRender}</div> : null}{' '}
            </div>
        </FlipBookContext.Provider>
    )
}
