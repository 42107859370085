import { useParams } from 'react-router-dom'
import { z } from 'zod'

import { ExtractRouteParams } from '../types/ExtractRouteParams'
import { Narrow } from '../types/Narrow'

type ZodShape<T extends string | number | symbol> = Record<T, z.ZodTypeAny>

export type RouteSchema<T, S extends z.ZodRawShape> = z.ZodObject<
    { [K in keyof S]: K extends keyof ExtractRouteParams<T> ? S[K] : never }
>

export const makeRoute = <T extends string, S extends ZodShape<keyof ExtractRouteParams<T>>>(
    path: Narrow<T>,
    schema: RouteSchema<T, S>,
) => {
    return {
        build: (params: ExtractRouteParams<T>): string =>
            (path as string)
                .split('/')
                .map(segment =>
                    segment[0] === ':'
                        ? params[segment.substring(1) as keyof ExtractRouteParams<T>]
                        : segment,
                )
                .join('/'),
        path,
        useParams: (): z.TypeOf<RouteSchema<T, S>> => {
            const params = useParams()
            return schema.parse(params)
        },
    }
}

export const stringAsPositiveNumber = () =>
    z
        .string()
        .refine(
            val => {
                const casted = Number(val)
                return !isNaN(casted) && casted > 0
            },
            {
                message: 'Value must be a positive number',
            },
        )
        .transform(val => Number(val))
