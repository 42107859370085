import React from 'react'
import Select, { Styles } from 'react-select'

import { ErrorMessage } from '../ErrorMessage'

export type SelectInputOption<T> = { value: T; label: string }

// eslint-disable-next-line @typescript-eslint/ban-types
const styles: Partial<Styles<{}, false>> = {
    control: provided => ({
        ...provided,
        backgroundColor: 'transparent',
        border: 'none',
    }),

    container: (provided, state) => ({
        ...provided,
        borderBottom: state.isDisabled ? 'none' : 'solid 1px #96c4d7',
        lineHeight: '36px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display,
        width: '3rem',
        color: state.isFocused ? '#00a7e1' : '#96c4d7',
        '&:hover': { color: '#00a7e1' },
        textAlign: 'center',
    }),

    input: provided => ({
        ...provided,
        color: '#0072a3',
    }),

    placeholder: provided => ({
        ...provided,
        color: '#64A8C6',
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#96c4d7' : '#0072a3',
        backgroundColor: state.isFocused ? '#a6e1f3' : '#fff',
    }),

    singleValue: provided => ({
        ...provided,
        color: '#0072a3',
    }),

    loadingIndicator: provided => ({
        ...provided,
        color: '#a6e1f3',
    }),
}

type SelectInputProps<T> = {
    options: SelectInputOption<T>[]
    value: T
    label?: string
    error?: string
    className?: string
    labelClassName?: string
    placeholder?: React.ReactNode
    onChange: (value: T | undefined) => void
}

export const SelectInput = <T,>(props: SelectInputProps<T>): JSX.Element => {
    const value = props.options.find(o => o.value === props.value)
    const onChange = (option: any) => {
        const selected = props.options.find(o => o.value === option.value)
        props.onChange(selected?.value)
    }

    return (
        <div className={props.className}>
            <div className={props.labelClassName}>{props.label}</div>
            <Select
                placeholder={props.placeholder}
                options={props.options}
                value={value}
                styles={styles}
                onChange={onChange}
            />
            {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : null}
        </div>
    )
}
