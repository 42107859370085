import { z } from 'zod'

import { ProjectRoleSchema } from '../../domain/project-role.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

const projectRolesApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listProjectRoles: buildQuery({
                input: z.any(),
                output: ListResponseSchema(ProjectRoleSchema),
                query: query => ({
                    url: `/project-role${serializeQueryParams(query)}`,
                    method: 'GET',
                }),
                providesTags: ['ProjectRoleList'],
            }),
            createProjectRole: buildMutation({
                input: ProjectRoleSchema.pick({ name: true, permissions: true }),
                output: ProjectRoleSchema.pick({ id: true }),
                query: body => ({
                    url: '/project-role',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['ProjectRoleList'],
            }),
            editProjectRole: buildMutation({
                input: ProjectRoleSchema.pick({ id: true }).merge(
                    ProjectRoleSchema.pick({ name: true, permissions: true }).partial(),
                ),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/project-role/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['ProjectRoleList'],
            }),
        }
    },
})

export const {
    useListProjectRolesQuery,
    useCreateProjectRoleMutation,
    useEditProjectRoleMutation,
} = projectRolesApi
