import { mdiCheck, mdiLoading, mdiAccountConvert, mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import {
    useGetProfileQuery as useGetUserProfileQuery,
    useEditProfileMutation as useEditUserProfileMutation,
} from '../../api/for-company/profile.api'
import {
    useGetProfileQuery as useGetSuperadminProfileQuery,
    useEditProfileMutation as useEditSuperadminProfileMutation,
} from '../../api/for-superadmin/profile.api'
import { ExternalErrors, ExternalFormError, Form, FORM_ERROR } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { AccountTypeFormType } from '../../domain/account-type.domain'
import { selectIsSuperadmin, selectToken } from '../../state/auth.slice'
import { useAppSelector } from '../../store'

const EditSuperadminProfileFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    email: z.string().min(1, 'Email required').email('Must be a valid email'),
})
type EditSuperadminProfileFormType = z.TypeOf<typeof EditSuperadminProfileFormSchema>

const EditUserProfileFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    email: z.string().min(1, 'Email required').email('Must be a valid email'),
})
type EditUserProfileFormType = z.TypeOf<typeof EditUserProfileFormSchema>

export const EditSuperadminProfile: React.FC<{
    onSave: () => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation('users')
    const [externalErrors, setExternalErrors] = useState<
        ExternalErrors<EditSuperadminProfileFormType>
    >({})
    const { data } = useGetSuperadminProfileQuery()
    const [editProfile, editProfileMeta] = useEditSuperadminProfileMutation()

    if (!data) {
        return null
    }

    const onSave = (newProfile: EditSuperadminProfileFormType) => {
        return editProfile({
            email: newProfile.email,
            name: newProfile.name,
        })
            .unwrap()
            .then(props.onSave)
            .catch(() => {
                setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
            })
    }

    return (
        <Form
            schema={EditSuperadminProfileFormSchema}
            defaults={{ email: data.email, name: data.name }}
            externalErrors={externalErrors}
            onSubmit={onSave}
        >
            <>
                <ExternalFormError />
                <div className='w-96 flex flex-col mt-5'>
                    <TextField
                        name='name'
                        label={t('UserName')}
                        placeholder={t('UserNameHint')}
                        className='mb-5'
                        inputClassName='border-b border-atamblue-300 font-medium text-atamblue-700 placeholder-atamblue-300 focus:outline-none'
                        labelClassName='text-atamblue-900'
                    />

                    <TextField
                        name='email'
                        label={t('EmailAddress')}
                        placeholder={t('EmailAddressHint')}
                        className='mb-5'
                        inputClassName='border-b border-atamblue-300 font-medium text-atamblue-700 placeholder-atamblue-300 focus:outline-none'
                        labelClassName='text-atamblue-900'
                    />
                </div>
                <div className='flex justify-center text-atamsky-900 mt-10'>
                    <IconButton
                        path={editProfileMeta.isLoading ? mdiLoading : mdiCheck}
                        spin={editProfileMeta.isLoading}
                        disabled={editProfileMeta.isLoading}
                        size={1}
                        isSubmit
                        className='mr-10 group text-atamblue-700 hover:text-atamsky-900'
                        iconClassName='text-green-300 group-hover:text-green-500 mr-2'
                    >
                        {t('Save')}
                    </IconButton>
                    <IconButton
                        path={mdiClose}
                        size={1}
                        onClick={props.onCancel}
                        className='ml-10 group text-atamblue-700 hover:text-atamsky-900'
                        iconClassName='text-red-400 group-hover:text-red-500 mr-2'
                    >
                        Cancel
                    </IconButton>
                </div>
            </>
        </Form>
    )
}

export const EditUserProfile: React.FC<{ onSave: () => void; onCancel: () => void }> = props => {
    const { t } = useTranslation('users')
    const [externalErrors, setExternalErrors] = useState<ExternalErrors<EditUserProfileFormType>>(
        {},
    )
    const { data } = useGetUserProfileQuery()
    const [editProfile, editProfileMeta] = useEditUserProfileMutation()

    if (!data) {
        return null
    }

    const onSave = (newProfile: EditUserProfileFormType) =>
        editProfile({
            email: newProfile.email,
            name: newProfile.name,
        })
            .unwrap()
            .then(props.onSave)
            .catch(() => {
                setExternalErrors({ [FORM_ERROR]: 'Unknown error' })
            })

    return (
        <Form
            schema={EditUserProfileFormSchema}
            defaults={{ email: data.email, name: data.name }}
            externalErrors={externalErrors}
            onSubmit={onSave}
        >
            <>
                <ExternalFormError />
                <div className='w-96 flex flex-col mt-5'>
                    <TextField
                        name='name'
                        label={t('UserName')}
                        placeholder={t('UserNameHint')}
                        className='mb-5'
                        inputClassName='border-b border-atamblue-300 font-medium text-atamblue-700 placeholder-atamblue-300 focus:outline-none'
                        labelClassName='text-atamblue-900'
                    />

                    <TextField
                        name='email'
                        label={t('EmailAddress')}
                        placeholder={t('EmailAddressHint')}
                        className='mb-5'
                        inputClassName='border-b border-atamblue-300 font-medium text-atamblue-700 placeholder-atamblue-300 focus:outline-none'
                        labelClassName='text-atamblue-900'
                    />
                </div>
                <div className='flex justify-center text-atamsky-900 mt-10'>
                    <IconButton
                        path={editProfileMeta.isLoading ? mdiLoading : mdiCheck}
                        spin={editProfileMeta.isLoading}
                        disabled={editProfileMeta.isLoading}
                        size={1}
                        isSubmit
                        className='mr-10 group text-atamblue-700 hover:text-atamsky-900'
                        iconClassName='text-green-300 group-hover:text-green-500 mr-2'
                    >
                        {t('Save')}
                    </IconButton>
                    <IconButton
                        path={mdiClose}
                        size={1}
                        onClick={props.onCancel}
                        className='ml-10 group text-atamblue-700 hover:text-atamsky-900'
                        iconClassName='text-red-400 group-hover:text-red-500 mr-2'
                    >
                        Cancel
                    </IconButton>
                </div>
            </>
        </Form>
    )
}

export const EditProfile: React.FC<{ onSave: () => void; onCancel: () => void }> = props => {
    const isSuperadmin = useAppSelector(selectIsSuperadmin)

    return (
        <div>
            <div className='text-atamblue-700 flex flex-col items-center'>
                <Icon path={mdiAccountConvert} size={1.5} />
                <div className='mt-2 text-lg font-medium text-atamsky-900'>Edit Profile</div>
            </div>
            {isSuperadmin ? (
                <EditSuperadminProfile onSave={props.onSave} onCancel={props.onCancel} />
            ) : (
                <EditUserProfile onSave={props.onSave} onCancel={props.onCancel} />
            )}
        </div>
    )
}
