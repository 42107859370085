import React from 'react'

import { hasAccountTypePermissions, AccountTypePermissions } from '../../domain/account-type.domain'
import {
    selectCurrentCompanyId,
    selectAccountType,
    selectIsSuperadmin,
} from '../../state/auth.slice'
import { useAppSelector } from '../../store'

export const ForCompany: React.FC = props => {
    const companyId = useAppSelector(selectCurrentCompanyId)
    if (companyId) {
        return <div key={companyId}>{props.children}</div>
    } else {
        return null
    }
}

export type ForAccountTypeProps = { fallback?: React.ReactNode } & (
    | { permission: AccountTypePermissions }
    | { anyPermission: AccountTypePermissions[] }
    | { allPermissions: AccountTypePermissions[] }
)
export const ForAccountType: React.FC<ForAccountTypeProps> = props => {
    const companyId = useAppSelector(selectCurrentCompanyId)
    const accountType = useAppSelector(selectAccountType)
    const isSuperadmin = useAppSelector(selectIsSuperadmin)

    const permission = (props as any).permission as AccountTypePermissions
    const anyPermission = (props as any).anyPermission as AccountTypePermissions[]
    const allPermissions = (props as any).allPermission as AccountTypePermissions[]

    if (companyId) {
        if (
            hasAccountTypePermissions({
                isSuperadmin,
                accountType,
                permission,
                anyPermission,
                allPermissions,
            })
        ) {
            return <>{props.children}</>
        }
    }
    return props.fallback ? <>{props.fallback}</> : null
}
