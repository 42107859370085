import classNames from 'classnames'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
    useEditPASSubjectRemarkMutation,
    useExportPASSummaryHtmlMutation,
    useExportPASSummaryPdfMutation,
    useExportPASSummaryXlsxMutation,
    useListPASSummaryQuery,
} from '../../api/for-company/pas.api'
import { CircledInitial, CircledText } from '../../atoms/CircledInitial'
import { ExportHtmlButton } from '../../atoms/Export/ExportHtmlButton'
import { ExportPdfButton } from '../../atoms/Export/ExportPdfButton'
import { ExportXlsButton } from '../../atoms/Export/ExportXlsButton'
import { ProgressBar } from '../../atoms/ProgressBar'
import { DefaultCellValue } from '../../atoms/Table/DefaultCell'
import { Table, TableColumn } from '../../atoms/Table/Table'
import { PASSummaryStatus, PASSummary } from '../../domain/PAS.domain'
import { ProjectRolePermissions } from '../../domain/project-role.domain'
import { openFile } from '../../lib/open-file'
import { routes } from '../../routes'
import { ForProjectRole } from '../Auth/ForProjectRole'
import { SummaryRemarksCell } from '../RAS/SummaryRemarks'

const PASSummaryStatusView: React.FC<{ status: PASSummaryStatus; short?: boolean }> = props => {
    const classMap = {
        [PASSummaryStatus.NotStarted]: 'border-red-500 text-atamblue-700',
        [PASSummaryStatus.NotApplicable]: 'border-gray-500 text-atamblue-700',
        [PASSummaryStatus.AwaitingData]: 'border-orange-500 text-atamblue-700',
        [PASSummaryStatus.AwaitingOILData]: 'border-atamblue-500 text-atamblue-700',
        [PASSummaryStatus.UnderReview]: 'border-blue-500 text-atamblue-700',
        [PASSummaryStatus.Completed]: 'border-green-500 text-atamblue-700',
    }
    const shortTextMap = {
        [PASSummaryStatus.NotStarted]: 'NS',
        [PASSummaryStatus.NotApplicable]: 'NA',
        [PASSummaryStatus.AwaitingData]: 'ED',
        [PASSummaryStatus.AwaitingOILData]: 'EOD',
        [PASSummaryStatus.UnderReview]: 'UR',
        [PASSummaryStatus.Completed]: 'C',
    }

    const longTextMap = {
        [PASSummaryStatus.NotStarted]: 'Not Started',
        [PASSummaryStatus.NotApplicable]: 'Not Applicable',
        [PASSummaryStatus.AwaitingData]: 'Awaiting Data',
        [PASSummaryStatus.AwaitingOILData]: 'Awaiting OIL Data',
        [PASSummaryStatus.UnderReview]: 'Under Review',
        [PASSummaryStatus.Completed]: 'Completed',
    }
    const short = props.short ?? false

    return short ? (
        <CircledInitial
            content={shortTextMap[props.status]}
            tooltip={longTextMap[props.status]}
            className={classNames('tex-center', classMap[props.status])}
        />
    ) : (
        <CircledText className={classNames('', classMap[props.status])}>
            <span className='truncate'>{longTextMap[props.status]}</span>
        </CircledText>
    )
}

const ListSummaryPASColumns = ({
    projectId,
}: {
    projectId: number
}): TableColumn<PASSummary, void>[] => [
    {
        id: 'reviewSubjects',
        Header: () => <div className='grow py-2 text-left'>Review Subjects</div>,
        Cell: ({ value }) => (
            <DefaultCellValue align='left'>
                <Link
                    to={routes.PAS.build({
                        projectId: projectId,
                        subjectId: value.id,
                    })}
                >
                    {value.name}
                </Link>
            </DefaultCellValue>
        ),
        width: 35,
    },
    {
        id: 'status',
        Header: () => <div className='grow py-2'>Status</div>,
        Cell: ({ value }) => {
            return <PASSummaryStatusView status={value.status} />
        },
        width: 17,
    },
    {
        id: 'poc',
        Header: () => <div className='py-2 text-left'>Percentage Completed</div>,
        Cell: ({ value }) => {
            if (value.status === PASSummaryStatus.NotApplicable) {
                return <div className='flex justify-center items-center'>-</div>
            }
            return (
                <DefaultCellValue>
                    <ProgressBar
                        progress={Math.round(value.poc)}
                        barHeight='0.75rem'
                        barProgressBg='#33c2ff'
                    />
                </DefaultCellValue>
            )
        },
        width: 18,
    },
    {
        id: 'summaryRemarks',
        Header: () => <div className='py-2'>Remarks</div>,
        Cell: ({ value }) => {
            const [editRemark, editRemarkMeta] = useEditPASSubjectRemarkMutation()
            return (
                <DefaultCellValue align='left'>
                    <SummaryRemarksCell
                        reviewSubject={value.name}
                        value={value.remark}
                        isLoading={editRemarkMeta.isLoading}
                        onChange={remark => editRemark({ projectId, subjectId: value.id, remark })}
                    />
                </DefaultCellValue>
            )
        },
        width: 30,
    },
]

export const ListSummaryPAS: React.FC<{ projectId: number }> = props => {
    const { projectId } = props
    const { data } = useListPASSummaryQuery({ projectId })
    const columns = useMemo(() => ListSummaryPASColumns({ projectId }), [projectId])
    const [exportXlsx, exportXlsxMeta] = useExportPASSummaryXlsxMutation()
    const [exportPdf, exportPdfMeta] = useExportPASSummaryPdfMutation()
    const [exportHtml, exportHtmlMeta] = useExportPASSummaryHtmlMutation()

    if (!data) {
        return null
    }

    return (
        <Table
            headerClassName='p-3'
            rowClassName={() => 'px-3 py-0.5'}
            columns={columns}
            data={data}
            tableActions={() => (
                <div className='flex items-center ml-12 mb-2'>
                    <ForProjectRole
                        permission={ProjectRolePermissions.PASExportXls}
                        projectId={projectId}
                    >
                        <ExportXlsButton
                            isLoading={exportXlsxMeta.isLoading}
                            onClick={() =>
                                exportXlsx({ projectId })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                                    .catch(noop)
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.PASExportPdf}
                        projectId={projectId}
                    >
                        <ExportPdfButton
                            isLoading={exportPdfMeta.isLoading}
                            onClick={() =>
                                exportPdf({ projectId })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                                    .catch(noop)
                            }
                        />
                    </ForProjectRole>
                    <ForProjectRole
                        permission={ProjectRolePermissions.PASExportStatic}
                        projectId={projectId}
                    >
                        <ExportHtmlButton
                            isLoading={exportHtmlMeta.isLoading}
                            onClick={() =>
                                exportHtml({ projectId })
                                    .unwrap()
                                    .then(({ path }) => openFile(path))
                                    .catch(noop)
                            }
                        />
                    </ForProjectRole>
                </div>
            )}
        ></Table>
    )
}
