import React from 'react'

import { useCreateAccountTypeMutation } from '../../api/for-company/account-types.api'
import { AccountType } from '../../domain/account-type.domain'

import { AccountTypeForm } from './AccountTypeForm'

export const CreateAccountType: React.FC<{ onSave: () => void; onCancel: () => void }> = props => {
    const [createAccountType, { isLoading }] = useCreateAccountTypeMutation()

    const onSave = (accountType: Omit<AccountType, 'id'>) =>
        createAccountType(accountType).unwrap().then(props.onSave)

    return <AccountTypeForm isSaving={isLoading} onSave={onSave} onCancel={props.onCancel} />
}
