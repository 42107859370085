import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Project } from '../domain/project.domain'
import { RootState } from '../store'

export type SidebarSlice = {
    status: 'expanded' | 'collapsed' | 'pinned'
    expandedProject: Project['id'] | undefined
}

export const getLSSidebarStatus = () => {
    const status = localStorage.getItem('sidebar_status') ?? ''
    return ['expanded', 'collapsed', 'pinned'].includes(status)
        ? (status as SidebarSlice['status'])
        : 'collapsed'
}
export const setLSSidebarStatus = (status: SidebarSlice['status']) =>
    localStorage.setItem('sidebar_status', status)

const initialState: SidebarSlice = {
    status: getLSSidebarStatus(),
    expandedProject: undefined,
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        expandProject: (state, action: PayloadAction<Project['id']>) => {
            state.expandedProject = action.payload
        },
        mouseEnter: state => {
            if (state.status === 'collapsed') {
                state.status = 'expanded'
            }
        },
        mouseLeave: state => {
            if (state.status === 'expanded') {
                state.status = 'collapsed'
                state.expandedProject = undefined
            }
        },
        pin: state => {
            state.status = 'pinned'
        },
        unpin: state => {
            state.status = 'expanded'
        },
        togglePinned: state => {
            if (state.status === 'pinned') {
                state.status = 'expanded'
            } else {
                state.status = 'pinned'
            }
        },
    },
})

export const sidebarActions = sidebarSlice.actions

/** Selectors */
const getSidebarState = (state: RootState) => state.sidebar

export const sidebarStatusSelector = createSelector(getSidebarState, sidebar => {
    const { status } = sidebar
    const isExpanded = status === 'expanded'
    const isCollapsed = status === 'collapsed'
    const isPinned = status === 'pinned'
    return {
        isExpanded,
        isCollapsed,
        isPinned,
        status,
    }
})

export const expandedProjectSelector = createSelector(getSidebarState, sidebar => {
    return sidebar.expandedProject
})
