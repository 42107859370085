import { z } from 'zod'

import { TemplateSchema } from '../../domain/template.domain'
import { forCompanyApi } from '../_base.api'
import { emptyContent, ListResponseSchema, makeBuilders, serializeQueryParams } from '../common'

export const TemplateMutationSchema = TemplateSchema.pick({ name: true }).merge(
    z.object({
        subparts: z.object({
            add: z.array(z.number()),
            remove: z.array(z.number()),
        }),
    }),
)

export const templatesApi = forCompanyApi.injectEndpoints({
    endpoints: builder => {
        const { buildQuery, buildMutation } = makeBuilders(builder)
        return {
            listTemplates: buildQuery({
                input: z.any(),
                output: ListResponseSchema(TemplateSchema),
                query: query => ({
                    url: `/template${serializeQueryParams(query)}`,
                    method: 'GET',
                }),
                providesTags: ['TemplatesList'],
            }),
            createTemplate: buildMutation({
                input: TemplateMutationSchema,
                output: TemplateSchema.pick({ id: true }),
                query: body => ({
                    url: '/template',
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['TemplatesList'],
            }),
            editTemplate: buildMutation({
                input: TemplateSchema.pick({ id: true }).merge(TemplateMutationSchema.partial()),
                output: emptyContent(),
                query: ({ id, ...body }) => ({
                    url: `/template/${id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['TemplatesList'],
            }),
            deleteTemplate: buildMutation({
                input: TemplateSchema.pick({ id: true }),
                output: emptyContent(),
                query: ({ id }) => ({
                    url: `/template/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['TemplatesList'],
            }),
        }
    },
})

export const {
    useListTemplatesQuery,
    useCreateTemplateMutation,
    useEditTemplateMutation,
    useDeleteTemplateMutation,
} = templatesApi
