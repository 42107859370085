import React, { useState } from 'react'

import { useListCompaniesQuery } from '../../api/for-superadmin/company.api'
import { DefaultCellValue } from '../../atoms/Table/DefaultCell'
import { DefaultHeader } from '../../atoms/Table/DefaultHeader'
import { Table, TableColumn } from '../../atoms/Table/Table'
import { Company } from '../../domain/company.domain'

import { CompanyMenu, CompanyHeaderMenu } from './CompanyMenus'

// eslint-disable-next-line @typescript-eslint/ban-types
const CompanyColumns: TableColumn<Company, {}>[] = [
    {
        id: 'menu',
        Header: () => <CompanyHeaderMenu />,
        Cell: ({ value }) => <CompanyMenu company={value} />,
        width: 3,
    },
    {
        id: 'name',
        Header: () => <DefaultHeader>Company name</DefaultHeader>,
        Cell: ({ value }) => <DefaultCellValue>{value.name}</DefaultCellValue>,
        width: 30,
    },
    {
        id: 'code',
        Header: () => <DefaultHeader>Company Code</DefaultHeader>,
        Cell: ({ value }) => <DefaultCellValue>{value.code}</DefaultCellValue>,
        width: 20,
    },
    {
        id: 'isActive',
        Header: () => <DefaultHeader>Is Active</DefaultHeader>,
        Cell: ({ value }) => <DefaultCellValue>{value.isActive ? 'Yes' : 'No'}</DefaultCellValue>,
        width: 15,
    },
    {
        id: 'id',
        Header: () => <DefaultHeader>ID</DefaultHeader>,
        Cell: ({ value }) => <DefaultCellValue>{value.id}</DefaultCellValue>,
        width: 30,
    },
]

export const CompaniesListContainer: React.FC = () => {
    const [args, setArgs] = useState({})
    const { data } = useListCompaniesQuery(args)

    if (!data) {
        return null
    }
    return (
        <Table
            headerClassName='p-3'
            rowClassName={() => 'px-3 py-4'}
            columns={CompanyColumns}
            data={data}
            onChange={setArgs}
        />
    )
}
