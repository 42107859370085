import React, { ReactNode } from 'react'
import { Control, Path, PathValue, useWatch } from 'react-hook-form'

export type FormWatcherProps<T> =
    | {
          control: Control<T>
          name: Path<T> | string
          children: (data: PathValue<T, Path<T>>) => ReactNode
      }
    | {
          name: string
          children: (data: any) => ReactNode
      }

export const FormWatcher = <T,>(props: FormWatcherProps<T>) => {
    const p = props as any
    const data = useWatch({ control: p.control, name: p.name })
    return <>{props.children(data)}</>
}
