import classNames from 'classnames'
import React from 'react'

import atamLogoLarge from '../../../assets/logo_lg_tag.svg'
import atamLogoSmall from '../../../assets/logo_sm.svg'
import { useSidebarContext } from '../../../atoms/Sidebar/Sidebar'

export const Logo: React.FC = () => {
    const { isCollapsed } = useSidebarContext()

    return (
        <div className={classNames({ grow: !isCollapsed }, 'top-0 h-16 p-2 z-50')}>
            <img
                // TODO: cache logos
                src={isCollapsed ? atamLogoSmall : atamLogoLarge}
                style={{
                    maxWidth: isCollapsed ? '2.5rem' : '13.75rem',
                    minHeight: isCollapsed ? '2.625rem' : '3.15rem',
                    height: '2.625rem',
                }}
            />
        </div>
    )
}
