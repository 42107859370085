import React from 'react'
import DatePicker from 'react-date-picker/dist/entry.nostyle'
import { useController } from 'react-hook-form'

import { ErrorMessage } from '../ErrorMessage'

import './DateInput.scss'

export type DateInputProps = {
    value?: Date
    name?: string
    disabled?: boolean
    label?: string
    minDate?: Date
    className?: string
    icon?: React.ReactElement
    labelClassName?: string
    error?: { message?: string }
    onChange: (newValue: Date) => void
}
// export const DateInput: React.FC<DateInputProps> = props => {
export const DateInput: React.FC<DateInputProps> = props => {
    const { className, disabled, error, label, labelClassName, icon, minDate, value, onChange } =
        props

    const divProps = { name: props.name } as any

    return (
        <div {...divProps}>
            {label ? <div className={labelClassName}>{label}</div> : null}
            <div className={className}>
                {icon ?? null}
                <DatePicker
                    format='dd-MM-y'
                    dayPlaceholder='dd'
                    monthPlaceholder='mm'
                    yearPlaceholder='yyyy'
                    value={value ? (value instanceof Date ? value : new Date(value)) : undefined}
                    minDate={minDate}
                    calendarIcon={null}
                    clearIcon={null}
                    disabled={disabled}
                    onChange={newValue => {
                        if (!Array.isArray(newValue)) {
                            onChange(newValue)
                        }
                    }}
                    showFixedNumberOfWeeks={true}
                />
            </div>
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
        </div>
    )
}

export type DateFieldProps = Omit<DateInputProps, 'value' | 'onChange'> & { name: string }
export const DateField: React.FC<DateFieldProps> = props => {
    const { name, ...dateInputProps } = props
    const { field, fieldState } = useController({ name })

    return (
        <DateInput
            {...dateInputProps}
            value={field.value}
            name={props.name}
            error={fieldState.error}
            onChange={field.onChange}
        />
    )
}
