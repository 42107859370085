import React from 'react'

import { ForAccountType } from '../components/Auth/ForAccountType'
import { ListSubjects } from '../components/Subjects/ListSubjects'
import { AccountTypePermissions } from '../domain/account-type.domain'

import { Page } from './shared/Page'

export const ManageSubjectsPage: React.FC = () => {
    return (
        <ForAccountType permission={AccountTypePermissions.SubjectList}>
            <Page>
                <ListSubjects />
            </Page>
        </ForAccountType>
    )
}
