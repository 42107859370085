import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

export const Pill: React.FC<{ className?: string; onClose?: () => void }> = props => {
    return (
        <div
            className={classNames(
                'flex justify-between items-center rounded-xl border border-atamsky-300 p-2 shadow-md',
                props.className,
            )}
        >
            {props.children}
            <div className='cursor-pointer ml-2 hover:text-atamsky-900' onClick={props.onClose}>
                <Icon path={mdiClose} size={0.5} />
            </div>
        </div>
    )
}
