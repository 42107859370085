import { mdiClose } from '@mdi/js'
import classNames from 'classnames'
import React from 'react'

import { Card } from '../../atoms/Card'
import { IconButton } from '../../atoms/IconButton'
import { BaseModal } from '../../atoms/Modal/BaseModal'
import { PASStatus } from '../../domain/PAS.domain'

import { PASStatusView } from './PASStatusView'

const statuses = [
    PASStatus.NotStarted,
    // PASStatus.Pending,
    PASStatus.AwaitingData,
    PASStatus.AwaitingOILData,
    PASStatus.UnderReview,
    PASStatus.Completed,
    PASStatus.NotApplicable,
]

export const PASStatusSelector: React.FC<{
    value: PASStatus
    short?: boolean
    className?: string
    onChange: (newStatus: PASStatus) => void
}> = props => {
    return (
        <BaseModal
            body={({ close }) => (
                <Card className='px-4 py-2 border border-atamsky-300'>
                    <div className='flex items-center justify-around'>
                        {statuses.map(status => (
                            <PASStatusView
                                key={status}
                                status={status}
                                short
                                className='mx-1 cursor-pointer'
                                onClick={() => {
                                    props.onChange(status)
                                    close()
                                }}
                            />
                        ))}
                        <IconButton
                            path={mdiClose}
                            size={0.75}
                            className='ml-3 text-red-500'
                            onClick={close}
                        />
                    </div>
                </Card>
            )}
            render={({ open }) => (
                <PASStatusView
                    status={props.value}
                    short={props.short}
                    className={classNames('cursor-pointer', props.className)}
                    onClick={open}
                />
            )}
        />
    )
}
