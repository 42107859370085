import { get } from 'lodash'
import { z } from 'zod'

export enum AccountTypePermissions {
    AccountTypeCreate = 'AccountTypeCreate',
    AccountTypeEdit = 'AccountTypeEdit',
    AccountTypeList = 'AccountTypeList',

    ProjectCreate = 'ProjectCreate',
    ProjectListAssigned = 'ProjectListAssigned',
    ProjectListArchived = 'ProjectListArchived',

    ProjectRoleCreate = 'ProjectRoleCreate',
    ProjectRoleEdit = 'ProjectRoleEdit',
    ProjectRoleList = 'ProjectRoleList',

    SubjectCreate = 'SubjectCreate',
    SubjectEdit = 'SubjectEdit',
    SubjectDelete = 'SubjectDelete',
    SubjectList = 'SubjectList',

    TemplateCreate = 'TemplateCreate',
    TemplateEdit = 'TemplateEdit',
    TemplateDelete = 'TemplateDelete',
    TemplateList = 'TemplateList',

    UserCreate = 'UserCreate',
    UserEdit = 'UserEdit',
    UserDelete = 'UserDelete',
    UserList = 'UserList',
    UserActivate = 'UserActivate',
    UserDeactivate = 'UserDeactivate',

    DashboardView = 'DashboardView',
    OnlineUsersView = 'OnlineUsersView',
}

export type HasAccountTypePermissionsArgs = {
    isSuperadmin: boolean
    accountType: Omit<AccountType, 'id'>
} & (
    | {
          permission: AccountTypePermissions
      }
    | {
          anyPermission: AccountTypePermissions[]
      }
    | {
          allPermissions: AccountTypePermissions[]
      }
)
export const hasAccountTypePermissions = (args: HasAccountTypePermissionsArgs): boolean => {
    const permission = get(args, 'permission', undefined) as AccountTypePermissions | undefined
    const anyPermission = get(args, 'anyPermission', undefined) as
        | AccountTypePermissions[]
        | undefined
    const allPermissions = get(args, 'allPermissions', undefined) as
        | AccountTypePermissions[]
        | undefined

    if (args.isSuperadmin) {
        return true
    }
    if (permission && args.accountType.permissions.includes(permission)) {
        return true
    }
    if (anyPermission && anyPermission.some(p => args.accountType.permissions.includes(p))) {
        return true
    }
    if (allPermissions && allPermissions.every(p => args.accountType.permissions.includes(p))) {
        return true
    }
    return false
}

/** Schemas */
export const AccountTypePermissionsSchema = z.nativeEnum(AccountTypePermissions)

export const AccountTypeSchema = z.object({
    id: z.number(),
    name: z.string(),
    permissions: z.array(AccountTypePermissionsSchema),
})
export type AccountType = z.TypeOf<typeof AccountTypeSchema>

export const AccountTypeFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    permissions: z
        .array(
            z.object({
                permission: AccountTypePermissionsSchema,
            }),
        )
        .min(1, 'Must contain at least 1 permission'),
})
export type AccountTypeFormType = z.TypeOf<typeof AccountTypeFormSchema>
