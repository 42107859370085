import { z } from 'zod'

import { CategorySchema } from './subject.domain'
import { UserSchema } from './user.domain'

export enum PASStatus {
    NotStarted = 'NotStarted',
    AwaitingData = 'AwaitingData',
    AwaitingOILData = 'AwaitingOILData',
    UnderReview = 'UnderReview',
    Completed = 'Completed',
    NotApplicable = 'NotApplicable',
}

export const PASRemarkSchema = z.object({
    id: z.number(),
    content: z.string(),
    user: UserSchema.pick({ id: true, name: true, code: true }),
    createdAt: z.date(),
    isDeleted: z.boolean(),
})
export type PASRemark = z.TypeOf<typeof PASRemarkSchema>

export const PASSchema = z.object({
    id: z.number(),
    name: z.string(),
    status: z.nativeEnum(PASStatus),
    poc: z.number(),
    ewd: z.number(),
    remarks: z.array(PASRemarkSchema),
    category: CategorySchema.pick({ id: true, name: true }),
    user: z.nullable(UserSchema.pick({ id: true, name: true, code: true })),
})
export type PAS = z.TypeOf<typeof PASSchema>

export enum PASSummaryStatus {
    NotStarted = 'NotStarted',
    NotApplicable = 'NotApplicable',
    AwaitingData = 'AwaitingData',
    AwaitingOILData = 'AwaitingOILData',
    UnderReview = 'UnderReview',
    Completed = 'Completed',
}

export const PASSummarySchema = z.object({
    id: z.number(),
    name: z.string(),
    status: z.nativeEnum(PASSummaryStatus),
    poc: z.number(),
    remark: z.string(),
})
export type PASSummary = z.TypeOf<typeof PASSummarySchema>
