import React from 'react'

import { ForSuperadmin } from '../components/Auth/ForSuperadmin'
import { CompaniesListContainer } from '../components/Company/CompaniesList'

import { Page } from './shared/Page'

export const ManageCompaniesPage: React.FC = () => {
    return (
        <ForSuperadmin>
            <Page>
                <CompaniesListContainer />
            </Page>
        </ForSuperadmin>
    )
}
