import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const ProgressBar = BaseAtom<{
    progress: number
    barHeight: string
    barStrippedBg?: string
    barProgressBg: string
}>(props => (
    <div className='flex items-center w-full'>
        <div
            className={classNames('grow rounded shadow-inner bg-ebony-200')}
            style={{ height: `${props.barHeight}`, background: `${props.barStrippedBg}` }}
        >
            <div
                className={classNames('h-full rounded shadow-inner')}
                style={{ width: `${props.progress}%`, background: `${props.barProgressBg}` }}
            ></div>
        </div>
        <div className='w-16 text-center'>{props.progress}%</div>
    </div>
))
