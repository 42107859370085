import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { App } from './App'
import en from './i18n/en'
import { initializeI18N } from './lib/i18n'
import { store } from './store'

import './assets/main.css'
import 'react-toastify/dist/ReactToastify.css'

initializeI18N({ en })

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
                <ToastContainer />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)
