import { mdiLoading, mdiCheck, mdiClose } from '@mdi/js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Form } from '../../atoms/Form/Form'
import { TextField } from '../../atoms/Form/TextInput'
import { IconButton } from '../../atoms/IconButton'
import { SubpartSchema } from '../../domain/subject.domain'

export const CategoryFormSchema = z.object({
    name: z.string().min(1, 'Name required'),
    subparts: z.array(SubpartSchema.omit({ categoryId: true })),
})
export type CategoryFormType = z.TypeOf<typeof CategoryFormSchema>

export const CategoryForm: React.FC<{
    category?: CategoryFormType
    isSaving: boolean
    onConfirm: (category: CategoryFormType) => void
    onCancel: () => void
}> = props => {
    const { t } = useTranslation()

    const defaultCategory = props.category ?? {
        name: '',
        subparts: [],
    }

    return (
        <Form schema={CategoryFormSchema} defaults={defaultCategory} onSubmit={props.onConfirm}>
            <div className='w-full text-sm'>
                <TextField
                    name='name'
                    autoFocus
                    maxLength={100}
                    placeholder='Type category name'
                    inputClassName='border-b border-atamblue-300 placeholder-atamblue-300 focus:outline-none'
                    labelClassName='text-atamblue-700'
                />
            </div>
            <div className='flex justify-center mt-10'>
                <IconButton
                    path={props.isSaving ? mdiLoading : mdiCheck}
                    spin={props.isSaving}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-green-300 group-hover:text-green-500'
                    isSubmit
                >
                    Save
                </IconButton>

                <IconButton
                    path={mdiClose}
                    className='w-64 h-12 text-atamblue-700 group hover:text-atamsky-900 focus:outline-none'
                    iconClassName='m-2 text-red-400 group-hover:text-red-500'
                    onClick={props.onCancel}
                >
                    {t('common:Cancel')}
                </IconButton>
            </div>
        </Form>
    )
}
