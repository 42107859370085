import {
    mdiViewDashboard,
    mdiAirplaneTakeoff,
    mdiCog,
    mdiAccountCircle,
    mdiBadgeAccountHorizontal,
    mdiFolderNetwork,
    mdiFolderStar,
    mdiStarCircle,
    mdiDomain,
    mdiTune,
} from '@mdi/js'
import React from 'react'

import { Accordion } from '../../../atoms/Containers/Accordion'
import { Separator } from '../../../atoms/Separator'
import { FlipBook } from '../../../atoms/Sidebar/FlipBook'
import { NavSeparator } from '../../../atoms/Sidebar/NavSeparator'
import { SidebarContainer, SidebarLink } from '../../../atoms/Sidebar/Sidebar'
import { AccountTypePermissions } from '../../../domain/account-type.domain'
import { routes } from '../../../routes'
import { ForAccountType, ForCompany } from '../../Auth/ForAccountType'
import { ForSuperadmin } from '../../Auth/ForSuperadmin'
import { SelectCompany } from '../../Company/CompanySelector'

import { Logo } from './Logo'
import { ProjectsListNavigation } from './ProjectNavigation'

export const PrimaryNavigation: React.FC = () => {
    return (
        <FlipBook>
            <Logo />

            <Separator className='w-full h-4' />
            <ForCompany>
                <SidebarLink
                    icon={mdiViewDashboard}
                    label={'Dashboard'}
                    href={routes.Dashboard.build()}
                />
                <SidebarLink
                    icon={mdiAirplaneTakeoff}
                    label={'Create Project'}
                    href={routes.CreateProject.build()}
                />

                <NavSeparator />

                <ProjectsListNavigation />

                <NavSeparator />

                <ForAccountType
                    anyPermission={[
                        AccountTypePermissions.UserList,
                        AccountTypePermissions.AccountTypeList,
                        AccountTypePermissions.ProjectRoleList,
                        AccountTypePermissions.SubjectList,
                        AccountTypePermissions.TemplateList,
                    ]}
                >
                    <Accordion>
                        <SidebarContainer
                            id='settings'
                            icon={mdiCog}
                            label='Settings'
                            className='text-atamblue-300 group'
                            iconClassName='group-hover:text-atamsky-900'
                            labelClassName='group-hover:text-atamsky-900'
                            actionIconClassName='group-hover:text-atamsky-900'
                        >
                            <ForAccountType permission={AccountTypePermissions.UserList}>
                                <SidebarLink
                                    icon={mdiAccountCircle}
                                    label='Users'
                                    href={routes.Users.build()}
                                />
                            </ForAccountType>
                            <ForAccountType permission={AccountTypePermissions.AccountTypeList}>
                                <SidebarLink
                                    icon={mdiStarCircle}
                                    label='Account Types'
                                    href={routes.AccountTypes.build()}
                                />
                            </ForAccountType>
                            <ForAccountType permission={AccountTypePermissions.ProjectRoleList}>
                                <SidebarLink
                                    icon={mdiBadgeAccountHorizontal}
                                    label='Project Roles'
                                    href={routes.ProjectRoles.build()}
                                />
                            </ForAccountType>
                            <ForAccountType permission={AccountTypePermissions.SubjectList}>
                                <SidebarLink
                                    icon={mdiFolderNetwork}
                                    label='Subjects'
                                    href={routes.Subjects.build()}
                                />
                            </ForAccountType>
                            <ForAccountType permission={AccountTypePermissions.TemplateList}>
                                <SidebarLink
                                    icon={mdiFolderStar}
                                    label='Project Templates'
                                    href={routes.Templates.build()}
                                />
                            </ForAccountType>
                        </SidebarContainer>
                    </Accordion>
                </ForAccountType>
            </ForCompany>

            <ForSuperadmin>
                <NavSeparator />
                <Accordion>
                    <SidebarContainer
                        id='users'
                        icon={mdiTune}
                        label='Superadmin'
                        className='text-atamblue-300 group'
                        iconClassName='group-hover:text-atamsky-900'
                        labelClassName='group-hover:text-atamsky-900'
                        actionIconClassName='group-hover:text-atamsky-900'
                    >
                        <SidebarLink
                            icon={mdiDomain}
                            label='Companies'
                            href={routes.Companies.build()}
                        />
                        <SelectCompany />
                    </SidebarContainer>
                </Accordion>
            </ForSuperadmin>
        </FlipBook>
    )
}
