import { forCompanyApi } from '../_base.api'

export type OILStatistics = {
    closed: number
    total: number
    criticalPath: { id: number; name: string; count: number }[]
}

export type ASStatistics = {
    closed: number
    total: number
}

export type Dashboard = {
    id: number
    name: string
    auditorName: string
    auditeeName: string
    startDate: Date
    endDate: Date
    roil: OILStatistics
    poil: OILStatistics
    ras: ASStatistics
    pas: ASStatistics
}

export type DashboardResponse = {
    results: Dashboard[]
}

export const dashboardApi = forCompanyApi.injectEndpoints({
    endpoints: builder => ({
        getDashboard: builder.query<DashboardResponse, void>({
            query: () => 'statistics/dashboard',
            providesTags: ['DashboardStats'],
        }),
    }),
})

export const { useGetDashboardQuery } = dashboardApi
