import {
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiPencil,
    mdiDeleteEmpty,
    mdiFilePlus,
    mdiPuzzleCheck,
    mdiPuzzlePlusOutline,
    mdiFolderNetwork,
    mdiAirplaneSearch,
    mdiArchiveSearch,
    mdiPlusNetwork,
} from '@mdi/js'
import Icon from '@mdi/react'
import { sortBy } from 'lodash'
import React, { useState } from 'react'

import {
    useCreateCategoryMutation,
    useCreateSubpartMutation,
    useDeleteCategoryMutation,
    useDeleteSubjectMutation,
    useDeleteSubpartMutation,
    useEditCategoryMutation,
    useEditSubpartMutation,
    useListSubjectsQuery,
} from '../../api/for-company/subjects.api'
import { Card } from '../../atoms/Card'
import { EmptyResponse } from '../../atoms/EmptyResponse'
import { IconButton } from '../../atoms/IconButton'
import { ConfirmationModal } from '../../atoms/Modal/ConfirmationModal'
import { Modal } from '../../atoms/Modal/Modal'
import { Tooltip } from '../../atoms/Tooltip'
import { Paginator } from '../../common/Paginator'
import { Subject, Category, Subpart } from '../../domain/subject.domain'

import { CategoryForm } from './CategoryForm'
import { CreateSubject } from './CreateSubject'
import { EditSubject } from './EditSubject'
import { SubpartForm } from './SubpartForm'

export const RASSubpart: React.FC<{ subpart: Subpart }> = props => {
    const { subpart } = props
    const [editSubpart] = useEditSubpartMutation()
    const [deleteSubpart] = useDeleteSubpartMutation()

    return (
        <div className='flex items-center mb-1 py-2 bg-atamblue-100 rounded-xl shadow text-sm'>
            <Icon path={mdiPuzzleCheck} size={0.75} className='mx-4 text-atamblue-300' />
            <div className='grow'>{subpart.name}</div>
            <div className='flex'>
                <Modal
                    title='Edit Subpart'
                    body={({ close }) => (
                        <SubpartForm
                            subpart={subpart}
                            isSaving={false}
                            onConfirm={editedFields =>
                                editSubpart({
                                    ...editedFields,
                                    id: subpart.id,
                                }).then(close)
                            }
                            onCancel={close}
                        />
                    )}
                >
                    <Tooltip text={`Edit subpart ${subpart.name}`} className='flex items-center'>
                        <IconButton
                            path={mdiPencil}
                            size={0.75}
                            className='text-atamblue-300 hover:text-atamsky-900'
                        />
                    </Tooltip>
                </Modal>
                <ConfirmationModal
                    message={'Are you sure you want to delete this subpart?'}
                    onConfirm={() => deleteSubpart(subpart)}
                >
                    <Tooltip
                        text={`Remove subpart ${subpart.name}`}
                        className='flex items-center w-6 mr-2'
                    >
                        <IconButton
                            path={mdiDeleteEmpty}
                            size={0.75}
                            className='text-atamblue-300 hover:text-atamsky-900'
                        />
                    </Tooltip>
                </ConfirmationModal>
            </div>
        </div>
    )
}

export const RASCategory: React.FC<{ category: Category }> = props => {
    const { category } = props
    const [isExpanded, setExpanded] = useState(false)
    const [editCategory, { isLoading: isEditingCategory }] = useEditCategoryMutation()
    const [deleteCategory] = useDeleteCategoryMutation()
    const [createSubpart, { isLoading: isCreatingSubpart }] = useCreateSubpartMutation()

    return (
        <div>
            <div className='flex items-center max-w-4xl my-1 py-2 bg-atamblue-100 rounded-xl shadow text-sm'>
                <div className='w-6 mx-2'>
                    <Tooltip text={`Expand category ${category.name}`} className='justify-center'>
                        <IconButton
                            className='text-atamblue-300 hover:text-atamsky-900'
                            size={0.75}
                            path={isExpanded ? mdiDotsVertical : mdiDotsHorizontal}
                            onClick={() => setExpanded(expanded => !expanded)}
                        />
                    </Tooltip>
                </div>
                <div className='grow'>{category.name}</div>
                <div className='flex'>
                    <Modal
                        title='Edit category'
                        body={({ close }) => (
                            <CategoryForm
                                category={category}
                                isSaving={isEditingCategory}
                                onConfirm={editedFields =>
                                    editCategory({
                                        ...editedFields,
                                        id: category.id,
                                    }).then(close)
                                }
                                onCancel={close}
                            />
                        )}
                    >
                        <Tooltip
                            text={`Edit category ${category.name}`}
                            className='flex items-center w-6'
                        >
                            <IconButton
                                path={mdiPencil}
                                size={0.75}
                                className='text-atamblue-300 hover:text-atamsky-900'
                            />
                        </Tooltip>
                    </Modal>
                    <ConfirmationModal
                        message={'Are you sure you want to delete this category?'}
                        onConfirm={() => deleteCategory(category)}
                    >
                        <Tooltip
                            text={`Remove category ${category.name}`}
                            className='flex items-center w-6 mr-2'
                        >
                            <IconButton
                                path={mdiDeleteEmpty}
                                size={0.75}
                                className='text-atamblue-300 hover:text-atamsky-900'
                            />
                        </Tooltip>
                    </ConfirmationModal>
                    <Modal
                        title='Add subpart'
                        body={({ close }) => (
                            <SubpartForm
                                subpart={undefined}
                                isSaving={isCreatingSubpart}
                                onConfirm={newSubpart =>
                                    createSubpart({
                                        ...newSubpart,
                                        categoryId: category.id,
                                    })
                                        .then(close)
                                        .then(() => setExpanded(true))
                                }
                                onCancel={close}
                            />
                        )}
                    >
                        <Tooltip
                            text={`Add new subpart for category ${category.name}`}
                            className='flex items-center w-6 mr-2'
                        >
                            <IconButton
                                path={mdiPuzzlePlusOutline}
                                size={0.75}
                                className='text-atamblue-300 hover:text-atamsky-900'
                            />
                        </Tooltip>
                    </Modal>
                </div>
            </div>
            {isExpanded ? (
                <div className='flex'>
                    <div style={{ width: '35px' }}></div>
                    <div className='grow'>
                        {category.subparts.length > 0 ? (
                            sortBy(category.subparts, subpart => subpart.order).map(subpart => (
                                <RASSubpart key={subpart.id} subpart={subpart} />
                            ))
                        ) : (
                            <EmptyResponse className='mt-2'>No subparts</EmptyResponse>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

const SubjectEntry: React.FC<{ subject: Subject }> = props => {
    const { subject } = props
    const [isExpanded, setExpanded] = useState(false)
    const [deleteSubject] = useDeleteSubjectMutation()
    const [createCategory, { isLoading: isCreateCategoryPending }] = useCreateCategoryMutation()

    return (
        <div>
            <div className='flex py-2 border-b border-ebony-400 text-sm'>
                <div className='w-6 mx-2'>
                    <Tooltip text={`Expand subject ${subject.name}`} className='justify-center'>
                        <IconButton
                            size={0.75}
                            className='text-atamblue-300 hover:text-atamsky-900'
                            path={isExpanded ? mdiDotsVertical : mdiDotsHorizontal}
                            onClick={() => setExpanded(expanded => !expanded)}
                        />
                    </Tooltip>
                </div>
                <div className='grow'>{subject.name}</div>
                <div className='w-1/6 flex justify-center'>
                    {subject.isPhysical ? (
                        <div className='flex items-center'>
                            <Icon
                                path={mdiAirplaneSearch}
                                size={0.75}
                                className='text-atamblue-300 mr-2'
                            />
                            Physical
                        </div>
                    ) : (
                        <div className='flex items-center'>
                            <Icon
                                path={mdiArchiveSearch}
                                size={0.75}
                                className='text-atamblue-300 mr-2'
                            />
                            Records
                        </div>
                    )}
                </div>
                <div className='w-1/6 text-center'>{subject.categories.length}</div>
                <div className='w-1/6 text-center'>
                    {subject.categories.reduce(
                        (acc, category) => acc + category.subparts.length,
                        0,
                    )}
                </div>
                <div className='w-1/6 text-center'>{subject._count.templates}</div>
            </div>
            {isExpanded ? (
                <div className='flex'>
                    <div className='flex flex-col items-center w-6 mx-2 my-1'>
                        <Modal
                            title='Edit subject'
                            body={({ close }) => (
                                <EditSubject subject={subject} onSave={close} onCancel={close} />
                            )}
                        >
                            <Tooltip text={`Edit subject ${subject.name}`} className='h-8'>
                                <IconButton
                                    path={mdiPencil}
                                    size={0.75}
                                    className='text-atamblue-300 hover:text-atamsky-900'
                                />
                            </Tooltip>
                        </Modal>
                        <ConfirmationModal
                            message={'Are you sure you want to delete this subject?'}
                            onConfirm={() => deleteSubject(subject)}
                        >
                            <Tooltip text={`Delete subject ${subject.name}`} className='h-8'>
                                <IconButton
                                    path={mdiDeleteEmpty}
                                    size={0.75}
                                    className='text-atamblue-300 hover:text-atamsky-900'
                                />
                            </Tooltip>
                        </ConfirmationModal>
                        <Modal
                            title='Create category'
                            body={({ close }) => (
                                <CategoryForm
                                    isSaving={isCreateCategoryPending}
                                    onConfirm={newCategory =>
                                        createCategory({
                                            ...newCategory,
                                            subjectId: subject.id,
                                        }).then(close)
                                    }
                                    onCancel={close}
                                />
                            )}
                        >
                            <Tooltip
                                text={`Add new category to subject ${subject.name}`}
                                className='flex items-centers h-8'
                            >
                                <IconButton
                                    path={mdiFilePlus}
                                    size={0.75}
                                    className='text-atamblue-300 hover:text-atamsky-900'
                                />
                            </Tooltip>
                        </Modal>
                    </div>
                    <div className='w-full'>
                        {subject.categories.length > 0 ? (
                            sortBy(subject.categories, subpart => subpart.order).map(category => (
                                <RASCategory key={category.id} category={category} />
                            ))
                        ) : (
                            <EmptyResponse className='mt-2'>No categories</EmptyResponse>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export const ListSubjects: React.FC = () => {
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const { data } = useListSubjectsQuery({
        page,
        perPage,
    })

    const Columns = (
        <div className='flex items-center border-b border-atamblue-300 py-1 font-medium text-sm text-atamblue-900'>
            <div className='flex justify-center w-6 mx-2'>
                <Modal
                    title='Create new subject'
                    body={({ close }) => <CreateSubject onSave={close} onCancel={close} />}
                >
                    <Tooltip text={`Create a new subject`} className='flex items-center'>
                        <IconButton
                            path={mdiPlusNetwork}
                            size={0.75}
                            className='py-1 text-atamblue-300 hover:text-atamsky-900'
                        />
                    </Tooltip>
                </Modal>
            </div>
            <div className='grow'>Subject Name</div>
            <div className='w-1/6 text-center'>Type</div>
            <div className='w-1/6 text-center'>Categories</div>
            <div className='w-1/6 text-center'>Subparts</div>
            <div className='w-1/6 text-center'>Templates</div>
        </div>
    )

    if (!data) {
        return null
    }
    const Entries = data.results.map(subject => <SubjectEntry key={subject.id} subject={subject} />)

    return (
        <>
            <Card className='mb-1 p-3'>
                {Columns}
                {Entries}
            </Card>
            <Paginator
                currentPage={page}
                totalPages={data.totalPages}
                perPage={perPage}
                onJumpToPage={setPage}
                onChangePageSize={setPerPage}
            />
        </>
    )
}
