import { differenceWith, template } from 'lodash'
import React, { useMemo } from 'react'

import { useEditTemplateMutation } from '../../api/for-company/templates.api'
import {
    hasCategorySubpartSelected,
    hasSubjectSubpartSelected,
    Template,
    TemplateFormType,
} from '../../domain/template.domain'

import { TemplateForm } from './TemplateForm'

export const EditTemplate: React.FC<{
    template: Template
    onSave: () => void
    onCancel: () => void
}> = props => {
    const [editTemplate, { isLoading }] = useEditTemplateMutation()
    const onSave = (editedTemplate: TemplateFormType) => {
        const oldSubparts = props.template.subjects
            .flatMap(subject => subject.categories.flatMap(category => category.subparts))
            .filter((subpart: any) => subpart.isSelected)
        const newSubparts = editedTemplate.subjects
            .flatMap(subject => subject.categories.flatMap(category => category.subparts))
            .filter(subpart => subpart.isSelected)

        const subpartsToAdd = differenceWith(
            newSubparts,
            oldSubparts,
            (subpartA, subpartB) => subpartA.id === subpartB.id,
        ).map(subpart => subpart.id)

        const subpartsToRemove = differenceWith(
            oldSubparts,
            newSubparts,
            (subpartA, subpartB) => subpartA.id === subpartB.id,
        ).map(subpart => subpart.id)

        return editTemplate({
            id: props.template.id,
            name: editedTemplate.name,
            subparts: {
                add: subpartsToAdd,
                remove: subpartsToRemove,
            },
        })
            .unwrap()
            .then(props.onSave)
    }

    const template = useMemo(
        () => ({
            ...props.template,
            subjects: props.template.subjects.map(subject => ({
                ...subject,
                isExpanded: false,
                categories: subject.categories.map(category => ({
                    ...category,
                    isExpanded: hasCategorySubpartSelected(category),
                })),
            })),
        }),
        [props.template],
    )

    return (
        <TemplateForm
            template={template}
            isSaving={isLoading}
            onSave={onSave}
            onCancel={props.onCancel}
        />
    )
}
