import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

const BasicTransitionAux: React.FC<{
    from: string
    to: string
    className?: string
    onTransitionEnd?: () => void
}> = props => {
    const [started, setStarted] = useState(false)
    const { from, to } = props

    useEffect(() => {
        const timeout = setTimeout(() => {
            setStarted(true)
        }, 1)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <div
            className={classNames(props.className, {
                [from]: !started,
                [to]: started,
            })}
            onTransitionEnd={props.onTransitionEnd}
        >
            {props.children}
        </div>
    )
}

export const BasicTransition: React.FC<{
    show: boolean
    from: string
    to: string
    className?: string
    onTransitionEnd?: () => void
}> = props => {
    const { show, from, to } = props

    if (!show) {
        return null
    }
    return (
        <BasicTransitionAux
            className={props.className}
            from={from}
            to={to}
            onTransitionEnd={props.onTransitionEnd}
        >
            {props.children}
        </BasicTransitionAux>
    )
}
