import Icon from '@mdi/react'
import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const Badge = BaseAtom<{
    icon: string
    size?: number
    className?: string
    iconClassName?: string
    contentClassName?: string
}>(props => {
    const size = props.size ?? 1
    return (
        <div className={classNames('relative', props.className)}>
            <Icon path={props.icon} size={size} className={props.iconClassName} />
            <div className={classNames('absolute -top-1 -right-1', props.contentClassName)}>
                {props.children}
            </div>
        </div>
    )
})
