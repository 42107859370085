import classNames from 'classnames'
import React from 'react'

import { BaseAtom } from './_BaseAtom'

export const EmptyResponse = BaseAtom(props => (
    <div className={classNames('text-sm text-gray-500 text-center', props.className)}>
        {props.children}
    </div>
))
